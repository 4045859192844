import {
  DashboardEntity,
  WidgetAlertEntity,
  WidgetAlertSettingsEntity,
  WidgetBigNumberEntity,
  WidgetGaugeEntity,
  WidgetLineEntity,
  WidgetPieChartEntity,
  WidgetTableEntity,
} from 'app/interfaces/entity/dashboard';
import { RELOAD_WIDGET_DATA } from 'modules/monitoring/constants';
import { DashboardType } from 'modules/monitoring/enums';
import {
  WidgetAlertDto,
  WidgetAlertSettingsDto,
  WidgetBigNumberDto,
  WidgetLineDto,
  WidgetPieChartDto,
  WidgetTableDto,
} from 'modules/monitoring/store/interfaces/dto';
import { WidgetGaugeDto } from 'modules/monitoring/store/interfaces/dto/widget-gauge.dto';
import {
  alertsColumnsMapping,
  formatDashboardsName,
  getMinMaxLineWidgetValue,
  getTimeScale,
  getTimeScaleByTimeRange,
  round,
} from 'modules/monitoring/utils';

import { DashboardDto } from './interfaces/dto';

export const dashboardDtoToEntityMapper =
  (type: DashboardType | undefined = DashboardType.Client) =>
  (data: DashboardDto): DashboardEntity =>
    formatDashboardsName({ ...data, dashboardId: data?.identifier, type });

export const widgetLineDtoToEntityMapper = (data: WidgetLineDto): WidgetLineEntity => {
  const startTime = data?.startTime || data?.timestamps?.[0];
  const endTime = data?.endTime || data?.timestamps?.[data?.timestamps?.length - 1];
  const timescale = getTimeScale(data?.timescale) || getTimeScaleByTimeRange(startTime, endTime);
  const [min, max] = getMinMaxLineWidgetValue(data?.items);
  return { ...data, timescale, startTime, endTime, min, max };
};

export const widgetTableDtoToEntityMapper = (data: WidgetTableDto): WidgetTableEntity => {
  return data;
};

export const widgetBigNumberDtoToEntityMapper = (data: WidgetBigNumberDto): WidgetBigNumberEntity => {
  let items = data.items;
  if (data?.items?.length) {
    items = data.items.map((item) => {
      const value = round(item.value, item.rounding);

      return {
        ...item,
        value,
      };
    });
  }
  return { ...data, items };
};

export const widgetGaugeDtoToEntityMapper = (data: WidgetGaugeDto): WidgetGaugeEntity => {
  const getValue = (maxValue: number, minValue: number, rawValue: number) => {
    let value = rawValue;
    return value && value > maxValue
      ? 100
      : value < minValue
        ? 0
        : ((value = (value - minValue) / (maxValue - minValue)), Math.round(1e4 * value) / 100);
  };

  const minValue = Number(data.minValue);
  const maxValue = Number(data.maxValue);
  const currentValue = data.currentValue > maxValue ? maxValue : data.currentValue;

  const historyTimestamps = data?.historyTimestamps?.map((time) => (String(time).length === 10 ? time * 1000 : time));

  return {
    ...data,
    currentValue,
    historyTimestamps,
    minValue: round(data.minValue),
    maxValue: round(data.maxValue),
    peakValue: (data.peakValue && (data.peakValue > maxValue ? maxValue : data.peakValue)) || 0,
    peakDisplayValue:
      data.displayType === 0
        ? `${getValue(maxValue, minValue, data.peakValue || 0)}%`
        : `${data.peakValue?.toFixed(2)}${data.displayUnit}`,
    displayValue:
      data.displayType === 0
        ? `${getValue(maxValue, minValue, currentValue)}%`
        : `${data.currentValue.toFixed(0)} ${data.displayUnit}`,
  };
};

export const widgetPieChartDtoToEntityMapper = (data: WidgetPieChartDto): WidgetPieChartEntity => {
  return data;
};

export const widgetAlertDtoToEntityMapper = (data: WidgetAlertDto): WidgetAlertEntity => {
  return { ...data, rows: data.items, total: Math.abs(data.total) };
};

export const widgetAlertSettingsDtoToEntityMapper = (data: WidgetAlertSettingsDto): WidgetAlertSettingsEntity => {
  const columns = data?.displaySettings?.columns?.filter((column) => column.visible).map(alertsColumnsMapping) || [];
  const interval = data?.interval ? data.interval * 60 : RELOAD_WIDGET_DATA;

  return { ...data, interval, columns };
};
