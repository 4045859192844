import { DATA_GRID_PAGINATION } from 'shared/constants/data-grid-pagination';
import { DataGridName } from 'shared/enums';
import { DataGridPaginationData } from 'shared/interfaces';

import { DataGridPaginationState } from './interfaces/data-grid-pagination-state';

export const setPaginationReducer = (
  state: DataGridPaginationState,
  action: { payload: DataGridPaginationData }
): void => {
  const { gridName, paginationModel } = action.payload;

  if (!gridName || !paginationModel) {
    return;
  }

  state[gridName] = state[gridName]
    ? { ...DATA_GRID_PAGINATION, ...state[gridName], ...paginationModel }
    : { ...DATA_GRID_PAGINATION, ...paginationModel };
};

export const resetReducer = (state: DataGridPaginationState): void => {
  for (const gridName in state) {
    state[gridName as DataGridName] = DATA_GRID_PAGINATION;
  }
};
