import { Theme } from '@mui/material';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',

    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      padding: 0,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
      },
      ...TYPOGRAPHY.b2SemiBold,
      '& .MuiLink-root': {
        color: theme.custom.colors.docLink,
      },
    },
  },
}));
