import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: theme.spacing(4.5),
    maxWidth: theme.spacing(18),
    objectFit: 'contain',
  },
}));
