export const cloudService = {
  components: {
    baseDetailsForm: {
      crossCloud: {
        label: 'Cross-Cloud Routing',
        tooltipTitle: 'Enable to allow routing of traffic from one cloud to another cloud through the FIX.',
      },
    },
    ChangeConnectionConfirm: {
      cloudConnectionType: 'Cloud',
      connectionType: 'Connection Type',
      curBandwidth: 'Current Bandwidth',
      newBandwidth: 'New Bandwidth',
      bandwidthRemaining: '{{bandwidthUnit}} remaining after change',
      bandwidthValue: '{{allocated}} of {{total}}',
    },
    ChangeConnectionForm: {
      bandwidthErrorMessage: 'Insufficient Bandwidth',
      bandwidthLabel: 'Bandwidth',
    },
    ChangeConnectionWizard: {
      successRequestMessage: 'Request for adjusting bandwidth accepted.',
      steps: {
        step1: {
          title: 'Review Connection',
          subtitle: 'Review the connection for which you will make adjustments.',
          content: {
            title: 'Information',
          },
        },
        step2: {
          title: 'Define Changes',
          subtitle: 'Make connection adjustments.',
          content: {
            providerTitle: 'Connection Type',
            bandwidthTitle: 'Current Bandwidth',
          },
        },
        step3: {
          title: 'Confirm',
          subtitle: 'Review and confirm the changes to the selected connections.',
          content: {
            title: 'Information',
          },
          footer: {
            label:
              'I acknowledge this request results in downtime of services until a new connection is setup and established.',
          },
        },
      },
    },
    AwsConnectionDetailsForm: {
      labels: {
        region: 'Region',
        accountID: 'AWS Account ID',
      },
    },
    AzureConnectionDetailsForm: {
      labels: {
        region: 'Region',
      },
    },
    GcpConnectionDetailsForm: {
      labels: {
        region: 'Region',
        pairingKey: 'GCP Pairing Key',
      },
    },
    OracleConnectionDetailsForm: {
      labels: {
        region: 'Region',
        tenancy: 'Tenancy OCID',
        localPeeringGateway: 'Local Peering Gateway OCID',
      },
    },
    InterRegionConnectionDetailsForm: {
      labels: {
        targetFactionLocation: 'Target Faction Location',
      },
    },
    FixConnections: {
      providerTypeOr: 'OR',
    },
    AttachToCloudWizard: {
      successRequestMessage: 'Request for connection creation accepted.',
      steps: {
        step1: {
          title: 'Select',
        },
        step2: {
          title: 'Connection Details',
        },
        step3: {
          title: 'Confirm',
          subtitle: 'By confirming you are initiating the attachment of the below Cloud Provider to the FIX.',
          content: {
            title: 'Information',
          },
        },
      },
    },
    GetCloudConnectionConfirmDetails: {
      connectionType: 'Connection Type',
      curBandwidth: 'Current Bandwidth',
      newBandwidth: 'New Bandwidth',
      bandwidthRemaining: '{{bandwidthUnit}} remaining after change',
      bandwidthValue: '{{allocated}} of {{total}}',
    },
    GetConnectionAdditionalDetails: {
      accountId: 'AWS Account ID',
      pairingKey: 'GCP Pairing Key',
      tenancy: 'Tenancy OCID',
      localPeeringGateway: 'Local Peering Gateway OCID',
      targetFactionLocation: 'Target Faction Location',
      crossCloud: { label: 'Cross-Cloud Routing', enabled: 'Enabled', disabled: 'Disabled' },
    },
    GetConnectionBaseDetails: {
      curFactionLocation: 'Current Faction Location',
      location: 'Location',
      connectionType: 'Connection Type',
      cloudDestination: 'Cloud destination',
      region: 'Region',
    },
    GetConnectionProviderDetails: {
      accountId: 'Account ID',
      vifIds: 'VIF IDs',
      authKey: 'Express Route URIs',
      vlanAttachmentNames: 'VLAN Attachment Names',
      tenancy: 'Tenancy OCID',
      requesterGroup: 'Requester Group ID',
      targetFactionLocation: 'Target Faction Location',
    },
    CcvCapacity: {
      label: 'Capacity',
      freeOf: ' free of ',
    },
    CcvHealthStatusBadge: {
      healthy: 'Healthy',
      unhealthy: 'Error',
      label: 'Status',
    },
    CcvDetails: {
      labels: {
        location: 'Faction Location',
        iops: 'IOPS',
        bandwidth: 'Bandwidth',
      },
    },
    CloudConnection: {
      labels: {
        accountId: 'Account',
        vifIds: 'Virtual Interface IDs',
        authKey: 'Authorization key',
        resourceId: 'Resource ID',
        vlanAttachmentNames: 'VLAN Attachment Names',
        tenancy: 'Tenancy OCID',
        requesterGroup: 'Requester Group ID',
        targetFactionLocation: 'Target Faction Location',
        crossCloud: { label: 'Cross-Cloud Routing', enabled: 'Enabled', disabled: 'Disabled' },
      },
    },
    CloudConnectionManageMenu: {
      changeBandwidth: 'Edit',
      deleteConnection: 'Delete',
      manageBtn: 'Manage',
    },
    ProviderConnectionBandwidth: {
      label: 'Bandwidth',
    },
    ProviderConnectionDetails: {
      curLocation: 'Current Faction Location',
      location: 'Faction Location',
    },
    NoResultsOverlay: {
      header: 'No results found',
      description: '"{{location}}" location does not contain any CCVs & Connections',
    },
    NewCloudConnectionButton: {
      btn: 'New connection',
    },
    ProviderConnectionStatusItem: {
      label: 'Status',
    },
    ProviderConnectionToast: {
      text: {
        provisioning:
          'Connection establishment is in progress. The connection status will be changed to "Active" upon completion of the process.',
        changeRequested:
          'Connection change is in progress. The connection status will be changed to "Active" upon completion of the process.',
      },
    },
    CloudConnectionDetailsCard: {
      tabs: {
        general: { title: 'General' },
        request: { title: 'Request', tooltip: "There're no active requests." },
      },
    },
  },
  constants: {
    MAP_WIZARD_FIELD_ERROR_MESSAGE: {
      bandwidth: 'Requested bandwidth is not allowed.',
      vlanAttachmentNames: 'VLAN Attachment Names is wrong.',
    },
  },
  hooks: {
    baseField: {
      labels: {
        crossCloud: 'Cross-Cloud Routing',
      },
    },
    errors: {
      oneFieldShouldBeChanged: 'At least one field should be changed',
    },
    getBandwidthSetting: {
      label: 'Bandwidth',
      mustBeNumber: '{{bandwidth}} must be a number',
      min: 'Insufficient Bandwidth',
      max: 'Insufficient Bandwidth',
    },
    awsConnectionDetailsValidationSchema: {
      labels: {
        accountId: 'AWS Account ID',
      },
    },
    gcpConnectionDetailsValidationSchema: {
      labels: {
        pairingKey: 'GCP Pairing Key',
      },
    },
    oracleConnectionDetailsValidationSchema: {
      labels: {
        tenancy: 'Tenancy OCID',
        localPeeringGateway: 'Local Peering Gateway OCID',
      },
    },
    providerConnectionDetailsValidationSchema: {
      labels: {
        region: 'Region',
        bandwidth: 'Bandwidth',
      },
    },
    providerTypeValidationSchema: {
      labels: {
        provider: 'Provider',
        subType: 'subType',
      },
    },
    interRegionConnectionDetailsValidationSchema: {
      labels: {
        targetFactionLocation: 'Target Faction Locations',
      },
    },
  },
  page: {
    successRequestMessage: 'Request for connection deletion accepted.',
    confirmation: {
      title: 'Delete {{name}}',
      text: 'Do you really want to delete {{name}}? This process cannot be undone.',
      btn: 'Delete',
    },
  },
};
