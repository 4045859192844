import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { CloudConnectionEntity } from 'app/interfaces/entity/cloud-connection';
import { CloudConnectionsState } from 'modules/cloud-services/store/cloud-connections/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: CloudConnectionEntity) => data.identifier,
});

export const initialState: CloudConnectionsState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
