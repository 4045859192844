import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    alignItems: 'center',
  },
  headerMode: {
    boxShadow: `inset 0px -1px 0px ${theme.palette.colors.neutral.T16}`,
  },
  skipPadding: {
    padding: 0,
  },
  skipPaddingBottom: {
    paddingBottom: 0,
  },
  fullWidth: {
    width: '100%',
  },
  body: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: theme.spacing(3, 2),

    '& > div': {
      width: '100%',
    },
  },
  actions: {
    padding: theme.spacing(1.5, 2, 2, 2),
    display: 'inline-flex',
    alignSelf: 'end',
    justifyContent: 'end',

    '&:has(> .fullWidth)': {
      width: '100%',
    },

    '& .MuiButtonBase-root': {
      '&:only-of-type': {
        flex: '0 0 100%',
      },
      display: 'flex',
      flex: '0 0 50%',
    },
  },
  closeIconBtn: {
    top: theme.spacing(0.625),
    right: theme.spacing(1.375),
    position: 'absolute',

    '&.headerMode': {},
    '&.skipPadding': {
      top: theme.spacing(1.25),
      right: theme.spacing(1.25),
    },
  },
  closeIcon: {
    fill: theme.palette.modal.closeIconColor,
  },
}));
