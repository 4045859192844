import { ConnectionSubType } from 'app/enums';
import { Identifier } from 'app/interfaces';

import { AwsCloudConnectionDataDto } from './aws-cloud-connection-data.dto';
import { AzureCloudConnectionDataDto } from './azure-cloud-connection-data.dto';
import { GcpCloudConnectionDataDto } from './gcp-cloud-connection-data.dto';
import { OracleCloudConnectionDataDto } from './oracle-cloud-connection-data.dto';

export type CloudConnectionData = {
  connectionSubType?: ConnectionSubType;
  bandwidthUnits: string;
  bandwidth: number;
  providerRegion: string;
  factionLocations: Identifier[];
  crossCloud?: boolean;
} & (
  | AwsCloudConnectionDataDto
  | AzureCloudConnectionDataDto
  | GcpCloudConnectionDataDto
  | OracleCloudConnectionDataDto
);

export type FixConnectionData = {
  connectionSubType?: ConnectionSubType;
  bandwidthUnits: string;
  bandwidth: number;
  factionLocations: Identifier[];
};

export enum RequestType {
  New = 'new',
  AdjustCrossCloud = 'adjust_cross_cloud',
  AdjustBandwidth = 'adjust_bandwidth',
}
export type RequestedChanges = {
  bandwidth: number;
  crossCloud: boolean;
  requestType: RequestType;
  createdAt: string;
  ticketNumber: string;
};
