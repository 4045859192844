import { UserEntity } from 'app/interfaces/entity/user';
import { Role } from 'modules/auth/enums';
import { UserProfile } from 'oidc-client-ts';

//TODO: Update logic when MFA logic implemets users?{userId}
export const legacyProfileToUserEntityMapper = (data: UserProfile): UserEntity => {
  const roles = (data.portal_roles as string)
    ?.split(',')
    .map((role) => role.trim())
    .filter((role) => Object.values(Role).includes(role as Role));

  return {
    identifier: data.sub,
    username: data.preferred_username ?? '',
    email: data.email ?? '',
    firstName: data.given_name ?? '',
    lastName: data.family_name ?? '',
    roles: roles ?? [],
    phone: data.phone,
    timezone: data.zoneinfo,
    photoUrl: data.photo,
    idpId: data.idp_id,
    mfaEnabled: data.mfa_enabled,
    population: data.population,
  } as UserEntity;
};

export const profileToUserEntityMapper = (data: UserProfile): UserEntity => {
  return {
    identifier: data.sub,
    idpId: data.idp_id,
    mfaEnabled: data.mfa_enabled,
    population: data.population,
  } as UserEntity;
};

export const userEntityMapper = (data: UserEntity): UserEntity => {
  return {
    ...data,
  };
};
