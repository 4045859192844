export const SHARED = {
  components: {
    DataGrid: {
      columnMenuLabel: 'Filter',
      FilterManager: {
        clearBtn: 'Clear All',
      },
      FilterControls: {
        clearBtn: 'Clear',
        submitBtn: 'Apply',
      },
      ProviderRegionGridFilter: {
        noResults: 'No matched results',
      },
      Header: {
        addBtn: 'Add new {{label}}',
      },
      ListDataGridFilter: {
        allLabel: 'Select All',
      },
      NumberDataGridFilter: {
        placeholder: 'Filter number',
      },
      TextDataGridFilter: {
        placeholder: 'Enter {{label}}',
      },
      DateDataGridFilter: {
        labelFrom: 'From',
        labelTo: 'To',
      },
      DataGridFilterManager: {
        filter: 'Filter',
      },
    },
    Form: {
      FileInputField: {
        attachmentBtn: 'Attach a file',
        label: 'Add attachment',
      },
      DragAndDropFileInput: {
        label: 'Add attachment',
      },
      PasswordField: {
        tooltip: {
          hide: 'Hide',
          show: 'Show',
        },
      },
      CopyText: {
        tooltip: {
          copy: 'Copy',
          copied: 'Copied!',
        },
      },
    },
    Format: {
      DateTime: {
        errMsg: '*Wrong date or format*',
      },
    },
    PasswordHint: {
      hintTitle: 'Minimum Password Requirements',
      validations: {
        moreThanRepeatedCharacter: 'No more than {{count}} repeated character',
        uniqueCharacter: '{{count}} unique character',
        characters: '{{count}} character',
        specialCharacter: '{{count}} special character',
        number: '{{count}} number',
        uppercaseCharacter: '{{count}} UPPERCASE character',
        lowercaseCharacter: '{{count}} lowercase character',
      },
    },
    Wizard: {
      btn: {
        back: 'Back',
        cancel: 'Cancel',
        submit: 'Submit',
        continue: 'Continue',
      },
    },
    CloseUnsavedConfirmationModal: {
      title: 'Confirmation',
      confirmationType: {
        close: 'close',
        cancel: 'cancel',
      },
      successBtnTitle: 'Yes',
      cancelBtnTitle: 'No',
      text: 'You are intending to {{confirmationType}} <i>{{windowName}}</i> {{windowType}}. All specified data will be lost.<br />Do you want to proceed?',
      windowType: {
        window: 'window',
        process: 'process',
      },
    },
    Modal: {
      closeText: 'Cancel',
    },
    ConfirmationModal: {
      successBtnTitle: 'Ok',
      cancelBtnTitle: 'Cancel',
    },
  },
  constants: {
    PageTitle: {
      AdminUserManagement: `{{defaultPageTitle}} | Admin User Management`,
      TicketsList: `{{defaultPageTitle}} | Tickets List`,
      TicketDetails: `{{defaultPageTitle}} | Tickets Details`,
      AdminSSOManagement: `{{defaultPageTitle}} | SSO Management`,
      Services: `{{defaultPageTitle}} | Services`,
      ServicesDashboards: `{{defaultPageTitle}} | Monitoring Services Dashboards`,
      SitePerformance: `{{defaultPageTitle}} | Site Performance`,
    },
  },
};
