// @todo FS-8384
export enum WizardTitle {
  AttachToCloud = 'New Connection',
  EditSSO = 'Edit SSO Connection',
  SetupSSO = 'Set Up SSO Connection',
  SetupSSOGroup = 'Set Up SSO Group',
  TicketCreate = 'Create New Ticket',
  ChangeConnection = 'Change Connection',
  ApiCredentialsCreate = 'Create API Credentials',
}
