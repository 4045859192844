import { Route } from 'app/enums';
import { GuardedRoute } from 'app/interfaces/route';
import { authGuard, rolesGuard } from 'app/routing/guards';
import React from 'react';

import { Role } from '../auth/enums';

const ChangeRequestsPage = React.lazy(() => import('modules/change-requests/pages/ChangeRequestsPage'));
const TicketPage = React.lazy(() => import('modules/tickets/pages/TicketPage'));

export const changeRequestsRoutes: GuardedRoute[] = [
  {
    path: Route.ChangeRequests,
    component: ChangeRequestsPage,
    availability: [Role.User, Role.Technician],
    guards: [rolesGuard([Role.User, Role.Technician]), authGuard],
    exact: true,
  },
  {
    path: Route.ChangeRequestDetails,
    component: TicketPage,
    availability: [Role.User, Role.Technician],
    guards: [rolesGuard([Role.User, Role.Technician]), authGuard],
    exact: true,
  },
];
