import { Box, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ThemeModeSwitcher } from 'app/components/Header/ThemeModeSwitcher';
import { useRoutes } from 'app/hooks';
import { MenuItem } from 'app/interfaces';
import { sessionSelector } from 'app/store/session/selectors';
import factionHeaderLogo from 'assets/images/Faction-Logo-Light-H-RGB.svg';
import { MfaCreateModal } from 'modules/mfa/components/MfaCreateModal';
import { ChangePasswordModal } from 'modules/profile/components/ChangePasswordModal';
import { DocumentationModal } from 'modules/profile/components/DocumentationModal';
import { ProfileMenu } from 'modules/profile/components/ProfileMenu';
import { ProfileModal } from 'modules/profile/components/ProfileModal';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useStyles } from './Header.styles';
import { HeaderLogo } from './HeaderLogo';
import { MainMenu } from './MainMenu';
import { OrganizationMenu } from './OrganizationMenu';
import { SubMenu } from './SubMenu';

interface HeaderProps {
  menuItems: MenuItem[];
  subMenuItems: MenuItem[];
}

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ menuItems, subMenuItems }) => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();

  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const { isAuthRoute } = useRoutes();

  const isHeaderHidden = useMemo(() => !isAuthorized || isAuthRoute, [isAuthorized, isAuthRoute]);

  if (isHeaderHidden) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.leftSide}>
          <HeaderLogo src={theme.icons?.general?.HeaderLogo ?? factionHeaderLogo} />
          <OrganizationMenu />
          <MainMenu items={menuItems} />
        </Box>
        <Box className={classes.rightSide}>
          <ThemeModeSwitcher />
          <ProfileMenu />
        </Box>
      </Box>
      {subMenuItems?.length > 0 && (
        <Box className={classes.subHeader}>
          <SubMenu items={subMenuItems} />
        </Box>
      )}
      <ProfileModal />
      <MfaCreateModal />
      <ChangePasswordModal />
      <DocumentationModal />
    </Box>
  );
};
