import { Box, FormControl, FormGroup, TextField, Typography } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import { VERIFICATION_CODE_EXPIRATION_TIME } from 'modules/mfa/constants';
import { MfaCreateFormFields } from 'modules/mfa/interfaces';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Trans, useTranslation } from 'react-i18next';
import { FormFieldError } from 'shared/components/Form/FormFieldError';

import { useStyles } from './MfaCreateForm.styles';

interface MfaCreateFormProps {
  form: UseFormReturn<MfaCreateFormFields>;
}

export const MfaCreateForm: FC<React.PropsWithChildren<MfaCreateFormProps>> = ({ form }) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();
  const { control } = form;

  return (
    <>
      <Box className={classes.detailsContainer}>
        <Typography variant="body2" className={classes.title}>
          {t('mfa.components.MfaCreateForm.title')}
        </Typography>
        <Typography variant="body2" paddingBottom="2" className={classes.text1}>
          <Trans i18nKey="mfa.components.MfaCreateForm.text1" ns={LangNameSpace.Modules} />
        </Typography>
        <Typography variant="body2" className={classes.text2}>
          <Trans
            i18nKey="mfa.components.MfaCreateForm.text2"
            ns={LangNameSpace.Modules}
            values={{ time: VERIFICATION_CODE_EXPIRATION_TIME }}
          />
        </Typography>
      </Box>
      <form>
        <FormGroup className={classes.formGroup}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl className="form-field" size="small">
                <TextField
                  data-testid="email"
                  required
                  size="small"
                  label="Enter Email Address"
                  error={invalid}
                  {...field}
                />
                <FormFieldError error={error?.message} />
              </FormControl>
            )}
          />
        </FormGroup>
      </form>
    </>
  );
};
