import { i18n, LangNameSpace } from 'app/i18n';
import { ProfileEditFormFields } from 'modules/profile/interfaces';
import { array, object, ObjectSchema, string } from 'yup';

export const profileEditValidationSchema: ObjectSchema<ProfileEditFormFields> = object().shape({
  firstName: string()
    .required()
    .max(256)
    .label(i18n.t('profile.hooks.profileEditValidationSchema.firstName.label', { ns: LangNameSpace.Modules })),
  lastName: string()
    .required()
    .max(256)
    .label(i18n.t('profile.hooks.profileEditValidationSchema.lastName.label', { ns: LangNameSpace.Modules })),
  email: string()
    .email()
    .required()
    .label(i18n.t('profile.hooks.profileEditValidationSchema.email.label', { ns: LangNameSpace.Modules })),
  phone: string()
    .ensure()
    .max(32)
    .label(i18n.t('profile.hooks.profileEditValidationSchema.phone.label', { ns: LangNameSpace.Modules })),
  timezone: string()
    .ensure()
    .label(i18n.t('profile.hooks.profileEditValidationSchema.timezone.label', { ns: LangNameSpace.Modules })),
  contactPreferences: array().label(
    i18n.t('admin.user.hooks.userCreateFormValidationSchema.contactPreferences', { ns: LangNameSpace.Modules })
  ),
});
