import { SsoEntity } from 'app/interfaces/entity/sso.entity';
import { SsoCreateRequest, SsoUpdateRequest } from 'modules/admin/sso-management/store/sso/interfaces';
import { SsoCreateDto, SsoDto, SsoUpdateDto } from 'modules/admin/sso-management/store/sso/interfaces/dto';

export const dtoToEntityMapper = (data: SsoDto): SsoEntity => {
  return data;
};

export const createRequestToDtoMapper = (data: Omit<SsoCreateRequest, 'organizationId'>): SsoCreateDto => {
  return data;
};

export const updateRequestToDtoMapper = (data: Omit<SsoUpdateRequest, 'organizationId'>): SsoUpdateDto => {
  return data;
};
