import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { PaginationDto } from 'app/interfaces/dto';
import { TicketEntity } from 'app/interfaces/entity/ticket';
import { ApiPaginatedResponse } from 'app/interfaces/store';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { entityStoreStatus } from 'app/utils/store';
import { isEmpty } from 'lodash';
import { TicketQueryHeaders } from 'modules/tickets/enums';
import { TicketCreateRequest } from 'modules/tickets/interfaces';
import { DataGridName } from 'shared/enums';
import { dataGridFilterGetters } from 'shared/utils/data-grid/getters';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper, requestToDtoMapper } from './dto-mappers';
import { TicketsState } from './interfaces';
import { CreateTicketDto, TicketDto } from './interfaces/dto';

export const getTicketsList = createAsyncThunk(
  `${EntityStoreName.Tickets}/getList`,
  (_, { signal }): Promise<ApiPaginatedResponse<TicketEntity>> => {
    const { limit, offset } = dataGridFilterGetters.getPaginationApiParams(DataGridName.Tickets);
    const filters = dataGridFilterGetters.getFiltersApiParams(DataGridName.Tickets);
    const sort = dataGridFilterGetters.getSortApiParams(DataGridName.Tickets);

    return apiRequestCall<PaginationDto<TicketDto>>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        params: {
          ...(!isEmpty(filters) ? filters : {}),
          ...(!isEmpty(sort) && { sortField: sort?.field, sortDirection: sort?.sort }),
          limit,
          offset,
        },
        signal,
        cache: false,
      },
      returnHeaders: true,
    }).then(({ data, headers }) => {
      return {
        data: data.map(dtoToEntityMapper),
        total: parseInt(headers[TicketQueryHeaders.Total]),
      };
    });
  }
);

export const createTicket = createAsyncThunk(
  `${EntityStoreName.Tickets}/create`,
  async ({ successRequestMessage, ...payload }: TicketCreateRequest): Promise<TicketEntity> =>
    apiRequestCall<TicketDto, CreateTicketDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        data: requestToDtoMapper(payload),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Tickets]],
      successRequestMessage,
    }).then(dtoToEntityMapper)
);

export const clearAllReducer = (state: TicketsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<TicketsState>): void => {
  builder
    .addCase(getTicketsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketsList.fulfilled, (state, action) => {
      state.total = action.payload.total;
      entityAdapter.setAll(state, action.payload.data);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createTicket.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicket.fulfilled, (state, action) => {
      entityAdapter.addOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicket.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    });
};
