import { Typography } from '@mui/material';
import { UserEntity } from 'app/interfaces/entity/user';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { useStyles } from './CommonUserTitle.styles';

interface CommonUserTitleProps {
  user: Omit<UserEntity, 'identifier'>;
  className?: string;
}

export const CommonUserTitle: FC<React.PropsWithChildren<CommonUserTitleProps>> = ({ user, className }) => {
  const { classes } = useStyles();

  const { name, capitalized } = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return {
        name: `${user.firstName} ${user.lastName}`,
        capitalized: classes.capitalized,
      };
    }

    return { name: user?.username };
  }, [user?.firstName, user?.lastName, user?.username, classes.capitalized]);

  return (
    <Typography
      variant="subtitle2"
      className={classNames([classes.root, capitalized, className])}
      data-testid="userMenuTitle"
    >
      {name}
    </Typography>
  );
};
