export enum Route {
  // App
  Root = '/',
  Login = '/login',
  Logout = '/logout',
  LoginRedirect = '/login-redirect',
  LogoutRedirect = '/logout-redirect',
  NotFound = '/404',

  // Services
  Services = '/services', // root

  // Monitoring
  Monitoring = '/monitoring', // root
  ServicesDashboards = '/monitoring/services-dashboards',
  SitePerformanceOld = '/monitoring/site-performance-page',
  SitePerformance = '/monitoring/site-performance',

  // Tickets
  Tickets = '/tickets', // root
  TicketsList = '/tickets/list',
  TicketDetails = '/tickets/list/:id',

  // Change Log
  ChangeRequests = '/tickets/change-requests',
  ChangeRequestDetails = '/tickets/change-requests/:id',

  // Admin settings
  Admin = '/admin', // root
  AdminUserManagement = '/admin/user-management',
  AdminSSOManagement = '/admin/sso-management',
  AdminAPIManagement = '/admin/api-credentials',
}
