import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { DashboardEntity } from 'app/interfaces/entity/dashboard';
import { DashboardClientState, DashboardGlobalState } from 'modules/monitoring/store/interfaces/dashboard-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: DashboardEntity) => data.identifier,
});

export const initialClientState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
}) as unknown as DashboardClientState;

export const initialGlobalState: DashboardGlobalState = {
  entity: {},
  empty: null,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
};
