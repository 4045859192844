import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialClientState, initialGlobalState } from './adapter';
import {
  clearAllClientReducer,
  clearGlobalReducer,
  registerClientReducers,
  registerGlobalReducers,
} from './repository';

export const clientDashboardsState = createSlice({
  name: EntityStoreName.ClientDashboards,
  initialState: initialClientState,
  reducers: {
    clearAll: clearAllClientReducer,
  },
  extraReducers: (builder) => registerClientReducers(builder),
});

export const globalDashboardsState = createSlice({
  name: EntityStoreName.GlobalDashboards,
  initialState: initialGlobalState,
  reducers: {
    clear: clearGlobalReducer,
  },
  extraReducers: (builder) => registerGlobalReducers(builder),
});

export const dashboardsClientReducer = {
  ...clientDashboardsState.actions,
};

export const dashboardsGlobalReducer = {
  ...globalDashboardsState.actions,
};
