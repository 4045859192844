import dayjs from 'dayjs';
import mapValues from 'lodash/mapValues';
import { DataGridFilterType } from 'shared/enums';
import {
  DataGridColumnFilterData,
  DataGridDateFilterReaderData,
  DataGridDateFilterWriterData,
  ProviderRegionsFilterData,
} from 'shared/interfaces';

const dateFormatter = (filterValue: DataGridDateFilterReaderData): DataGridDateFilterWriterData | null => {
  return {
    from:
      filterValue.from && dayjs(filterValue.from).isValid()
        ? dayjs(filterValue.from).utc(true).startOf('day').toISOString()
        : '',
    to:
      filterValue.to && dayjs(filterValue.to).isValid()
        ? dayjs(filterValue.to).utc(true).endOf('day').toISOString()
        : '',
  };
};

const providerRegionFormatter = (filterValue: ProviderRegionsFilterData) => {
  return mapValues(filterValue.toObject(), (provider) => provider.toObject());
};

export const writerFormatter = (filter: DataGridColumnFilterData): DataGridColumnFilterData => {
  switch (filter.type) {
    case DataGridFilterType.Date:
      return { ...filter, value: dateFormatter(filter.value) };
    case DataGridFilterType.List:
      return { ...filter, value: filter.value.toObject() };
    case DataGridFilterType.Region:
      return { ...filter, value: providerRegionFormatter(filter.value) };
    case DataGridFilterType.String:
    case DataGridFilterType.Number:
    default:
      return { ...filter, value: filter.value };
  }
};
