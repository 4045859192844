import { FormControl, FormGroup } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import { ChangePasswordFormFields } from 'modules/profile/interfaces';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { FormFieldError } from 'shared/components/Form/FormFieldError';
import { PasswordField } from 'shared/components/Form/PasswordField';
import { PasswordHint } from 'shared/components/PasswordHint';

interface ChangePasswordFormProps {
  form: UseFormReturn<ChangePasswordFormFields>;
}

export const ChangePasswordForm: FC<React.PropsWithChildren<ChangePasswordFormProps>> = ({ form }) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { control, trigger, getValues } = form;
  const { newPassword } = getValues();

  return (
    <form>
      <FormGroup>
        <Controller
          name="currentPassword"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <PasswordField
                required
                size="small"
                label={t('profile.components.ChangePasswordForm.labels.currentPassword')}
                error={invalid}
                {...field}
                onChange={async (e) => {
                  field.onChange(e);
                  if (getValues().newPassword) {
                    await trigger('newPassword');
                  }
                }}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <PasswordField
                required
                size="small"
                label={t('profile.components.ChangePasswordForm.labels.newPassword')}
                error={invalid}
                {...field}
                onChange={async (e) => {
                  field.onChange(e);
                  if (getValues().confirmNewPassword) {
                    await trigger('confirmNewPassword');
                  }
                }}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="confirmNewPassword"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <PasswordField
                required
                size="small"
                label={t('profile.components.ChangePasswordForm.labels.confirmNewPassword')}
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <PasswordHint value={newPassword} />
      </FormGroup>
    </form>
  );
};
