import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DialogName, Route } from 'app/enums';
import { useStore } from 'app/hooks';
import { LangNameSpace } from 'app/i18n';
import { pingIdentityClient } from 'app/services/ping-identity';
import { modalSelector } from 'app/store/modal/selectors';
import { modalReducers } from 'app/store/modal/slice';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useStyles } from './ExpiredSessionDialog.styles';

export const ExpiredSessionDialog: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clearUserSession } = useStore();
  const isOpen = useSelector(modalSelector.isOpen(DialogName.ExpiredSession));
  const isInitialized = useRef(false);
  const theme = useTheme<Theme>();

  useEffect(() => {
    if (isOpen && !isInitialized.current) {
      pingIdentityClient.removeUser();
      clearUserSession(location.pathname);
      isInitialized.current = true;
    }
  }, [clearUserSession, isOpen, location.pathname]);

  return (
    <Dialog fullScreen={true} open={isOpen} className={classes.root}>
      <DialogContent sx={{ padding: 0 }} dividers={false}>
        <Box className={classes.body}>
          <img className={classes.logo} src={theme.icons.general.fullLogo} />

          <Typography variant="h1" className={classes.title}>
            {t('auth.components.ExpiredSessionDialog.title')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('auth.components.ExpiredSessionDialog.subtitle')}
          </Typography>

          <Link className={classes.link} to={Route.Login}>
            <Button onClick={() => dispatch(modalReducers.setOpenModal())} color="primary" variant="contained">
              {t('auth.components.ExpiredSessionDialog.loginBtn')}
            </Button>
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
