import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

import { Lang, LangNameSpace } from './enums';
import { LangResources } from './interfaces';
import { translations } from './locales';

export const resources: LangResources = {
  [Lang.En]: translations[Lang.En].translations,
};

export const i18n = i18next;
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: Lang.En,
    returnNull: false,
    debug: false,
    defaultNS: LangNameSpace.App,
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: { order: ['localStorage'] },
    react: {
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
      transWrapTextNodes: '',
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key) => {
      Sentry.captureException(new Error(`Missing i18n key[${key}] ns[${ns}]`));
    },
  });

let firstLoad = false;

i18n.on('languageChanged', (lng: Lang) => {
  const lang = lng in translations ? lng : Lang.En;
  setLocale(translations[lang].yupLocale);
  if (firstLoad && window) {
    window.location.reload();
  }
  firstLoad = true;
});

export default i18n;
