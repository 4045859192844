import { Route } from 'app/enums';
import { Guard } from 'app/interfaces/guard';
import { cancelable } from 'cancelable-promise';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'shared/components/Loader';

interface GuardedRouteProps {
  path: Route;
  guards?: Guard[];
}

export const GuardedRoute: FC<React.PropsWithChildren<GuardedRouteProps>> = ({ children, path, guards = [] }) => {
  const navigate = useNavigate();
  const [resolvedGuardsCount, setResolvedGuardsCount] = useState<number>(0);
  const [executedGuardsCount, setExecutedGuardsCount] = useState<number>(0);
  const hasGuards = useMemo<boolean>(() => Boolean(guards && guards.length > 0), [guards]);
  const [redirect, setRedirect] = useState<string | null>();

  const nextCallback = useCallback(() => {
    setResolvedGuardsCount((count) => count + 1);
  }, []);

  const redirectCallback = useCallback((to: string) => {
    setRedirect(to);
    setResolvedGuardsCount((count) => count + 1);
  }, []);

  const resolver = useMemo(
    () => ({ next: nextCallback, redirect: redirectCallback }),
    [nextCallback, redirectCallback]
  );

  const runAllGuards = useCallback(
    () =>
      Promise.all(
        guards.map((guard: Guard) =>
          guard(path, resolver).finally(() => {
            setExecutedGuardsCount((count) => count + 1);
          })
        )
      ),
    [resolver, setExecutedGuardsCount, path, guards]
  );

  useEffect(() => {
    const promise = cancelable(runAllGuards());

    return () => {
      promise.cancel();
    };
  }, [runAllGuards, guards?.length]);

  useEffect(() => {
    if (executedGuardsCount === guards?.length && redirect) {
      navigate(redirect, { replace: true });
    }
  }, [redirect, executedGuardsCount, navigate, guards?.length]);

  if (!hasGuards || (resolvedGuardsCount === executedGuardsCount && executedGuardsCount === guards?.length)) {
    return <>{children}</>;
  }

  return <Loader />;
};
