export class RedirectConfigModel {
  private readonly _from: string;

  private readonly _exact: boolean;

  constructor(
    from: string,
    public to: string,
    exact: boolean
  ) {
    this._from = from;
    this._exact = exact;
  }

  get from(): string {
    return `${this._from}${this._exact ? '' : '/*'}`;
  }
}
