import { RoleTypes } from 'app/enums/roles/roles-types';
import { RolesEntity } from 'app/interfaces/entity/roles';
import upperCamelCase from 'uppercamelcase';

import { RolesDto } from './interfaces/roles.dto';

export const dtoToEntityMapper = (data: RolesDto): RolesEntity[] => {
  let returnedData: RolesEntity[] = [];

  const preparedTitle = (title: string) => {
    const wordToSplit = 'Roles';
    const fistWord = upperCamelCase(title.split(wordToSplit)[0]);

    return `${fistWord} ${wordToSplit}`;
  };

  Object.entries(data).map(([key, roles]) => {
    const group = preparedTitle(key);

    if (Array.isArray(roles)) {
      roles.forEach((role) => {
        returnedData = [...returnedData, { ...role, group, subGroup: undefined, type: key as RoleTypes }];
      });
    } else {
      Object.entries(roles).forEach(([nestedKey, nestedRoles]) => {
        nestedRoles.forEach((nestedRole) => {
          returnedData = [...returnedData, { ...nestedRole, group, subGroup: nestedKey, type: key as RoleTypes }];
        });
      });
    }
  });

  return returnedData;
};
