import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-8px',
    minHeight: theme.spacing(30),
  },
  modal: {},
  modalControls: {
    display: 'flex',
    width: '100%',

    '& .MuiButtonBase-root': {
      '&:only-of-type': {
        flex: 'initial',
      },
      display: 'flex',
      flex: 'initial',
    },
  },

  modalActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(1.5),
  },
  formContainer: {
    width: '100%',
  },

  title: {
    fontWeight: FontWeight.Bold,
    marginBottom: theme.spacing(1),
  },

  detailsContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },

  actions: {
    display: 'flex',
  },
}));
