import { LangNameSpace } from 'app/i18n';
import { LangTranslation } from 'app/i18n/interfaces/lang-translation';

import { APP } from './app';
import { MODULES } from './modules';
import { SHARED } from './shared';
import { YUP_LOCALE } from './yup';

export const translations = {
  [LangNameSpace.App]: APP,
  [LangNameSpace.Shared]: SHARED,
  [LangNameSpace.Modules]: MODULES,
};

export const translationEnglish: LangTranslation = {
  translations,
  yupLocale: YUP_LOCALE,
};
