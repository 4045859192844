import { Route } from 'app/enums';
import { i18n, LangNameSpace } from 'app/i18n';
import { MenuItem } from 'app/interfaces/menu-item';
import { Role } from 'modules/auth/enums';

export const APP_MENU: MenuItem[] = [
  {
    name: i18n.t('constants.APP_MENU.service', { ns: LangNameSpace.App }),
    link: Route.Services,
    roles: [Role.Technician, Role.User],
    submenu: [],
  },
  {
    name: i18n.t('constants.APP_MENU.tickets', { ns: LangNameSpace.App }),
    link: Route.Tickets,
    roles: [Role.Technician, Role.User],
    submenu: [
      {
        name: i18n.t('constants.APP_MENU.ticketsList', { ns: LangNameSpace.App }),
        link: Route.TicketsList,
      },
    ],
  },
  {
    name: i18n.t('constants.APP_MENU.monitoring', { ns: LangNameSpace.App }),
    link: Route.Monitoring,
    roles: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin, Role.Technician, Role.User],
    submenu: [
      {
        name: i18n.t('constants.APP_MENU.monitoringServicesDashboards', { ns: LangNameSpace.App }),
        link: Route.ServicesDashboards,
      },
      {
        name: i18n.t('constants.APP_MENU.monitoringSitePerformance', { ns: LangNameSpace.App }),
        link: Route.SitePerformance,
      },
    ],
  },
  {
    name: i18n.t('constants.APP_MENU.admin', { ns: LangNameSpace.App }),
    link: Route.Admin,
    roles: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin],
    submenu: [
      {
        name: i18n.t('constants.APP_MENU.adminUserManagement', { ns: LangNameSpace.App }),
        link: Route.AdminUserManagement,
      },
      {
        name: i18n.t('constants.APP_MENU.adminSSOManagement', { ns: LangNameSpace.App }),

        link: Route.AdminSSOManagement,
      },
      {
        name: i18n.t('constants.APP_MENU.adminAPICredentials', { ns: LangNameSpace.App }),
        link: Route.AdminAPIManagement,
      },
    ],
  },
];
