import { ConfirmationModalName } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import { modalReducers } from 'app/store/modal/slice';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'shared/components/Modal';
import { CloseUnsavedConfirmationModalData } from 'shared/interfaces/modal';

export interface CloseUnsavedConfirmationModalProps {
  name?: ConfirmationModalName;
  onSuccess?: () => void;
}

export const CloseUnsavedConfirmationModal: FC<React.PropsWithChildren<CloseUnsavedConfirmationModalProps>> = ({
  name,
  onSuccess,
}) => {
  const { t } = useTranslation(LangNameSpace.Shared);
  const dispatch = useDispatch();

  const onSuccessHandler = useCallback(() => dispatch(modalReducers.setOpenModal()), [dispatch]);

  const textCallback = useCallback(
    (data: CloseUnsavedConfirmationModalData) => {
      const confirmationType = data?.buttonName || 'close';
      const windowType =
        confirmationType === 'close'
          ? t('components.CloseUnsavedConfirmationModal.windowType.window')
          : t('components.CloseUnsavedConfirmationModal.windowType.process');
      return t('components.CloseUnsavedConfirmationModal.text', {
        confirmationType: t(`components.CloseUnsavedConfirmationModal.confirmationType.${confirmationType}`, {
          defaultValue: confirmationType,
        }),
        windowType,
        windowName: data?.windowName,
      });
    },
    [t]
  );

  return (
    <ConfirmationModal
      name={name || ConfirmationModalName.CloseUnsavedConfirmationModal}
      onSuccess={onSuccess || onSuccessHandler}
      successBtnTitle={t('components.CloseUnsavedConfirmationModal.successBtnTitle')}
      cancelBtnTitle={t('components.CloseUnsavedConfirmationModal.cancelBtnTitle')}
      title={t('components.CloseUnsavedConfirmationModal.title')}
      text={textCallback}
    />
  );
};
