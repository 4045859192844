import { EntityStoreRequestType } from 'app/enums/store';
import { EntityStore } from 'app/store';

const addRequestId = (state: EntityStore, status: EntityStoreRequestType, requestId: string) => {
  state.status[status].push(requestId);
};

const removeRequestId = (state: EntityStore, status: EntityStoreRequestType, requestId: string) => {
  state.status[status] = state.status[status].filter((id: string) => requestId !== id);
};

export const entityStoreStatus = { removeRequestId, addRequestId };
