import { createSlice } from '@reduxjs/toolkit';
import { StoreName } from 'app/enums/store';
import { ModalsState } from 'app/store/modal/interfaces/modals-state';

import {
  closeAllConfirmationModalReducer,
  closeAllModalReducer,
  closeConfirmationModalReducer,
  closeModalReducer,
  setOpenConfirmationModalReducer,
  setOpenModalReducer,
  updateOpenModalDataReducer,
  updateOpenModalSettingsReducer,
  updateOpenModalTitleReducer,
} from './reducers';

const initialState: ModalsState = {
  modal: { name: undefined, data: undefined, settings: undefined, title: undefined },
  confirmationModal: { name: undefined, data: undefined },
};

export const modalState = createSlice({
  name: StoreName.Modals,
  initialState,
  reducers: {
    setOpenModal: setOpenModalReducer,
    setOpenConfirmationModal: setOpenConfirmationModalReducer,
    updateOpenModalData: updateOpenModalDataReducer,
    updateOpenModalSettings: updateOpenModalSettingsReducer,
    updateOpenModalTitle: updateOpenModalTitleReducer,
    closeModal: closeModalReducer,
    closeAllModal: closeAllModalReducer,
    closeConfirmationModal: closeConfirmationModalReducer,
    closeAllConfirmationModal: closeAllConfirmationModalReducer,
  },
});

export const modalReducers = { ...modalState.actions };
