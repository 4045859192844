import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { RolesEntity } from 'app/interfaces/entity/roles';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { store } from 'app/store';
import { entityStoreStatus } from 'app/utils/store';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper } from './dto-mappers';
import { RolesDto } from './interfaces';
import { RolesState } from './roles-state';

export const getRolesList = createAsyncThunk(`${EntityStoreName.Roles}`, (_, { signal }): Promise<RolesEntity[]> => {
  const organizationId = store.getState().session.organizationId;

  return apiRequestCall<RolesDto>({
    params: {
      method: 'GET',
      apiVersion: ApiVersion.V1,
      resource: ApiResource.Organizations,
      urlPrefix: `${organizationId}/${ApiResource.Roles}`,
      signal,
    },
  }).then((data) => dtoToEntityMapper(data));
});

export const clearAllReducer = (state: RolesState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<RolesState>): void => {
  builder
    .addCase(getRolesList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getRolesList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getRolesList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
