import { EntityStoreName } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { OrganizationEntity } from 'app/interfaces/entity/organization';
import { AppState } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';
import { createSelector } from 'reselect';

import { entityAdapter } from './adapter';

const selectAll = entityAdapter.getSelectors<AppState>((state) => state[EntityStoreName.Organizations]).selectAll;

const selectCurrent = createSelector(selectAll, sessionSelector.organizationId, (organizations, selectedOrgId) =>
  organizations.find((organization: OrganizationEntity) => organization.identifier === selectedOrgId)
);

const selectByPopulationId = (pingPopulationId: Identifier) =>
  createSelector(selectAll, (organizations) =>
    organizations.find((organization: OrganizationEntity) => organization.pingPopulationId === pingPopulationId)
  );

export const organizationsSelector = {
  all: selectAll,
  current: selectCurrent,
  getByPopulationId: selectByPopulationId,
};
