import { TicketCommentEntity } from 'app/interfaces/entity/ticket';

import { CreateTicketCommentDto, TicketCommentDto } from './interfaces/dto';

export const dtoToEntityMapper = (data: TicketCommentDto): TicketCommentEntity => {
  return data;
};

export const requestToDtoMapper = (data: { text: string }): CreateTicketCommentDto => {
  return data;
};
