import { Route } from 'app/enums';
import { GuardedRoute } from 'app/interfaces/route';

import { Login } from './components/Login';
import { LoginRedirect } from './components/LoginRedirect';
import { Logout } from './components/Logout';
import { LogoutRedirect } from './components/LogoutRedirect';

export const authRoutes: GuardedRoute[] = [
  {
    path: Route.Login,
    component: Login,
    availability: [],
    exact: true,
  },
  {
    path: Route.Logout,
    component: Logout,
    availability: [],
    exact: true,
  },
  {
    path: Route.LoginRedirect,
    component: LoginRedirect,
    availability: [],
    exact: true,
  },
  {
    path: Route.LogoutRedirect,
    component: LogoutRedirect,
    availability: [],
    exact: true,
  },
];
