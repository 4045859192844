import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { LocationAvailabilityEntity } from 'app/interfaces/entity/location';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { LocationAvailabilityState } from 'app/store/locations-availability/interfaces';
import { LocationsAvailabilityDto } from 'app/store/locations-availability/interfaces/dto';
import { entityStoreStatus } from 'app/utils/store';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper } from './dto-mappers';

export const getLocationAvailabilitiesList = createAsyncThunk(
  `${EntityStoreName.LocationAvailability}/getList`,
  (locationIdentifier: Identifier, { signal }): Promise<LocationAvailabilityEntity[]> => {
    return apiRequestCall<LocationsAvailabilityDto[], LocationAvailabilityEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Locations,
        urlPrefix: `${locationIdentifier}/availabilities`,
        signal,
      },
    }).then((data) => data.map(dtoToEntityMapper));
  }
);

export const clearAllReducer = (state: LocationAvailabilityState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<LocationAvailabilityState>): void => {
  builder
    .addCase(getLocationAvailabilitiesList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getLocationAvailabilitiesList.fulfilled, (state, action) => {
      if (action?.payload) {
        entityAdapter.setAll(state, action.payload);
      }
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getLocationAvailabilitiesList.rejected, (state, action) => {
      entityAdapter.setAll(state, []);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
