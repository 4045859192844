import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: {
    wordBreak: 'break-word',
  },
  capitalized: {
    textTransform: 'capitalize',
  },
}));
