import { ActionReducerMapBuilder, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { UserEntity } from 'app/interfaces/entity/user/user.entity';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { ErrorResponse } from 'app/services/api/interfaces';
import { entityStoreStatus } from 'app/utils/store';
import { AxiosError } from 'axios';
import { entityAdapter } from 'modules/admin/user-management/store/users/adapter';
import {
  changePasswordRequestToDtoMapper,
  createRequestToDtoMapper,
  dtoToEntityMapper,
  updateRequestToDtoMapper,
} from 'modules/admin/user-management/store/users/dto-mappers';
import {
  UserAvatarRequest,
  UserChangePasswordRequest,
  UserCreateRequest,
  UserGetRequest,
  UsersState,
  UserUpdateRequest,
} from 'modules/admin/user-management/store/users/interfaces';
import {
  UserChangePasswordDto,
  UserCreateDto,
  UserDto,
  UserUpdateDto,
} from 'modules/admin/user-management/store/users/interfaces/dto';

export const getUsersList = createAsyncThunk(
  `${EntityStoreName.Users}/getList`,
  (_, { signal }): Promise<UserEntity[]> => {
    return apiRequestCall<UserDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        signal,
      },
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const getUserById = createAsyncThunk(
  `${EntityStoreName.Users}/getById`,
  ({ identifier, organizationId }: UserGetRequest, { signal }): Promise<UserEntity[]> => {
    return apiRequestCall<UserDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        params: { userId: identifier },
        headers: { 'X-Organization-Id': organizationId },
        signal,
        cache: false,
      },
      invalidationKeys: [[identifier, ApiResource.Users]],
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const createUser = createAsyncThunk(
  `${EntityStoreName.Users}`,
  async ({ successRequestMessage, ...payload }: UserCreateRequest): Promise<UserEntity> => {
    return apiRequestCall<UserDto, UserCreateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        data: createRequestToDtoMapper(payload),
        skipShowErrorInSnackbar: true,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users]],
      successRequestMessage,
    }).then(dtoToEntityMapper);
  },
  {
    serializeError: (e) => {
      return (e as AxiosError<ErrorResponse>)?.response?.data || (e as SerializedError);
    },
  }
);

export const removeUser = createAsyncThunk(
  `${EntityStoreName.Users}/removeUser`,
  (identifier: Identifier, { signal }): Promise<string> => {
    return apiRequestCall<string>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: identifier,
        signal,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users]],
    }).then(() => identifier);
  }
);

export const updateUser = createAsyncThunk(
  `${EntityStoreName.Users}/updateUser`,
  ({ successRequestMessage, password, organizationId, ...payload }: UserUpdateRequest): Promise<UserEntity> =>
    apiRequestCall<UserDto, Partial<UserUpdateDto>>({
      params: {
        method: 'PATCH',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: payload.identifier,
        data: updateRequestToDtoMapper(payload),
        headers: { 'X-Password': password ?? '', 'X-Organization-Id': organizationId },
      },
      successRequestMessage,
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users]],
    }).then(dtoToEntityMapper)
);

export const uploadAvatar = createAsyncThunk(
  `${EntityStoreName.Users}/uploadAvatar`,
  ({ successRequestMessage, file }: UserAvatarRequest): Promise<string> => {
    const blob = new Blob([file], {
      type: file.type,
    });

    const formData = new FormData();
    formData.append(file.name, blob, file.name);

    return apiRequestCall<string, FormData>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: 'avatar',
        data: formData,
      },
      successRequestMessage,
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users]],
    });
  }
);

export const changePassword = createAsyncThunk(
  `${EntityStoreName.Users}/changePassword`,
  ({ successRequestMessage, ...payload }: UserChangePasswordRequest, { signal }): Promise<UserChangePasswordDto> => {
    return apiRequestCall<UserChangePasswordDto, UserChangePasswordDto>({
      params: {
        method: 'PUT',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: `${payload.identifier}/password`,
        data: changePasswordRequestToDtoMapper(payload),
        signal,
      },
      successRequestMessage,
    });
  }
);

export const clearAllReducer = (state: UsersState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<UsersState>): void => {
  builder
    .addCase(getUsersList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getUsersList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getUsersList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getUserById.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getUserById.fulfilled, (state, action) => {
      entityAdapter.setMany(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getUserById.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(removeUser.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(removeUser.fulfilled, (state, action) => {
      entityAdapter.removeOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(removeUser.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(updateUser.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      entityAdapter.upsertOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateUser.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(uploadAvatar.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(uploadAvatar.fulfilled, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(uploadAvatar.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(createUser.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createUser.fulfilled, (state, action) => {
      entityAdapter.addOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createUser.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(changePassword.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(changePassword.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    });
};
