import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { ChangeRequestEntity } from 'app/interfaces/entity/change-request';
import { ChageRequestsState } from 'modules/change-requests/store/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: ChangeRequestEntity) => data.identifier,
});

export const initialState: ChageRequestsState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
