import { Map } from 'immutable';
import { DataGridFilterRequestType, DataGridName } from 'shared/enums';
import { DataGridColumnFilterData } from 'shared/interfaces';
import { dataGridFilterFormatters } from 'shared/utils/data-grid/formatters';

export const extractFilteringOptions = (
  filters: DataGridColumnFilterData[],
  gridName: DataGridName,
  requestType: DataGridFilterRequestType = DataGridFilterRequestType.Async
) => {
  return filters
    .filter((filter) => filter?.requestType === requestType && filter.gridName === gridName)
    .reduce(
      (acc, item) => acc.set(item.columnName, dataGridFilterFormatters.readerFormatter(item)),
      Map<string, DataGridColumnFilterData>()
    );
};
