import { Box } from '@mui/material';
import { MenuItem } from 'app/interfaces/menu-item';
import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { useStyles } from './MainMenu.styles';

interface MainMenuProps {
  items: MenuItem[];
}

const MainMenuComponent: FC<React.PropsWithChildren<MainMenuProps>> = ({ items }) => {
  const { classes } = useStyles({ itemsLength: items?.length ?? 0 });

  return (
    <Box className={classes.root} data-testid="headerMenu">
      {items.map((item) => (
        <NavLink
          to={item.link}
          key={item.link}
          title={item.name}
          className={({ isActive }) => classNames([classes.item, { active: isActive }])}
        >
          {item.name}
        </NavLink>
      ))}
    </Box>
  );
};

export const MainMenu = memo(MainMenuComponent);
