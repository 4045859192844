import { Provider } from 'app/enums';
import { FixBandwidthChartItemType } from 'modules/cloud-services/enums/fix-bandwidth-chart-item-type';

import { FixBandwidthSpecialItemKey } from '../enums';

export const FIX_BANDWIDTH_CHART_COLORS_CONFIG = {
  [FixBandwidthSpecialItemKey.Empty]: {
    background: '#eeeeee',
    badge: '#eeeeee',
  },
  [FixBandwidthSpecialItemKey.Overload]: {
    background: '#eeeeee',
    badge: '#f15a2a',
  },
  [`${Provider.Aws}-${FixBandwidthChartItemType.Active}`]: {
    background: '#cececf',
    badge: '#7a7a7c',
  },
  [`${Provider.Aws}-${FixBandwidthChartItemType.Increase}`]: {
    background: `url(#${Provider.Aws}-${FixBandwidthChartItemType.Increase})`,
    badge: '#7a7a7c',
    gradient: '#cececf',
  },
  [`${Provider.Aws}-${FixBandwidthChartItemType.Decrease}`]: {
    background: `url(#${Provider.Aws}-${FixBandwidthChartItemType.Decrease})`,
    badge: '#7a7a7c',
    gradient: '#cececf',
  },
  [`${Provider.Azure}-${FixBandwidthChartItemType.Active}`]: {
    background: '#adadae',
    badge: '#7a7a7c',
  },
  [`${Provider.Azure}-${FixBandwidthChartItemType.Increase}`]: {
    background: `url(#${Provider.Azure}-${FixBandwidthChartItemType.Increase})`,
    badge: '#7a7a7c',
    gradient: '#adadae',
  },
  [`${Provider.Azure}-${FixBandwidthChartItemType.Decrease}`]: {
    background: `url(#${Provider.Azure}-${FixBandwidthChartItemType.Decrease})`,
    badge: '#7a7a7c',
    gradient: '#adadae',
  },
  [`${Provider.Gcp}-${FixBandwidthChartItemType.Active}`]: {
    background: '#757575',
    badge: '#7a7a7c',
  },
  [`${Provider.Gcp}-${FixBandwidthChartItemType.Increase}`]: {
    background: `url(#${Provider.Gcp}-${FixBandwidthChartItemType.Increase})`,
    badge: '#7a7a7c',
    gradient: '#757575',
  },
  [`${Provider.Gcp}-${FixBandwidthChartItemType.Decrease}`]: {
    background: `url(#${Provider.Gcp}-${FixBandwidthChartItemType.Decrease})`,
    badge: '#7a7a7c',
    gradient: '#757575',
  },
  [`${Provider.Oci}-${FixBandwidthChartItemType.Active}`]: {
    background: '#616161',
    badge: '#7a7a7c',
  },
  [`${Provider.Oci}-${FixBandwidthChartItemType.Increase}`]: {
    background: `url(#${Provider.Oci}-${FixBandwidthChartItemType.Increase})`,
    badge: '#7a7a7c',
    gradient: '#616161',
  },
  [`${Provider.Oci}-${FixBandwidthChartItemType.Decrease}`]: {
    background: `url(#${Provider.Oci}-${FixBandwidthChartItemType.Decrease})`,
    badge: '#7a7a7c',
    gradient: '#616161',
  },
};

export const FIX_BANDWIDTH_CHART_SIZE = {
  barWidth: 270,
  label: {
    width: 200,
    height: 24,
    offsetY: 10,
  },
  overloadLabel: {
    height: 200,
    padding: 85,
    offsetX: 40,
    offsetY: 100,
  },
};
