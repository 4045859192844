import { MfaEntity, MfaRequest } from 'modules/mfa/store/mfa/interfaces';
import { MfaDto } from 'modules/mfa/store/mfa/interfaces/dto';

export const dtoToEntityMapper = (data: MfaDto): MfaEntity => {
  return {
    email: data.email,
    mfaEnabled: data.mfaEnabled,
    confirmationCode: data.confirmationCode,
  };
};

export const requestToDtoMapper = (data: MfaRequest): MfaDto => {
  return {
    email: data.email,
    mfaEnabled: data.mfaEnabled,
    confirmationCode: data.confirmationCode,
  };
};
