import { ModalName, ModalTitle } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { modalReducers } from 'app/store/modal/slice';
import { sessionSelector } from 'app/store/session/selectors';
import { ChangePasswordForm } from 'modules/profile/components/ChangePasswordForm';
import { useChangePasswordForm } from 'modules/profile/hooks';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderButton } from 'shared/components/LoaderButton';
import { Modal } from 'shared/components/Modal';
import { withModalOpenChecker } from 'shared/hocs/ModalOpenChecker';
import { SnackbarUtil } from 'utils/snackbar';

export const ChangePasswordModalComponent = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const form = useChangePasswordForm();
  const { identifier } = useSelector(sessionSelector.userProfile);

  const {
    formState: { isValid },
    reset,
  } = form;

  const submitHandler = useCallback(() => {
    setSaving(true);
    apiRequestCall({
      params: {
        method: 'PUT',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: `${identifier}/password`,
        data: {
          currentPassword: form.getValues().currentPassword,
          newPassword: form.getValues().newPassword,
        },
      },
      handlers: {
        success: () => {
          SnackbarUtil.success(t('profile.components.ChangePasswordModal.successMessage'));
          dispatch(modalReducers.closeModal({ name: ModalName.ProfileChangePassword }));
        },
      },
    }).finally(() => {
      setSaving(false);
    });
  }, [t, dispatch, form, identifier]);

  return (
    <Modal
      maxWidth="xs"
      title={ModalTitle.ProfileChangePassword}
      name={ModalName.ProfileChangePassword}
      onClose={reset}
      skipBottomContentPadding={false}
      actions={
        <LoaderButton
          isLoading={saving}
          variant="contained"
          color="primary"
          onClick={submitHandler}
          disabled={saving || !isValid}
          data-testid="saveBtn"
        >
          {t('profile.components.ChangePasswordModal.saveBtn')}
        </LoaderButton>
      }
      data-testid="changePasswordModal"
      headerMode={true}
      closeText={t('profile.components.ChangePasswordModal.closeBtn')}
    >
      <ChangePasswordForm form={form} />
    </Modal>
  );
};

export const ChangePasswordModal = withModalOpenChecker(ChangePasswordModalComponent, ModalName.ProfileChangePassword);
