import { Theme } from '@mui/material';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  detailsContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
    marginTop: theme.spacing(2),
  },

  options: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  optionChip: {
    margin: theme.spacing(1, 1, 0, 0),
    borderRadius: theme.spacing(0.5),
    color: theme.custom.colors.MuiChip,
    backgroundColor: theme.custom.colors.MuiChipBg,

    '& .MuiChip-label': {
      ...TYPOGRAPHY.b2Regular,
      padding: theme.spacing(0, 1),
    },

    '&:last-child': {
      marginRight: theme.spacing(0),
    },
  },

  mfaTitle: {
    fontSize: theme.spacing(2),
  },

  iconWrapper: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontSize: theme.spacing(2),
  },

  icon: {
    fontSize: theme.spacing(2.25),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
    color: theme.palette.text.primary,
  },
}));
