import { FormControl, FormGroup } from '@mui/material';
import { PasswordConfirmFormFields } from 'modules/profile/interfaces/password-confirm-form-fields';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { FormFieldError } from 'shared/components/Form/FormFieldError';
import { PasswordField } from 'shared/components/Form/PasswordField';

interface PasswordConfirmationFormProps {
  form: UseFormReturn<PasswordConfirmFormFields>;
}

export const PasswordConfirmationForm: FC<React.PropsWithChildren<PasswordConfirmationFormProps>> = ({ form }) => {
  const { control } = form;
  return (
    <form data-testid="passwordConfirmationForm">
      <FormGroup>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <PasswordField
                required
                fullWidth
                size="small"
                data-testid="passwordConfirmationInput"
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
      </FormGroup>
    </form>
  );
};
