import { EntityStoreName } from 'app/enums/store';
import { AppState, createAppSelector } from 'app/store';
import { entityAdapter } from 'modules/admin/api-credentials-management/store/keys/adapter';
import { useMemo } from 'react';

const apiCredentialsState = (state: AppState) => state[EntityStoreName.ApiCredentials];

const selectAll = entityAdapter.getSelectors<AppState>(apiCredentialsState).selectAll;
const selectOne = (clientId: string) =>
  createAppSelector(apiCredentialsState, (state) => entityAdapter.getSelectors().selectById(state, String(clientId)));

export const apiCredentialsSelector = {
  all: selectAll,
};

export const apiCredentialsSelectorFactory = {
  useOne: (clientId: string) => useMemo(() => selectOne(clientId), [clientId]),
};
