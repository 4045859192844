import { DataGridName } from 'shared/enums';
import { DataGridSortData } from 'shared/interfaces/data-grid';

import { initialState } from './initialState';
import { DataGridSortState } from './interfaces/data-grid-sort-state';

export const setSortModelReducer = (state: DataGridSortState, action: { payload: DataGridSortData }): void => {
  const { gridName, sortModel } = action.payload;

  state[gridName] = sortModel;
};

export const resetReducer = (state: DataGridSortState): void => {
  for (const gridName in state) {
    if (initialState[gridName as DataGridName]) {
      state[gridName as DataGridName] = initialState[gridName as DataGridName];
    }
  }
};
