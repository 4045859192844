import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { clearAllReducer, registerReducers } from './repository';

export const locationAvailabilityState = createSlice({
  name: EntityStoreName.LocationAvailability,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const locationAvailabilityReducers = { ...locationAvailabilityState.actions };
