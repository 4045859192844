import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';
import { initialState } from 'modules/change-requests/store/adapter';
import { registerReducers } from 'modules/change-requests/store/repository';

export const changeRequestsState = createSlice({
  name: EntityStoreName.ChangeRequests,
  initialState,
  reducers: {},
  extraReducers: (builder) => registerReducers(builder),
});
