import { WizardPalette } from '@types';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';

export const WIZARD_LIGHT: WizardPalette = {
  background: COLORS_LIGHT.neutral.white,
  text: COLORS_LIGHT.neutral.black,
  stepper: {
    labelColors: {
      active: COLORS_LIGHT.neutral[900],
      default: COLORS_LIGHT.neutral[900],
      completed: COLORS_LIGHT.primary[100],
    },
    iconColors: {
      active: COLORS_LIGHT.primary[100],
      default: COLORS_LIGHT.neutral[300],
      completed: COLORS_LIGHT.primary[100],
    },
  },
  contentTitle: {
    background: COLORS_LIGHT.neutral.T8,
    text: COLORS_LIGHT.neutral.black,
    border: COLORS_LIGHT.neutral.T16,
  },
  content: {
    background: COLORS_LIGHT.neutral.T4,
    text: COLORS_LIGHT.neutral.black,
    border: COLORS_LIGHT.neutral.T16,
  },
  active: COLORS_LIGHT.primary[100],
  errorBlock: {
    backgroundColor: '#F8E5E6',
    borderColor: '#EEBEBE',
    iconColor: '#C62828',
    textColor: '#9E0303',
  },
  provider: '#EFEFF1',
  providerIcon: '#21B0EF',
  radioBtn: COLORS_LIGHT.neutral[500],
  label: COLORS_LIGHT.neutral[900],
  divider: {
    text: COLORS_LIGHT.neutral[400],
    border: COLORS_LIGHT.neutral.T40,
  },
};
export const WIZARD_DARK: WizardPalette = {
  background: COLORS_DARK.neutral[800],
  text: COLORS_DARK.neutral.white,
  stepper: {
    labelColors: {
      active: COLORS_DARK.neutral.white,
      default: COLORS_DARK.neutral.white,
      completed: COLORS_DARK.primary[100],
    },
    iconColors: {
      active: COLORS_DARK.primary[100],
      default: COLORS_DARK.neutral[300],
      completed: COLORS_DARK.primary[100],
    },
  },
  contentTitle: {
    background: COLORS_DARK.neutral.T8,
    text: COLORS_DARK.neutral.white,
    border: COLORS_DARK.neutral.T16,
  },
  content: {
    background: COLORS_DARK.neutral.T4,
    text: COLORS_DARK.neutral.white,
    border: COLORS_DARK.neutral.T16,
  },
  active: COLORS_DARK.primary[100],
  errorBlock: {
    backgroundColor: '#F8E5E6',
    borderColor: '#EEBEBE',
    iconColor: '#C62828',
    textColor: '#9E0303',
  },
  label: COLORS_DARK.neutral[200],
  provider: '#44454A',
  providerIcon: '#FFFFFF',
  radioBtn: COLORS_DARK.neutral[200],
  divider: {
    text: COLORS_DARK.neutral[400],
    border: COLORS_DARK.neutral.T40,
  },
};
