import { ApiResource } from 'app/services/api/enums';
import { errorMessageParser } from 'app/utils';
import axios, { AxiosError } from 'axios';
import { DashboardType } from 'modules/monitoring/enums';
import { SnackbarUtil } from 'utils/snackbar';

import { ErrorResponse, ResponseInterceptor } from '../../interfaces';

const hasSkippedApiCall = (err: AxiosError<ErrorResponse>): boolean => {
  return (
    Boolean(err.response?.status === 404) &&
    (Boolean(err?.config?.url?.endsWith(`/${ApiResource.Sso}`)) ||
      Boolean(err.request?.responseURL?.endsWith(`/${ApiResource.Dashboards}?type=${DashboardType.Client}`))) &&
    err?.config?.method === 'get'
  );
};

const isShowErrorNeeded = (err: AxiosError<ErrorResponse>) =>
  (!axios.isCancel(err) as boolean) &&
  err.response?.status !== 401 &&
  err.response?.data?.status !== 401 &&
  err.code !== 'ECONNABORTED' &&
  !hasSkippedApiCall(err);

export const errorHandlerInterceptor: ResponseInterceptor = {
  onFulfilled: (res) => Promise.resolve(res),
  onRejected: (err) => {
    if (isShowErrorNeeded(err) && !err.config?.skipShowErrorInSnackbar) {
      SnackbarUtil.error(errorMessageParser(err), { autoHideDuration: null });
    }
    return Promise.reject(err);
  },
};
