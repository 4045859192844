import * as React from 'react';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from 'shared/components/Loader';

import { persistor, store } from '../store';

export const StoreProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;
