import { Route } from 'app/enums';
import { RedirectConfig } from 'app/interfaces/redirect-config';
import { GuardedRoute } from 'app/interfaces/route';
import { RedirectConfigModel } from 'app/models';
import { authGuard, rolesGuard } from 'app/routing/guards';
import { Role } from 'modules/auth/enums';
import React from 'react';

const UserManagementPage = React.lazy(() => import('modules/admin/user-management/pages/UserManagementPage'));
const SsoManagementPage = React.lazy(() => import('modules/admin/sso-management/pages/SsoManagementPage'));
const ApiManagementPage = React.lazy(() => import('modules/admin/api-credentials-management/pages/ApiManagementPage'));

export const adminRoutes: GuardedRoute[] = [
  {
    path: Route.AdminUserManagement,
    component: UserManagementPage,
    availability: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin],
    guards: [rolesGuard([Role.Master_Admin, Role.Group_Admin, Role.Org_Admin]), authGuard],
    exact: true,
  },
  {
    path: Route.AdminSSOManagement,
    component: SsoManagementPage,
    availability: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin],
    guards: [rolesGuard([Role.Master_Admin, Role.Group_Admin, Role.Org_Admin]), authGuard],
    exact: true,
  },
  {
    path: Route.AdminAPIManagement,
    component: ApiManagementPage,
    availability: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin],
    guards: [rolesGuard([Role.Master_Admin, Role.Group_Admin, Role.Org_Admin]), authGuard],
    exact: true,
  },
];

export const adminRedirects: RedirectConfig[] = [new RedirectConfigModel(Route.Admin, Route.AdminUserManagement, true)];
