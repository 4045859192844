import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

export interface LoaderButtonProps extends ButtonProps {
  isLoading?: boolean;
  spinnerColor?: 'primary' | 'secondary' | 'inherit';
}

export const LoaderButton: FC<React.PropsWithChildren<LoaderButtonProps>> = ({
  spinnerColor = 'primary',
  children,
  isLoading = false,
  ...rest
}) => {
  return (
    <Button {...rest}>
      {isLoading ? <CircularProgress data-testid="spinner" color={spinnerColor} size={24} /> : children}
    </Button>
  );
};
