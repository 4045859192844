import { Box, Button } from '@mui/material';
import { ConfirmationModalName, ModalName } from 'app/enums';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { useDispatchMultiple, useEntityRepositoryStatus, useFetchData } from 'app/hooks';
import { LangNameSpace } from 'app/i18n';
import { pingIdentityClient } from 'app/services/ping-identity';
import { modalSelector } from 'app/store/modal/selectors';
import { modalReducers } from 'app/store/modal/slice';
import { legacyProfileToUserEntityMapper } from 'app/store/session/dto-mappers';
import { sessionSelector } from 'app/store/session/selectors';
import { sessionReducers } from 'app/store/session/slice';
import { dispatchUnwrap } from 'app/utils/store';
import { UserAvatar } from 'modules/admin/user-management/components/UserAvatar';
import { MfaCreateForm } from 'modules/mfa/components/MfaCreateForm';
import { MfaVerificationForm } from 'modules/mfa/components/MfaVerificationForm';
import { useMfaCreateForm } from 'modules/mfa/hooks/use-mfa-create-form';
import { useMfaVerifyForm } from 'modules/mfa/hooks/use-mfa-verify-form';
import { MfaEntity } from 'modules/mfa/store/mfa/interfaces';
import { createMfa, getMfa, updateMfa } from 'modules/mfa/store/mfa/repository';
import { mfaSelector } from 'modules/mfa/store/mfa/selectors';
import { UserProfile } from 'oidc-client-ts';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'shared/components/Loader';
import { LoaderButton } from 'shared/components/LoaderButton';
import { CloseUnsavedConfirmationModal, Modal } from 'shared/components/Modal';
import { withModalOpenChecker } from 'shared/hocs/ModalOpenChecker';

import { useStyles } from './MfaCreateModal.styles';

const MfaCreateModalComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const dispatchMultiple = useDispatchMultiple();

  const creating = useEntityRepositoryStatus({
    [EntityStoreName.Mfa]: EntityStoreRequestType.Creating,
  });
  const saving = useEntityRepositoryStatus({
    [EntityStoreName.Mfa]: EntityStoreRequestType.Updating,
  });

  const user = useSelector(sessionSelector.userProfile);
  const mfa = useSelector(mfaSelector.get);
  const mfaIsInitialized = useSelector(mfaSelector.isInitialized);
  const modalTitle = useSelector(modalSelector.modalTitle);
  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const isMfaInitialized = useSelector(mfaSelector.isInitialized);
  const mfaActions = useMemo(() => [getMfa()], []);
  const [modalState, setModalState] = useState<MfaEntity | null>(mfa);
  const [disableResendBtn, setDisableResendBtn] = useState<boolean>(false);
  const mfaCreateForm = useMfaCreateForm();
  const mfaVerifyForm = useMfaVerifyForm();
  const isSaveDisabled = useMemo(
    () => creating || !mfaCreateForm.formState.isValid,
    [mfaCreateForm.formState.isValid, creating]
  );
  const isVerifyDisabled = useMemo(
    () => saving || !mfaVerifyForm.formState.isValid || creating,
    [saving, mfaVerifyForm.formState.isValid, creating]
  );

  const { fetch: fetchMfa, loading } = useFetchData(mfaActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  useEffect(() => {
    if (!isMfaInitialized && isAuthorized) {
      fetchMfa();
    }
  }, [isMfaInitialized, fetchMfa, isAuthorized]);

  const onCloseConfirmHandler = useCallback(() => {
    mfaCreateForm.reset();
    mfaVerifyForm.reset();
    setModalState(null);
    setDisableResendBtn(false);
    dispatch(modalReducers.closeModal({ name: ModalName.MfaCreate }));
  }, [dispatch, mfaCreateForm, mfaVerifyForm]);

  const onCloseHandler = useCallback(() => {
    if (mfaCreateForm.formState.isDirty) {
      dispatch(
        modalReducers.setOpenConfirmationModal({
          name: ConfirmationModalName.CloseUnsavedMfaCreateModal,
          data: { windowName: modalTitle },
        })
      );
    } else {
      onCloseConfirmHandler();
    }

    return !mfaCreateForm.formState.isDirty;
  }, [dispatch, mfaCreateForm.formState.isDirty, onCloseConfirmHandler, modalTitle]);

  const submitCreateHandler = useCallback(async () => {
    setDisableResendBtn(true);

    await dispatchUnwrap(
      createMfa({
        ...mfaCreateForm.getValues(),
        successRequestMessage: t('mfa.components.MfaCreateModal.successRequestMessages.create'),
      })
    );

    setTimeout(() => {
      setDisableResendBtn(false);
    }, 5000);
  }, [t, mfaCreateForm]);

  const submitResendEmailHandler = useCallback(async () => {
    setDisableResendBtn(true);

    await dispatchUnwrap(
      createMfa({
        email: mfa?.email,
        successRequestMessage: t('mfa.components.MfaCreateModal.successRequestMessages.resendEmail'),
      }),
      {
        success: () => {
          mfaCreateForm.reset();
          mfaVerifyForm.reset();
        },
      }
    );

    setTimeout(() => {
      setDisableResendBtn(false);
    }, 5000);
  }, [t, mfa?.email, mfaCreateForm, mfaVerifyForm]);

  const submitConfirmHandler = useCallback(async () => {
    setDisableResendBtn(true);
    await dispatchUnwrap(
      updateMfa({
        ...mfa,
        ...mfaVerifyForm.getValues(),
        successRequestMessage: t('mfa.components.MfaCreateModal.successRequestMessages.confirm'),
      }),
      {
        success: (data: MfaEntity) => {
          return pingIdentityClient.signinSilent().then((dataUser) => {
            dispatchMultiple([
              sessionReducers.setAuthToken(dataUser?.access_token),
              sessionReducers.setUserProfile({
                ...legacyProfileToUserEntityMapper(dataUser?.profile as UserProfile),
                mfaEnabled: data.mfaEnabled,
              }),
            ]);
            onCloseConfirmHandler();
          });
        },
      }
    );
    setTimeout(() => {
      setDisableResendBtn(false);
    }, 2000);
  }, [t, dispatchMultiple, mfa, mfaVerifyForm, onCloseConfirmHandler]);

  useEffect(() => {
    if (mfaIsInitialized) {
      setModalState(mfa);
    }
    if (!mfaCreateForm.getValues()?.email && user?.email) {
      mfaCreateForm.setValue('email', user.email);
    }
  }, [mfa, mfaIsInitialized, mfaCreateForm, user?.email]);

  return (
    <>
      <Modal
        className={classes.modal}
        maxWidth="xs"
        isCloseButtonAvailable={false}
        name={ModalName.MfaCreate}
        onCloseRequest={onCloseHandler}
        actionsBlockFullWidth={true}
        actions={
          !loading ? (
            <>
              {modalState?.email && (
                <Box className={classes.modalControls}>
                  <Button size="medium" color="primary" variant="text" onClick={() => setModalState(null)}>
                    {t('mfa.components.MfaCreateModal.btnBack')}
                  </Button>
                </Box>
              )}
              <Box className={cx(classes.modalActions, 'fullWidth')}>
                <Box className={classes.actions}>
                  <LoaderButton
                    variant="outlined"
                    color="primary"
                    onClick={onCloseHandler}
                    disabled={creating || saving}
                  >
                    {t('mfa.components.MfaCreateModal.closeBtn')}
                  </LoaderButton>
                  {!modalState?.email && (
                    <LoaderButton
                      isLoading={creating}
                      variant="contained"
                      color="primary"
                      onClick={submitCreateHandler}
                      disabled={isSaveDisabled}
                      data-testid="createBtn"
                    >
                      {t('mfa.components.MfaCreateModal.actions.create')}
                    </LoaderButton>
                  )}
                  {modalState?.email && (
                    <LoaderButton
                      isLoading={saving}
                      variant="contained"
                      color="primary"
                      onClick={submitConfirmHandler}
                      disabled={isVerifyDisabled}
                      data-testid="confirmationBtn"
                    >
                      {t('mfa.components.MfaCreateModal.actions.confirm')}
                    </LoaderButton>
                  )}
                </Box>
              </Box>
            </>
          ) : null
        }
        data-testid="mfaModal"
      >
        <Box className={classes.root}>
          {loading ? (
            <Loader fullPage={false} />
          ) : (
            <>
              <UserAvatar user={user} />
              <Box className={classes.formContainer}>
                {!modalState?.email && <MfaCreateForm form={mfaCreateForm} />}
                {modalState?.email && (
                  <MfaVerificationForm
                    onSubmitResendEmailHandler={submitResendEmailHandler}
                    form={mfaVerifyForm}
                    email={mfa.email}
                    disabledResendBtn={disableResendBtn}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <CloseUnsavedConfirmationModal
        name={ConfirmationModalName.CloseUnsavedMfaCreateModal}
        onSuccess={onCloseConfirmHandler}
      />
    </>
  );
};

export const MfaCreateModal = withModalOpenChecker(MfaCreateModalComponent, ModalName.MfaCreate);
