import { DefaultDisplayValue } from 'app/enums';
import React, { FC, memo } from 'react';

interface ValueOrDefaultFormatProps {
  value?: string | number | null;
  defaultValue?: string | number | null | DefaultDisplayValue;
  units?: string | null;
  showUnitsForDefault?: boolean;
}

const ValueOrDefaultFormatComponent: FC<React.PropsWithChildren<ValueOrDefaultFormatProps>> = ({
  value,
  defaultValue = DefaultDisplayValue.DASH,
  units,
  showUnitsForDefault = false,
}) => {
  if (value) {
    return units ? (
      <>
        {value} {units}
      </>
    ) : (
      <>{value}</>
    );
  } else if (showUnitsForDefault) {
    return (
      <>
        {defaultValue} {units}
      </>
    );
  } else {
    return <>{defaultValue}</>;
  }
};

export const ValueOrDefaultFormat = memo(ValueOrDefaultFormatComponent);
