import { adminRoutes } from 'modules/admin/routes';
import { changeRequestsRoutes } from 'modules/change-requests/routes';
import { cloudServicesRoutes } from 'modules/cloud-services/routes';
import { monitoringRoutes } from 'modules/monitoring/routes';
import { rootRoutes } from 'modules/root/routes';
import { ticketsRoutes } from 'modules/tickets/routes';

import { GuardedRoute } from '../interfaces/route';

/**
 * Main application routes list which union routes.ts files from all modules
 */
export const moduleRoutes: GuardedRoute[] = [
  ...cloudServicesRoutes,
  ...ticketsRoutes,
  ...changeRequestsRoutes,
  ...adminRoutes,
  ...monitoringRoutes,
  ...rootRoutes,
];
