import { sessionSelector } from 'app/store/session/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ThemeMode } from '../enums/theme';

const DARK_COLOR_SCHEME_MEDIA_QUERY = '(prefers-color-scheme: dark)';

export const useThemeMode = (): ThemeMode => {
  const selectedThemeMode = useSelector(sessionSelector.themeMode) as ThemeMode;
  const systemThemeMode = window.matchMedia(DARK_COLOR_SCHEME_MEDIA_QUERY)?.matches ? ThemeMode.Dark : ThemeMode.Light;

  const [systemPaletteMode, setSystemPaletteMode] = useState(systemThemeMode);

  const themeMode = useMemo(
    () => (selectedThemeMode === ThemeMode.System ? systemPaletteMode : selectedThemeMode),
    [systemPaletteMode, selectedThemeMode]
  );

  const mediaQueriesListener = useCallback(
    (event: MediaQueryListEvent) => setSystemPaletteMode(event.matches ? ThemeMode.Dark : ThemeMode.Light),
    [setSystemPaletteMode]
  );

  useEffect(() => {
    const darkThemeMediaQuery = window.matchMedia(DARK_COLOR_SCHEME_MEDIA_QUERY);
    darkThemeMediaQuery?.addEventListener('change', mediaQueriesListener);
    return () => darkThemeMediaQuery?.removeEventListener('change', mediaQueriesListener);
  }, [mediaQueriesListener]);

  return themeMode;
};
