import {
  ConfirmationModalPayload,
  ModalPayload,
  ModalsState,
  ModalUpdateDataPayload,
  ModalUpdateSettingsPayload,
} from 'app/store/modal/interfaces/modals-state';

export const setOpenModalReducer = (state: ModalsState, action: { payload: ModalPayload | undefined }): void => {
  const payload = action?.payload;

  state.modal.name = payload?.name;
  state.modal.data = payload?.data;
  state.modal.settings = payload?.settings;
  state.modal.title = payload?.title;
};

export const closeModalReducer = (state: ModalsState, action: { payload: ModalPayload | undefined }): void => {
  const payload = action?.payload;

  if (payload?.name && state.modal?.name === payload?.name) {
    state.modal = {};
  }
};

export const closeAllModalReducer = (state: ModalsState): void => {
  state.modal = {};
};

export const updateOpenModalDataReducer = (state: ModalsState, action: { payload: ModalUpdateDataPayload }): void => {
  const { name, ...data } = action?.payload;

  if (name && state.modal?.name === name) {
    state.modal.data = { ...state.modal.data, ...data };
  }
};

export const updateOpenModalSettingsReducer = (
  state: ModalsState,
  action: { payload: ModalUpdateSettingsPayload }
): void => {
  const { name, ...settings } = action?.payload;

  if (name && state.modal?.name === name) {
    state.modal.settings = { ...state.modal.settings, ...settings };
  }
};

export const setOpenConfirmationModalReducer = (
  state: ModalsState,
  action: { payload: ConfirmationModalPayload | undefined }
): void => {
  const payload = action?.payload;

  state.confirmationModal.name = payload?.name;
  state.confirmationModal.data = payload?.data;
  state.confirmationModal.title = payload?.title;
};
export const closeConfirmationModalReducer = (
  state: ModalsState,
  action: { payload: ConfirmationModalPayload | undefined }
): void => {
  const payload = action?.payload;

  if (payload?.name && state.confirmationModal?.name === payload?.name) {
    state.confirmationModal = {};
  }
};

export const closeAllConfirmationModalReducer = (state: ModalsState): void => {
  state.confirmationModal = {};
};

export const updateOpenModalTitleReducer = (state: ModalsState, action: { payload: ModalPayload }): void => {
  const { title } = action.payload;
  state.modal = { ...state.modal, title };
};
