import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ hasSubmenu: boolean }>()((theme: Theme, { hasSubmenu }) => {
  const headerHeight = hasSubmenu
    ? Number(theme.custom.headerHeight) + Number(theme.custom.subHeaderHeight)
    : Number(theme.custom.headerHeight);

  return {
    root: {
      maxHeight: '100vh',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
    },
    content: {
      display: 'flex',
      flex: 1,
      height: `calc(100vh - ${headerHeight}px)`,
    },
  };
});
