export enum ModalName {
  ChangeRequestView = 'change-request-view',
  Documentation = 'documentation',
  UserEdit = 'user-edit',
  UserChangePassword = 'user-change-password',
  UserCreate = 'user-create',
  Profile = 'profile',
  MfaCreate = 'mfa-create',
  ApiCredentialCreate = 'api-credential-create',
  ApiCredentialsEdit = 'api-credential-edit',
  ProfileChangePassword = 'profile-change-password',
  SsoGroupMappingCreate = 'sso-group-mapping-create',
  SsoGroupMappingEdit = 'sso-group-mapping-edit',
  AlertDetail = 'alert-detail',
  DashboardCustomDateRange = 'dashboard-custom-date-range',
}

export enum ConfirmationModalName {
  UserDeleteConfirm = 'user-delete-confirm',
  UserMfaDeleteConfirm = 'user-mfa-delete-confirm',
  SsoDeleteConfirm = 'sso-delete-confirm',
  SsoGroupMappingDeleteConfirm = 'sso-group-mapping-delete-confirm',
  CloudConnectionDeleteConfirm = 'cloud-connection-delete-confirm',
  CloseUnsavedConfirmationModal = 'close-unsaved-confirm',
  CloseUnsavedProfileConfirmationModal = 'close-unsaved-profile-confirm',
  CloseUnsavedMfaCreateModal = 'close-unsaved-mfa-create-confirm',
  PasswordRequired = 'password-required',
  ApiCredentialsDeleteConfirm = 'api-credential-delete-confirm',
}
