import { configureStore, PreloadedStateShapeFromReducersMapObject } from '@reduxjs/toolkit';
import { locationAvailabilityState } from 'app/store/locations-availability/slice';
import { apiCredentialsState } from 'modules/admin/api-credentials-management/store/keys';
import { ssoState } from 'modules/admin/sso-management/store/sso/slice';
import { ssoGroupMappingsState } from 'modules/admin/sso-management/store/sso-group-mappings/slice';
import { usersState } from 'modules/admin/user-management/store/users/slice';
import { changeRequestsState } from 'modules/change-requests/store/slice';
import { ccvsState } from 'modules/cloud-services/store/ccvs/slice';
import { cloudConnectionsState } from 'modules/cloud-services/store/cloud-connections/slice';
import { fixBandwidthState } from 'modules/cloud-services/store/fix-bandwidth/slice';
import { mfaState } from 'modules/mfa/store/mfa/slice';
import { clientDashboardsState, globalDashboardsState } from 'modules/monitoring/store/slice';
import { organizationsState } from 'modules/organization/store/slice';
import { rolesState } from 'modules/roles/store/slice';
import { ticketAttachmentsState } from 'modules/tickets/store/ticket-attachments/slice';
import { ticketCommentsState } from 'modules/tickets/store/ticket-comments/slice';
import { ticketsState } from 'modules/tickets/store/tickets/slice';
import { useDispatch } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { dataGridFiltersState } from 'shared/store/data-grid/filters/slice';
import { dataGridPaginationState } from 'shared/store/data-grid/pagination/slice';
import { dataGridSortState } from 'shared/store/data-grid/sorting/slice';

import { EntityStoreName, StoreName } from '../enums/store';
import { locationsState } from './locations/slice';
import { modalState } from './modal/slice';
import { sessionState } from './session/slice';

const sessionReducer = persistReducer(
  {
    key: StoreName.Session,
    storage: storageSession,
  },
  sessionState.reducer
);

const organizationsReducer = persistReducer(
  {
    key: EntityStoreName.Organizations,
    storage,
    blacklist: ['status'],
  },
  organizationsState.reducer
);

export const createStore = ({
  preloadedState,
}: { preloadedState?: PreloadedStateShapeFromReducersMapObject<unknown> } = {}) => {
  return configureStore({
    reducer: {
      [EntityStoreName.ApiCredentials]: apiCredentialsState.reducer,
      [EntityStoreName.Ccvs]: ccvsState.reducer,
      [EntityStoreName.CloudConnections]: cloudConnectionsState.reducer,
      [EntityStoreName.ChangeRequests]: changeRequestsState.reducer,
      [EntityStoreName.ClientDashboards]: clientDashboardsState.reducer,
      [EntityStoreName.GlobalDashboards]: globalDashboardsState.reducer,
      [EntityStoreName.FixBandwidth]: fixBandwidthState.reducer,
      [EntityStoreName.Locations]: locationsState.reducer,
      [EntityStoreName.LocationAvailability]: locationAvailabilityState.reducer,
      [EntityStoreName.Organizations]: organizationsReducer as unknown as typeof organizationsState.reducer,
      [EntityStoreName.Roles]: rolesState.reducer,

      [EntityStoreName.Tickets]: ticketsState.reducer,
      [EntityStoreName.TicketComments]: ticketCommentsState.reducer,
      [EntityStoreName.TicketAttachments]: ticketAttachmentsState.reducer,
      [EntityStoreName.Users]: usersState.reducer,
      [EntityStoreName.Sso]: ssoState.reducer,
      [EntityStoreName.Mfa]: mfaState.reducer,
      [EntityStoreName.SsoGroupMappings]: ssoGroupMappingsState.reducer,
      [StoreName.DataGridFilters]: dataGridFiltersState.reducer,
      [StoreName.DataGridPagination]: dataGridPaginationState.reducer,
      [StoreName.DataGridSort]: dataGridSortState.reducer,
      [StoreName.Modals]: modalState.reducer,
      [StoreName.Session]: sessionReducer as unknown as typeof sessionState.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    preloadedState,
  });
};

export const store = createStore();

export const persistor = persistStore(store);

export type Store = AppState[StoreName];
export type EntityStore = AppState[EntityStoreName];
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
