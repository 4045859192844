import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { registerReducers } from './repository';

export const fixBandwidthState = createSlice({
  name: EntityStoreName.FixBandwidth,
  initialState,
  reducers: {},
  extraReducers: (builder) => registerReducers(builder),
});
