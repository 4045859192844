import { PROVIDER_CONFIG } from 'app/constants';
import { ConnectionSubType, Provider } from 'app/enums';
import React, { FC, memo, useMemo } from 'react';
import { ValueOrDefaultFormat } from 'shared/components/Format/ValueOrDefaultFormat';

interface ProviderNameFormatProps {
  provider: Provider;
  subType?: ConnectionSubType;
}

const ProviderNameFormatComponent: FC<React.PropsWithChildren<ProviderNameFormatProps>> = ({ provider, subType }) => {
  const providerData = PROVIDER_CONFIG[provider];

  const connectionName = useMemo(() => {
    if (providerData?.subtypes && subType) {
      const subTypeData = providerData?.subtypes[subType];
      return `${providerData.displayName} - ${subTypeData?.displayName}`;
    }

    return providerData?.displayName;
  }, [providerData?.displayName, providerData?.subtypes, subType]);

  return <ValueOrDefaultFormat value={connectionName} />;
};

export const ProviderNameFormat = memo(ProviderNameFormatComponent);
