import { ModalName, ModalTitle } from 'app/enums';
import { useAuthedUser, useFetchData, useRoutes } from 'app/hooks';
import { i18n, Lang } from 'app/i18n';
import { modalReducers } from 'app/store/modal/slice';
import { sessionSelector } from 'app/store/session/selectors';
import { getCcvList } from 'modules/cloud-services/store/ccvs/repository';
import { getMfa } from 'modules/mfa/store/mfa/repository';
import { getClientDashboardsList } from 'modules/monitoring/store/repository';
import { getOrganizationsList } from 'modules/organization/store/repository';
import { getRolesList } from 'modules/roles/store/repository';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AppInit: FC<React.PropsWithChildren<unknown>> = () => {
  useAuthedUser();

  const organizationId = useSelector(sessionSelector.organizationId);

  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const language = useSelector(sessionSelector.language);
  const { isAuthRoute } = useRoutes();
  const isMfaConfigurationRequired = useSelector(sessionSelector.isMfaConfigurationRequired);
  const dispatch = useDispatch();
  const isInitialized = useRef(false);

  const rolesActions = useMemo(() => [getRolesList()], []);
  const { fetch: fetchRoles } = useFetchData(rolesActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: true,
  });

  const organizationsActions = useMemo(() => [getOrganizationsList()], []);
  const { fetch: fetchOrganizations } = useFetchData(organizationsActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  const mfaActions = useMemo(() => [getMfa()], []);
  const { fetch: fetchMfa, loading: fetchMfaLoading } = useFetchData(mfaActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  const ccvsActions = useMemo(() => [getCcvList()], []);
  const { fetch: fetchCcvs } = useFetchData(ccvsActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  const dashboardActions = useMemo(() => [getClientDashboardsList()], []);
  const { fetch: fetchDashboard } = useFetchData(dashboardActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  useEffect(() => {
    if (isAuthorized && !isAuthRoute) {
      fetchOrganizations();
    }
  }, [fetchOrganizations, isAuthRoute, isAuthorized]);

  useEffect(() => {
    if (isAuthorized && !isAuthRoute && !isMfaConfigurationRequired && organizationId) {
      fetchCcvs();
      fetchDashboard();
      fetchRoles();
    }
  }, [fetchCcvs, fetchDashboard, fetchRoles, isAuthRoute, isAuthorized, isMfaConfigurationRequired, , organizationId]);

  useEffect(() => {
    if (isAuthorized && !isAuthRoute && isMfaConfigurationRequired && organizationId) {
      dispatch(modalReducers.setOpenModal({ name: ModalName.MfaCreate, title: ModalTitle.MfaCreate }));
    }
  }, [dispatch, isMfaConfigurationRequired, isAuthRoute, isAuthorized, organizationId, fetchMfaLoading, fetchMfa]);

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }
    if (i18n.language !== Lang.En) {
      i18n.changeLanguage(language as unknown as string);
    }
    isInitialized.current = true;
  }, [language]);

  return null;
};
