import { Tooltip, TooltipProps } from '@mui/material';
import classNames from 'classnames';
import React, { cloneElement, FC, memo, useCallback, useEffect } from 'react';

import { useStyles } from './CropTextFormatter.styles';

type EllipsisFormatProps = {
  width?: number;
  title?: string | null | JSX.Element;
} & Omit<TooltipProps, 'title'>;

const CropTextFormatterComponent: FC<React.PropsWithChildren<EllipsisFormatProps>> = ({
  title = '',
  placement = 'bottom',
  width,
  children,
}) => {
  const { classes } = useStyles({ width });
  const tipRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const cb = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (open && !entry.isIntersecting) {
        setOpen(false);
      }
    },
    [open]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [cb, tipRef]);

  const clone = cloneElement(children, {
    className: classNames([classes.root, children.props?.className]),
  });

  return (
    <>
      {title ? (
        <Tooltip
          ref={tipRef}
          open={open}
          title={String(title)}
          placement={placement}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {clone}
        </Tooltip>
      ) : (
        clone
      )}
    </>
  );
};

export const CropTextFormatter = memo(CropTextFormatterComponent);
