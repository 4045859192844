import { invalidate } from 'app/services/api/cache';
import { ApiRequestHandler } from 'app/services/api/interfaces';
import axios, { AxiosInstance } from 'axios';
import { CacheRequestConfig } from 'axios-cache-interceptor';
import { SnackbarUtil } from 'utils/snackbar';

import { apiService } from './api';
import { ApiResource, ApiVersion } from './enums';
import { buildApiPath } from './utils';

interface UrlParams {
  apiVersion: ApiVersion;
  resource: ApiResource;
  urlPrefix?: string;
}

interface ApiRequest<ResponseDto, RequestDto> {
  params: CacheRequestConfig<ResponseDto, RequestDto> & UrlParams & { skipShowErrorInSnackbar?: boolean };
  handlers?: ApiRequestHandler<ResponseDto>;
  rejectOnCancel?: boolean;
  invalidationKeys?: string[][];
  successRequestMessage?: string;
  returnHeaders?: boolean;
}

export const apiRequestCall = <ResponseDto, RequestDto = object>({
  params,
  handlers: { success = () => null, error = () => null } = {},
  rejectOnCancel = true,
  invalidationKeys = [[]],
  successRequestMessage = '',
  returnHeaders = false,
}: ApiRequest<ResponseDto, RequestDto>): Promise<ResponseDto> => {
  const url = buildApiPath(params.apiVersion, params.resource, params.urlPrefix);

  return (apiService as AxiosInstance)
    .request({ url, ...params })
    .then(async (data) => {
      if (invalidationKeys?.length) {
        await invalidate(invalidationKeys.map((keyParts) => keyParts.join('/')).filter((key) => key));
      }

      if (successRequestMessage) {
        SnackbarUtil.success(successRequestMessage);
      }

      success(data.data);

      return returnHeaders ? data : data.data;
    })
    .catch(async (err) => {
      error(err);

      if (axios.isCancel(err)) {
        await invalidate([url]);
      }

      if (!rejectOnCancel && axios.isCancel(err)) {
        return Promise.resolve(err);
      }
      return Promise.reject(err);
    });
};
