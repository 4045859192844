import { ConfirmationModalName, DialogName, ModalName, WizardName } from 'app/enums';
import { StoreName } from 'app/enums/store';
import { ConfirmationModalPayload, ModalPayload, ModalsState } from 'app/store/modal/interfaces/modals-state';
import { OutputSelector, Selector } from 'reselect';

import { createAppSelector } from '../createAppSelector';
import { AppState } from '../store';

export type ModalPayloadSelector<T> = OutputSelector<[Selector<AppState, ModalsState>], (modal: ModalsState) => T>;

const selectModalState = (state: AppState): ModalsState => state[StoreName.Modals];

const isOpen: (modalName: ModalName | DialogName | WizardName) => (state: AppState) => boolean = (
  modalName: ModalName | DialogName | WizardName
): ((state: AppState) => boolean) =>
  createAppSelector(selectModalState, (modal: ModalsState) => modal?.modal?.name === modalName);

const isConfirmationOpen: (modalName: ConfirmationModalName) => (state: AppState) => boolean = (
  modalName: ConfirmationModalName
): ((state: AppState) => boolean) =>
  createAppSelector(selectModalState, (modal: ModalsState) => modal?.confirmationModal?.name === modalName);

const getModalDataByDataKey: <T>(dataKey: keyof Omit<ModalPayload, 'name'>) => ModalPayloadSelector<T> = (dataKey) =>
  createAppSelector(selectModalState, (modal: ModalsState) => modal?.modal?.[dataKey]);

const getConfirmationModalDataByDataKey: <T>(
  dataKey: keyof Omit<ConfirmationModalPayload, 'name'>
) => ModalPayloadSelector<T> = (dataKey) =>
  createAppSelector(selectModalState, (modal: ModalsState) => modal?.confirmationModal?.[dataKey]);

const modalName = createAppSelector(selectModalState, (modal: ModalsState) => modal?.modal.name ?? undefined);

const modalTitle = createAppSelector(selectModalState, (modal: ModalsState) => modal?.modal?.title ?? '');

const confirmationModalTitle = createAppSelector(
  selectModalState,
  (modal: ModalsState) => modal?.confirmationModal?.title ?? ''
);

const confirmationModalName = createAppSelector(
  selectModalState,
  (modal: ModalsState) => modal?.confirmationModal.name ?? undefined
);

export const modalSelector = {
  isOpen,
  isConfirmationOpen,
  data: getModalDataByDataKey<ModalPayload['data']>('data'),
  settings: getModalDataByDataKey<ModalPayload['settings']>('settings'),
  confirmationData: getConfirmationModalDataByDataKey<ConfirmationModalPayload['data']>('data'),
  confirmationModalName,
  modalName,
  modalTitle,
  confirmationModalTitle,
};
