const INTER_REGION_FACTION_LOCATIONS_SIZE = 2;

export const getInterRegionTargetLocation = (
  factionLocations?: string[],
  currentLocationId?: string
): string | undefined => {
  if (factionLocations && factionLocations.length === INTER_REGION_FACTION_LOCATIONS_SIZE && currentLocationId) {
    return factionLocations?.find((loc) => loc !== currentLocationId);
  }
};
