export const monitoring = {
  components: {
    NotFoundWidget: {
      text: 'Widget(Data) Not Found',
    },
    TableWidgetPagination: {
      total: 'Total: {{count}}',
    },
    Widgets: {
      unsupportedWidget: 'Unsupported widget type\u00A0<b>{{type}}</b>',
    },
    MonitoringGlobalHeader: {
      crossCloud: {
        label: 'Cross-Cloud',
      },
    },
    AlertWidget: {
      cellValue: {
        InSDT: {
          yes: 'Yes',
          no: 'No',
        },
      },
      titles: {
        noAlert: 'No Alerts',
      },
    },
    servicesDashboard: {
      component: {
        DateRangeModal: {
          btn: 'Save',
          from: 'From',
          to: 'To',
        },
      },
    },
  },
};
