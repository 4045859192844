import { EntityStoreRequestType } from 'app/enums/store';
import { fixBandwidthStateMock } from 'modules/cloud-services/store/fix-bandwidth/fix-bandwidth-state.mock';
import { FixBandwidthState } from 'modules/cloud-services/store/fix-bandwidth/interfaces';

export const initialState: FixBandwidthState = {
  entity: fixBandwidthStateMock.entity,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
};
