import { AxiosHeaders } from 'axios';
import { InternalCacheRequestConfig } from 'axios-cache-interceptor';

export const authTokenInterceptor = async (config: InternalCacheRequestConfig): Promise<InternalCacheRequestConfig> => {
  const { store } = await import('app/store');
  const { token } = store.getState().session;

  const headers = new AxiosHeaders({ ...config.headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) });

  return {
    ...config,
    headers,
  };
};
