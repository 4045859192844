import { Box, Link, List, ListItem } from '@mui/material';
import { ModalName, ModalTitle } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/components/Modal';
import { withModalOpenChecker } from 'shared/hocs/ModalOpenChecker';

import { useStyles } from './DocumentationModal.styles';

export const DocumentationModalComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();

  return (
    <Modal
      className={classes.root}
      maxWidth="xs"
      title={ModalTitle.Documentation}
      name={ModalName.Documentation}
      data-testid="documentationModal"
      skipBottomContentPadding={false}
      closeText="Close"
      closeBtnVariant="contained"
    >
      <Box className={classes.content}>
        <List>
          <ListItem>
            <Link
              data-testid="portalUserDocumentLink"
              href="/docs/Faction Customer Portal User Documentation 1-2.pdf"
              target="_blank"
            >
              {t('profile.components.DocumentationModal.documentation')}
            </Link>
          </ListItem>
          <ListItem>
            <Link data-testid="portalReportLink" href="/docs/attribution-report.html" target="_blank">
              {t('profile.components.DocumentationModal.report')}
            </Link>
          </ListItem>
        </List>
      </Box>
    </Modal>
  );
};

export const DocumentationModal = withModalOpenChecker(DocumentationModalComponent, ModalName.Documentation);
