import { UserEntity } from 'app/interfaces/entity/user';
import { isEqual } from 'lodash';

export const getEdited = <FormType extends object>(formValues: FormType, user: UserEntity) => {
  const modifiedValues: Partial<FormType> = {};
  Object.keys(formValues)
    .filter((key) => !isEqual(user[key as keyof UserEntity], formValues[key as keyof FormType]))
    .forEach((key) => (modifiedValues[key as keyof FormType] = formValues[key as keyof FormType]));

  return modifiedValues;
};
