import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ itemsLength: number }>()((theme: Theme, { itemsLength }) => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${itemsLength}, max-content)`,
    gap: theme.spacing(0, 5.75),
  },
  item: {
    padding: theme.spacing(0, 1.5),
    fontSize: theme.spacing(2),
    fontWeight: FontWeight.Regular,
    color: theme.palette.header.text.subMenu.main,
    height: '100%',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '&:after': {
      borderTopRightRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopWidth: theme.spacing(0.5),
      borderTopStyle: 'solid',
      borderTopColor: 'transparent',
      content: '""',
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: -1,
    },

    '&.active': {
      color: theme.palette.header.text.subMenu.active,
      fontWeight: FontWeight.SemiBold,
      '&:after': {
        borderTopColor: theme.palette.header.text.subMenu.active,
      },
    },
  },
}));
