import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& .MuiMenu-paper': {
      backgroundColor: theme.palette.header.background.MuiPaper,
      border: `1px solid ${theme.custom.border.default}`,
      width: theme.spacing(38.5),
    },
  },
  menuItem: {
    '& .MuiMenuItem-root': {
      fontSize: theme.spacing(2),
      padding: theme.spacing(0.875, 2.25),
      maxHeight: theme.spacing(5.5),
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(3.375),
      marginRight: theme.spacing(1.6875),
    },
  },
}));
