import { ThemeMode } from 'app/enums/theme';

export const LINE_COLORS: Record<ThemeMode.Light | ThemeMode.Dark, string[]> = {
  [ThemeMode.Light]: [
    '#1A9CE8',
    '#7E39ED',
    '#FADB14',
    '#185407',
    '#C41D7F',
    '#69461D',
    '#04A099',
    '#E39115',
    '#A1A1A1',
    '#610B00',
    '#FF97F5',
    '#0D6F6B',
    '#B7EB8F',
    '#371C73',
    '#D3ADF7',
    '#AC845F',
    '#389E0D',
    '#00474F',
    '#DF3F18',
    '#87E8DE',
    '#1A2F9C',
    '#FFA39E',
  ],
  [ThemeMode.Dark]: [
    '#1A9CE8',
    '#7E39ED',
    '#FADB14',
    '#1D6409',
    '#C41D7F',
    '#69461D',
    '#04A099',
    '#E39115',
    '#A1A1A1',
    '#801305',
    '#FF97F5',
    '#0D6F6B',
    '#B7EB8F',
    '#371C73',
    '#D3ADF7',
    '#AC845F',
    '#389E0D',
    '#00474F',
    '#DF3F18',
    '#87E8DE',
    '#1A2F9C',
    '#FFA39E',
  ],
};
