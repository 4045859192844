import { i18n, LangNameSpace } from 'app/i18n';
import { sortBy } from 'lodash';
import { Role } from 'modules/auth/enums';

export const ROLES = {
  [Role.Master_Admin]: {
    power: 1,
    name: i18n.t('auth.constants.ROLES.Master_Admin', { ns: LangNameSpace.Modules }),
  },
  [Role.Group_Admin]: {
    power: 2,
    name: i18n.t('auth.constants.ROLES.Group_Admin', { ns: LangNameSpace.Modules }),
  },
  [Role.Org_Admin]: {
    power: 3,
    name: i18n.t('auth.constants.ROLES.Org_Admin', { ns: LangNameSpace.Modules }),
  },
  [Role.Technician]: {
    power: 4,
    name: i18n.t('auth.constants.ROLES.Technician', { ns: LangNameSpace.Modules }),
  },
  [Role.User]: {
    power: 5,
    name: i18n.t('auth.constants.ROLES.User', { ns: LangNameSpace.Modules }),
  },
};

export const ROLE_NAMES = Object.keys(ROLES);

export const ROLES_OPTIONS = sortBy(Object.entries(ROLES), ([, val]) => val.power).map(([key]) => key) as Role[];
