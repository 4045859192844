// @todo FS-8384
export enum ModalTitle {
  Documentation = 'Documentation',
  Profile = 'Profile',
  ProfileEdit = 'Edit Profile',
  ProfileChangePassword = 'Change Password',
  ChangeRequestView = 'Change Request Details',
  MfaCreate = 'Setup MFA',
  UserEdit = 'Edit User',
  UserCreate = 'Add New User',
  ApiCredentials = 'API Credentials',
  UserView = 'View User',
  UserChangePassword = 'Change Password',
  EditSSO = 'Edit SSO Group',
  SsoGroupMappingCreate = 'Set Up SSO Connection',
  AlertDetail = 'Alert Details',
  DashboardCustomDateRange = 'Custom Date Range',
}

export enum ConfirmationModalTitle {
  UserDeleteConfirm = 'Delete User',
  CloudConnectionDelete = 'Delete Cloud Connection',
  SsoGroupMappingDeleteConfirm = 'Delete SSO Group',
  CloudConnectionDeleteConfirm = 'Delete Cloud Connection',
  UserMfaDeleteConfirm = 'Delete MFA',
  UserMfaCreateConfirm = 'Confirm MFA',
  SsoDeleteConfirm = 'Delete SSO Connection',
  ApiCredentialsDelete = 'Delete API Credentials',
}
