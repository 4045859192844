import { Close as CloseIcon } from '@mui/icons-material';
import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from 'assets/images/search-icon.svg?react';
import React, { FC, useEffect, useState } from 'react';

import { useStyles } from './SearchInput.styles';

export type SearchInputProps = {
  onClear?: () => void;
} & TextFieldProps;

export const SearchInput: FC<React.PropsWithChildren<SearchInputProps>> = ({ value, onClear, ...props }) => {
  const { classes } = useStyles();
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    if (!shrink && value) {
      setShrink(true);
    }
  }, [shrink, value]);

  return (
    <TextField
      value={value}
      className={classes.root}
      size="small"
      variant="outlined"
      onFocus={() => setShrink(true)}
      onBlur={(e) => setShrink(!!e.target.value)}
      InputLabelProps={{ shrink }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start" className={classes.closeIcon}>
            {value ? <CloseIcon onClick={onClear} cursor="pointer" /> : <Box />}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
