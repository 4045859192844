import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ size: 'small' | 'large' }>()((theme: Theme, { size }) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(13.5),
    height: theme.spacing(13.5),
    margin: theme.spacing(0.5),
    fontSize: size === 'large' ? theme.spacing(5) : 0,
    backgroundColor: theme.custom.colors.profileAvatarBg,

    '& .MuiSvgIcon-root': {
      margin: size === 'large' ? theme.spacing(-1.5, 0, 0, 0) : 0,
      color: theme.custom.colors.profileAvatarColor,
      width: theme.spacing(size === 'large' ? 7 : 4),
      height: theme.spacing(size === 'large' ? 7 : 4),
    },
  },

  userNameLabel: {
    fontWeight: FontWeight.Medium,
    fontSize: 12,
    lineHeight: '26px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.wizard.active,
    padding: theme.spacing(0.25, 1.25),
    borderRadius: theme.spacing(3.75),
    border: `2px solid ${theme.palette.background.paper}`,
    zIndex: 1,
  },

  username: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(-3.25),
  },
}));
