import { createSlice } from '@reduxjs/toolkit';
import { StoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { clearAllReducer, removeAllGridFiltersReducer, removeFilterReducer, setFilterReducer } from './reducers';

export const dataGridFiltersState = createSlice({
  name: StoreName.DataGridFilters,
  initialState,
  reducers: {
    setFilter: setFilterReducer,
    removeFilter: removeFilterReducer,
    removeAllGridFilters: removeAllGridFiltersReducer,
    clearAll: clearAllReducer,
  },
});

export const dataGridFiltersReducers = { ...dataGridFiltersState.actions };
