import { Theme } from '@mui/material/styles';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {},
    '& .MuiBackdrop-root': {
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiPaper-root': {
      borderColor: theme.custom.border.secondary,
      borderRadius: theme.spacing(0.5),
    },
  },
  body: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    height: theme.spacing(5),
    objectFit: 'contain',
  },
  title: {
    ...TYPOGRAPHY.h1Bold,
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(7),
  },
  text: {
    ...TYPOGRAPHY.b1Regular,
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    marginTop: theme.spacing(7),

    '& .MuiButtonBase-root': {
      padding: theme.spacing(1.25, 7),
    },
  },
}));
