import { DashboardCrossCloudEntity, DashboardGlobalEntity, WidgetConfigEntity } from 'app/interfaces/entity/dashboard';
import { omit } from 'lodash';
import { DashboardDto, WidgetConfigDto } from 'modules/monitoring/store/interfaces/dto';

type GlobalDashboardsData = Record<string, DashboardGlobalEntity[]>;

type ExtendWidgetsConfig = WidgetConfigDto & { cloudNames?: string[]; widgetTypeName?: string };

type DashboardEntityExtend = Omit<DashboardDto, 'widgetsConfig'> & {
  location: string;
  dashboardId: string;
  widgetsConfig: ExtendWidgetsConfig[];
};

const CloudNameMapping = {
  aws: 'AWS',
  gcp: 'GCP',
  gpc: 'GCP',
  azure: 'Azure',
  oci: 'OCI',
};

const sortByName = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);

const getCloudName = (name?: string) => {
  return CloudNameMapping[name?.toLowerCase() as keyof typeof CloudNameMapping];
};

const getCrossCloudConnectionNamesAndType = (name: string): { cloudNames: string[]; widgetTypeName: string } => {
  const [cloudNameStr, widgetTypeName] = name.split('_');
  const cloudNames = cloudNameStr.split('/').map(getCloudName);
  return { cloudNames, widgetTypeName };
};

const recalculateWidgetPosition = (widgets: WidgetConfigEntity[]): WidgetConfigEntity[] => {
  const sortedWidgets = widgets.sort(sortByName);

  let row = 1;
  if (widgets.length === 1) {
    return sortedWidgets;
  }

  for (const widgetIndex in sortedWidgets) {
    const widget = sortedWidgets[widgetIndex];

    sortedWidgets[widgetIndex].row = row;
    row = row + widget.sizey;
  }

  return sortedWidgets;
};

const prepareGlobal = (dashboard: DashboardDto): DashboardEntityExtend => {
  const name = dashboard?.name.trim().replace(/cnry/i, '').replace(/\s\s+/g, ' ').trim();

  const [location, connection] = name.split(' ');

  return {
    ...dashboard,
    dashboardId: dashboard?.identifier,
    location: location.toUpperCase(),
    name: getCloudName(connection),
  };
};

const prepareCrossCloud = (dashboard: DashboardDto): DashboardEntityExtend => {
  const location = dashboard?.name.trim().split(' ').pop() as string;

  let widgetsConfig = dashboard?.widgetsConfig;

  if (widgetsConfig && widgetsConfig?.length) {
    widgetsConfig = widgetsConfig.map((widget) => ({
      ...widget,
      ...getCrossCloudConnectionNamesAndType(widget.name),
    }));
  }

  return {
    ...dashboard,
    widgetsConfig,
    dashboardId: dashboard?.identifier,
    location: location?.toUpperCase(),
  };
};

const makeGlobalDashboardObject = (dashboardData: DashboardEntityExtend[]): Record<string, DashboardDto[] | []> => {
  const returnData: Record<string, DashboardDto[] | []> = {};

  for (const dashboard of dashboardData) {
    if (!returnData[dashboard.location]) {
      returnData[dashboard.location] = [];
    }

    (returnData[dashboard.location] as DashboardDto[]).push(omit(dashboard, ['location']));
  }

  return returnData;
};

const mergeGlobalAndCrossCloudDashboard = (
  globalDashboards: Record<string, DashboardDto[]>,
  crossCloudDashboards: DashboardEntityExtend[]
): GlobalDashboardsData => {
  const returnData: GlobalDashboardsData = {};

  for (const location in globalDashboards) {
    const globalDashboardData = globalDashboards[location];
    const crossCloudData = crossCloudDashboards?.find((e) => e.location === location);

    returnData[location] = globalDashboards[location] as DashboardGlobalEntity[];

    for (const dashboardIndex in globalDashboardData) {
      const cloudName = globalDashboardData[dashboardIndex]?.name;

      if (!crossCloudData || !crossCloudData?.widgetsConfig) {
        returnData[location][dashboardIndex].crossClouds = null;
        continue;
      }

      const crossCloudWidgets = crossCloudData.widgetsConfig.filter(({ cloudNames }) => {
        return cloudNames?.includes(cloudName);
      });

      const crossCloudWidgetsData: Record<string, DashboardCrossCloudEntity> = {};
      for (const crossCloudWidget of crossCloudWidgets) {
        const crossCloudName = crossCloudWidget?.cloudNames?.filter((e) => e !== cloudName)?.[0] || '';

        if (!crossCloudWidgetsData[crossCloudName]) {
          crossCloudWidgetsData[crossCloudName] = {
            identifier: `${crossCloudData.identifier}_${crossCloudName}`,
            dashboardId: crossCloudData.dashboardId,
            name: crossCloudName,
            widgetsConfig: [],
          };
        }

        crossCloudWidgetsData[crossCloudName].widgetsConfig.push({
          ...omit(crossCloudWidget, ['widgetTypeName', 'cloudNames']),
          name: crossCloudWidget?.widgetTypeName || '',
        });

        crossCloudWidgetsData[crossCloudName].widgetsConfig = recalculateWidgetPosition(
          crossCloudWidgetsData[crossCloudName].widgetsConfig
        );
      }

      returnData[location][dashboardIndex].crossClouds = Object.values(crossCloudWidgetsData);
    }
  }

  return returnData;
};

export const prepareGlobalDashboards = (data: DashboardDto[]): GlobalDashboardsData => {
  const globalDashboards = data
    .filter((e) => e.name.match(/cnry/i))
    .map(prepareGlobal)
    .sort(sortByName);

  const crossCloudDashboards = data.filter((e) => e.name.match(/cross cloud/i)).map(prepareCrossCloud);
  const formatDashboardData = makeGlobalDashboardObject(globalDashboards);

  return mergeGlobalAndCrossCloudDashboard(formatDashboardData, crossCloudDashboards);
};
