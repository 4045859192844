import { FormHelperText } from '@mui/material';
import React, { FC } from 'react';

import { useStyles } from './FormFieldError.styles';

interface FormFieldErrorProps {
  error: string | undefined;
  keepHeight?: boolean;
}

export const FormFieldError: FC<React.PropsWithChildren<FormFieldErrorProps>> = ({ error = '', keepHeight = true }) => {
  const { classes } = useStyles();

  if (!error && !keepHeight) {
    return null;
  }

  return (
    <FormHelperText variant="standard" className={classes.root}>
      {error}
    </FormHelperText>
  );
};
