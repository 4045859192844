import { TypographyOptions } from '@mui/material/styles/createTypography';
import { FontWeight } from 'app/enums/theme';
import { MONTSERRAT_FONT_NAME } from 'app/theme/self-hosted-fonts';

export const DEFAULT_FONT_FAMILIES = 'Tofu,"Avenir Next","Helvetica Neue",Arial,sans-serif';

export const TYPOGRAPHY: TypographyOptions = {
  googleFonts: [],
  fontFamily: `${MONTSERRAT_FONT_NAME},${DEFAULT_FONT_FAMILIES}`,
  fontSize: 14,
  fontWeightLight: FontWeight.Light,
  fontWeightRegular: FontWeight.Regular,
  fontWeightMedium: FontWeight.Medium,
  fontWeightBold: FontWeight.Bold,
  h1Bold: {
    fontWeight: FontWeight.Bold,
    fontSize: 26,
    lineHeight: '36px',
  },
  h2SemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 20,
    lineHeight: '30px',
  },
  b1SemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 16,
    lineHeight: '28px',
  },
  b1Medium: {
    fontWeight: FontWeight.Medium,
    fontSize: 16,
    lineHeight: '28px',
  },
  b1Regular: {
    fontWeight: FontWeight.Regular,
    fontSize: 16,
    lineHeight: '28px',
  },
  b2SemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: '24px',
  },
  b2Regular: {
    fontWeight: FontWeight.Regular,
    fontSize: 14,
    lineHeight: '24px',
  },
  Button1SemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 15,
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
  Button2SemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 12,
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
  Pagination: {
    fontWeight: FontWeight.Regular,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  PaginationSemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  TableHeaderSemiBold: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 12,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  TableBody: {
    fontWeight: FontWeight.Regular,
    fontSize: 14,
    lineHeight: '24px',
  },
};
