import { Warning } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import { UserEntity } from 'app/interfaces/entity/user';
import { UserAvatar } from 'modules/admin/user-management/components/UserAvatar';
import { getContactPreference, getRoleName } from 'modules/auth/utils';
import { MfaEntity } from 'modules/mfa/store/mfa/interfaces';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CropTextFormatter, ValueOrDefaultFormat } from 'shared/components/Format';
import { LabeledText } from 'shared/components/LabeledText';

import { useStyles } from './UserDetails.styles';

interface UserDetailsProps {
  user: Omit<UserEntity, 'identifier'>;
  mfa?: MfaEntity;
  isMfaRequired?: boolean;
}

export const UserDetails: FC<React.PropsWithChildren<UserDetailsProps>> = ({ user, mfa, isMfaRequired }) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();

  return (
    <Box className={classes.root} data-testid="userDetail">
      <UserAvatar user={user} />
      <Box className={classes.detailsContainer}>
        <Typography variant="h6" typography="h2SemiBold" data-testid="fullName">
          <ValueOrDefaultFormat value={`${user.firstName ?? ''} ${user.lastName ?? ''}`} />
        </Typography>
        <Box display="flex" mt={1} alignItems="center" flexDirection="row" justifyContent="center">
          <CropTextFormatter title={user?.email}>
            <Typography
              alignItems="center"
              variant="body2"
              color="text.secondary"
              typography="b2SemiBold"
              data-testid="email"
            >
              <ValueOrDefaultFormat value={user?.email} />
            </Typography>
          </CropTextFormatter>
        </Box>
      </Box>

      <Box className={classes.detailsContainer}>
        <LabeledText label={t('admin.user.components.UserDetails.rolesPermissions')}>
          <Stack direction="row" className={classes.options} data-testid="roles">
            {user?.roles?.map((role, key) => (
              <Chip data-testid="roleType" key={key} className={classes.optionChip} label={getRoleName(role)} />
            ))}
          </Stack>
        </LabeledText>
      </Box>
      {user?.contactPreferences !== undefined && user?.contactPreferences.length > 0 && (
        <Box className={classes.detailsContainer}>
          <LabeledText label={t('admin.user.components.UserDetails.contactPrefences')}>
            <Stack direction="row" className={classes.options} data-testid="contactPreferences">
              {user?.contactPreferences?.map((preference, key) => (
                <Chip
                  data-testid="contactPreferenceType"
                  key={key}
                  className={classes.optionChip}
                  label={getContactPreference(preference) ?? 'None'}
                />
              ))}
            </Stack>
          </LabeledText>
        </Box>
      )}
      {user.phone && (
        <Box className={classes.detailsContainer}>
          <LabeledText
            mt={1}
            label={t('admin.user.components.UserDetails.phone')}
            value={user.phone}
            data-testid="phone"
          />
        </Box>
      )}
      {user.timezone && (
        <Box className={classes.detailsContainer}>
          <LabeledText
            mt={1}
            label={t('admin.user.components.UserDetails.timezone')}
            value={user.timezone}
            data-testid="timeZone"
          />
        </Box>
      )}
      {isMfaRequired && (
        <Box className={classes.detailsContainer}>
          <LabeledText mt={1} label={t('admin.user.components.UserDetails.mfaLabel')}>
            {mfa?.mfaEnabled ? (
              <ValueOrDefaultFormat value={mfa?.email} />
            ) : (
              <Typography variant="subtitle2" className={classes.iconWrapper} data-testid="mfaNotConfigured">
                <Warning className={classes.icon} /> {t('admin.user.components.UserDetails.mfaNotConfigured')}
              </Typography>
            )}
          </LabeledText>
        </Box>
      )}
    </Box>
  );
};
