import { CloudConnectionStatus } from 'app/enums/cloud-connection';
import { CloudConnectionDto, RequestType } from 'modules/cloud-services/store/cloud-connections/interfaces/dto';

export const getActualRequestedChanges = (
  data: CloudConnectionDto
): { bandwidth?: number; crossCloud?: boolean } | undefined => {
  const requestedChanges = data?.requestedChanges || [];

  if (data.state === CloudConnectionStatus.Active) {
    return;
  }

  if (data.state === CloudConnectionStatus.Provisioning) {
    const newRequest = requestedChanges.find((r) => r.requestType === RequestType.New);
    return {
      bandwidth: newRequest?.bandwidth,
      crossCloud: newRequest?.crossCloud,
    };
  }

  if (data.state === CloudConnectionStatus.ChangeRequested) {
    const changeBandwidth = requestedChanges.find((r) => r.requestType === RequestType.AdjustBandwidth);
    const changeCrossCloud = requestedChanges.find((r) => r.requestType === RequestType.AdjustCrossCloud);

    return {
      bandwidth: changeBandwidth?.bandwidth,
      crossCloud: changeCrossCloud?.crossCloud,
    };
  }
};
