import { sessionSelector } from 'app/store/session/selectors';
import dayjs from 'dayjs';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getFormatData } from 'shared/components/Format/DateTimeFormat/utils';

interface DateTimeFormatProps {
  date: string;
  format?: string;
  text?: string;
  skipTz?: boolean;
  parseFormat?: string;
  showTz?: boolean;
}

const DateTimeFormatComponent: FC<React.PropsWithChildren<DateTimeFormatProps>> = (props) => {
  const user = useSelector(sessionSelector.userProfile);
  const userTZ = user?.timezone || dayjs.tz.guess();

  const formatData = getFormatData({ ...props, timeZone: userTZ });

  return <>{formatData}</>;
};

export const DateTimeFormat = memo(DateTimeFormatComponent);
