import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { store } from 'app/store';
import { entityStoreStatus } from 'app/utils/store';
import { SsoDeleteRequest } from 'modules/admin/sso-management/store/sso/interfaces';
import {
  createRequestToDtoMapper,
  dtoToEntityMapper,
  updateRequestToDtoMapper,
} from 'modules/admin/sso-management/store/sso-group-mappings/dto-mappers';
import {
  SsoGroupMappingCreateRequest,
  SsoGroupMappingCreateUpdateDto,
  SsoGroupMappingDeleteRequest,
  SsoGroupMappingDto,
  SsoGroupMappingEntity,
  SsoGroupMappingsState,
  SsoGroupMappingUpdateRequest,
} from 'modules/admin/sso-management/store/sso-group-mappings/interfaces';

import { entityAdapter } from './adapter';

export const getSsoGroupMappingsList = createAsyncThunk(
  `${EntityStoreName.SsoGroupMappings}/getList`,
  (_, { signal }): Promise<SsoGroupMappingEntity[]> => {
    const organizationId = store.getState().session.organizationId;

    return apiRequestCall<SsoGroupMappingDto[], SsoGroupMappingEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.SsoGroupMappings}`,
        signal,
      },
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const deleteSsoGroupMapping = createAsyncThunk(
  `${EntityStoreName.SsoGroupMappings}/delete`,
  async ({ identifier, successRequestMessage }: SsoGroupMappingDeleteRequest): Promise<Identifier> => {
    const organizationId = store.getState().session.organizationId as Identifier;

    return apiRequestCall<Identifier, SsoDeleteRequest>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.SsoGroupMappings}/${identifier}`,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Organizations, organizationId, ApiResource.SsoGroupMappings]],
      successRequestMessage,
    }).then(() => identifier);
  }
);

export const createSsoGroupMapping = createAsyncThunk(
  `${EntityStoreName.SsoGroupMappings}/create`,
  async ({ successRequestMessage, ...payload }: SsoGroupMappingCreateRequest): Promise<SsoGroupMappingEntity> => {
    const organizationId = store.getState().session.organizationId;

    return apiRequestCall<SsoGroupMappingDto, SsoGroupMappingCreateUpdateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.SsoGroupMappings}`,
        data: createRequestToDtoMapper(payload),
      },
      successRequestMessage,
    }).then(dtoToEntityMapper);
  }
);

export const updateSsoGroupMapping = createAsyncThunk(
  `${EntityStoreName.SsoGroupMappings}/update`,
  async ({ successRequestMessage, ...payload }: SsoGroupMappingUpdateRequest): Promise<SsoGroupMappingEntity> => {
    const organizationId = store.getState().session.organizationId as Identifier;

    return apiRequestCall<SsoGroupMappingDto, SsoGroupMappingCreateUpdateDto>({
      params: {
        method: 'PUT',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.SsoGroupMappings}/${payload.identifier}`,
        data: updateRequestToDtoMapper(payload),
      },
      successRequestMessage,
      invalidationKeys: [[ApiVersion.V1, ApiResource.Organizations, organizationId, ApiResource.SsoGroupMappings]],
    }).then(dtoToEntityMapper);
  }
);

export const registerReducers = (builder: ActionReducerMapBuilder<SsoGroupMappingsState>): void => {
  builder
    .addCase(getSsoGroupMappingsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getSsoGroupMappingsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getSsoGroupMappingsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(deleteSsoGroupMapping.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteSsoGroupMapping.fulfilled, (state, action) => {
      entityAdapter.removeOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteSsoGroupMapping.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(createSsoGroupMapping.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createSsoGroupMapping.fulfilled, (state, action) => {
      entityAdapter.addOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createSsoGroupMapping.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(updateSsoGroupMapping.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateSsoGroupMapping.fulfilled, (state, action) => {
      entityAdapter.upsertOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateSsoGroupMapping.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    });
};
