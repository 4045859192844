export enum TicketStatus {
  New = 'New',
  InProgress = 'InProgress',
  OnHold = 'OnHold',
  Resolved = 'Resolved',
  Closed = 'Closed',
  Canceled = 'Canceled',
  Open = 'Open',
  Acknowledged = 'Acknowledged',
  Assigned = 'Assigned',
  Monitoring = 'Monitoring',
  AwaitingInfo = 'AwaitingInfo',
}
