import { TicketEntity } from 'app/interfaces/entity/ticket';

import { TicketCreateRequest } from './interfaces';
import { CreateTicketDto, TicketDto } from './interfaces/dto';

export const dtoToEntityMapper = (data: TicketDto): TicketEntity => {
  return data;
};

export const requestToDtoMapper = (data: TicketCreateRequest): CreateTicketDto => {
  return {
    type: data.type,
    title: data.title,
    description: data.description,
  };
};
