import { Box, Button, Tooltip } from '@mui/material';
import { ConfirmationModalName, ConfirmationModalTitle, ModalName, ModalTitle } from 'app/enums';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { useDispatchMultiple, useEntityRepositoryStatus, useFetchData } from 'app/hooks';
import { LangNameSpace } from 'app/i18n';
import { UserEntity } from 'app/interfaces/entity/user';
import { pingIdentityClient } from 'app/services/ping-identity';
import { modalReducers } from 'app/store/modal/slice';
import { legacyProfileToUserEntityMapper } from 'app/store/session/dto-mappers';
import { sessionSelector } from 'app/store/session/selectors';
import { sessionReducers } from 'app/store/session/slice';
import { dispatchUnwrap } from 'app/utils/store';
import { SsoGroupMappingDeleteConfirmModalData } from 'modules/admin/sso-management/interfaces';
import { UserAvatarUpload } from 'modules/admin/user-management/components/UserAvatarUpload';
import { UserDetails } from 'modules/admin/user-management/components/UserDetails';
import { UserUpdateRequest } from 'modules/admin/user-management/store/users/interfaces';
import { updateUser, uploadAvatar } from 'modules/admin/user-management/store/users/repository';
import { deleteMfa, getMfa } from 'modules/mfa/store/mfa/repository';
import { mfaSelector } from 'modules/mfa/store/mfa/selectors';
import { ProfileEditForm } from 'modules/profile/components/ProfileEditForm';
import { PASSWORD_REQUIRED_FIELDS } from 'modules/profile/constants/edit-user';
import { useAvatarUploadForm, useProfileEditForm } from 'modules/profile/hooks';
import { UserProfile } from 'oidc-client-ts';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldError } from 'shared/components/Form/FormFieldError';
import { Loader } from 'shared/components/Loader';
import { LoaderButton } from 'shared/components/LoaderButton';
import { CloseUnsavedConfirmationModal, ConfirmationModal, Modal } from 'shared/components/Modal';
import { withModalOpenChecker } from 'shared/hocs/ModalOpenChecker';
import { safePick } from 'shared/utils';

import { getEdited } from '../../utils/getEdited';
import { PasswordConfirmationModal as PasswordConfirmationModal } from '../PasswordConfirmationModal/PasswordConfirmationModal';
import { useStyles } from './ProfileModal.styles';

export const ProfileModalComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const dispatchMultiple = useDispatchMultiple();
  const [editMode, setEditMode] = useState(false);
  const saving = useEntityRepositoryStatus({
    [EntityStoreName.Users]: EntityStoreRequestType.Updating,
  });

  const user = useSelector(sessionSelector.userProfile);
  const mfa = useSelector(mfaSelector.get);
  const isSsoUser = useSelector(sessionSelector.isSsoUser);
  const isMfaConfigurationRequired = useSelector(sessionSelector.isMfaConfigurationRequired);
  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const isMfaInitialized = useSelector(mfaSelector.isInitialized);
  const mfaActions = useMemo(() => [getMfa()], []);
  const userOrganizationId = useSelector(sessionSelector.userOrganizationId);

  const profileForm = useProfileEditForm();
  const avatarUploadForm = useAvatarUploadForm();

  const { fetch: fetchMfa, loading } = useFetchData(mfaActions, {
    fetchOnInit: false,
    fetchOnOrganizationChange: false,
  });

  const {
    formState: { isValid: isProfileFormValid, isDirty: isProfileFormDirty },
    reset: resetProfileForm,
  } = profileForm;
  const {
    formState: { isValid: isAvatarUploadFormValid, isDirty: isAvatarUploadFormDirty, errors: avatarUploadFormErrors },
    reset: resetAvatarUploadForm,
    setValue: setAvatarValue,
    register: registerAvatarUploadFields,
  } = avatarUploadForm;

  const isSaveDisabled = useMemo(
    () => saving || !isProfileFormValid || !isAvatarUploadFormValid || !(isAvatarUploadFormDirty || isProfileFormDirty),
    [saving, isProfileFormValid, isAvatarUploadFormValid, isProfileFormDirty, isAvatarUploadFormDirty]
  );

  const onAvatarChange = useCallback(
    (file: File) => {
      if (!file) {
        return;
      }

      setAvatarValue('photoData', file, { shouldDirty: true, shouldValidate: true });
    },
    [setAvatarValue]
  );

  useEffect(() => {
    if (!isMfaInitialized && isAuthorized) {
      fetchMfa();
    }
  }, [isMfaInitialized, fetchMfa, isAuthorized]);

  const resetForm = useCallback(() => {
    resetProfileForm({
      ...safePick(user, ['firstName', 'lastName', 'email', 'phone', 'timezone', 'contactPreferences']),
    });
    resetAvatarUploadForm();
    setEditMode(false);
  }, [resetAvatarUploadForm, resetProfileForm, user]);

  const saveProfile = useCallback(
    (updatedUser: UserUpdateRequest, password = '') =>
      dispatchUnwrap(
        updateUser({
          ...updatedUser,
          password,
          organizationId: userOrganizationId,
          successRequestMessage: t('profile.components.ProfileModal.successRequestMessages.profileUpdate'),
        }),
        {
          success: () => {
            return pingIdentityClient.signinSilent().then((dataUser) => {
              dispatchMultiple([
                sessionReducers.setAuthToken(dataUser?.access_token),
                sessionReducers.setUserProfile({ ...user, ...updatedUser } as UserEntity),
              ]);
              setEditMode(false);
            });
          },
        }
      ),
    [t, dispatchMultiple, userOrganizationId, user]
  );

  const submitHandler = useCallback(
    (password = '') => {
      const modifiedValues = getEdited(profileForm.getValues(), user);
      const passwordRequired = Object.keys(modifiedValues).some((key) => PASSWORD_REQUIRED_FIELDS.includes(key));
      if (passwordRequired && password === '') {
        dispatch(
          modalReducers.setOpenConfirmationModal({
            name: ConfirmationModalName.PasswordRequired,
          })
        );
        return;
      }

      const { photoData } = avatarUploadForm.getValues();

      if (photoData?.name != null) {
        return dispatchUnwrap(uploadAvatar({ file: photoData }), {
          success: (avatarUrl: string) =>
            saveProfile(
              {
                identifier: user.identifier,
                ...modifiedValues,
                photoUrl: avatarUrl,
              },
              password
            ),
        });
      }

      return saveProfile(
        {
          identifier: user.identifier,
          ...modifiedValues,
          photoUrl: user.photoUrl,
        },
        password
      );
    },
    [avatarUploadForm, profileForm, saveProfile, user, dispatch]
  );

  const submitWithPassword = useCallback(
    async (password: string) => {
      await submitHandler(password);
    },
    [submitHandler]
  );

  const deleteMfaHandler = useCallback(
    async () =>
      dispatchUnwrap(
        deleteMfa({
          successRequestMessage: t('profile.components.ProfileModal.successRequestMessages.deleteMfa'),
        }),
        {
          success: () => {
            pingIdentityClient.signinSilent().then((dataUser) => {
              dispatchMultiple([
                sessionReducers.setAuthToken(dataUser?.access_token),
                sessionReducers.setUserProfile({
                  ...legacyProfileToUserEntityMapper(dataUser?.profile as UserProfile),
                  mfaEnabled: false,
                }),
              ]);
            });
          },
        }
      ),
    [t, dispatchMultiple]
  );

  const onCloseHandler = useCallback(
    (buttonName: 'close' | 'cancel' = 'close') => {
      const isDirty = isProfileFormDirty || isAvatarUploadFormDirty;

      if (isDirty) {
        dispatch(
          modalReducers.setOpenConfirmationModal({
            name: ConfirmationModalName.CloseUnsavedProfileConfirmationModal,
            data: { windowName: ModalTitle.Profile, buttonName },
          })
        );
      } else {
        resetForm();
      }
      return !isDirty;
    },
    [dispatch, isAvatarUploadFormDirty, isProfileFormDirty, resetForm]
  );

  useEffect(() => {
    if (!editMode) {
      dispatch(modalReducers.updateOpenModalTitle({ title: ModalTitle.Profile }));
    }
  }, [dispatch, editMode]);

  useEffect(() => {
    if (user) {
      resetProfileForm({
        ...safePick(user, ['firstName', 'lastName', 'email', 'phone', 'timezone', 'contactPreferences']),
      });
      resetAvatarUploadForm();
    }
  }, [resetAvatarUploadForm, resetProfileForm, user]);

  return (
    <>
      <Modal
        className={classes.modal}
        isCloseButtonAvailable={false}
        headerMode={true}
        name={ModalName.Profile}
        onCloseRequest={onCloseHandler}
        maxWidth="xs"
        actionsBlockFullWidth={!editMode}
        actions={
          editMode ? (
            <>
              <LoaderButton
                data-testid="cancelBtn"
                variant="outlined"
                color="primary"
                onClick={() => onCloseHandler('cancel')}
                disabled={saving}
              >
                {t('profile.components.ProfileModal.cancelBtn')}
              </LoaderButton>
              <LoaderButton
                data-testid="saveBtn"
                isLoading={saving}
                variant="contained"
                color="primary"
                onClick={() => submitHandler()}
                disabled={isSaveDisabled}
                className={classes.saveBtn}
              >
                {t('profile.components.ProfileModal.saveBtn')}
              </LoaderButton>
            </>
          ) : (
            <Box className={cx(classes.actions, 'fullWidth')}>
              <Tooltip
                title={isSsoUser ? t('profile.components.ProfileModal.tooltipTitle') : ''}
                placement="bottom"
                arrow
                className={classes.actionButton}
              >
                <span>
                  <Button
                    component="button"
                    color="primary"
                    variant="contained"
                    disabled={isSsoUser}
                    className={classes.editProfileLink}
                    onClick={() => {
                      setEditMode(true);
                      dispatch(modalReducers.updateOpenModalTitle({ title: ModalTitle.ProfileEdit }));
                    }}
                    data-testid="editProfile"
                  >
                    {t('profile.components.ProfileModal.editProfileBtn')}
                  </Button>
                </span>
              </Tooltip>
              {!isSsoUser && isMfaConfigurationRequired && (
                <Button
                  component="button"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() =>
                    dispatch(modalReducers.setOpenModal({ name: ModalName.MfaCreate, title: ModalTitle.MfaCreate }))
                  }
                >
                  {t('profile.components.ProfileModal.setMfaBtn')}
                </Button>
              )}
              {!isSsoUser && !isMfaConfigurationRequired && (
                <Button
                  component="button"
                  color="primary"
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() =>
                    dispatch(
                      modalReducers.setOpenConfirmationModal({
                        name: ConfirmationModalName.UserMfaDeleteConfirm,
                        title: ConfirmationModalTitle.UserMfaDeleteConfirm,
                        data: { name: mfa?.email },
                      })
                    )
                  }
                >
                  {t('profile.components.ProfileModal.removeMfaBtn')}
                </Button>
              )}
            </Box>
          )
        }
        data-testid="profileModal"
      >
        <Box className={classes.root}>
          {loading ? (
            <Loader fullPage={false} />
          ) : editMode ? (
            <>
              <Box className={classes.readonlyBlock}>
                <UserAvatarUpload
                  user={user}
                  {...registerAvatarUploadFields('photoData')}
                  disabled={saving}
                  onChange={onAvatarChange}
                />
                <FormFieldError error={avatarUploadFormErrors?.photoData?.message} />
              </Box>
              <Box className={classes.profileFormContainer}>
                <ProfileEditForm form={profileForm} />
              </Box>
            </>
          ) : (
            <>
              <UserDetails user={user} mfa={mfa} isMfaRequired={!isSsoUser} />
            </>
          )}
        </Box>
      </Modal>
      <CloseUnsavedConfirmationModal
        name={ConfirmationModalName.CloseUnsavedProfileConfirmationModal}
        onSuccess={resetForm}
      />
      <ConfirmationModal
        name={ConfirmationModalName.UserMfaDeleteConfirm}
        title={t('profile.components.ProfileModal.confirmation.title')}
        text={({ name }: SsoGroupMappingDeleteConfirmModalData) =>
          t('profile.components.ProfileModal.confirmation.text', { name })
        }
        successBtnTitle={t('profile.components.ProfileModal.confirmation.btn')}
        onSubmit={deleteMfaHandler}
      />
      <PasswordConfirmationModal onSubmit={submitWithPassword} />
    </>
  );
};

export const ProfileModal = withModalOpenChecker(ProfileModalComponent, ModalName.Profile);
