import { Autocomplete, AutocompleteProps, Checkbox, Chip, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { useStyles } from 'shared/components/Form/SelectInput/SelectInput.styles';

interface SelectInputProps
  extends Omit<AutocompleteProps<string, true, false, true>, 'renderInput' | 'getOptionLabel'> {
  inputProps: TextFieldProps;
  getOptionLabel: (option: string) => string;
  fixedOptions?: string[];
  label?: string;
}

export const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  ({ fixedOptions = [], getOptionLabel, options, inputProps, onChange, ...rest }, ref) => {
    const { classes } = useStyles();

    return (
      <Autocomplete
        className={classes.root}
        size="small"
        multiple
        disableCloseOnSelect
        options={options}
        onChange={(event, newValue) => {
          onChange?.(
            event,
            [...fixedOptions, ...newValue.filter((option) => fixedOptions.indexOf(option) === -1)],
            'selectOption'
          );
        }}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, { selected }) => (
          <li {...props} className={classes.listItem}>
            <Checkbox
              size="small"
              checked={fixedOptions.includes(option) || selected}
              disabled={fixedOptions.includes(option)}
            />
            {getOptionLabel?.(option)}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              {...getTagProps({ index })}
              label={getOptionLabel?.(option)}
              key={index}
              disabled={fixedOptions.includes(option)}
              data-testid="selectTag"
            />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="outlined" {...inputProps} ref={ref} />}
        {...rest}
      />
    );
  }
);
