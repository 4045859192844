import { EntityStoreName } from 'app/enums/store';
import { AppState } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';
import { MfaState } from 'modules/mfa/store/mfa/interfaces';
import { createSelector } from 'reselect';

const mfaState = (state: AppState): MfaState => state[EntityStoreName.Mfa];

const get = createSelector(mfaState, (state) => state.entity);

const isEmpty = createSelector(mfaState, (state) => state.empty);

const isInitialized = createSelector(
  mfaState,
  sessionSelector.isSsoUser,
  (state, required) => required || state.empty !== null
);

const isConfigured = createSelector(mfaState, (state) => state?.entity?.email && state?.entity?.mfaEnabled);

export const mfaSelector = {
  get,
  isConfigured,
  isInitialized,
  isEmpty,
};
