import { TimeScaleType } from 'app/enums';
import dayjs from 'dayjs';

const timescaleToLabelFormat = new Map([
  [TimeScaleType.hour, 'HH:mm'],
  [TimeScaleType.day, 'MMM DD'],
  [TimeScaleType.month, 'MMM DD'],
  [TimeScaleType.year, 'MMM YY'],
]);

export const xAxisFormatterFactory = (scaleType: TimeScaleType = TimeScaleType.hour) => {
  let prevDate: number | undefined;

  return (time: number, index: number) => {
    if (index === 0) {
      prevDate = undefined;
    }
    const curDay = parseInt(dayjs(time).format('DD'));
    const isNewDay = scaleType === TimeScaleType.hour && prevDate && curDay > prevDate;

    prevDate = curDay;
    return dayjs(time).format(timescaleToLabelFormat.get(isNewDay ? TimeScaleType.day : scaleType));
  };
};
