import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { SsoEntity } from 'app/interfaces/entity/sso.entity';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { store } from 'app/store';
import { entityStoreStatus } from 'app/utils/store';
import { initialState } from 'modules/admin/sso-management/store/sso/adapter';
import {
  createRequestToDtoMapper,
  dtoToEntityMapper,
  updateRequestToDtoMapper,
} from 'modules/admin/sso-management/store/sso/dto-mappers';
import {
  SsoCreateRequest,
  SsoDeleteRequest,
  SsoState,
  SsoUpdateRequest,
} from 'modules/admin/sso-management/store/sso/interfaces';
import { SsoCreateDto, SsoDto, SsoUpdateDto } from 'modules/admin/sso-management/store/sso/interfaces/dto';

export const createSso = createAsyncThunk(
  `${EntityStoreName.Sso}/create`,
  async ({ successRequestMessage, ...payload }: SsoCreateRequest): Promise<SsoEntity> => {
    const organizationId = store.getState().session.organizationId as Identifier;

    return apiRequestCall<SsoDto, SsoCreateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Sso}`,
        data: createRequestToDtoMapper(payload),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Organizations, organizationId, ApiResource.Sso]],
      successRequestMessage,
    }).then(dtoToEntityMapper);
  }
);

export const updateSso = createAsyncThunk(
  `${EntityStoreName.Sso}/update`,
  async ({ successRequestMessage, ...payload }: SsoUpdateRequest): Promise<SsoEntity> => {
    const organizationId = store.getState().session.organizationId as Identifier;

    return apiRequestCall<SsoDto, SsoUpdateDto>({
      params: {
        method: 'PUT',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Sso}`,
        data: updateRequestToDtoMapper(payload),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Organizations, organizationId, ApiResource.Sso]],
      successRequestMessage,
    }).then(dtoToEntityMapper);
  }
);

export const deleteSso = createAsyncThunk(
  `${EntityStoreName.Sso}/delete`,
  async ({ successRequestMessage }: SsoDeleteRequest): Promise<Identifier> => {
    const organizationId = store.getState().session.organizationId as Identifier;

    return apiRequestCall<Identifier, SsoDeleteRequest>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Sso}`,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Organizations, organizationId, ApiResource.Sso]],
      successRequestMessage,
    });
  }
);

export const getSso = createAsyncThunk(`${EntityStoreName.Sso}/get`, (_, { signal }): Promise<SsoEntity> => {
  const organizationId = store.getState().session.organizationId;

  return apiRequestCall<SsoDto, SsoEntity>({
    params: {
      method: 'GET',
      apiVersion: ApiVersion.V1,
      resource: ApiResource.Organizations,
      urlPrefix: `${organizationId}/${ApiResource.Sso}`,
      signal,
    },
  }).then(dtoToEntityMapper);
});

export const registerReducers = (builder: ActionReducerMapBuilder<SsoState>): void => {
  builder
    .addCase(createSso.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createSso.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.empty = false;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createSso.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(updateSso.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateSso.fulfilled, (state, action) => {
      state.entity = action.payload;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateSso.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(getSso.pending, (state, action) => {
      state.empty = true;
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getSso.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.empty = false;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getSso.rejected, (state, action) => {
      state.empty = true;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(deleteSso.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteSso.fulfilled, (state, action) => {
      state.entity = initialState.entity;
      state.empty = true;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteSso.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    });
};
