import { Box, Button } from '@mui/material';
import { Route } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './NotFound.styles';

export const NotFound: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(LangNameSpace.App);

  const clickHandler = (): void => {
    navigate(Route.Root);
  };

  return (
    <Box className={classes.root}>
      <h1 className={classes.title}>{t('components.NotFound.title')}</h1>
      <p className={classes.text}>
        <Trans i18nKey="components.NotFound.text" ns={LangNameSpace.App} values={{ value: window.location.pathname }} />
      </p>
      <Button onClick={clickHandler} type="button" variant="contained" color="primary">
        {t('components.NotFound.btnText')}
      </Button>
    </Box>
  );
};
