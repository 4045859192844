import { i18n, LangNameSpace } from 'app/i18n';
import dayjs from 'dayjs';

interface DateTimeFormatProps {
  date: string;
  format?: string;
  text?: string;
  skipTz?: boolean;
  parseFormat?: string;
  showTz?: boolean;
  timeZone?: string;
}

export const getFormatData = ({
  date,
  format = 'MMM D, YYYY h:mm A',
  text,
  skipTz = false,
  parseFormat,
  showTz,
  timeZone,
}: DateTimeFormatProps) => {
  const userTZ = timeZone || dayjs.tz.guess();
  const utcDate = dayjs(date, parseFormat).utc(true);

  if (!utcDate.isValid()) {
    return i18n.t('components.Format.DateTime.errMsg', { ns: LangNameSpace.Shared });
  }

  const showDate = skipTz ? utcDate : utcDate.tz(userTZ);

  return `${text ? `${text} ` : ''}${showDate.format(format)}${showTz ? ` (${userTZ})` : ''}`;
};
