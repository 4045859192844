import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  input: {
    display: 'none',
  },

  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '&:hover': {
      '& .add-button': {
        visibility: 'visible',
      },
      '& .userAvatar': {
        filter: `brightness(75%) blur(${theme.spacing(0.5)})`,
      },
    },

    '& .add-button': {
      display: 'flex',
      justifyContent: 'center',
      visibility: 'hidden',
      position: 'absolute',
      width: theme.spacing(16.5),
      height: theme.spacing(16.5),
      borderStyle: 'solid',
      borderWidth: 0,
      borderRadius: '50%',
      borderColor: 'transparent',
      overflow: 'hidden',
      zIndex: 1,
    },
  },

  actionButton: {
    flex: 1,
    borderRadius: 0,
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
      backgroundColor: 'transparent',
    },
  },

  avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
