import { sessionSelector } from 'app/store/session/selectors';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Role } from '../enums';
import { hasAllRoles as hasAllRolesUtil, hasAnyRole as hasAnyRoleUtil } from '../utils';

export interface UseRolesHook {
  userRoles: string[];
  hasAllRoles: (roles: string[]) => boolean;
  hasAnyRole: (roles: string[]) => boolean;
  isChangeOrganizationAllowed: boolean;
}

export const useRoles = (): UseRolesHook => {
  const userRoles = useSelector(sessionSelector.userRoles);

  const hasAllRoles = useCallback((roles: string[]) => hasAllRolesUtil(roles, userRoles), [userRoles]);
  const hasAnyRole = useCallback((roles: string[]) => hasAnyRoleUtil(roles, userRoles), [userRoles]);

  const isChangeOrganizationAllowed = useMemo(() => hasAnyRole([Role.Master_Admin, Role.Group_Admin]), [hasAnyRole]);

  return { userRoles, hasAllRoles, hasAnyRole, isChangeOrganizationAllowed };
};
