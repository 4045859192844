import { Box, FormControl, FormGroup, Link, TextField, Typography } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import { MfaVerificationFormFields } from 'modules/mfa/interfaces';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Trans, useTranslation } from 'react-i18next';
import { FormFieldError } from 'shared/components/Form/FormFieldError';

import { useStyles } from './MfaVerificationForm.styles';

interface MfaCreateFormProps {
  form: UseFormReturn<MfaVerificationFormFields>;
  email: string;
  onSubmitResendEmailHandler: () => unknown;
  disabledResendBtn: boolean;
}

export const MfaVerificationForm: FC<React.PropsWithChildren<MfaCreateFormProps>> = ({
  form,
  email,
  onSubmitResendEmailHandler,
  disabledResendBtn,
}) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();
  const { control } = form;

  return (
    <>
      <Box className={classes.detailsContainer}>
        <Typography variant="body2">
          <Trans i18nKey="mfa.components.MfaVerificationForm.text" ns={LangNameSpace.Modules} values={{ email }} />
        </Typography>
      </Box>
      <form>
        <FormGroup className={classes.formGroup}>
          <Controller
            name="confirmationCode"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl className="form-field" size="small">
                <TextField
                  required
                  size="small"
                  label={t('mfa.components.MfaVerificationForm.labels.confirmationCode')}
                  error={invalid}
                  data-testid="confirmationCode"
                  {...field}
                />
                <FormFieldError error={error?.message} />
              </FormControl>
            )}
          />
        </FormGroup>
        <Box className={classes.linkBlock}>
          <Link
            component="button"
            color="primary"
            variant="body2"
            className={classes.linkAction}
            onClick={onSubmitResendEmailHandler}
            disabled={disabledResendBtn}
            data-testid="resendEmail"
          >
            {t('mfa.components.MfaCreateModal.actions.resendEmail')}
          </Link>
        </Box>
      </form>
    </>
  );
};
