import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { TicketCommentEntity } from 'app/interfaces/entity/ticket';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { entityStoreStatus } from 'app/utils/store';
import { TicketCommentCreateRequest } from 'modules/tickets/interfaces';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper, requestToDtoMapper } from './dto-mappers';
import { TicketCommentsState } from './interfaces';
import { CreateTicketCommentDto, TicketCommentDto } from './interfaces/dto';

export const getTicketCommentsList = createAsyncThunk(
  `${EntityStoreName.TicketComments}/getList`,
  (ticketIdentifier: Identifier, { signal }): Promise<TicketCommentEntity[]> => {
    return apiRequestCall<TicketCommentDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        urlPrefix: `${ticketIdentifier}/${ApiResource.Comments}`,
        signal,
      },
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const createTicketComment = createAsyncThunk(
  `${EntityStoreName.TicketComments}/create`,
  ({ ticketIdentifier, text, ...rest }: TicketCommentCreateRequest): Promise<TicketCommentEntity> =>
    apiRequestCall<TicketCommentDto, CreateTicketCommentDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        urlPrefix: `${ticketIdentifier}/${ApiResource.Comments}`,
        data: requestToDtoMapper({ text }),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Tickets, ticketIdentifier, ApiResource.Comments]],
      ...rest,
    }).then(dtoToEntityMapper)
);

export const clearAllReducer = (state: TicketCommentsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<TicketCommentsState>): void => {
  builder
    .addCase(getTicketCommentsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketCommentsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketCommentsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createTicketComment.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicketComment.fulfilled, (state, action) => {
      if (action.payload) {
        entityAdapter.addOne(state, action.payload);
      }
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicketComment.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    });
};
