import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { FC } from 'react';

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey;
}

export const SnackbarCloseButton: FC<React.PropsWithChildren<SnackbarCloseButtonProps>> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton data-testid="closeSnackbar" size="medium" onClick={() => closeSnackbar(snackbarKey)}>
      <Close fontSize="medium" />
    </IconButton>
  );
};
