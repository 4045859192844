import { Box, Typography } from '@mui/material';
import { UserEntity } from 'app/interfaces/entity/user';
import { UserAvatar } from 'modules/admin/user-management/components/UserAvatar';
import React, { FC } from 'react';
import { CommonUserTitle } from 'shared/components/CommonUserTitle';
import { ValueOrDefaultFormat } from 'shared/components/Format';

import { useStyles } from './ProfileDetails.styles';

interface ProfileAvatarProps {
  user: Omit<UserEntity, 'identifier'>;
  showName?: boolean;
}

export const ProfileDetails: FC<React.PropsWithChildren<ProfileAvatarProps>> = ({ user }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <UserAvatar user={user} showName={false} className={classes.avatar} size="small" />
      <Box className={classes.userInfo}>
        <CommonUserTitle user={user} className={classes.userNameLabel} />
        <Typography variant="subtitle2" data-testid="email" className={classes.email}>
          <ValueOrDefaultFormat value={user.email} />
        </Typography>
      </Box>
    </Box>
  );
};
