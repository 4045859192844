export enum AlertsGridField {
  FirstColumn = 'firstColumn',
  Severity = 'severity',
  Began = 'startEpoch',
  ResourceWebsite = 'monitorObjectName',
  LogicModule = 'resourceTemplateName',
  Instance = 'instanceName',
  Datapoint = 'dataPointName',
  Value = 'alertValue',
  StaticThreshold = 'threshold',
  DynamicThreshold = 'dynamicThresholds',
  Notes = 'ackComment',
  AcknowledgedBy = 'ackedBy',
  AcknowledgedOn = 'ackedEpoch',
  ClearedOn = 'endEpoch',
  InSDT = 'sdted',
  AlertRule = 'rule',
  EscalationChain = 'chain',
  InstanceDescription = 'instanceDescription',
  RoutingState = 'dependencyRoutingState',
  DependencyRole = 'dependencyRole',
  DependentAlerts = 'dependentAlerts',
  FullPath = 'monitorObjectGroups.fullPath',
  Group = 'monitorObjectGroups.name',
}
