import {
  SsoGroupMappingCreateRequest,
  SsoGroupMappingCreateUpdateDto,
  SsoGroupMappingDto,
  SsoGroupMappingEntity,
  SsoGroupMappingUpdateRequest,
} from 'modules/admin/sso-management/store/sso-group-mappings/interfaces';

export const dtoToEntityMapper = (data: SsoGroupMappingDto): SsoGroupMappingEntity => {
  return data;
};

export const createRequestToDtoMapper = (data: SsoGroupMappingCreateRequest): SsoGroupMappingCreateUpdateDto => {
  return {
    groupName: data.groupName,
    roles: data.roles,
  };
};

export const updateRequestToDtoMapper = (data: SsoGroupMappingUpdateRequest): SsoGroupMappingCreateUpdateDto => {
  return {
    groupName: data.groupName,
    roles: data.roles,
  };
};
