import { store } from 'app/store';
import { intersection } from 'lodash';

export const hasAnyRole = (roles: string[] = [], userRoles?: string[]): boolean => {
  if (roles.length === 0) {
    return true;
  }

  return intersection(roles, (userRoles as string[]) ?? store.getState().session?.userProfile?.roles ?? []).length > 0;
};
