import { StoreName } from 'app/enums/store';
import { store } from 'app/store';
import { DataGridName } from 'shared/enums';

export const getPaginationApiParams = (gridName: DataGridName) => {
  const limit = store.getState()[StoreName.DataGridPagination][gridName].pageSize;
  const offset = store.getState()[StoreName.DataGridPagination][gridName].page * limit;

  return { offset, limit };
};
