import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

import { PasswordConfirmFormFields } from '../interfaces/password-confirm-form-fields';
import { confirmPasswordValidationSchema } from './validation-schemas/password-confirm-form';

export const usePasswordConfirm = () => {
  const resolver = yupResolver(confirmPasswordValidationSchema);

  const form = useForm<PasswordConfirmFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
    },
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
