import { Person as PersonIcon } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import { UserEntity } from 'app/interfaces/entity/user';
import classNames from 'classnames';
import React, { FC } from 'react';
import { ValueOrDefaultFormat } from 'shared/components/Format';

import { useStyles } from './UserAvatar.styles';

interface UserAvatarProps {
  user: Omit<UserEntity, 'identifier'>;
  showName?: boolean;
  className?: string;
  size?: 'small' | 'large';
}

export const UserAvatar: FC<React.PropsWithChildren<UserAvatarProps>> = ({
  user,
  className,
  showName = true,
  size = 'large',
}) => {
  const { classes } = useStyles({ size });

  return (
    <Box className={classes.root}>
      <Avatar src={user?.photoUrl} className={classNames([classes.avatar, className])} data-testid="avatar">
        <PersonIcon fontSize={size} />
      </Avatar>
      {showName && (
        <Box className={classes.username}>
          <Typography variant="subtitle2" data-testid="username" className={classes.userNameLabel}>
            <ValueOrDefaultFormat value={user.username} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
