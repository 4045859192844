import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MfaVerificationFormFields } from 'modules/mfa/interfaces';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

import { mfaVerificationFormValidationSchema } from './validation-schemas';

export const useMfaVerifyForm = () => {
  const resolver = yupResolver(mfaVerificationFormValidationSchema);

  const form = useForm<MfaVerificationFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
    defaultValues: {
      confirmationCode: '',
    },
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
