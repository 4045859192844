import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, Tooltip } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PasswordField: ForwardRefExoticComponent<PropsWithoutRef<OutlinedInputProps>> = forwardRef(
  (props, ref) => {
    const [visible, setVisible] = useState<boolean>(false);
    const { t } = useTranslation(LangNameSpace.Shared);
    return (
      <>
        {props.label ? (
          <InputLabel required={props.required} error={props?.error}>
            {props.label}
          </InputLabel>
        ) : null}
        <OutlinedInput
          ref={ref}
          type={visible ? 'text' : 'password'}
          inputProps={{ role: 'PasswordField', 'data-testid': 'password' }}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  visible
                    ? t('components.Form.PasswordField.tooltip.hide')
                    : t('components.Form.PasswordField.tooltip.show')
                }
              >
                <IconButton onClick={() => setVisible((state) => !state)} edge="end" size="small">
                  {visible ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          {...props}
        />
      </>
    );
  }
);
