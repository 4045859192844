import { createSlice } from '@reduxjs/toolkit';
import { StoreName } from 'app/enums/store';
import { ThemeMode } from 'app/enums/theme';
import { Lang } from 'app/i18n';

import { SessionState } from './interfaces/session-state';
import {
  setAuthTokenReducer,
  setClientSecretReducer,
  setDashboardIdReducer,
  setLanguageReducer,
  setLocationIdReducer,
  setNewApiModeReducer,
  setOrganizationIdReducer,
  setOrgIdFromMenuReducer,
  setPerformaceDashboardCustomDateRangeReducer,
  setPerformanceDashboardCrossCloudDashboardIdReducer,
  setPerformanceDashboardDateRangeReducer,
  setPerformanceDashboardIdReducer,
  setPerformanceDashboardLocationIdReducer,
  setRedirectAfterLoginPathReducer,
  setThemeModeReducer,
  setTicketDetailsIdReducer,
  setUserIdReducer,
  setUserPopulationReducer,
  setUserProfileReducer,
} from './reducers';

const initialState: SessionState = {
  token: '',
  userProfile: undefined,
  userId: undefined,
  organizationId: '',
  IsOrgIdFromMenu: false,
  ticketDetailsId: '',
  themeMode: ThemeMode.Light,
  organizationLocations: {},
  organizationDashboards: {},
  performanceDashboardId: '',
  performanceDashboardLocationId: '',
  performanceCrossCloudDashboardId: '',
  performanceDashboardDateRange: undefined,
  performanceDashboardCustomDateRange: undefined,
  isNewApiMode: true,
  language: Lang.En,
  clientSecret: undefined,
};

export const sessionState = createSlice({
  name: StoreName.Session,
  initialState,
  reducers: {
    setAuthToken: setAuthTokenReducer,
    setUserProfile: setUserProfileReducer,
    setUserId: setUserIdReducer,
    setUserPopulation: setUserPopulationReducer,
    setOrganizationId: setOrganizationIdReducer,
    setOrgIdFromMenu: setOrgIdFromMenuReducer,
    setTicketId: setTicketDetailsIdReducer,
    setThemeMode: setThemeModeReducer,
    setApiMode: setNewApiModeReducer,
    setRedirectAfterLoginPath: setRedirectAfterLoginPathReducer,
    setLocationId: setLocationIdReducer,
    setDashboardId: setDashboardIdReducer,
    setPerformanceDashboardId: setPerformanceDashboardIdReducer,
    setPerformanceDashboardDateRange: setPerformanceDashboardDateRangeReducer,
    setPerformanceDashboardLocationId: setPerformanceDashboardLocationIdReducer,
    setPerformanceCrossCloudDashboardId: setPerformanceDashboardCrossCloudDashboardIdReducer,
    setPerformaceDashboardCustomDateRange: setPerformaceDashboardCustomDateRangeReducer,
    setLanguage: setLanguageReducer,
    setClientSecret: setClientSecretReducer,
  },
});

export const sessionReducers = { ...sessionState.actions };
