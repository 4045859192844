/* eslint-disable @typescript-eslint/no-explicit-any */

import { AsyncThunkAction } from '@reduxjs/toolkit';
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkRejectedActionCreator,
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import { store } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export interface FetchDataHook<T> {
  fetch: () => void;
  loading: boolean;
  data: T[];
}

type FetchDataPromise<R, A> = Promise<
  | ReturnType<AsyncThunkFulfilledActionCreator<R, A>>
  | ReturnType<AsyncThunkRejectedActionCreator<A, Record<string, never>>>
> & {
  abort: (reason?: string) => void;
  requestId: string;
  arg: A;
  unwrap: () => Promise<R>;
};

interface FetchDataOptions {
  /**
   * Fetch data on component init
   */
  fetchOnInit?: boolean;
  /**
   * Fetch data on change organization
   */
  fetchOnOrganizationChange?: boolean;
  /**
   * Repeat request interval in seconds
   *
   * @default 0
   */
  fetchInterval?: number;
}

export const useFetchData = <T = unknown>(
  actions: AsyncThunkAction<any, any, Record<string, any>>[],
  options: FetchDataOptions = { fetchOnInit: true, fetchOnOrganizationChange: true, fetchInterval: 0 }
): FetchDataHook<T> => {
  const { fetchOnInit = true, fetchOnOrganizationChange = true, fetchInterval = 0 } = options;

  const [loading, setLoading] = useState<boolean>(fetchOnInit);
  const [data, setData] = useState<T[]>([]);
  const organizationId = useSelector(sessionSelector.organizationId);
  const [promisesList, setPromises] = useState<FetchDataPromise<any, any>[]>([]);
  const isInitialized = useRef(false);

  const fetch = useCallback(() => {
    setLoading(true);

    const promises = actions.map((action) => store.dispatch(action));

    Promise.all(promises)
      .then((payload) => setData(payload.map((item) => item.payload)))
      .finally(() => setLoading(false));

    setPromises(promises);
  }, [actions]);

  useEffect(() => {
    if (fetchOnInit) {
      fetch();
    }
  }, [fetch, fetchOnInit]);

  useEffect(() => {
    if (fetchInterval) {
      const timer = setInterval(fetch, fetchInterval * 1000);

      return () => clearInterval(timer);
    }
  }, [fetch, fetchInterval]);

  useEffect(() => {
    if (fetchOnOrganizationChange && organizationId && isInitialized.current) {
      fetch();
    }
    isInitialized.current = true;
  }, [fetch, organizationId, fetchOnOrganizationChange]);

  useEffect(() => {
    return () => promisesList.forEach((promise) => promise?.abort());
  }, [promisesList]);

  return { fetch, loading, data };
};
