import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import React, { FC } from 'react';
import { SnackbarCloseButton } from 'shared/components/SnackbarCloseButton';

import { SnackbarUtilsConfigurator } from '../SnackbarUtilsConfigurator';
import { CustomSnackbarContent } from './CustomSnackbarContent';
import { useStyles } from './SnackbarProvider.styles';

export const SnackbarProvider: FC<React.PropsWithChildren<{ maxSnack?: number }>> = ({ children, maxSnack }) => {
  const { classes } = useStyles();

  return (
    <NotistackProvider
      maxSnack={maxSnack}
      Components={{
        default: CustomSnackbarContent,
        success: CustomSnackbarContent,
        error: CustomSnackbarContent,
        warning: CustomSnackbarContent,
        info: CustomSnackbarContent,
      }}
      classes={classes}
      iconVariant={{
        success: <CheckCircleIcon />,
        info: <InfoIcon />,
        warning: <ErrorIcon />,
        error: <WarningIcon />,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      preventDuplicate
      SnackbarProps={{ 'data-testid': 'snackbar' } as React.HTMLAttributes<HTMLDivElement>}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </NotistackProvider>
  );
};
