import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { TicketAttachmentEntity } from 'app/interfaces/entity/ticket';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { entityStoreStatus } from 'app/utils/store';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper, requestToDtoMapper } from './dto-mappers';
import { TicketAttachmentCreateRequest, TicketAttachmentsState } from './interfaces';
import { TicketAttachmentDto } from './interfaces/dto';

export const getTicketAttachmentsList = createAsyncThunk(
  `${EntityStoreName.TicketAttachments}/getList`,
  (ticketIdentifier: Identifier, { signal }): Promise<TicketAttachmentEntity[]> => {
    return apiRequestCall<TicketAttachmentDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        urlPrefix: `${ticketIdentifier}/${ApiResource.Attachments}`,
        signal,
      },
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const createTicketAttachment = createAsyncThunk(
  `${EntityStoreName.TicketAttachments}/create`,
  async ({ ticketIdentifier, files, ...rest }: TicketAttachmentCreateRequest): Promise<TicketAttachmentEntity[]> => {
    return apiRequestCall<TicketAttachmentDto[], FormData>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Tickets,
        urlPrefix: `${ticketIdentifier}/${ApiResource.Attachments}`,
        data: requestToDtoMapper(files),
        headers: { 'content-type': 'multipart/form-data' },
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Tickets, ticketIdentifier, ApiResource.Attachments]],
      ...rest,
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const clearAllReducer = (state: TicketAttachmentsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<TicketAttachmentsState>): void => {
  builder
    .addCase(getTicketAttachmentsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketAttachmentsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getTicketAttachmentsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createTicketAttachment.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicketAttachment.fulfilled, (state, action) => {
      entityAdapter.addMany(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createTicketAttachment.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    });
};
