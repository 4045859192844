import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { clearAllReducer, registerReducers } from './repository';

export const cloudConnectionsState = createSlice({
  name: EntityStoreName.CloudConnections,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const cloudConnectionsReducers = { ...cloudConnectionsState.actions };
