import { Identifier } from 'app/interfaces';
import { userEntityMapper } from 'app/store/session/dto-mappers';
import { sessionSelector } from 'app/store/session/selectors';
import { sessionReducers } from 'app/store/session/slice';
import { dispatchUnwrap } from 'app/utils/store';
import { getUserById } from 'modules/admin/user-management/store/users/repository';
import { usersSelectorFactory } from 'modules/admin/user-management/store/users/selectors';
import { organizationsSelector } from 'modules/organization/store/selectors';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAuthedUser = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector(sessionSelector?.userProfile);
  const organizationByPopulation = useSelector(
    organizationsSelector.getByPopulationId(userProfile?.population as Identifier)
  );

  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const filteredUser = useSelector(usersSelectorFactory.useOne(userProfile?.identifier));
  const IsOrgIdFromMenu = useSelector(sessionSelector.IsOrgIdFromMenu);

  useEffect(() => {
    if (organizationByPopulation === undefined) return;
    if (!isAuthorized) return;
    if (!IsOrgIdFromMenu) {
      dispatch(sessionReducers.setOrganizationId(organizationByPopulation.identifier));
    }

    if (userProfile?.identifier === undefined) return;
    const fetchUser = async () =>
      dispatchUnwrap(
        getUserById({ identifier: userProfile.identifier, organizationId: organizationByPopulation.identifier })
      );
    fetchUser();
  }, [userProfile, organizationByPopulation, isAuthorized, dispatch, IsOrgIdFromMenu]);

  const preparedUser = useMemo(() => {
    if (userProfile === undefined) return;
    if (filteredUser === undefined) return;

    return { ...filteredUser, ...userProfile };
  }, [filteredUser, userProfile]);

  useEffect(() => {
    if (userProfile === undefined) return;
    if (preparedUser === undefined) return;
    if (filteredUser === undefined) return;
    if (filteredUser?.identifier === userProfile?.identifier) {
      if (Object.entries(preparedUser).toString() === Object.entries(userProfile).toString()) return;
      dispatch(sessionReducers.setUserProfile({ ...userEntityMapper(preparedUser) }));
    }
  }, [dispatch, filteredUser, preparedUser, userProfile]);
};
