import { FontStyle, FontWeight } from 'app/enums/theme';
import MetropolisBlack from 'assets/fonts/Metropolis/Metropolis-Black.otf';
import MetropolisBlackItalic from 'assets/fonts/Metropolis/Metropolis-BlackItalic.otf';
import MetropolisBold from 'assets/fonts/Metropolis/Metropolis-Bold.otf';
import MetropolisBoldItalic from 'assets/fonts/Metropolis/Metropolis-BoldItalic.otf';
import MetropolisExtraBold from 'assets/fonts/Metropolis/Metropolis-ExtraBold.otf';
import MetropolisExtraBoldItalic from 'assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.otf';
import MetropolisExtraLight from 'assets/fonts/Metropolis/Metropolis-ExtraLight.otf';
import MetropolisExtraLightItalic from 'assets/fonts/Metropolis/Metropolis-ExtraLightItalic.otf';
import MetropolisLight from 'assets/fonts/Metropolis/Metropolis-Light.otf';
import MetropolisLightItalic from 'assets/fonts/Metropolis/Metropolis-LightItalic.otf';
import MetropolisMedium from 'assets/fonts/Metropolis/Metropolis-Medium.otf';
import MetropolisMediumItalic from 'assets/fonts/Metropolis/Metropolis-MediumItalic.otf';
import MetropolisRegular from 'assets/fonts/Metropolis/Metropolis-Regular.otf';
import MetropolisRegularItalic from 'assets/fonts/Metropolis/Metropolis-RegularItalic.otf';
import MetropolisSemiBold from 'assets/fonts/Metropolis/Metropolis-SemiBold.otf';
import MetropolisSemiBoldItalic from 'assets/fonts/Metropolis/Metropolis-SemiBoldItalic.otf';
import MetropolisThin from 'assets/fonts/Metropolis/Metropolis-Thin.otf';
import MetropolisThinItalic from 'assets/fonts/Metropolis/Metropolis-ThinItalic.otf';

export const METROPOLIS_FONT_NAME = 'Metropolis';

export const METROPOLIS_FONT_FACES = `
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Metropolis-Regular'), url(${MetropolisRegular}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Metropolis-Regular-Italic'), url(${MetropolisRegularItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Metropolis-Black'), url(${MetropolisBlack}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Metropolis-BlackItalic'), url(${MetropolisBlackItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Metropolis-Bold'), url(${MetropolisBold}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Metropolis-BoldItalic'), url(${MetropolisBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.ExtraBold};
    font-display: swap;
    src: local('Metropolis-ExtraBold'), url(${MetropolisExtraBold}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.ExtraBold};
    font-display: swap;
    src: local('Metropolis-ExtraBoldItalic'), url(${MetropolisExtraBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.ExtraLight};
    font-display: swap;
    src: local('Metropolis-ExtraLight'), url(${MetropolisExtraLight}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.ExtraLight};
    font-display: swap;
    src: local('Metropolis-ExtraLightItalic'), url(${MetropolisExtraLightItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Metropolis-Light'), url(${MetropolisLight}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Metropolis-LightItalic'), url(${MetropolisLightItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Metropolis-Medium'), url(${MetropolisMedium}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Metropolis-MediumItalic'), url(${MetropolisMediumItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.SemiBold};
    font-display: swap;
    src: local('Metropolis-SemiBold'), url(${MetropolisSemiBold}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.SemiBold};
    font-display: swap;
    src: local('Metropolis-SemiBoldItalic'), url(${MetropolisSemiBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Metropolis-Thin'), url(${MetropolisThin}) format('opentype');
  }
  @font-face {
    font-family: "Metropolis";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Metropolis-ThinItalic'), url(${MetropolisThinItalic}) format('opentype');
  }
`;
