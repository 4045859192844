import React, { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react';

export class ProviderModel<T> {
  constructor(
    public component: JSXElementConstructor<PropsWithChildren<T>>,
    public props?: T
  ) {}
}

interface CombineProvidersProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providers: ProviderModel<any>[];
  children: ReactNode;
}

export const CombineProviders = (props: CombineProvidersProps) => {
  const { providers = [], children } = props;

  return (
    <>
      {providers.reduceRight((acc, provider) => {
        const Provider = provider.component;
        return <Provider {...provider.props}>{acc}</Provider>;
      }, children)}
    </>
  );
};
