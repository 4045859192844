import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(3.25),
    lineHeight: theme.spacing(4.45),
    fontWeight: FontWeight.Bold,
  },
  text: {
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(3.5),
    fontWeight: FontWeight.Regular,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6.25),
  },
}));
