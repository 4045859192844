import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: '#fff',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  default: {
    backgroundColor: '#313131',
  },
  success: {
    backgroundColor: theme.palette.snackbar.background.success,
  },
  error: {
    backgroundColor: theme.palette.snackbar.background.error,
  },
  warning: {
    backgroundColor: theme.palette.snackbar.background.warning,
  },
  info: {
    backgroundColor: theme.palette.snackbar.background.info,
  },
  messageBlock: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  moreMessage: { display: 'flex' },
  message: {
    display: 'flex',
    fontSize: theme.spacing(2.375),
    color: theme.custom.colors.white,
    lineHeight: theme.spacing(3.5),

    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    height: '100%',
    minHeight: '0px',
    maxHeight: `calc(100vh - 120px)`,

    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      minHeight: theme.spacing(3.5),
    },
    alignContent: 'stretch',
    alignItems: 'center',
    '&.showLess': {
      alignItems: 'start',
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3),
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'self-start',
    '& .MuiSvgIcon-root': {
      margin: theme.spacing(1, 2),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  action: {
    display: 'flex',
    alignItems: 'self-start',
    margin: theme.spacing(0, 1),
    padding: '0',

    '& .MuiSvgIcon-root': {
      color: theme.custom.colors.white,
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  textContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&.showLess': {
      height: 0,
      alignItems: 'self-start',
    },
  },
  moreLink: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: theme.spacing(1.75),
    fontWeight: FontWeight.Regular,
    color: theme.custom.colors.white,
  },
}));
