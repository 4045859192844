import { Route } from 'app/enums';
import { GuardedRoute } from 'app/interfaces/route';
import { authGuard, rolesGuard } from 'app/routing/guards';
import React from 'react';

import { Role } from '../auth/enums';

const CloudServicesPage = React.lazy(() => import('./pages/CloudServicesPage'));

export const cloudServicesRoutes: GuardedRoute[] = [
  {
    path: Route.Services,
    component: CloudServicesPage,
    availability: [Role.User, Role.Technician],
    guards: [rolesGuard([Role.User, Role.Technician]), authGuard],
    exact: true,
    children: [],
  },
];
