import { DataGridName } from 'shared/enums';
import { DataGridColumnFilterData } from 'shared/interfaces';
import { dataGridFilterGenerators } from 'shared/utils/data-grid/generators';

import { entityAdapter } from './adapter';
import { DataGridFiltersState } from './interfaces/data-grid-filters-state';

export const setFilterReducer = (state: DataGridFiltersState, action: { payload: DataGridColumnFilterData }): void => {
  const { gridName, columnName } = action.payload;
  const id = dataGridFilterGenerators.generateFilterIdentifier(gridName as string, columnName);

  if (!id) {
    return;
  }

  if (state.entities[id]) {
    entityAdapter.updateOne(state, { id, changes: action.payload });
  } else {
    entityAdapter.addOne(state, action.payload);
  }
};

export const removeFilterReducer = (
  state: DataGridFiltersState,
  action: { payload: DataGridColumnFilterData }
): void => {
  const { gridName, columnName } = action.payload;
  const id = dataGridFilterGenerators.generateFilterIdentifier(gridName as string, columnName);

  if (!id) {
    return;
  }

  entityAdapter.removeOne(state, id);
};

export const removeAllGridFiltersReducer = (state: DataGridFiltersState, action: { payload: DataGridName }): void => {
  const ids = state.ids.filter((id: string) => (id as string).includes(action.payload));

  entityAdapter.removeMany(state, ids);
};

export const clearAllReducer = (state: DataGridFiltersState): void => {
  entityAdapter.removeAll(state);
};
