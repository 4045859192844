import 'app/i18n';

import { App } from 'app/components/App';
import { AppConfiguration } from 'app/components/AppConfiguration';
import StoreProvider from 'app/store/providers/StoreProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { CombineProviders, ProviderModel } from 'shared/components/CombineProviders';

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <CombineProviders
    providers={[
      new ProviderModel(StoreProvider),
      new ProviderModel(AppConfiguration),
      new ProviderModel(HelmetProvider),
    ]}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CombineProviders>
);
