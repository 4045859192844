import { Route } from 'app/enums';
import { RedirectConfig } from 'app/interfaces';
import { GuardedRoute } from 'app/interfaces/route';
import { RedirectConfigModel } from 'app/models';
import { appRedirects } from 'app/routing/redirects';
import { moduleRoutes } from 'app/routing/routes';
import { sessionSelector } from 'app/store/session/selectors';
import { authRoutes } from 'modules/auth/routes';
import { hasAnyRole } from 'modules/auth/utils';
import { ccvsSelector } from 'modules/cloud-services/store/ccvs/selectors';
import { dashboardsClientSelector } from 'modules/monitoring/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface RoutesHook {
  appRoutes: GuardedRoute[];
  appRedirects: RedirectConfig[];
  firstAvailableRoutePath: Route;
  isAuthRoute: boolean;
}

export const useRoutes = (): RoutesHook => {
  const isEmptyCcvs = useSelector(ccvsSelector.isEmpty);
  const isEmptyClientDashboards = useSelector(dashboardsClientSelector.isEmpty);
  const roles = useSelector(sessionSelector.userRoles);
  const location = useLocation();

  const filteredModuleRoutes = useMemo(
    () =>
      moduleRoutes.filter((route) => {
        if (route.path === Route.Services && isEmptyCcvs) {
          return false;
        }

        return !(route.path === Route.ServicesDashboards && isEmptyClientDashboards);
      }),
    [isEmptyCcvs, isEmptyClientDashboards]
  );

  const appRoutes = useMemo(() => {
    return [...filteredModuleRoutes, ...authRoutes];
  }, [filteredModuleRoutes]);

  const firstAvailableRoutePath = useMemo(
    () => filteredModuleRoutes.find((route) => hasAnyRole(route.availability, roles))?.path || Route.Login,
    [filteredModuleRoutes, roles]
  );

  const redirects = useMemo(() => {
    const items = [...appRedirects];

    if (isEmptyCcvs) {
      items.push(new RedirectConfigModel(Route.Services, Route.Tickets, true));
    }
    if (isEmptyClientDashboards) {
      items.push(new RedirectConfigModel(Route.ServicesDashboards, Route.SitePerformance, true));
    }

    if (location.pathname === Route.SitePerformanceOld) {
      items.push(new RedirectConfigModel(Route.SitePerformanceOld, Route.SitePerformance, true));
    }

    return items;
  }, [isEmptyCcvs, isEmptyClientDashboards, location.pathname]);

  const isAuthRoute = useMemo(
    () => Boolean(authRoutes.find((route) => location.pathname.startsWith(route.path))),
    [location.pathname]
  );

  return { appRoutes, appRedirects: redirects, firstAvailableRoutePath, isAuthRoute };
};
