export enum ApiResource {
  Attachments = 'attachments',
  Ccvs = 'ccv/volumes',
  CloudConnections = 'fix/connections',
  Comments = 'comments',
  Dashboards = 'dashboards',
  Locations = 'locations',
  Organizations = 'organizations',
  Keys = 'keys',
  Tickets = 'tickets',
  Users = 'users',
  Sso = 'sso',
  Mfa = 'mfa',
  SsoGroupMappings = 'sso/groups',
  Widgets = 'widgets',
  Roles = 'roles',
}
