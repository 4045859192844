import { ModalPalette } from '@types';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';

export const MODAL_LIGHT: ModalPalette = {
  closeIconColor: COLORS_LIGHT.neutral[500],
};

export const MODAL_DARK: ModalPalette = {
  closeIconColor: COLORS_DARK.neutral[200],
};
