import { SERVICE_ROUTES } from 'app/constants';
import { Route } from 'app/enums';
import { ThemeMode } from 'app/enums/theme';
import { Lang } from 'app/i18n';
import { CustomDateRange } from 'app/interfaces/entity/dashboard';
import { WidgetDateRangeEntity } from 'app/interfaces/entity/dashboard/date-range.entity';
import { UserEntity } from 'app/interfaces/entity/user';

import { SessionState } from './interfaces/session-state';

export const setAuthTokenReducer = (state: SessionState, action: { payload: string | undefined }): void => {
  state.token = action.payload;
};

export const setUserProfileReducer = (state: SessionState, action: { payload: UserEntity | undefined }): void => {
  state.userProfile = action.payload;
};

export const setUserIdReducer = (state: SessionState, action: { payload: string }): void => {
  state.userId = action.payload;
};

export const setUserPopulationReducer = (state: SessionState, action: { payload: string }): void => {
  state.population = action.payload;
};

export const setOrgIdFromMenuReducer = (state: SessionState, action: { payload: boolean }): void => {
  state.IsOrgIdFromMenu = action.payload;
};

export const setOrganizationIdReducer = (state: SessionState, action: { payload: string | undefined }): void => {
  state.organizationId = action.payload;
};

export const setTicketDetailsIdReducer = (state: SessionState, action: { payload: string | undefined }): void => {
  state.ticketDetailsId = action.payload;
};

export const setThemeModeReducer = (state: SessionState, action: { payload: ThemeMode | undefined }): void => {
  state.themeMode = action.payload ?? ThemeMode.Light;
};

export const setNewApiModeReducer = (state: SessionState, action: { payload: boolean }): void => {
  state.isNewApiMode = action.payload;
};

export const setLanguageReducer = (state: SessionState, action: { payload: Lang | undefined }): void => {
  state.language = action.payload ?? Lang.En;
};

export const setRedirectAfterLoginPathReducer = (
  state: SessionState,
  action: { payload: string | undefined }
): void => {
  if (!SERVICE_ROUTES.includes(action.payload as Route)) {
    state.redirectAfterLoginPath = action.payload;
  }
};

export const setLocationIdReducer = (state: SessionState, action: { payload: string }): void => {
  if (state.organizationId) {
    state.organizationLocations = { ...state.organizationLocations, [state.organizationId]: action.payload };
  }
};

export const setDashboardIdReducer = (state: SessionState, action: { payload: string }): void => {
  if (state.organizationId) {
    state.organizationDashboards = { ...state.organizationDashboards, [state.organizationId]: action.payload };
  }
};

export const setPerformanceDashboardIdReducer = (
  state: SessionState,
  action: { payload: string | undefined }
): void => {
  state.performanceDashboardId = action.payload;
};

export const setPerformanceDashboardDateRangeReducer = (
  state: SessionState,
  action: { payload: WidgetDateRangeEntity }
): void => {
  state.performanceDashboardDateRange = action.payload;
};

export const setPerformanceDashboardLocationIdReducer = (
  state: SessionState,
  action: { payload: string | undefined }
): void => {
  state.performanceDashboardLocationId = action.payload;
};

export const setPerformanceDashboardCrossCloudDashboardIdReducer = (
  state: SessionState,
  action: { payload: string | undefined }
): void => {
  state.performanceCrossCloudDashboardId = action.payload;
};

export const setPerformaceDashboardCustomDateRangeReducer = (
  state: SessionState,
  action: { payload: CustomDateRange }
): void => {
  state.performanceDashboardCustomDateRange = action.payload;
};

export const setClientSecretReducer = (state: SessionState, action: { payload: string | undefined }): void => {
  state.clientSecret = action.payload;
};
