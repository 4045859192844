import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { OrganizationEntity } from 'app/interfaces/entity/organization';

import { OrganizationsState } from './interfaces/organizations-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: OrganizationEntity) => data.identifier,
});

export const initialState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
}) as unknown as OrganizationsState;
