import { Route } from 'app/enums';
import { sessionSelector } from 'app/store/session/selectors';
import { useRoles } from 'modules/auth/hooks';
import { ccvsSelector } from 'modules/cloud-services/store/ccvs/selectors';
import { dashboardsClientSelector } from 'modules/monitoring/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { APP_MENU } from '../constants';
import { MenuItem } from '../interfaces';

interface MainMenuHook {
  subMenuItems: MenuItem[];
  menuItems: MenuItem[];
}

export const useMainMenu = (): MainMenuHook => {
  const isEmptyCcvs = useSelector(ccvsSelector.isEmpty);
  const isEmptyClientDashboards = useSelector(dashboardsClientSelector.isEmpty);
  const isMfaConfigurationRequired = useSelector(sessionSelector.isMfaConfigurationRequired);
  const location = useLocation();
  const { hasAnyRole } = useRoles();

  const menuItems: MenuItem[] = useMemo(
    () =>
      APP_MENU.filter((item) => {
        if (isMfaConfigurationRequired) {
          return false;
        }

        if (isEmptyCcvs && item.link === Route.Services) {
          return false;
        }
        return hasAnyRole(item?.roles ?? []);
      }),
    [hasAnyRole, isEmptyCcvs, isMfaConfigurationRequired]
  );

  const subMenuItems = useMemo(
    () =>
      (menuItems.find((item) => location.pathname.startsWith(item.link))?.submenu || []).filter((item) => {
        if (isEmptyClientDashboards && item.link === Route.ServicesDashboards) {
          return false;
        }
        return hasAnyRole(item?.roles ?? []);
      }),
    [hasAnyRole, menuItems, location.pathname, isEmptyClientDashboards]
  );

  return { subMenuItems, menuItems };
};
