import { DialogName } from 'app/enums';
import { store } from 'app/store';
import { modalReducers } from 'app/store/modal/slice';
import { AxiosError } from 'axios';
import { CacheAxiosResponse } from 'axios-cache-interceptor';
import { isEmpty } from 'lodash';

import { ErrorResponse, ResponseInterceptor } from '../../interfaces';

export const unauthorizedInterceptor: ResponseInterceptor = {
  onFulfilled: (res: CacheAxiosResponse) => Promise.resolve(res),
  onRejected: (err: AxiosError<ErrorResponse>) => {
    if (
      !isEmpty(store.getState().session?.token) &&
      (err.response?.status === 401 || err.response?.data.status === 401)
    ) {
      store.dispatch(modalReducers.setOpenModal({ name: DialogName.ExpiredSession }));
    }
    return Promise.reject(err);
  },
};
