import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },

    '& .MuiPaper-root': {
      backgroundColor: theme.palette.header.background.MuiPaper,
      boxShadow: theme.custom.boxShadow.menu,
    },

    '& .MuiMenuItem-root': {
      padding: theme.spacing(3.25, 3.875, 2.875, 2),
      borderStyle: 'solid',
      borderTopColor: theme.custom.border.default,
      borderTopWidth: 0.5,
      color: theme.palette.text.primary,
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3.5),
      fontWeight: FontWeight.Medium,

      '&:first-of-type': {
        border: 'none',
      },
      '&.Mui-selected': {
        color: theme.palette.dropDown.color.active,
        fontWeight: FontWeight.Bold,
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
}));
