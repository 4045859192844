import { Theme } from '@mui/material/styles';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    ...TYPOGRAPHY.b2Regular,
    color: theme.custom.colors.label,
    '&:first-of-type': {
      marginTop: 0,
    },

    marginTop: theme.spacing(1.25),
    fontSize: theme.spacing(1.5),
  },
  value: {
    ...TYPOGRAPHY.b1Medium,
    color: theme.custom.colors.value,
  },
}));
