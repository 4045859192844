import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { UserEntity } from 'app/interfaces/entity/user/user.entity';
import { UsersState } from 'modules/admin/user-management/store/users/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: UserEntity) => data.identifier,
});

export const initialState: UsersState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
