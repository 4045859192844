export const admin = {
  sso: {
    components: {
      SsoGroupMappingCreateModal: {
        successRequestMessage: 'SSO Group successfully created.',
        createBtn: 'Create',
      },
      SsoGroupMappingEditModal: {
        successRequestMessage: 'SSO Group successfully updated.',
        saveBtn: 'Save',
      },
      SsoGroupMappingsGrid: {
        title: 'SSO Enabled',
        columnsName: {
          GroupName: 'Group Name',
          Roles: 'Roles',
        },
        actions: {
          createLabel: 'Group',
        },
        menu: {
          edit: 'Edit Connection',
          delete: 'Delete Connection',
        },
      },
      SsoNotConfigured: {
        title: 'SSO Management',
        text: 'Your organization has not yet established an SSO connection for logging in.<br /> Please click the button below to complete the request form to setup SSO.',
        addBtn: 'Setup SSO',
      },
      SsoAttributeMappingForm: {
        userName: {
          label: 'Username',
          placeholder: 'Username',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        externalAuthorization: {
          label: 'External Authorization',
          placeholder: 'External Authorization',
        },
      },
      SsoConfirm: {
        labels: {
          clientId: 'Client ID',
          clientSecret: 'Client Secret',
          discoveryUri: 'Discovery Document URI',
          userName: 'Username',
          email: 'Email',
          externalAuthorization: 'External Authorization',
        },
      },
      SsoCredentialsForm: {
        clientId: {
          label: 'Client ID',
          placeholder: 'Client ID',
        },
        clientSecret: {
          label: 'Client SecretD',
        },
        discoveryUri: {
          label: 'Discovery Document URI',
          placeholder: 'Discovery Document URI',
        },
        callbackURL: {
          label: 'Callback URL',
          placeholder: 'Callback URL',
        },
      },
      CreateSsoWizard: {
        successRequestMessage: 'SSO successfully created.',
      },
      EditSsoWizard: {
        successRequestMessage: 'SSO successfully updated.',
      },
      SsoWizard: {
        steps: {
          step1: {
            title: 'SSO Credentials',
          },
          step2: {
            title: 'Attribute Mapping',
            subtitle: 'Input the attribute mapping for each field below,<br /> e.g. "table.user" for "Username".',
          },
          step3: {
            title: 'Confirm',
            subtitle: 'Please confirm the below configuration are correct.<br />Click on "Finish" to confirm.',
            footer: {
              text: "As a requirement to establish SSO, Multi-Factor Authentication (MFA) must be enabled with your organization's Identity Provider(IdP).",
              componentLabel: "I confirm MFA is enabled with my organization's IdP",
            },
            content: {
              title: 'Credentials',
            },
          },
        },
      },
    },
    hooks: {
      attributeMappingFormValidationSchema: {
        userName: 'Username',
        email: 'Email',
        externalAuthorization: 'External Authorization',
      },
      credentialsFormValidationSchema: {
        clientId: 'Client ID',
        clientSecret: 'Client Secret',
        discoveryUri: 'Discovery Document URI',
      },
      ssoGroupMappingFormValidationSchema: {
        groupName: 'Group Name',
        roles: {
          minText: '${path} is a required field',
          label: 'Roles',
        },
      },
    },
    page: {
      successRequestMessage: {
        deleteConfiguration: 'SSO configuration successfully deleted.',
        deleteGroup: 'SSO group mapping successfully deleted.',
      },
      confirmation: {
        deleteConfiguration: {
          text: 'Do you really want to delete SSO Connection?<br/> This process cannot be undone.',
          successBtn: 'Delete',
        },
        deleteGroup: {
          text: 'Do you really want to delete <i>{{name}}</i> Group?<br /> This process cannot be undone.',
          successBtn: 'Delete',
        },
      },
    },
  },
  user: {
    components: {
      UserChangePasswordForm: {
        newPassword: {
          text: 'Create a one time password below to give this user access to his or her account. <b>It can only be used once.</b>',
          btn: 'Generate password',
        },
      },
      UserChangePasswordModal: {
        successRequestMessage: 'Password successfully changed.',
        btn: 'Save',
      },
      UserCreateForm: {
        username: {
          label: 'Username',
        },
        email: {
          label: 'Email',
        },
        firstName: {
          label: 'First Name',
        },
        lastName: {
          label: 'Last Name',
        },
        contactPreferences: {
          label: 'Contact Preference',
        },
        roles: {
          label: 'Roles',
        },
        phone: {
          label: 'Phone',
        },
        timezone: {
          label: 'Timezone',
        },
      },
      UserCreateModal: {
        successRequestMessage: 'User successfully created.',
        btn: 'Create',
      },
      UserDetails: {
        rolesPermissions: 'Roles & Permissions',
        contactPrefences: 'Contact Preferences ',
        phone: 'Phone',
        timezone: 'Timezone',
        mfaLabel: 'MFA Enabled',
        mfaNotConfigured: 'Not configured',
      },
      UserEditForm: {
        email: {
          tooltip: "SSO admin cannot edit other users' {{name}}.",
          label: 'Email',
        },
        userName: {
          label: 'Username',
        },
        firstName: {
          label: 'First Name',
        },
        lastName: {
          label: 'Last Name',
        },
        roles: {
          label: 'Roles',
        },
        phone: {
          label: 'Phone',
        },
        timezone: {
          label: 'Timezone',
        },
      },
      UserEditModal: {
        successRequestMessage: 'User successfully updated.',
        btn: 'Save',
        label: 'Username',
      },
      UsersGrid: {
        actions: {
          createLabel: 'User',
        },
        title: 'Users',
        USERS_DATA_GRID_COLUMNS: {
          Username: 'Username',
          Email: 'Email',
          FirstName: 'First Name',
          LastName: 'Last Name',
          Roles: 'Roles',
          Source: 'User’s source',
        },
      },
    },
    constants: {
      USER_TYPES: {
        sso: 'sso',
        local: 'local',
      },
      MAP_MODAL_FIELD_ERROR_MESSAGE: {
        username: {
          unique: 'Provided username already exists',
        },
      },
      contactPreferences: {
        primary: 'Primary Contact',
        technical: 'Technical Notifications',
      },
    },
    hooks: {
      userChangePasswordValidationSchema: {
        number: '${path} must be at least one number',
        lowercaseCharacter: '${path} must be at least one lowercase character',
        uppercaseCharacter: '${path} must be at least one UPPERCASE character',
        specialCharacter: '${path} must be at least one special character',
        label: 'Password',
      },
      userCreateFormValidationSchema: {
        username: 'Username',
        email: {
          label: 'Email',
          error: 'Invalid Email Address',
        },
        contactPreferences: 'Primary Contact',
      },
      userEditValidationSchema: {
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        timezone: 'Timezone',
        roles: {
          label: 'Roles',
          error: '${path} is a required field',
        },
      },
      useUsersGridRowActions: {
        tooltips: {
          edit: 'You cannot modify the user with an upper-ranked role, SSO user or yourself (use the profile menu instead).',
          delete: 'You cannot delete the user with an upper-ranked role, SSO user or yourself.',
          changePassword:
            'You cannot change password for the user with an upper-ranked role, SSO user or yourself (use the user menu instead).',
        },
      },
    },
    page: {
      confirmation: {
        title: 'Delete Confirmation',
        text: 'Do you really want to delete this user: <i>{{firstName}} {{lastName}} ({{username}})</i>?',
        btn: 'Delete',
      },
    },
  },
  api_credentials: {
    hooks: {
      useApiCredentialsGridRowActions: {
        tooltips: {
          edit: 'You cannot modify the user with an upper-ranked role, SSO user or yourself (use the profile menu instead).',
          delete: 'You cannot delete the user with an upper-ranked role, SSO user or yourself.',
          changePassword:
            'You cannot change password for the user with an upper-ranked role, SSO user or yourself (use the user menu instead).',
        },
      },
      useApiCredentialsCreateFormValidationSchema: {
        credentialName: 'Credential Name',
        email: {
          label: 'Email',
          error: 'Invalid Email Address',
        },

        roles: {
          label: 'Roles',
          error: '${path} is a required field',
        },
      },
    },
    components: {
      ApiCredentialsForm: {
        clientSecret: {
          label: 'Copy Client Secret',
          placeholder: 'Getting Client Secret',
          error: 'There was an error with getting the client secret',
        },
      },
      ApiCredentialsGrid: {
        actions: {
          createLabel: 'API Credentials',
        },
        title: 'API Credentials',
        API_CREDENTIALS_DATA_COLUMNS: {
          CredentialName: 'Credential Name',
          Roles: 'Roles',
          ClientId: 'Client ID',
          CreatedAt: 'Creation Date',
          LastUsed: 'Last Used Date',
          CreatedBy: 'Created By',
        },
      },
      ApiCredentialsCreateForm: {
        loaderText: 'Generating Client Secret ...',
        credentialName: {
          label: 'Credential Name',
        },
      },

      ApiCredentialsWizard: {
        errors: {
          nameExists: 'Name already exists',
        },
        buttonTitle: 'Submit',
        cancelBtnText: 'Close',
        steps: {
          step1: {
            title: 'Setup Credentials',
          },
          step2: {
            title: 'Copy Credentials',
            subtitle:
              'Please, save <b>Client Secret</b> in a safe place. It will not be available after wizard closing.',
            content: {
              title: 'Information',
              name: 'Credential Name',
              clientId: 'Client ID',
              clientSecret: 'Client Secret',
            },
          },
        },
      },
      CreateApiCredentialsWizard: { successRequestMessage: 'Credential successfully created' },
    },
    page: {
      successRequestMessage: {
        deleteCredential: 'Credential successfully deleted.',
      },
      confirmation: {
        title: 'Delete Confirmation',
        text: 'Do you really want to delete Credential: <i>({{name}})</i>?',
        btn: 'Delete',
      },
    },
  },
};
