import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { useEntityRepositoryStatus, useMainMenu } from 'app/hooks';
import { sessionSelector } from 'app/store/session/selectors';
import { MONTSERRAT_FONT_FACES } from 'app/theme/self-hosted-fonts';
import { ExpiredSessionDialog } from 'modules/auth/components/ExpiredSessionDialog';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'shared/components/Loader';

import { AppHead } from '../AppHead';
import { AppInit } from '../AppInit';
import { AppRouter } from '../AppRouter';
import { Header } from '../Header';
import { SentryFallback } from '../SentryFallback';
import { useStyles } from './App.styles';

export const App: FC<React.PropsWithChildren<unknown>> = () => {
  const { menuItems, subMenuItems } = useMainMenu();
  const { classes } = useStyles({ hasSubmenu: Boolean(subMenuItems.length) });

  const organizationsLoading = useEntityRepositoryStatus({
    [EntityStoreName.Organizations]: EntityStoreRequestType.Loading,
  });
  const organizationId = useSelector(sessionSelector.organizationId);

  return (
    <Box className={classes.root}>
      <Sentry.ErrorBoundary
        fallback={({ resetError, error }) => <SentryFallback resetError={resetError} error={error as Error} />}
      >
        <AppInit />
        <AppHead fontFaces={[MONTSERRAT_FONT_FACES]} />
        <Header menuItems={menuItems} subMenuItems={subMenuItems} />
        <Box className={classes.content}>
          {!organizationId && organizationsLoading ? <Loader fullPage /> : <AppRouter />}
        </Box>
      </Sentry.ErrorBoundary>
      <ExpiredSessionDialog />
    </Box>
  );
};
