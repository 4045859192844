import { getProviderV2, PROVIDER_CONFIG } from 'app/constants';
import { ConnectionType } from 'app/enums';
import { CloudConnectionStatus } from 'app/enums/cloud-connection';
import { CloudConnectionEntity } from 'app/interfaces/entity/cloud-connection';
import { omit } from 'lodash';
import { getActualRequestedChanges } from 'modules/cloud-services/utils/get-actual-requested-changes';

import {
  CloudConnectionChangeConnectionRequest,
  ConnectionCreateRequest,
  FixConnectionCreateRequest,
} from './interfaces';
import {
  CloudConnectionChangeConnectionDto,
  CloudConnectionCreateDto,
  CloudConnectionData,
  CloudConnectionDto,
} from './interfaces/dto';

export const dtoToEntityMapper = (data: CloudConnectionDto): CloudConnectionEntity => {
  const connectionData = data?.connectionData || {};
  const requestedChanges = data?.requestedChanges || [];

  if (data.connectionType === ConnectionType.Cloud && !connectionData.hasOwnProperty('crossCloud')) {
    (connectionData as CloudConnectionData).crossCloud = false;
  }

  const requestChange = getActualRequestedChanges(data);

  const crossCloud =
    data.state === CloudConnectionStatus.Provisioning
      ? requestChange && requestChange.hasOwnProperty('crossCloud')
        ? (requestChange.crossCloud as boolean)
        : (connectionData as CloudConnectionData).crossCloud
      : (connectionData as CloudConnectionData).crossCloud;

  return omit(
    {
      fixId: 'fix',
      identifier: data.identifier,
      status: data.state,
      type: data.connectionType,
      subType: connectionData?.connectionSubType,
      provider: getProviderV2(connectionData?.connectionSubType || data.connectionType),
      region: (connectionData as CloudConnectionData)?.providerRegion,
      factionLocationId: connectionData.factionLocations[0],
      ...connectionData,
      crossCloud,
      requestedChanges,
      requestChange,
    },
    ['connectionType', 'connectionSubType', 'providerRegion']
  ) as CloudConnectionEntity;
};

export const createRequestToDtoMapper = (data: ConnectionCreateRequest): CloudConnectionCreateDto => {
  const providerData = PROVIDER_CONFIG[data.provider];
  const factionLocations = data.factionLocations;
  if ((data as FixConnectionCreateRequest)?.targetFactionLocation) {
    factionLocations.push((data as FixConnectionCreateRequest).targetFactionLocation as string);
  }

  return {
    connectionType: providerData.connectionType,
    connectionData: {
      connectionSubType: data?.subType || providerData?.connectionSubType,
      factionLocations,
      providerRegion: data?.region || undefined,
      bandwidth: parseInt(data.bandwidth as unknown as string),
      accountId: data?.accountId,
      pairingKey: data?.pairingKey,
      tenancy: data?.tenancy,
      localPeeringGateway: data?.localPeeringGateway,
      crossCloud: data?.crossCloud,
    },
  };
};

export const updateRequestToDtoMapper = (
  data: CloudConnectionChangeConnectionRequest
): { bandwidth?: number; crossCloud?: boolean } => {
  return {
    bandwidth: data?.bandwidth ? parseInt(data?.bandwidth as unknown as string) : undefined,
    crossCloud: data?.crossCloud,
  };
};

export const updateResponseToDtoMapper = (data: CloudConnectionChangeConnectionDto): CloudConnectionEntity => {
  const preparedData = dtoToEntityMapper(data as CloudConnectionDto);

  return {
    ...preparedData,
    active: data.active,
  };
};
