import { Theme } from '@mui/material';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    alignItems: 'center',
    boxShadow: `inset 0px -1px 0px ${theme.palette.colors.neutral.T16}`,
  },
  closeIconBtn: {
    top: theme.spacing(0.625),
    right: theme.spacing(1.375),
    position: 'absolute',
  },
  closeIcon: {
    fill: theme.palette.modal.closeIconColor,
  },
  skipPaddingBottom: {
    paddingBottom: 0,
  },
  body: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '0 0 auto',
    padding: theme.spacing(3, 2),
  },
  actions: {
    padding: theme.spacing(1.5, 2, 2, 2),
    display: 'inline-flex',
    alignSelf: 'end',
    justifyContent: 'end',

    '&:has(> .fullWidth)': {
      width: '100%',
    },

    '& .MuiButtonBase-root': {
      '&:only-of-type': {
        flex: '0 0 100%',
      },
      display: 'flex',
      flex: '0 0 50%',
    },
  },
  submitButton: {
    width: theme.spacing(12.5),
  },
  textContainer: {
    ...TYPOGRAPHY.b2Regular,
    overflowWrap: 'anywhere',
  },
}));
