/**
 * Workaround from this issue https://github.com/iamhosseindhv/notistack/issues/30
 */
import { OptionsObject, ProviderContext } from 'notistack';

interface SnackbarUtilProps {
  snackbarRef: ProviderContext | null;
  setSnackbarRef: (ref: ProviderContext) => void;
  toast: (msg: string, options: OptionsObject) => void;
  success: (msg: string, options?: OptionsObject) => void;
  info: (msg: string, options?: OptionsObject) => void;
  warning: (msg: string, options?: OptionsObject) => void;
  error: (msg: string, options?: OptionsObject) => void;
}

export const SnackbarUtil: SnackbarUtilProps = {
  snackbarRef: null,
  setSnackbarRef(ref: ProviderContext) {
    this.snackbarRef = ref;
  },
  toast(msg: string, options = { variant: 'default', autoHideDuration: 5000 }): void {
    if (!this.snackbarRef) {
      throw new Error('Set "snackbarRef" before usage in SnackbarProvider scope');
    }

    this.snackbarRef.enqueueSnackbar(msg, options);
  },
  success(msg: string, options = {}): void {
    this.toast(msg, { variant: 'success', ...options });
  },
  info(msg: string, options = {}): void {
    this.toast(msg, { variant: 'info', ...options });
  },
  warning(msg: string, options = {}): void {
    this.toast(msg, { variant: 'warning', ...options });
  },
  error(msg: string, options = {}): void {
    this.toast(msg, { variant: 'error', ...options });
  },
};
