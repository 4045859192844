import {
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  SettingsBrightness as SettingsBrightnessIcon,
} from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ThemeMode } from 'app/enums/theme';
import { LangNameSpace } from 'app/i18n';
import { sessionSelector } from 'app/store/session/selectors';
import { sessionReducers } from 'app/store/session/slice';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './ThemeModeSwitcher.styles';

export const ThemeModeSwitcher: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector(sessionSelector.themeMode);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const { t } = useTranslation(LangNameSpace.App);

  const themeIcon = useMemo(() => {
    switch (themeMode) {
      case ThemeMode.System:
        return <SettingsBrightnessIcon />;
      case ThemeMode.Dark:
        return <Brightness4Icon />;
      case ThemeMode.Light:
        return <Brightness7Icon />;
    }
  }, [themeMode]);

  const changeModeHandler = useCallback(
    (value: ThemeMode) => {
      dispatch(sessionReducers.setThemeMode(value));
      setAnchor(null);
    },
    [dispatch]
  );

  return (
    <>
      <IconButton size="large" onClick={(e) => setAnchor(e.currentTarget)}>
        {themeIcon}
      </IconButton>
      <Menu
        className={classes.menu}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <MenuItem onClick={() => changeModeHandler(ThemeMode.Light)} selected={themeMode === ThemeMode.Light}>
          <Brightness7Icon />
          {t('theme.light')}
        </MenuItem>
        <MenuItem onClick={() => changeModeHandler(ThemeMode.System)} selected={themeMode === ThemeMode.System}>
          <SettingsBrightnessIcon />
          {t('theme.system')}
        </MenuItem>
        <MenuItem onClick={() => changeModeHandler(ThemeMode.Dark)} selected={themeMode === ThemeMode.Dark}>
          <Brightness4Icon />
          {t('theme.dark')}
        </MenuItem>
      </Menu>
    </>
  );
};
