import { LogicOperator, Route } from 'app/enums';
import { Guard } from 'app/interfaces/guard';
import { Role } from 'modules/auth/enums';
import { hasAllRoles, hasAnyRole } from 'modules/auth/utils';

export const rolesGuard =
  (roles: Role[], operator: LogicOperator = LogicOperator.OR): Guard =>
  async (to, resolver) => {
    if (operator === LogicOperator.OR && hasAnyRole(roles)) {
      return resolver.next();
    }
    if (operator === LogicOperator.AND && hasAllRoles(roles)) {
      return resolver.next();
    }

    return resolver.redirect(Route.NotFound);
  };
