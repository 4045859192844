export const ticket = {
  components: {
    Ticket: {
      backTooltipTitle: 'Back',
      descTitle: 'Description',
    },
    TicketAttachments: {
      title: 'Attachments',
      label: 'Attachment(s)',
      btn: 'Attach',
    },
    TicketComment: {
      commentedOn: 'commented on',
      showMore: 'Show more...',
      showLess: 'Show less',
    },
    TicketComments: {
      title: 'Comments',
      textareaPlaceholder: 'Add comment...',
      addBtn: 'Add',
    },
    TicketCaseFrom: {
      title: {
        label: 'Title',
        placeholder: 'Short description',
      },
      description: {
        label: 'Description',
        placeholder: 'Affected Item(s), Impact, Time the problem was first noticed, Recent changes to environment',
      },
    },
    TicketRequestForm: {
      title: {
        label: 'Title',
        placeholder: 'Short description',
      },
      description: {
        label: 'Description',
        placeholder:
          'Affected Item(s), What needs to be done, Hours to complete request (During Business Hours, After Business Hours, Weekend or Other)',
      },
    },
    TicketCreateWizard: {
      successRequestMessage: 'Ticket successfully created.',
      steps: {
        step1: {
          title: 'Ticket Details',
        },
      },
    },
    TicketDetails: {
      status: 'Status:',
      number: 'Ticket Number:',
      lastUpdated: 'Last Updated:',
      openedAt: 'Opened At:',
      openedBy: 'Opened By:',
    },
    TicketsGrid: {
      title: 'Title',
      number: 'Request Number',
      openedAt: 'Created',
      lastUpdated: 'Last Updated',
      status: 'Status',
      actions: {
        create: {
          label: 'Ticket',
        },
      },
      gridTitle: 'Tickets',
    },
  },
  constants: {
    TICKET_STATUSES: {
      new: 'New',
      inProgress: 'In Progress',
      onHold: 'On Hold',
      resolved: 'Resolved',
      closed: 'Closed',
      canceled: 'Canceled',
      open: 'Open',
      acknowledged: 'Acknowledged',
      assigned: 'Assigned',
      monitoring: 'Monitoring',
      awaitingInfo: 'Awaiting Info',
    },
  },
  hooks: {
    ticketAttachmentFormValidationSchema: {
      attachments: {
        label: 'Attachment',
      },
    },
    ticketCommentFormValidationSchema: {
      comment: {
        label: 'Comment',
      },
    },
    caseFormValidationSchema: {
      title: {
        label: 'Title',
      },
      description: {
        label: 'Description',
      },
      attachments: {
        label: 'Attachment',
      },
    },
    requestFormValidationSchema: {
      title: {
        label: 'Title',
      },
      description: {
        label: 'Description',
      },
      attachments: {
        label: 'Attachment',
      },
    },
  },
};
