import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { CcvEntity } from 'app/interfaces/entity/ccv';
import { CcvsState } from 'modules/cloud-services/store/ccvs/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: CcvEntity) => data.identifier,
});

export const initialState: CcvsState = entityAdapter.getInitialState({
  ids: null,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
