import { DialogName } from 'app/enums';
import { store } from 'app/store';
import { modalReducers } from 'app/store/modal/slice';
import { UserManager } from 'oidc-client-ts';

import { PING_IDENTITY_CONFIGURATION } from './constants/configuration';

export const pingIdentityClient = new UserManager(PING_IDENTITY_CONFIGURATION);

pingIdentityClient.events.addSilentRenewError(() => {
  store.dispatch(modalReducers.setOpenModal({ name: DialogName.ExpiredSession }));
});

export default pingIdentityClient;
