import { TicketStatusesPalette } from '@types';
import { TicketStatus } from 'modules/tickets/enums';

export const STATUSES_COLOURS: {
  light: TicketStatusesPalette;
  dark: TicketStatusesPalette;
} = {
  light: {
    [TicketStatus.New]: {
      color: '#42A5F5',
      backgroundColor: `rgba(66, 165, 245, 0.1)`,
    },
    [TicketStatus.Canceled]: { color: '#EF5350', backgroundColor: `rgba(239, 83, 80, 0.1)` },
    [TicketStatus.InProgress]: {
      color: '#4CAF50',
      backgroundColor: `rgba(76, 175, 80, 0.1)`,
    },
    [TicketStatus.Open]: { color: '#37E4EF', backgroundColor: `rgba(55, 228, 239, 0.1)` },
    [TicketStatus.Assigned]: { color: '#1565C0', backgroundColor: `rgba(21, 101, 192, 0.1)` },
    [TicketStatus.Closed]: { color: '#C62828', backgroundColor: `rgba(198, 40, 40, 0.1)` },
    [TicketStatus.Resolved]: { color: '#1B5E20', backgroundColor: `rgba(27, 94, 32, 0.1)` },
    [TicketStatus.OnHold]: { color: '#ED6C02', backgroundColor: `rgba(237, 108, 2, 0.1)` },
    [TicketStatus.Acknowledged]: { color: '#FFBF42', backgroundColor: `rgba(255, 191, 66, 0.1)` },
    [TicketStatus.Monitoring]: { color: '#BA68C8', backgroundColor: `rgba(186, 104, 200, 0.1)` },
    [TicketStatus.AwaitingInfo]: { color: '#764A21', backgroundColor: `rgba(118, 74, 33, 0.1)` },
  },
  dark: {
    [TicketStatus.New]: { color: '#10B5FF', backgroundColor: `rgba(3, 169, 244, 0.2)` },
    [TicketStatus.Canceled]: { color: '#EF5350', backgroundColor: `rgba(239, 83, 80, 0.2)` },
    [TicketStatus.InProgress]: { color: '#94DD5B', backgroundColor: `rgba(148, 221, 91, 0.1)` },
    [TicketStatus.Open]: { color: '#37EFD9', backgroundColor: `rgba(55, 228, 239, 0.1)` },
    [TicketStatus.Assigned]: { color: '#779DFF', backgroundColor: `rgba(7, 106, 255, 0.2)` },
    [TicketStatus.Closed]: { color: '#E22E2E', backgroundColor: `rgba(187, 17, 17, 0.2)` },
    [TicketStatus.Resolved]: { color: '#1CB75A', backgroundColor: `rgba(30, 154, 39, 0.15)` },
    [TicketStatus.OnHold]: { color: '#ED6C02', backgroundColor: `rgba(237, 108, 2, 0.1)` },
    [TicketStatus.Acknowledged]: { color: '#FFBF42', backgroundColor: `rgba(255, 191, 66, 0.1)` },
    [TicketStatus.Monitoring]: { color: '#E242FD', backgroundColor: `rgba(226, 66, 253, 0.15)` },
    [TicketStatus.AwaitingInfo]: { color: '#D39D76', backgroundColor: `rgba(160, 118, 88, 0.15)` },
  },
};
