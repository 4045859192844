import { useSnackbar } from 'notistack';
import React, { FC, useEffect } from 'react';
import { SnackbarUtil } from 'utils/snackbar';

export const SnackbarUtilsConfigurator: FC<React.PropsWithChildren<unknown>> = () => {
  const snackbar = useSnackbar();

  useEffect(() => {
    SnackbarUtil.setSnackbarRef(snackbar);
  }, [snackbar]);

  return null;
};
