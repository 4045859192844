import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { clearAllReducer, registerReducers } from './repository';

export const locationsState = createSlice({
  name: EntityStoreName.Locations,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const locationsReducers = { ...locationsState.actions };
