import { Route } from 'app/enums';
import { useStore } from 'app/hooks';
import { pingIdentityClient } from 'app/services/ping-identity';
import { sessionSelector } from 'app/store/session/selectors';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'shared/components/Loader';

export const LogoutRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { clearUserSession } = useStore();
  const isAuthorized = useSelector(sessionSelector.isAuthorized);

  useEffect(() => {
    pingIdentityClient
      .signoutRedirectCallback()
      .then(() => clearUserSession())
      .catch(() => navigate(Route.Root));
  }, [navigate, clearUserSession]);

  useEffect(() => {
    if (!isAuthorized) {
      navigate(Route.Root);
    }
  }, [isAuthorized, navigate]);

  return <Loader />;
};
