export const auth = {
  components: {
    ExpiredSessionDialog: {
      title: 'Session Timed Out',
      subtitle: 'Your session has timed out and you must log in again to continue.',
      loginBtn: 'Login',
    },
  },
  constants: {
    ROLES: {
      Master_Admin: 'Master Admin',
      Group_Admin: 'Group Admin',
      Org_Admin: 'Organization Admin',
      Technician: 'Technician',
      User: 'User',
    },
  },
};
