import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: theme.spacing(21.875),
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    margin: theme.spacing(0, 8.75, 0, 7.5),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(0, 2, 0, 3),
    },
  },
  menuContainer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.header.background.MuiPaper,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.custom.colors.MuiInputBase,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.custom.colors.MuiOutlinedInputBorder,
      },
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(3.25, 3.875, 2.875, 2),
      borderStyle: 'solid',
      borderTopColor: theme.custom.border.default,
      borderTopWidth: 1,
      color: theme.palette.text.primary,
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3.5),
      fontWeight: FontWeight.Medium,
      '&.Mui-selected': {
        color: theme.palette.dropDown.color.active,
        fontWeight: FontWeight.Bold,
        backgroundColor: 'transparent',
      },
    },
  },

  button: {
    '&.MuiButton-root': {
      fontSize: theme.spacing(1.75),
      height: '100%',
      width: '100%',
      padding: theme.spacing(1.5, 1.5, 1.25, 2.25),
      backgroundColor: theme.palette.header.background.mainMenu.button,
      borderRadius: 0,
    },

    color: theme.palette.header.text.mainMenu.main,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  oneCompanyRoot: {},
  oneCompanyBlock: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: theme.palette.header.background.mainMenu.button,
    color: theme.palette.header.text.mainMenu.main,
    textTransform: 'capitalize',
    textAlign: 'center',
    cursor: 'default',
  },
  oneCompanyOrgTitle: {
    lineHeight: theme.spacing(2.625),
  },

  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  orgTitle: {
    textTransform: 'uppercase',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.375),
    },
    opacity: 0.6,
  },

  selected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontWeight: FontWeight.SemiBold,
    fontSize: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },

  arrowIcon: {
    fontSize: theme.spacing(3.75),
  },

  menu: {
    minWidth: theme.spacing(37.875),
  },
  search: {
    padding: theme.spacing(1.75),
    '& .MuiFormControl-root': {
      width: '100%',
      height: theme.spacing(6.25),
    },

    '& .MuiInputBase-root': {
      height: '100%',
      padding: theme.spacing(0, 1.5),
    },
  },
  list: {
    overflowY: 'auto',
    maxHeight: theme.spacing(40),
  },
  noMatchFound: {
    textAlign: 'center',
    padding: theme.spacing(1.25, 0, 3),
    '& .MuiTypography-root': {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3.5),
      color: theme.palette.wizard.label,
      padding: 0,
    },
  },
}));
