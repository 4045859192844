import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { ApiCredentialsEntity } from 'app/interfaces/entity/api-credentials/api-credentials.entity';

import { ApiCredentialsState } from '../interfaces/api-credentials-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: ApiCredentialsEntity) => data.clientId,
});

export const initialState: ApiCredentialsState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
