import { SankeyPalette } from '@types';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';

export const SANKEY_LIGHT: SankeyPalette = {
  link: {
    background: COLORS_LIGHT.neutral[100],
    active: COLORS_LIGHT.primary[100],
  },
  border: {
    default: COLORS_LIGHT.neutral.T16,
    hover: COLORS_LIGHT.neutral.T48,
    inActive: COLORS_LIGHT.neutral.T24,
    divided: COLORS_LIGHT.neutral.T12,
  },
  background: {
    default: COLORS_LIGHT.neutral.white,
    inActive: COLORS_DARK.neutral.T40,
  },
  text: {
    title: {
      active: COLORS_LIGHT.neutral.black,
      inActive: COLORS_LIGHT.neutral[400],
    },
    subTitle: {
      active: COLORS_LIGHT.neutral[500],
      inActive: '',
    },
    status: {
      active: COLORS_LIGHT.success[300],
      inActive: COLORS_LIGHT.primary[100],
    },
    property: {
      label: {
        active: COLORS_LIGHT.neutral[500],
        inActive: COLORS_LIGHT.neutral[500],
      },
      value: {
        active: COLORS_LIGHT.primary[800],
        inActive: COLORS_LIGHT.neutral[500],
      },
    },
  },
};

export const SANKEY_DARK: SankeyPalette = {
  link: {
    background: COLORS_DARK.neutral[900],
    active: COLORS_DARK.primary[300],
  },
  border: {
    default: COLORS_DARK.neutral.T16,
    hover: COLORS_DARK.neutral.T48,
    inActive: COLORS_DARK.neutral.T24,
    divided: COLORS_DARK.neutral.T12,
  },
  background: {
    default: COLORS_DARK.neutral[800],
    inActive: COLORS_DARK.neutral.T4,
  },
  text: {
    title: {
      active: COLORS_DARK.neutral.white,
      inActive: COLORS_DARK.neutral[500],
    },
    subTitle: {
      active: COLORS_DARK.neutral[300],
      inActive: COLORS_DARK.neutral[500],
    },
    status: {
      active: COLORS_LIGHT.success[300],
      inActive: COLORS_DARK.primary[100],
    },
    property: {
      label: {
        active: COLORS_DARK.neutral[300],
        inActive: COLORS_DARK.neutral[500],
      },
      value: {
        active: COLORS_DARK.neutral[100],
        inActive: COLORS_DARK.neutral[500],
      },
    },
  },
};
