import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: 20,
    color: theme.palette.error.main,
    fontWeight: FontWeight.Medium,
    fontSize: theme.spacing(1.25),
    lineHeight: theme.spacing(2),
  },
}));
