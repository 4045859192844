import React, { createContext, FC } from 'react';

import { OemConfiguration } from '../interfaces';
import { OEM_DEFAULT } from '../theme/constants';

interface OemContextProps {
  setOemConfig: (oem: OemConfiguration) => void;
  oemConfig: OemConfiguration;
}

export const OemConfigurationContext = createContext<OemContextProps>({
  setOemConfig: () => '',
  oemConfig: OEM_DEFAULT.oemConfig,
});

export const OemConfigurationProvider: FC<React.PropsWithChildren<OemContextProps>> = ({
  children,
  oemConfig,
  setOemConfig,
}) => {
  return (
    <OemConfigurationContext.Provider
      value={{
        oemConfig,
        setOemConfig,
      }}
    >
      {children}
    </OemConfigurationContext.Provider>
  );
};
