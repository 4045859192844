import { StoreName } from 'app/enums/store';
import { store } from 'app/store';
import { DataGridFilterType, DataGridName } from 'shared/enums';
import { CheckboxMap, DataGridColumnFilterData } from 'shared/interfaces';
import { extractFilteringOptions } from 'shared/utils/data-grid/getters/extract-filtering-options';

function convertFiltersToParams(filters: DataGridColumnFilterData[]) {
  return filters.reduce((res, filter) => {
    switch (filter.type) {
      case DataGridFilterType.List:
        const checkedList = (filter.value as CheckboxMap).filter(({ checked }) => checked);
        return { ...res, [filter.columnName]: Array.from(checkedList.keys()).join(',') };
      case DataGridFilterType.Date:
        return {
          ...res,
          ...{
            ...(filter.value.from ? { [`${filter.columnName}From`]: filter.value?.from?.toISOString() } : {}),
            ...(filter.value.to ? { [`${filter.columnName}To`]: filter.value?.to?.toISOString() } : {}),
          },
        };
      case DataGridFilterType.String:
      case DataGridFilterType.Number:
        return { ...res, [filter.columnName]: filter.value };
      default:
        return res;
    }
  }, {});
}

export const getFiltersApiParams = (gridName: DataGridName) => {
  const filters = Object.values(store.getState()[StoreName.DataGridFilters].entities) as DataGridColumnFilterData[];
  const gridFilters = extractFilteringOptions(filters, gridName);

  return convertFiltersToParams(Array.from(gridFilters.values()));
};
