import { Box, CircularProgress, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import React, { FC } from 'react';

import { useStyles } from './Loader.styles';

export interface LoaderProps {
  size?: number;
  fullPage?: boolean;
  logo?: string;
  dataTestId?: string;
}

export const Loader: FC<React.PropsWithChildren<LoaderProps>> = ({
  size = 48,
  fullPage = true,
  dataTestId = 'loader',
}) => {
  const { classes } = useStyles({ size });
  const theme = useTheme<Theme>();
  const themeLogo = theme?.icons?.general?.Loader;

  return (
    <Box
      className={classNames({
        [classes.fullPage]: fullPage,
        [classes.relative]: !fullPage,
      })}
      data-testid={dataTestId}
    >
      <Box className={classes.container}>
        {themeLogo ? (
          <object type="image/svg+xml" data={themeLogo} name="svg-animation" />
        ) : (
          <CircularProgress size={size} />
        )}
      </Box>
    </Box>
  );
};
