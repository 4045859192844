import { createSlice } from '@reduxjs/toolkit';
import { StoreName } from 'app/enums/store';
import { DATA_GRID_PAGINATION } from 'shared/constants/data-grid-pagination';
import { DataGridName } from 'shared/enums';
import { DataGridPaginationState } from 'shared/store/data-grid/pagination/interfaces';

import { resetReducer, setPaginationReducer } from './reducers';

export const dataGridPaginationState = createSlice({
  name: StoreName.DataGridPagination,
  initialState: {
    [DataGridName.Tickets]: DATA_GRID_PAGINATION,
    [DataGridName.ChangeRequests]: DATA_GRID_PAGINATION,
    [DataGridName.Users]: DATA_GRID_PAGINATION,
  } as DataGridPaginationState,
  reducers: {
    setPagination: setPaginationReducer,
    reset: resetReducer,
  },
});

export const dataGridPaginationReducers = { ...dataGridPaginationState.actions };
