import { Route } from 'app/enums';
import { Guard } from 'app/interfaces/guard';
import { pingIdentityClient } from 'app/services/ping-identity';
import { store } from 'app/store';
import { sessionReducers } from 'app/store/session/slice';

export const authGuard: Guard = async (to, resolver) => {
  const user = await pingIdentityClient.getUser();
  const token = store?.getState()?.session?.token;
  if (!token) {
    store.dispatch(sessionReducers.setRedirectAfterLoginPath(to));

    return resolver.redirect(Route.Login);
  }

  if (!user && token) {
    store.dispatch(sessionReducers.setRedirectAfterLoginPath(to));

    return resolver.redirect(Route.Logout);
  }

  resolver.next();
};
