import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { avatarUploadValidationSchema } from 'modules/profile/hooks/validation-schemas';
import { AvatarUploadFormFields } from 'modules/profile/interfaces';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

export const useAvatarUploadForm = () => {
  const resolver = yupResolver(avatarUploadValidationSchema);

  const form = useForm<AvatarUploadFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
