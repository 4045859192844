import { EntityStoreRequestType } from 'app/enums/store';
import { SsoState } from 'modules/admin/sso-management/store/sso/interfaces';

export const initialState: SsoState = {
  entity: {
    identifier: '',
    clientId: '',
    clientSecret: '',
    discoveryUri: '',
    userName: '',
    email: '',
    externalAuthorization: '',
    mfaAttest: true,
  },
  empty: null,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
};
