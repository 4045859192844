export const round = (val: string | number = 0, rounding: 0 | 1 | 2 = 0) => {
  const rawVal = Number(val);
  let value = rawVal;

  switch (rounding) {
    case 0:
      value = Math.round(rawVal);
      break;
    case 1:
      value = Math.round(10 * rawVal) / 10;
      break;
    case 2:
      value = Math.round(100 * rawVal) / 100;
  }

  return value;
};
