import { GridColDef } from '@mui/x-data-grid';
import { AlertItems } from 'app/interfaces/entity/dashboard';
import { AlertsGridField } from 'modules/monitoring/enums';

export const alertsColumnsMapping = (column: {
  visible: boolean;
  isDynamic: boolean;
  columnLabel: string;
  columnKey: string;
}): { valueGetterPath?: string[] } & GridColDef<AlertItems> => {
  const mapping: Record<string, AlertsGridField> = {
    'alert-severity': AlertsGridField.Severity, //Severity
    'alert-began': AlertsGridField.Began, //Began
    'alert-device': AlertsGridField.ResourceWebsite, //Resource/Website
    'alert-datasource': AlertsGridField.LogicModule, //LogicModule
    'alert-datasource-instance': AlertsGridField.Instance, //Instance
    'alert-datapoint': AlertsGridField.Datapoint, //Datapoint
    'alert-value': AlertsGridField.Value, //Value
    'alert-static-threshold': AlertsGridField.StaticThreshold, //Static Threshold
    'alert-dynamic-threshold': AlertsGridField.DynamicThreshold, //Dynamic Threshold ?
    'alert-notes': AlertsGridField.Notes, //Notes
    'alert-acked-by': AlertsGridField.AcknowledgedBy, //Acknowledged By
    'alert-acked-on': AlertsGridField.AcknowledgedOn, //Acknowledged On
    'alert-cleared-on': AlertsGridField.ClearedOn, //Cleared On
    'alert-in-sdt': AlertsGridField.InSDT, //In SDT
    'alert-rule-name': AlertsGridField.AlertRule, //Alert Rule
    'alert-escalation-chain': AlertsGridField.EscalationChain, //Escalation Chain
    'alert-datasource-instance-description': AlertsGridField.InstanceDescription, //Instance Description
    'alert-routing-state': AlertsGridField.RoutingState, //Routing State
    'alert-dependency-role': AlertsGridField.DependencyRole, //Dependency Role
    'alert-dependent-alerts': AlertsGridField.DependentAlerts, //Dependent Alerts ?
    'alert-full-path': AlertsGridField.FullPath, //Full Path
    'alert-group': AlertsGridField.Group, //Group
  };

  let name: AlertsGridField | string = mapping?.[column.columnKey];
  let valueGetterPath = undefined;

  if (column?.isDynamic) {
    valueGetterPath = ['row', 'customColumns', column.columnKey.replaceAll('_', '.')];

    name = `customColumns.${column.columnKey.replaceAll('_', '.')}`;
  }

  return { field: name, headerName: column.columnLabel, valueGetterPath };
};
