import * as Sentry from '@sentry/react';
import { Route } from 'app/enums';
import { useStore } from 'app/hooks';
import { pingIdentityClient } from 'app/services/ping-identity';
import { sessionSelector } from 'app/store/session/selectors';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'shared/components/Loader';

export const LoginRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { setUserSession } = useStore();
  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const redirectAfterLoginPath = useSelector(sessionSelector.redirectAfterLoginPath);

  useEffect(() => {
    pingIdentityClient
      .signinRedirectCallback()
      .then((pingUser) => {
        setUserSession(pingUser, (pingUser?.state as { redirectAfterLoginPath?: string })?.redirectAfterLoginPath);
      })
      .catch((error) => Sentry.captureException(error));
  }, [navigate, setUserSession]);

  useEffect(() => {
    if (isAuthorized) {
      navigate(redirectAfterLoginPath ? redirectAfterLoginPath : Route.Root);
    }
  }, [redirectAfterLoginPath, isAuthorized, navigate]);

  return <Loader />;
};
