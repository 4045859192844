export const getObjectValue = <T extends object>(
  object: T,
  key: string,
  caseInsensitive = true
): T[keyof T] | undefined => {
  if (!key) {
    return;
  }

  const objectKeys: string[] = Object.keys(object);
  const objectKey = caseInsensitive
    ? objectKeys.find((value: string) => value.toLowerCase() === key.toLowerCase())
    : objectKeys.find((value: string) => value === key);

  if (!objectKey) {
    return;
  }

  return object[objectKey as keyof typeof object];
};
