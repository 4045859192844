import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  listbox: {
    '& .MuiAutocomplete-option': {
      fontSize: theme.spacing(1.75),
    },
    '&::-webkit-scrollbar-thumb': {
      minHeight: theme.spacing(3.5),
    },

    '& .MuiAutocomplete-groupUl': {
      '& .MuiMenuItem-root': {
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(2),
      },
    },

    '& .MuiAutocomplete-option[aria-selected="true"]': {
      color: theme.palette.dropDown.color.active,
      fontWeight: FontWeight.SemiBold,
      backgroundColor: 'transparent !important',
    },
  },
  groupLabel: {
    position: 'initial',
    fontSize: theme.spacing(1.25),
    color: theme.palette.text.secondary,
    lineHeight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(1.25),
  },
}));
