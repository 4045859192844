import { Route } from 'app/enums';
import { RedirectConfig } from 'app/interfaces';
import { GuardedRoute } from 'app/interfaces/route';
import { RedirectConfigModel } from 'app/models';
import { authGuard, rolesGuard } from 'app/routing/guards';
import React from 'react';

import { Role } from '../auth/enums';

const ServicesDashboardsPage = React.lazy(() => import('./pages/ServicesDashboardsPage'));
const SitePerformancePage = React.lazy(() => import('./pages/SitePerformancePage'));

export const monitoringRoutes: GuardedRoute[] = [
  {
    path: Route.ServicesDashboards,
    component: ServicesDashboardsPage,
    availability: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin, Role.Technician, Role.User],
    guards: [rolesGuard([Role.Master_Admin, Role.Group_Admin, Role.Org_Admin, Role.Technician, Role.User]), authGuard],
    exact: true,
    children: [],
  },
  {
    path: Route.SitePerformance,
    component: SitePerformancePage,
    availability: [Role.Master_Admin, Role.Group_Admin, Role.Org_Admin, Role.Technician, Role.User],
    guards: [rolesGuard([Role.Master_Admin, Role.Group_Admin, Role.Org_Admin, Role.Technician, Role.User]), authGuard],
    exact: true,
    children: [],
  },
];

export const monitoringRedirects: RedirectConfig[] = [
  new RedirectConfigModel(Route.Monitoring, Route.ServicesDashboards, true),
];
