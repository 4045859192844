export const profile = {
  components: {
    ChangePasswordForm: {
      labels: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
      },
    },
    ChangePasswordModal: {
      successMessage: 'Password successfully changed.',
      saveBtn: 'Save',
      closeBtn: 'Cancel',
    },
    DocumentationModal: {
      documentation: 'Customer Portal User Documentation',
      report: 'Portal Attribution Report',
    },
    PasswordConfirmationModal: {
      title: 'Confirm Change',
      text: 'Enter Password to Confirm Email Change',
      cancelBtn: 'Cancel',
      saveBtn: 'Confirm',
    },
    ProfileEditForm: {
      labels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        timezone: 'Timezone',
        contactPreferences: 'Contact Preferences',
      },
    },
    ProfileMenu: {
      profile: 'Profile',
      changePassword: 'Change Password',
      documentation: 'Documentation',
      signOut: 'Log out',
    },
    ProfileModal: {
      successRequestMessages: {
        profileUpdate: 'Profile successfully updated.',
        deleteMfa: 'MFA device successfully deleted.',
      },
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
      tooltipTitle: "This user's profile is managed using SSO",
      editProfileBtn: ' Edit Profile',
      setMfaBtn: 'Set MFA',
      removeMfaBtn: 'Remove MFA',
      confirmation: {
        title: 'Delete MFA Device',
        text: 'Do you really want to delete MFA device: {{name}}?<br /> This process cannot be undone.',
        btn: 'Delete',
      },
    },
  },
  hooks: {
    avatarUploadValidationSchema: {
      fileType: {
        name: 'Invalid file type',
        message: 'Supported image types: {{type}}',
      },
      fileSize: {
        name: 'Invalid file size',
        message: 'The file is too large, max: 2 {{unit}}',
      },
    },
    changePasswordValidationSchema: {
      currentPassword: {
        label: 'Current password',
      },
      newPassword: {
        label: 'New password',
        number: '${path} must be at least one number',
        lowercaseCharacter: '${path} must be at least one lowercase character',
        uppercaseCharacter: '${path} must be at least one UPPERCASE character',
        specialCharacter: '${path} must be at least one special character',
        repeated_more_2_times: '${path} must not be more than 2 repeated characters',
        unique: '${path} must be at least 5 unique characters',
        match_passwords: 'Current and new passwords are equal',
      },
      confirmNewPassword: {
        label: 'Confirm new password',
        match_passwords: 'Passwords do not match',
      },
    },
    confirmPasswordValidationSchema: {
      password: {
        label: 'Password',
      },
    },
    profileEditValidationSchema: {
      firstName: {
        label: 'First name',
      },
      lastName: {
        label: 'Last name',
      },
      email: {
        label: 'Email',
      },
      phone: {
        label: 'Phone',
      },
      timezone: {
        label: 'Timezone',
      },
      contactPreferences: {
        label: 'Contact Preferences',
      },
    },
  },
};
