import { useDispatchMultiple } from 'app/hooks/store/use-dispatch-multiple';
import { locationsReducers } from 'app/store/locations/slice';
import { legacyProfileToUserEntityMapper } from 'app/store/session/dto-mappers';
import { sessionReducers } from 'app/store/session/slice';
import { usersReducers } from 'modules/admin/user-management/store/users/slice';
import { cloudConnectionsReducers } from 'modules/cloud-services/store/cloud-connections/slice';
import { mfaReducers } from 'modules/mfa/store/mfa/slice';
import { dashboardsClientReducer, dashboardsGlobalReducer } from 'modules/monitoring/store/slice';
import { organizationsReducers } from 'modules/organization/store/slice';
import { ticketAttachmentsReducers } from 'modules/tickets/store/ticket-attachments/slice';
import { ticketCommentsReducers } from 'modules/tickets/store/ticket-comments/slice';
import { ticketsReducers } from 'modules/tickets/store/tickets/slice';
import { User } from 'oidc-client-ts';
import { dataGridFiltersReducers } from 'shared/store/data-grid/filters/slice';
import { dataGridPaginationReducers } from 'shared/store/data-grid/pagination/slice';
import { dataGridSortReducers } from 'shared/store/data-grid/sorting/slice';

export const useStore = () => {
  const dispatchMultiple = useDispatchMultiple();

  const clearOnOrganizationChange = () => {
    dispatchMultiple([
      locationsReducers.clearAll(),
      cloudConnectionsReducers.clearAll(),
      ticketsReducers.clearAll(),
      ticketCommentsReducers.clearAll(),
      ticketAttachmentsReducers.clearAll(),
      usersReducers.clearAll(),
      dashboardsClientReducer.clearAll(),
      dashboardsGlobalReducer.clear(),
      dataGridFiltersReducers.clearAll(),
      dataGridPaginationReducers.reset(),
      dataGridSortReducers.reset(),
      sessionReducers.setPerformanceDashboardLocationId(''),
      sessionReducers.setPerformanceDashboardId(''),
      sessionReducers.setPerformanceCrossCloudDashboardId(''),
    ]);
  };

  const setUserSession = (user: User, redirectPath?: string) => {
    dispatchMultiple([
      sessionReducers.setAuthToken(user?.access_token),
      sessionReducers.setUserProfile(legacyProfileToUserEntityMapper(user.profile)),
      sessionReducers.setRedirectAfterLoginPath(redirectPath),
    ]);
  };

  const clearUserSession = (redirectPath = '') => {
    dispatchMultiple([
      sessionReducers.setAuthToken(),
      sessionReducers.setUserProfile(),
      sessionReducers.setRedirectAfterLoginPath(redirectPath),
      organizationsReducers.clearAll(),
      mfaReducers.clear(),
    ]);
  };

  return { clearOnOrganizationChange, clearUserSession, setUserSession };
};
