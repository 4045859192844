import { DEFAULT_ERROR_MESSAGE } from 'app/services/api/constants';
import { ErrorResponse } from 'app/services/api/interfaces';
import { AxiosError } from 'axios';

// TODO: add more logic when the backend will be done.
export const errorMessageParser = (error?: AxiosError<ErrorResponse> | ErrorResponse): string => {
  if (!error) {
    return DEFAULT_ERROR_MESSAGE;
  }
  const errorData = (error as AxiosError<ErrorResponse>)?.response?.data || (error as ErrorResponse);

  const errorText = errorData?.error && errorData?.message ? `${errorData.error}: ${errorData.message}` : '';

  return errorText || error.message || DEFAULT_ERROR_MESSAGE;
};
