import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { clearAllReducer, registerReducers } from './repository';

export const ticketCommentsState = createSlice({
  name: EntityStoreName.TicketComments,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const ticketCommentsReducers = { ...ticketCommentsState.actions };
