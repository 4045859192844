import { Route } from 'app/enums';
import { i18n, LangNameSpace } from 'app/i18n';
import { OEM_DEFAULT } from 'app/theme/constants';

const defaultPageTitle = OEM_DEFAULT.oemConfig.pageTitle;

export const PageTitle = {
  root: {
    default: defaultPageTitle,
    [Route.AdminUserManagement]: i18n.t('constants.PageTitle.AdminUserManagement', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.TicketsList]: i18n.t('constants.PageTitle.TicketsList', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.TicketDetails]: i18n.t('constants.PageTitle.TicketDetails', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.AdminSSOManagement]: i18n.t('constants.PageTitle.AdminSSOManagement', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.Services]: i18n.t('constants.PageTitle.Services', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.ServicesDashboards]: i18n.t('constants.PageTitle.ServicesDashboards', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
    [Route.SitePerformance]: i18n.t('constants.PageTitle.SitePerformance', {
      ns: LangNameSpace.Shared,
      defaultPageTitle,
    }),
  },
};
