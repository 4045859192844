import { admin } from './admin';
import { auth } from './auth';
import { changeRequest } from './change-requests';
import { cloudService } from './cloud-services';
import { mfa } from './mfa';
import { monitoring } from './monitoring';
import { profile } from './profile';
import { sankey } from './sankey';
import { ticket } from './ticket';

export const MODULES = {
  admin,
  auth,
  changeRequest,
  cloudService,
  mfa,
  monitoring,
  profile,
  sankey,
  ticket,
};
