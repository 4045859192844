import { ConfirmationModalTitle, ModalName, Route, WizardTitle } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import { locationsSelectorFactory } from 'app/store/locations/selectors';
import { ModalPayload } from 'app/store/modal/interfaces/modals-state';
import { modalSelector } from 'app/store/modal/selectors';
import { sessionSelector } from 'app/store/session/selectors';
import { OEM_DEFAULT } from 'app/theme/constants';
import { mfaSelector } from 'modules/mfa/store/mfa/selectors';
import { dashboardsPerformanceSelectorFactory, dashboardsSelectorFactory } from 'modules/monitoring/store/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageTitle } from 'shared/constants/page-title';

export const usePageTitle = () => {
  const [pageTitle, setPageTitle] = useState<string>(OEM_DEFAULT.oemConfig.pageTitle as string);
  const { t } = useTranslation(LangNameSpace.App);
  const selectedLocation = useSelector(locationsSelectorFactory.useSelectOne());
  const selectedDashboardName = useSelector(dashboardsSelectorFactory.useSelectOne())?.name;
  const selectedPerformanceDashboard = useSelector(dashboardsPerformanceSelectorFactory.useSelectOne())?.name;
  const modalTitle = useSelector(modalSelector.modalTitle);
  const confirmationModalTitle = useSelector(modalSelector.confirmationModalTitle);
  const modalData = useSelector(modalSelector.data) as unknown as ModalPayload;
  const modalSettings = useSelector(modalSelector.settings) as unknown as { page: number };
  const ticketId = useSelector(sessionSelector.ticketId);

  const modalPage = modalSettings?.page;
  const isMfaConfigured = useSelector(mfaSelector.isConfigured);
  const isMfaCreate = useSelector(modalSelector.isOpen(ModalName.MfaCreate));

  const location = useLocation();

  const globalTitle = OEM_DEFAULT.oemConfig.pageTitle;

  const preparedModalTitle = useMemo(() => {
    const currentStep = t('hooks.usePageTitle.currentStep', {
      page: modalPage,
    });

    if (modalPage === undefined || '') {
      return confirmationModalTitle || modalTitle;
    } else if (modalTitle === WizardTitle.AttachToCloud && modalPage !== undefined) {
      if (modalData?.data?.provider !== undefined && modalPage >= 2 && selectedLocation?.name !== undefined) {
        if (selectedLocation?.name === undefined) return modalTitle;

        return `${modalTitle} - ${currentStep} - ${selectedLocation?.name} to ${
          (modalData as ModalPayload).data.provider
        }`;
      }
      return `${modalTitle} - ${selectedLocation?.name} | ${currentStep}`;
    }

    return `${modalTitle} | ${currentStep}`;
  }, [t, confirmationModalTitle, modalTitle, modalData, selectedLocation?.name, modalPage]);

  const preparedMontoringTitle = useCallback((selectedDashboard: string | undefined) => {
    if (selectedDashboard === undefined) return '';

    return `| ${selectedDashboard}`;
  }, []);

  useEffect(() => {
    if ((confirmationModalTitle || modalTitle) === '') {
      switch (location.pathname) {
        case Route.Services:
          setPageTitle(`${PageTitle.root[Route.Services]}`);
          break;
        case Route.TicketsList:
          setPageTitle(`${PageTitle.root[Route.TicketsList]}`);
          break;
        // tickets details tickets/list/:id
        case `${Route.TicketsList}/${ticketId}`:
          setPageTitle(`${PageTitle.root[Route.TicketDetails]}`);
          break;
        case Route.ServicesDashboards:
          setPageTitle(`${PageTitle.root[Route.ServicesDashboards]} ${preparedMontoringTitle(selectedDashboardName)}`);
          break;
        case Route.SitePerformance:
          setPageTitle(
            `${PageTitle.root[Route.SitePerformance]} ${preparedMontoringTitle(selectedPerformanceDashboard)}`
          );
          break;
        case Route.AdminUserManagement:
          setPageTitle(`${PageTitle.root[Route.AdminUserManagement]}`);
          break;
        case Route.AdminSSOManagement:
          setPageTitle(`${PageTitle.root[Route.AdminSSOManagement]}`);
          break;
        default:
          setPageTitle(globalTitle ?? '');
      }
    }

    if ((confirmationModalTitle || modalTitle) !== '') {
      switch (location.pathname) {
        case Route.AdminUserManagement:
          setPageTitle(`${PageTitle.root[Route.AdminUserManagement]} | ${preparedModalTitle}`);
          break;
        default:
          setPageTitle(`${PageTitle.root.default} | ${preparedModalTitle}`);
          break;
      }
    }

    if (isMfaCreate && !isMfaConfigured) {
      setPageTitle(`${PageTitle.root.default} | ${ConfirmationModalTitle.UserMfaCreateConfirm}`);
    }
  }, [
    confirmationModalTitle,
    pageTitle,
    selectedLocation,
    globalTitle,
    modalTitle,
    modalSettings,
    location.pathname,
    ticketId,
    preparedModalTitle,
    selectedDashboardName,
    preparedMontoringTitle,
    selectedPerformanceDashboard,
    isMfaCreate,
    isMfaConfigured,
  ]);

  return { pageTitle };
};
