import { EntityStoreName } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { AppState, createAppSelector } from 'app/store';
import { entityAdapter } from 'modules/admin/user-management/store/users/adapter';
import { useMemo } from 'react';

const userState = (state: AppState) => state[EntityStoreName.Users];

const selectAll = entityAdapter.getSelectors<AppState>(userState).selectAll;

const selectOne = (userId: Identifier) =>
  createAppSelector(userState, (state) => entityAdapter.getSelectors().selectById(state, String(userId)));

export const usersSelector = {
  all: selectAll,
};

export const usersSelectorFactory = {
  useOne: (userId: Identifier) => useMemo(() => selectOne(userId), [userId]),
};
