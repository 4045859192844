import { Description, ExitToApp, Lock, Person as PersonIcon } from '@mui/icons-material';
import { Box, Divider, IconButton, Menu } from '@mui/material';
import { ModalName, ModalTitle, Route } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import { modalReducers } from 'app/store/modal/slice';
import { sessionSelector } from 'app/store/session/selectors';
import { ProfileDetails } from 'modules/profile/components/ProfileDetails/ProfileDetails';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuItemWithIcon } from 'shared/components/MenuItemWithIcon';

import { useStyles } from './ProfileMenu.styles';

export const ProfileMenu: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const user = useSelector(sessionSelector.userProfile);

  const logoutHandler = useCallback(() => {
    setAnchor(null);
    navigate(Route.Logout);
  }, [navigate, setAnchor]);

  const openProfileModal = useCallback(() => {
    dispatch(modalReducers.setOpenModal({ name: ModalName.Profile, title: ModalTitle.Profile }));
    setAnchor(null);
  }, [dispatch]);

  const openDocumentationModal = useCallback(() => {
    dispatch(modalReducers.setOpenModal({ name: ModalName.Documentation, title: ModalTitle.Documentation }));
    setAnchor(null);
  }, [dispatch]);

  const openChangePasswordModal = useCallback(() => {
    dispatch(
      modalReducers.setOpenModal({ name: ModalName.ProfileChangePassword, title: ModalTitle.ProfileChangePassword })
    );
    setAnchor(null);
  }, [dispatch]);

  return (
    <>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)} size="large" data-testid="userProfileMenu">
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        MenuListProps={{ className: classes.menuItem }}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        className={classes.root}
      >
        <Box px={2}>
          <ProfileDetails user={user} />
        </Box>
        <Divider orientation="horizontal" />
        <MenuItemWithIcon
          text={t('profile.components.ProfileMenu.profile')}
          startIcon={<PersonIcon />}
          onClick={openProfileModal}
          data-testid="menuProfile"
        />
        {!user?.idpId && (
          <MenuItemWithIcon
            text={t('profile.components.ProfileMenu.changePassword')}
            startIcon={<Lock />}
            onClick={openChangePasswordModal}
            data-testid="menuChangePassword"
          />
        )}
        <MenuItemWithIcon
          text={t('profile.components.ProfileMenu.documentation')}
          startIcon={<Description />}
          onClick={openDocumentationModal}
          data-testid="menuDocumentation"
        />
        <Divider orientation="horizontal" />
        <MenuItemWithIcon
          text={t('profile.components.ProfileMenu.signOut')}
          startIcon={<ExitToApp />}
          onClick={logoutHandler}
          data-testid="menuSignOut"
        />
      </Menu>
    </>
  );
};
