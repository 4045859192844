import { i18n, LangNameSpace } from 'app/i18n';
import { object, string } from 'yup';

export const mfaVerificationFormValidationSchema = object().shape({
  confirmationCode: string()
    .required()
    .length(6)
    .matches(
      new RegExp('^[0-9]*$'),
      i18n.t('mfa.hooks.mfaVerificationFormValidationSchema.confirmationCode.error', { ns: LangNameSpace.Modules })
    )
    .label(
      i18n.t('mfa.hooks.mfaVerificationFormValidationSchema.confirmationCode.label', { ns: LangNameSpace.Modules })
    ),
});
