import { Box, Typography } from '@mui/material';
import { ConfirmationModalName } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import { modalSelector } from 'app/store/modal/selectors';
import { usePasswordConfirm } from 'modules/profile/hooks/use-password-confirm';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from 'shared/components/Modal';

import { PasswordConfirmationForm } from '../PasswordConfirmationForm';

export interface PasswordConfirmationModalProps {
  onSubmit?: (password: string) => Promise<void>;
}

const PasswordConfirmationModalComponent: FC<React.PropsWithChildren<PasswordConfirmationModalProps>> = ({
  onSubmit = () => Promise.resolve(),
}) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const opened = useSelector(modalSelector.isConfirmationOpen(ConfirmationModalName.PasswordRequired));
  const form = usePasswordConfirm();

  const onCloseHandler = useCallback(() => {
    form.reset();
  }, [form]);

  const onSubmitHandler = useCallback(
    () => onSubmit(form.getValues().password).then(() => form.reset()),
    [onSubmit, form]
  );

  const submitDisabled = useCallback(() => !form.formState.isValid, [form]);

  if (!opened) return null;

  return (
    <ConfirmationModal
      name={ConfirmationModalName.PasswordRequired}
      onSubmit={onSubmitHandler}
      successBtnTitle={t('profile.components.PasswordConfirmationModal.saveBtn')}
      cancelBtnTitle={t('profile.components.PasswordConfirmationModal.cancelBtn')}
      title={t('profile.components.PasswordConfirmationModal.title')}
      disableSubmitBtn={submitDisabled}
      onClose={onCloseHandler}
      skipBottomContentPadding={true}
    >
      <Box display="flex" width="100%" flexDirection="column" mb={0.5} alignItems="flex-start">
        <Typography variant="body2" typography="b2Regular" mb={2}>
          {t('profile.components.PasswordConfirmationModal.text')}
        </Typography>
        <PasswordConfirmationForm form={form} />
      </Box>
    </ConfirmationModal>
  );
};

export const PasswordConfirmationModal = PasswordConfirmationModalComponent;
