import { i18n, LangNameSpace } from 'app/i18n';
import { ChangePasswordFormFields } from 'modules/profile/interfaces';
import { object, ObjectSchema, string } from 'yup';

export const changePasswordValidationSchema: ObjectSchema<ChangePasswordFormFields> = object().shape({
  currentPassword: string()
    .required()
    .label(i18n.t('profile.hooks.changePasswordValidationSchema.currentPassword.label', { ns: LangNameSpace.Modules })),
  newPassword: string()
    .required()
    .min(8)
    .max(255)
    .matches(
      /[0-9]+/,
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.number', { ns: LangNameSpace.Modules })
    )
    .matches(
      /[a-z]+/,
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.lowercaseCharacter', {
        ns: LangNameSpace.Modules,
      })
    )
    .matches(
      /[A-Z]+/,
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.uppercaseCharacter', {
        ns: LangNameSpace.Modules,
      })
    )
    .matches(
      /[^a-zA-Z0-9]+/,
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.specialCharacter', { ns: LangNameSpace.Modules })
    )
    .test(
      'repeated_more_2_times',
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.repeated_more_2_times', {
        ns: LangNameSpace.Modules,
      }),
      (value = '') => (value.match(/(.)\1{2,}/) ?? []).length === 0
    )
    .test(
      '5_unique',
      i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.unique', { ns: LangNameSpace.Modules }),
      (value) => new Set(value).size >= 5
    )
    .when('currentPassword', {
      is: (val = '') => val.length > 0,
      then: (schema) =>
        schema.test(
          'match_passwords',
          i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.match_passwords', {
            ns: LangNameSpace.Modules,
          }),
          function (value) {
            return this.parent.currentPassword !== value;
          }
        ),
    })
    .label(i18n.t('profile.hooks.changePasswordValidationSchema.newPassword.label', { ns: LangNameSpace.Modules })),
  confirmNewPassword: string()
    .required()
    .when('newPassword', {
      is: (val = '') => val.length > 0,
      then: (schema) =>
        schema.required().test(
          'match passwords',
          i18n.t('profile.hooks.changePasswordValidationSchema.confirmNewPassword.match_passwords', {
            ns: LangNameSpace.Modules,
          }),
          function (value) {
            return this.parent.newPassword === value;
          }
        ),
    })

    .label(
      i18n.t('profile.hooks.changePasswordValidationSchema.confirmNewPassword.label', { ns: LangNameSpace.Modules })
    ),
});
