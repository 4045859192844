import { MenuItem, MenuItemProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';

export interface ArrowMenuItemProps extends MenuItemProps {
  text: string;
  startIcon: ReactNode;
}

export const MenuItemWithIcon: FC<React.PropsWithChildren<ArrowMenuItemProps>> = ({
  text,
  startIcon = null,
  ...rest
}) => {
  return (
    <MenuItem {...rest}>
      {startIcon != null && startIcon}
      {text}
    </MenuItem>
  );
};
