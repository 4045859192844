import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Autocomplete, AutocompleteProps, createFilterOptions, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Timezone } from 'shared/interfaces';

import { useStyles } from './TimeZoneDropdown.styles';

interface TimezoneOption extends Omit<Timezone, 'countries'> {
  id: string;
  country: string;
}

interface TimeZoneDropdownProps
  extends Omit<
    AutocompleteProps<TimezoneOption, false, false, false>,
    'renderInput' | 'options' | 'value' | 'onChange'
  > {
  inputProps: TextFieldProps;
  onChange?: (value: string) => void;
  value?: string;
}

const parseTimeZoneOptions = (timezones: Timezone[]): TimezoneOption[] =>
  timezones
    .reduce<TimezoneOption[]>(
      (acc, timezone) => [
        ...acc,
        ...timezone.countries.map((country) => ({
          id: `${country}_${timezone.code}`.toLocaleLowerCase().replace('/', '_'),
          country,
          code: timezone.code,
          offset: timezone.offset,
        })),
      ],
      []
    )
    .sort((a, b) => a.country.localeCompare(b.country));

export const TimeZoneDropdown = forwardRef<HTMLDivElement, TimeZoneDropdownProps>(
  ({ inputProps, onChange, value, ...rest }, ref) => {
    const { classes } = useStyles();
    const [options, setOptions] = useState<TimezoneOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const filterOptions = useMemo(
      () =>
        createFilterOptions<TimezoneOption>({
          stringify: ({ country, code, offset }) => `${country} ${code} ${offset}`,
        }),
      []
    );

    const timezoneValue = useMemo(() => {
      const valueData = value && options.find((e) => e.code === value);
      return valueData || null;
    }, [options, value]);

    useEffect(() => {
      setLoading(true);
      import('assets/json/timezones.json')
        .then(({ default: timezones }) => {
          setOptions(parseTimeZoneOptions(Array.from(timezones)));
        })

        .finally(() => setLoading(false));
    }, []);

    return (
      <Autocomplete
        classes={classes}
        size="small"
        value={timezoneValue}
        onChange={(_, val) => {
          onChange?.(val?.code || '');
        }}
        loading={loading}
        options={options}
        groupBy={(option) => option.country}
        getOptionLabel={(option) => `${option.code} (${option.offset})`}
        filterOptions={filterOptions}
        openOnFocus={true}
        includeInputInList={true}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => <TextField {...params} {...inputProps} ref={ref} />}
        isOptionEqualToValue={(option, currentVal) => option.code === currentVal.code}
        {...rest}
      />
    );
  }
);
