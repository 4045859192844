import { FontStyle, FontWeight } from 'app/enums/theme';
import RobotoBlack from 'assets/fonts/Roboto/Roboto-Black.ttf';
import RobotoBlackItalic from 'assets/fonts/Roboto/Roboto-BlackItalic.ttf';
import RobotoBold from 'assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoBoldItalic from 'assets/fonts/Roboto/Roboto-BoldItalic.ttf';
import RobotoLight from 'assets/fonts/Roboto/Roboto-Light.ttf';
import RobotoLightItalic from 'assets/fonts/Roboto/Roboto-LightItalic.ttf';
import RobotoMedium from 'assets/fonts/Roboto/Roboto-Medium.ttf';
import RobotoMediumItalic from 'assets/fonts/Roboto/Roboto-MediumItalic.ttf';
import RobotoRegular from 'assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoRegularItalic from 'assets/fonts/Roboto/Roboto-RegularItalic.ttf';
import RobotoThin from 'assets/fonts/Roboto/Roboto-Thin.ttf';
import RobotoThinItalic from 'assets/fonts/Roboto/Roboto-ThinItalic.ttf';

export const ROBOTO_FONT_NAME = 'Roboto';

export const ROBOTO_FONT_FACES = `
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Roboto-Regular'), url(${RobotoRegular}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Roboto-RegularItalic'), url(${RobotoRegularItalic}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Roboto-Black'), url(${RobotoBlack}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Roboto-BlackItalic'), url(${RobotoBlackItalic}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Roboto-Bold'), url(${RobotoBold}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Roboto-BoldItalic'), url(${RobotoBoldItalic}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Roboto-Light'), url(${RobotoLight}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Roboto-LightItalic'), url(${RobotoLightItalic}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Roboto-Medium'), url(${RobotoMedium}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Roboto-MediumItalic'), url(${RobotoMediumItalic}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Roboto-Thin'), url(${RobotoThin}) format('truetype');
  }
  @font-face {
    font-family: "Roboto";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Roboto-ThinItalic'), url(${RobotoThinItalic}) format('truetype');
  }
`;
