import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { LocationEntity } from 'app/interfaces/entity/location';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { LocationDto } from 'app/store/locations/interfaces/dto';
import { entityStoreStatus } from 'app/utils/store';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper } from './dto-mappers';
import { LocationsState } from './interfaces';

export const getLocationsList = createAsyncThunk(
  `${EntityStoreName.Locations}`,
  (_, { signal }): Promise<LocationEntity[]> => {
    return apiRequestCall<LocationDto[], LocationEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Locations,
        signal,
      },
    }).then((data) => data.map(dtoToEntityMapper));
  }
);

export const clearAllReducer = (state: LocationsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<LocationsState>): void => {
  builder
    .addCase(getLocationsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getLocationsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getLocationsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
