import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import { SsoGroupMappingsGridField } from 'modules/admin/sso-management/enums';
import { UsersGridField } from 'modules/admin/user-management/enums/users-grid-field';
import { ChangeRequestsGridField } from 'modules/change-requests/enums';
import { TicketsGridField } from 'modules/tickets/enums';
import { DataGridName } from 'shared/enums';
import { DataGridSortState } from 'shared/store/data-grid/sorting/interfaces/data-grid-sort-state';

export const initialState: DataGridSortState = {
  [DataGridName.Tickets]: [{ field: TicketsGridField.LastUpdated, sort: 'desc' as GridSortDirection }],
  [DataGridName.ChangeRequests]: [{ field: ChangeRequestsGridField.CreatedDate, sort: 'desc' as GridSortDirection }],
  [DataGridName.Users]: [{ field: UsersGridField.FirstName, sort: 'asc' as GridSortDirection }],
  [DataGridName.SsoGroupMappings]: [{ field: SsoGroupMappingsGridField.GroupName, sort: 'asc' as GridSortDirection }],
};
