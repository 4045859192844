import { ResponseInterceptor } from 'app/services/api/interfaces';
import { InternalCacheRequestConfig } from 'axios-cache-interceptor';

import { authTokenInterceptor } from './request/auth-token-interceptor';
import { organizationInterceptor } from './request/organization-interceptor';
import { errorHandlerInterceptor } from './response/error-handler-interceptor';
import { unauthorizedInterceptor } from './response/unauthorized-interseptor';

/**
 * Array of request interceptors. Could be extended with another line 'x-organization' header, etc.
 */
export const requestInterceptors: Array<
  (config: InternalCacheRequestConfig) => Promise<InternalCacheRequestConfig> | InternalCacheRequestConfig
> = [authTokenInterceptor, organizationInterceptor];

/**
 * Array of response interceptors
 */
export const responseInterceptors: Array<ResponseInterceptor> = [unauthorizedInterceptor, errorHandlerInterceptor];
