import { extractFilteringOptions } from './extract-filtering-options';
import { getFiltersApiParams } from './get-filters-api-params';
import { getPaginationApiParams } from './get-pagination-api-params';
import { getSortApiParams } from './get-sort-api-params';

export const dataGridFilterGetters = {
  extractFilteringOptions,
  getFiltersApiParams,
  getPaginationApiParams,
  getSortApiParams,
};
