import { ThemeOptions } from '@mui/material/styles/createTheme';
import { FontWeight } from 'app/enums/theme';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';
import { TYPOGRAPHY } from 'app/theme/constants/typography';

export const COMPONENTS_LIGHT: ThemeOptions['components'] = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiOutlinedInput-root': {
          '&.MuiInputBase-sizeSmall': {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1.125),
            '& .MuiAutocomplete-input': {
              marginTop: theme.spacing(1),
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: theme.spacing(1.75),
            },
          },
        },
        '& .MuiChip-label': {
          ...TYPOGRAPHY.b2Regular,
          color: theme.custom.colors.MuiChip,
          paddingLeft: 0,
          paddingRight: theme.spacing(1.5),
        },
        '& .MuiChip-root': {
          padding: theme.spacing(0, 0.625, 0, 1),
          margin: theme.spacing(0.5, 0.5, 0, 0.5),
          borderRadius: theme.spacing(0.5),
        },
      }),
      inputRoot: ({ theme }) => ({
        padding: theme.spacing(1.75, 1.75, 1.75, 2.5),
        lineHeight: theme.spacing(3.5),
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS_LIGHT.neutral.T48,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS_LIGHT.neutral.T24,
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_LIGHT.neutral.T12,
            borderWidth: 1,
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_LIGHT.primary[300],
            borderWidth: 1,
          },
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_LIGHT.warning[400],
            borderWidth: 1,
          },
        },
      },
      input: ({ theme }) => ({
        ...TYPOGRAPHY.b1Regular,
        color: COLORS_LIGHT.neutral.black,
        padding: theme.spacing(1.25, 1, 1.25, 1.5),
        borderColor: COLORS_LIGHT.neutral.T24,
        '&.Mui-disabled': {
          color: COLORS_LIGHT.neutral[300],
          WebkitTextFillColor: COLORS_LIGHT.neutral[300],
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        ':hover': {
          boxShadow: 'none',
        },
      },
    },
    variants: [
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: COLORS_LIGHT.neutral[500],
          border: `1px solid ${COLORS_LIGHT.neutral[300]}`,
          ':hover, &.active': {
            backgroundColor: COLORS_LIGHT.neutral.T8,
            border: `1px solid ${COLORS_LIGHT.neutral[300]}`,
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: 'rgba(224, 227, 231, 0.6)',
          },
          ':disabled': {
            color: COLORS_LIGHT.neutral[200],
            border: `1px solid ${COLORS_LIGHT.neutral[200]}`,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: COLORS_LIGHT.neutral.white,
          backgroundColor: COLORS_LIGHT.primary[500],
          border: 'none',
          ':hover': {
            backgroundColor: COLORS_LIGHT.primary[600],
            border: 'none',
          },
          ':disabled': {
            color: COLORS_LIGHT.neutral.white,
            backgroundColor: COLORS_LIGHT.primary.T48,
            border: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: COLORS_LIGHT.neutral[500],
          ':hover': {
            backgroundColor: COLORS_LIGHT.neutral.T8,
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: 'rgba(224, 227, 231, 0.6)',
          },
          ':disabled': {
            color: COLORS_LIGHT.neutral[200],
            backgroundColor: 'transparent',
          },
        },
      },
    ],
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3.75, 0),
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        '&.Mui-focused': {
          color: COLORS_LIGHT.primary[300],
        },
        '&.Mui-disabled': {
          color: COLORS_LIGHT.neutral[300],
        },
        '&.Mui-focused.Mui-error': {
          color: COLORS_LIGHT.warning[400],
        },
      },
      shrink: ({ theme }) => ({
        fontSize: theme.spacing(1.75),
        fontWeight: FontWeight.Medium,
      }),
      root: ({ theme }) => ({
        ...TYPOGRAPHY.b1Regular,
        color: COLORS_LIGHT.neutral[400],
        lineHeight: theme.spacing(3.25),
        fontSize: theme.spacing(2),
        '&.Mui-error': {
          color: COLORS_LIGHT.warning[400],
        },
        '&.Mui-disabled': {
          color: COLORS_LIGHT.neutral[300],
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: COLORS_LIGHT.neutral.black,
        ...TYPOGRAPHY.b1SemiBold,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: COLORS_LIGHT.neutral.black,
        ...TYPOGRAPHY.b1Regular,
      },
      label: {
        color: COLORS_LIGHT.neutral.black,
        ...TYPOGRAPHY.b2Regular,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: ({ theme }) => ({
        fontSize: theme.spacing(2),
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&[type="password"]': {
          fontFamily: 'Verdana',
          letterSpacing: theme.spacing(0.5),
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: COLORS_LIGHT.warning[100],
        color: COLORS_LIGHT.warning[400],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.warning[400],
        },
      },
      standardWarning: {
        backgroundColor: COLORS_LIGHT.alert[100],
        color: COLORS_DARK.alert[400],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.alert[400],
        },
      },
      standardInfo: {
        backgroundColor: COLORS_LIGHT.info[300],
        color: COLORS_LIGHT.info[400],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.info[400],
        },
      },
      standardSuccess: {
        backgroundColor: COLORS_LIGHT.success[100],
        color: COLORS_LIGHT.success[400],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.success[400],
        },
      },

      filledError: {
        backgroundColor: COLORS_LIGHT.warning[400],
        color: COLORS_LIGHT.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.neutral.white,
        },
      },
      filledWarning: {
        backgroundColor: COLORS_LIGHT.alert[300],
        color: COLORS_LIGHT.neutral.black,

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.neutral.black,
        },
      },
      filledInfo: {
        backgroundColor: COLORS_LIGHT.info[300],
        color: COLORS_LIGHT.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.neutral.white,
        },
      },
      filledSuccess: {
        backgroundColor: COLORS_LIGHT.success[300],
        color: COLORS_LIGHT.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.neutral.white,
        },
      },
      message: {
        ...TYPOGRAPHY.b1Regular,
      },
      icon: {
        opacity: 1,
        '& .MuiSvgIcon-root': {
          fontSize: '34px',
        },
      },
    },
  },
};

export const COMPONENTS_DARK: ThemeOptions['components'] = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiOutlinedInput-root': {
          '&.MuiInputBase-sizeSmall': {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1.125),
            '& .MuiAutocomplete-input': {
              marginTop: theme.spacing(1),
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: theme.spacing(1.75),
            },
          },
        },
        '& .MuiChip-label': {
          ...TYPOGRAPHY.b2Regular,
          color: theme.custom.colors.MuiChip,
          paddingLeft: 0,
          paddingRight: theme.spacing(1.5),
        },
        '& .MuiChip-root': {
          padding: theme.spacing(0, 0.625, 0, 1),
          margin: theme.spacing(0.5, 0.5, 0, 0.5),
          borderRadius: theme.spacing(0.5),
        },
      }),
      inputRoot: ({ theme }) => ({
        padding: theme.spacing(1.75, 1.75, 1.75, 2.5),
        lineHeight: theme.spacing(3.5),
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS_DARK.neutral.T48,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS_DARK.neutral.T24,
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_DARK.neutral.T12,
            borderWidth: 1,
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_LIGHT.primary[100],
            borderWidth: 1,
          },
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS_LIGHT.warning[400],
            borderWidth: 1,
          },
        },
      },
      input: ({ theme }) => ({
        ...TYPOGRAPHY.b1Regular,
        color: COLORS_DARK.neutral.white,
        padding: theme.spacing(1.25, 1, 1.25, 1.5),
        borderColor: COLORS_DARK.neutral.T24,

        '&.Mui-disabled': {
          color: COLORS_DARK.neutral[600],
          WebkitTextFillColor: COLORS_DARK.neutral[600],
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        ':hover': {
          boxShadow: 'none',
        },
      },
    },
    variants: [
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: COLORS_DARK.neutral.white,
          border: `1px solid ${COLORS_DARK.neutral[300]}`,
          ':hover': {
            backgroundColor: COLORS_DARK.neutral[800],
            border: `1px solid ${COLORS_DARK.neutral[300]}`,
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          ':disabled': {
            color: COLORS_DARK.neutral[200],
            border: `1px solid ${COLORS_DARK.neutral[200]}`,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: COLORS_DARK.neutral.white,
          backgroundColor: COLORS_DARK.primary[300],
          border: 'none',
          ':hover': {
            backgroundColor: COLORS_DARK.primary[500],
            border: 'none',
          },
          ':disabled': {
            color: COLORS_DARK.neutral.white,
            backgroundColor: COLORS_DARK.primary.T48,
            border: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: COLORS_DARK.neutral.white,
          ':hover': {
            backgroundColor: COLORS_DARK.neutral.T8,
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          ':disabled': {
            color: COLORS_DARK.neutral[200],
            backgroundColor: 'transparent',
          },
        },
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        background: COLORS_DARK.neutral[800],
        boxShadow: `0px 4px 12px ${COLORS_LIGHT.neutral.T12}`,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3.75, 0),
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        '&.Mui-focused': {
          color: COLORS_DARK.primary[100],
        },
        '&.Mui-disabled': {
          color: COLORS_DARK.neutral[600],
        },
        '&.Mui-focused.Mui-error': {
          color: COLORS_LIGHT.warning[400],
        },
      },
      shrink: ({ theme }) => ({
        fontSize: theme.spacing(1.75),
        fontWeight: FontWeight.Medium,
      }),
      root: ({ theme }) => ({
        ...TYPOGRAPHY.b1Regular,
        color: COLORS_DARK.neutral[400],
        lineHeight: theme.spacing(3.25),
        fontSize: theme.spacing(2),
        '&.Mui-error': {
          color: COLORS_LIGHT.warning[400],
        },
        '&.Mui-disabled': {
          color: COLORS_DARK.neutral[600],
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: COLORS_DARK.neutral.white,
        ...TYPOGRAPHY.b1SemiBold,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: COLORS_DARK.neutral[200],
        ...TYPOGRAPHY.b1Regular,
      },
      label: {
        color: COLORS_DARK.neutral[200],
        ...TYPOGRAPHY.b2Regular,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: ({ theme }) => ({
        fontSize: theme.spacing(2),
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&[type="password"]': {
          fontFamily: 'Verdana',
          letterSpacing: theme.spacing(0.5),
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: COLORS_DARK.warning[100],
        color: COLORS_LIGHT.warning[100],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.warning[200],
        },
      },
      standardWarning: {
        backgroundColor: COLORS_DARK.alert[100],
        color: COLORS_LIGHT.alert[100],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.alert[200],
        },
      },
      standardInfo: {
        backgroundColor: COLORS_DARK.info[100],
        color: COLORS_LIGHT.info[100],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.info[200],
        },
      },
      standardSuccess: {
        backgroundColor: COLORS_DARK.success[100],
        color: COLORS_LIGHT.success[100],

        '& .MuiAlert-icon': {
          color: COLORS_LIGHT.success[200],
        },
      },

      filledError: {
        backgroundColor: COLORS_DARK.warning[400],
        color: COLORS_DARK.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_DARK.neutral.white,
        },
      },
      filledWarning: {
        backgroundColor: COLORS_DARK.alert[400],
        color: COLORS_DARK.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_DARK.neutral.white,
        },
      },
      filledInfo: {
        backgroundColor: COLORS_DARK.info[300],
        color: COLORS_DARK.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_DARK.neutral.white,
        },
      },
      filledSuccess: {
        backgroundColor: COLORS_DARK.success[300],
        color: COLORS_DARK.neutral.white,

        '& .MuiAlert-icon': {
          color: COLORS_DARK.neutral.white,
        },
      },
      message: {
        ...TYPOGRAPHY.b1Regular,
      },
    },
  },
};
