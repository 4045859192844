import { store } from 'app/store';
import { difference } from 'lodash';

export const hasAllRoles = (requiredRoles: string[] = [], userRoles?: string[]): boolean => {
  if (requiredRoles.length === 0) {
    return true;
  }

  return (
    difference(requiredRoles, (userRoles as string[]) ?? store.getState().session?.userProfile?.roles ?? []).length ===
    0
  );
};
