import { Theme } from '@mui/material';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  detailsContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
    paddingTop: theme.spacing(4),

    '& .MuiTypography-root': {
      lineHeight: theme.spacing(3),
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },

  formGroup: {},
  linkBlock: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  linkAction: {
    ...TYPOGRAPHY.b1Regular,
    textDecoration: 'none',
    '&[disabled]': {
      color: theme.palette.action.disabled,
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
