import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useFormWatchers = (forms: UseFormReturn<any>[], wait = 400): void => {
  useEffect(() => {
    const subscriptions = forms?.map((form) =>
      form.watch(debounce((value, { name }) => (name ? form.trigger(name) : null), wait))
    );
    return () => subscriptions.filter((filter) => filter).forEach((form) => form.unsubscribe());
  }, [forms, wait]);
};
