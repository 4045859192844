import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { MfaEntity } from 'modules/mfa/store/mfa/interfaces';
import { MfaState } from 'modules/mfa/store/mfa/interfaces/mfa-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: MfaEntity) => data.email,
});

export const initialState: MfaState = {
  entity: {
    email: '',
    mfaEnabled: false,
    confirmationCode: '',
  },
  empty: null,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
};
