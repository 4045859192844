import { i18n, LangNameSpace } from 'app/i18n';
import { WizardErrorField } from 'modules/cloud-services/enums';

export const MAP_WIZARD_FIELD_ERROR_MESSAGE = {
  [WizardErrorField.bandwidth]: i18n.t('cloudService.constants.MAP_WIZARD_FIELD_ERROR_MESSAGE.bandwidth', {
    ns: LangNameSpace.Modules,
  }),
  [WizardErrorField.vlanAttachmentNames]: i18n.t(
    'cloudService.constants.MAP_WIZARD_FIELD_ERROR_MESSAGE.vlanAttachmentNames',
    {
      ns: LangNameSpace.Modules,
    }
  ),
};
