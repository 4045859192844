import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRequestHandler } from 'app/services/api/interfaces';
import { store } from 'app/store';
import { AxiosError } from 'axios';

type DispatchUnwrap = ReturnType<ReturnType<typeof createAsyncThunk>>;

export const dispatchUnwrap = <Entity = void>(
  action: DispatchUnwrap,
  handlers: ApiRequestHandler<Entity> = { success: () => null, error: () => null },
  skipError = false
): Promise<Entity | void> => {
  return store
    .dispatch(action)
    .unwrap()
    .then((data) => {
      handlers.success?.(data as Entity);
      return data as Entity;
    })
    .catch((err: AxiosError) => {
      handlers.error?.(err);

      return skipError ? Promise.resolve() : Promise.reject(err);
    });
};
