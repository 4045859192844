import { SizeUnit } from 'app/enums';
import { i18n, LangNameSpace } from 'app/i18n';
import { AVATAR_RESTRICTIONS } from 'modules/profile/constants';
import { AvatarUploadFormFields } from 'modules/profile/interfaces';
import { mixed, object, ObjectSchema } from 'yup';

export const avatarUploadValidationSchema: ObjectSchema<AvatarUploadFormFields> = object().shape({
  photoData: mixed<File>()
    .optional()
    .test(
      i18n.t('profile.hooks.avatarUploadValidationSchema.fileType.name', { ns: LangNameSpace.Modules }),
      i18n.t('profile.hooks.avatarUploadValidationSchema.fileType.message', {
        ns: LangNameSpace.Modules,
        type: 'AVATAR_RESTRICTIONS.inputTypes',
      }),
      (value) => {
        if (value?.name != null) {
          return AVATAR_RESTRICTIONS.types.includes(value.type);
        }

        return true;
      }
    )
    .test(
      i18n.t('profile.hooks.avatarUploadValidationSchema.fileSize.name', { ns: LangNameSpace.Modules }),
      i18n.t('profile.hooks.avatarUploadValidationSchema.fileSize.message', {
        ns: LangNameSpace.Modules,
        unit: SizeUnit.MB,
      }),
      (value) => {
        if (value?.name != null) {
          return value.size <= AVATAR_RESTRICTIONS.maxSize;
        }

        return true;
      }
    ),
});
