import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { TicketEntity } from 'app/interfaces/entity/ticket';
import { TicketsState } from 'modules/tickets/store/tickets/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: TicketEntity) => data.identifier,
});

export const initialState = entityAdapter.getInitialState({
  total: 0,
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
}) as unknown as TicketsState;
