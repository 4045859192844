import dayjs from 'dayjs';
import { Map } from 'immutable';
import mapValues from 'lodash/mapValues';
import { DataGridFilterType } from 'shared/enums';
import {
  DataGridColumnFilterData,
  DataGridDateFilterReaderData,
  DataGridDateFilterWriterData,
  ProviderRegionsFilterData,
} from 'shared/interfaces';

const dateFormatter = (filterValue: DataGridDateFilterWriterData): DataGridDateFilterReaderData => {
  return {
    from: filterValue.from ? dayjs.utc(filterValue.from) : null,
    to: filterValue.to ? dayjs.utc(filterValue.to) : null,
  };
};

const providerRegionFormatter = (filterValue: object): ProviderRegionsFilterData => {
  return Map(mapValues(filterValue, (provider) => Map(provider))) as ProviderRegionsFilterData;
};

export const readerFormatter = (filter: DataGridColumnFilterData): DataGridColumnFilterData => {
  switch (filter.type) {
    case DataGridFilterType.Date:
      return { ...filter, value: dateFormatter(filter.value) };
    case DataGridFilterType.List:
      return { ...filter, value: Map(filter.value) };
    case DataGridFilterType.Region:
      return { ...filter, value: providerRegionFormatter(filter.value) };
    case DataGridFilterType.String:
      return { ...filter, value: String(filter.value) };
    case DataGridFilterType.Number:
      return { ...filter, value: Number(filter.value) };
    default:
      return { ...filter, value: filter.value };
  }
};
