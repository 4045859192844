import { SankeyDetailPanelPalette } from '@types';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';

export const SANKEY_DETAIL_PANEL_LIGHT: SankeyDetailPanelPalette = {
  background: {
    default: COLORS_LIGHT.neutral.white,
    toast: COLORS_LIGHT.info[100],
    tabDivider: COLORS_LIGHT.neutral.black,
    requestDivider: COLORS_LIGHT.neutral[300],
  },
  border: {
    default: COLORS_LIGHT.neutral.T16,
    tabList: COLORS_LIGHT.neutral.T16,
    button: COLORS_LIGHT.neutral[300],
    request: COLORS_LIGHT.neutral.T16,
  },
  text: {
    title: COLORS_LIGHT.neutral.black,
    subTitle: COLORS_LIGHT.neutral[400],
    toast: COLORS_LIGHT.info[400],
    tab: { default: COLORS_LIGHT.neutral.black, disabled: COLORS_LIGHT.neutral[400] },
    property: {
      label: {
        active: COLORS_LIGHT.neutral[400],
        inActive: COLORS_LIGHT.neutral[300],
      },
      value: {
        active: COLORS_LIGHT.neutral.black,
        inActive: COLORS_LIGHT.neutral[400],
      },
      divider: COLORS_LIGHT.neutral[200],
    },
    button: COLORS_LIGHT.neutral[500],
    request: {
      title: COLORS_LIGHT.neutral.black,
      link: COLORS_LIGHT.primary[300],
      time: COLORS_LIGHT.neutral[400],
    },
  },
};

export const SANKEY_DETAIL_PANEL_DARK: SankeyDetailPanelPalette = {
  background: {
    default: COLORS_DARK.neutral[800],
    toast: COLORS_DARK.info[100],
    tabDivider: COLORS_DARK.neutral.T48,
    requestDivider: COLORS_DARK.neutral[400],
  },
  border: {
    default: COLORS_DARK.neutral.T16,
    tabList: COLORS_DARK.neutral.T16,
    button: COLORS_DARK.neutral[300],
    request: COLORS_DARK.neutral.T16,
  },
  text: {
    title: COLORS_DARK.neutral.white,
    subTitle: COLORS_DARK.neutral[400],
    toast: COLORS_LIGHT.info[100],
    tab: { default: COLORS_DARK.neutral.white, disabled: COLORS_DARK.neutral[500] },

    property: {
      label: {
        active: COLORS_LIGHT.neutral[400],
        inActive: COLORS_LIGHT.neutral[300],
      },
      value: {
        active: COLORS_DARK.neutral.white,
        inActive: COLORS_LIGHT.neutral[500],
      },
      divider: COLORS_LIGHT.neutral[600],
    },
    button: COLORS_DARK.neutral.white,
    request: {
      title: COLORS_DARK.neutral[200],
      link: COLORS_DARK.primary[300],
      time: COLORS_DARK.neutral[400],
    },
  },
};
