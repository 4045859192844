import { i18n, LangNameSpace } from 'app/i18n';
import { ModalErrorMessageKey } from 'modules/admin/user-management/enums';

export const MAP_MODAL_FIELD_ERROR_MESSAGE = {
  [ModalErrorMessageKey.usernameUnavailable]: i18n.t(
    'admin.user.constants.MAP_MODAL_FIELD_ERROR_MESSAGE.username.unique',
    {
      ns: LangNameSpace.Modules,
    }
  ),
  [ModalErrorMessageKey.username]: undefined,
};
