import { AxiosHeaders } from 'axios';
import { InternalCacheRequestConfig } from 'axios-cache-interceptor';
import { isEmpty } from 'lodash';

import { ApiResource, ApiVersion } from '../../enums';

export const organizationInterceptor = async (
  config: InternalCacheRequestConfig
): Promise<InternalCacheRequestConfig> => {
  const { store } = await import('app/store');
  let { organizationId } = store.getState().session;

  if (config.url && [`/${ApiVersion.V1}/${ApiResource.Organizations}`].includes(config.url)) {
    return config;
  }

  if (!isEmpty(config.headers?.['X-Organization-Id'])) {
    organizationId = config.headers['X-Organization-Id'];
  }

  const headers = new AxiosHeaders({
    ...config.headers,
    ...(organizationId ? { 'X-Organization-Id': organizationId } : {}),
  });

  return {
    ...config,
    headers,
  };
};
