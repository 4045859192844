import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& .MuiAutocomplete-tag': { margin: theme.spacing(0.5, 0.5, 0, 0.5), lineHeight: theme.spacing(3) },
  },
  listItem: {
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
  },
}));
