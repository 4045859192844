import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ size: number }>()((_theme, { size }) => ({
  relative: {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    height: size,
    width: size,
  },
  fullPage: {
    top: `calc(50% - ${size / 2}px)`,
    left: `calc(50% - ${size / 2}px)`,
    position: 'fixed',
  },
}));
