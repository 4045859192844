import { EntityStoreName } from 'app/enums/store';
import { DashboardCrossCloudEntity, DashboardEntity, DashboardGlobalEntity } from 'app/interfaces/entity/dashboard';
import { AppState } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';
import { DashboardGlobalState } from 'modules/monitoring/store/interfaces';
import { useMemo } from 'react';
import { createSelector } from 'reselect';

import { entityAdapter } from './adapter';

const selectClientAll = entityAdapter.getSelectors<AppState>(
  (state) => state[EntityStoreName.ClientDashboards]
).selectAll;

const globalDashboardsState = (state: AppState): DashboardGlobalState => state[EntityStoreName.GlobalDashboards];

const getGlobalDashboards = createSelector(globalDashboardsState, (state) => state.entity);
const selectOneClientFactory = (identifier?: string): ((state: AppState) => DashboardEntity | undefined) =>
  createSelector(selectClientAll, sessionSelector.monitoringDashboardId, (dashboards, dashboardsId) =>
    dashboards.find((dashboard) => dashboard?.identifier === (identifier ?? dashboardsId))
  );

const selectOneGlobalFactory = (
  identifier?: string,
  locationIdentifier?: string
): ((state: AppState) => DashboardGlobalEntity | undefined) =>
  createSelector(
    getGlobalDashboards,
    sessionSelector.monitoringPerformanceDashboardLocationId,
    sessionSelector.monitoringPerformanceDashboardId,
    (state, dashboardLocationId, dashboardsId) => {
      const locationId = locationIdentifier ?? dashboardLocationId;
      return locationId
        ? state?.[locationId]?.find((dashboards) => dashboards?.identifier === (identifier ?? dashboardsId))
        : undefined;
    }
  );

const selectCrossCloudAllGlobalFactory = (
  identifier?: string,
  locationIdentifier?: string
): ((state: AppState) => DashboardCrossCloudEntity[] | undefined) =>
  createSelector(selectOneGlobalFactory(identifier, locationIdentifier), (dashboard) => {
    return (dashboard && dashboard?.crossClouds) || undefined;
  });

const selectCrossCloudOneGlobalFactory = (
  identifier?: string,
  locationIdentifier?: string,
  crossCloudIdentifier?: string
): ((state: AppState) => DashboardCrossCloudEntity | undefined) =>
  createSelector(
    selectCrossCloudAllGlobalFactory(identifier, locationIdentifier),
    sessionSelector.monitoringPerformanceDashboardCrossCloudId,
    (dashboards, crossCloudDashboardId) => {
      return dashboards
        ? dashboards?.find(
            (crossCloudDashboards) =>
              crossCloudDashboards?.identifier === (crossCloudIdentifier ?? crossCloudDashboardId)
          )
        : undefined;
    }
  );

const getAllGlobalDashboardsByLocation = createSelector(
  getGlobalDashboards,
  sessionSelector.monitoringPerformanceDashboardLocationId,
  (state, dashboardLocationId): DashboardGlobalEntity[] | undefined =>
    dashboardLocationId ? state?.[dashboardLocationId] : undefined
);

const selectOneGlobalLocationFactory = (
  identifier?: string
): ((state: AppState) => { identifier: string; name: string } | undefined) =>
  createSelector(
    getGlobalDashboards,
    sessionSelector.monitoringPerformanceDashboardLocationId,
    (locations, dashboardLocationId) => {
      let locationName;
      if (identifier && locations.hasOwnProperty(identifier)) {
        locationName = identifier;
      } else if (dashboardLocationId && locations.hasOwnProperty(dashboardLocationId)) {
        locationName = dashboardLocationId;
      }
      return locationName ? { identifier: locationName, name: locationName } : undefined;
    }
  );

const selectGlobalLocations = createSelector(getGlobalDashboards, (dashboards) => {
  return Object.keys(dashboards);
});

const isEmptyClient = createSelector(
  (state: AppState) => state[EntityStoreName.ClientDashboards],
  (dashboardList) => dashboardList.ids !== null && dashboardList.ids.length === 0
);

const isEmptyGlobal = createSelector(globalDashboardsState, (state) => state.empty);

export const dashboardsClientSelector = {
  all: selectClientAll,
  isEmpty: isEmptyClient,
};

export const dashboardsGlobalSelector = {
  allLocations: selectGlobalLocations,
  allByLocation: getAllGlobalDashboardsByLocation,
  isEmpty: isEmptyGlobal,
};

export const dashboardsSelectorFactory = {
  useSelectOne: (dashboardId?: string) => useMemo(() => selectOneClientFactory(dashboardId), [dashboardId]),
};

export const dashboardsPerformanceSelectorFactory = {
  useSelectOne: (dashboardId?: string, locationId?: string) =>
    useMemo(() => selectOneGlobalFactory(dashboardId, locationId), [dashboardId, locationId]),
  useSelectCrossCloudOne: ({
    dashboardId,
    locationId,
    crossCloudDashboardId,
  }: {
    dashboardId?: string;
    locationId?: string;
    crossCloudDashboardId?: string;
  } = {}) =>
    useMemo(
      () => selectCrossCloudOneGlobalFactory(dashboardId, locationId, crossCloudDashboardId),
      [dashboardId, locationId, crossCloudDashboardId]
    ),
  useSelectCrossCloudAll: (dashboardId?: string, locationId?: string) =>
    useMemo(() => selectCrossCloudAllGlobalFactory(dashboardId, locationId), [dashboardId, locationId]),
  useSelectOneLocation: (locationId?: string) =>
    useMemo(() => selectOneGlobalLocationFactory(locationId), [locationId]),
};
