import { i18n, LangNameSpace } from 'app/i18n';
import { sortBy } from 'lodash';

import { ContactPreference } from '../enums';

export const CONTACT_PREFERENCES = {
  [ContactPreference.Primary]: {
    name: i18n.t('admin.user.constants.contactPreferences.primary', { ns: LangNameSpace.Modules }),
  },

  [ContactPreference.Technical]: {
    name: i18n.t('admin.user.constants.contactPreferences.technical', { ns: LangNameSpace.Modules }),
  },
};

export const CONTACT_OPTIONS = sortBy(Object.entries(CONTACT_PREFERENCES), ([, val]) => val.name).map(
  ([key]) => key
) as ContactPreference[];
