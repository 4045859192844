import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    margin: theme.spacing(0, 2.25, 0, 0),
  },
  userInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  userNameLabel: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    lineHeight: theme.spacing(3.75),
    marginBottom: theme.spacing(0.375),
    fontWeight: FontWeight.SemiBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  email: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(3),
    fontWeight: FontWeight.Regular,
    opacity: 0.8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
}));
