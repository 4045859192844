import React, { createContext, FC, RefObject } from 'react';

interface PageContextProviderProps {
  pageRef: RefObject<HTMLElement> | undefined;
}

export const PageContext = createContext<PageContextProviderProps>({
  pageRef: undefined,
});

export const PageContextProvider: FC<React.PropsWithChildren<PageContextProviderProps>> = ({ children, pageRef }) => {
  return <PageContext.Provider value={{ pageRef }}>{children}</PageContext.Provider>;
};
