import { WidgetLineEntity } from 'app/interfaces/entity/dashboard';
import { concat, isNumber, map, sortBy } from 'lodash';

const getMinMax = (array: number[]) => {
  const arr = sortBy(array);
  return [arr[0], arr[arr.length - 1]];
};

export const getMinMaxValue = (items: number[]) => {
  if (!items || !Array.isArray(items) || !items?.length) {
    return [0, 1];
  }
  return getMinMax(items).filter(isNumber);
};

export const getMinMaxLineWidgetValue = (items: WidgetLineEntity['items']) => {
  return getMinMaxValue(concat(...map(items, 'data')));
};
