import { generateKey } from 'app/services/api/cache';
import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import env from 'config/env.json';

import { API_CACHE_TTL } from './constants';
import { requestInterceptors, responseInterceptors } from './interceptors';

export const apiService = setupCache(
  axios.create({
    baseURL: env.REACT_APP_API_URL,
  }),
  {
    cacheTakeover: false,
    staleIfError: true,
    ttl: API_CACHE_TTL,
    generateKey,
  }
);

requestInterceptors.forEach((requestInterceptor) => {
  apiService.interceptors.request.use(requestInterceptor);
});

responseInterceptors.forEach(({ onFulfilled, onRejected }) => {
  apiService.interceptors.response.use(onFulfilled, onRejected);
});
