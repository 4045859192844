import { ActionReducerMapBuilder, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { ApiCredentialsEntity } from 'app/interfaces/entity/api-credentials/api-credentials.entity';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { ErrorResponse } from 'app/services/api/interfaces';
import { store } from 'app/store';
import { entityStoreStatus } from 'app/utils/store';
import { AxiosError } from 'axios/index';

import { ApiCredentialgDeleteRequest, ApiCredentialsCreateRequest } from '../interfaces';
import { ApiCredentialsState } from '../interfaces/api-credentials-state';
import {
  ApiCredentialCreateDto,
  ApiCredentialsDto,
  createRequestToDtoMapper,
  dtoToEntityMapper,
} from '../interfaces/dto';
import { entityAdapter } from './adapter';

export const getKeysList = createAsyncThunk(
  `${EntityStoreName.ApiCredentials}/getList`,
  (_, { signal }): Promise<ApiCredentialsEntity[]> => {
    const organizationId = store.getState().session.organizationId;

    return apiRequestCall<ApiCredentialsDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Keys}`,
        signal,
      },
    }).then((data) => data.map((item) => dtoToEntityMapper(item)));
  }
);

export const createApiCredential = createAsyncThunk(
  `${EntityStoreName.ApiCredentials}`,
  async ({ successRequestMessage, ...payload }: ApiCredentialsCreateRequest): Promise<ApiCredentialsEntity> => {
    const organizationId = store.getState().session.organizationId;

    return apiRequestCall<ApiCredentialsDto, ApiCredentialCreateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Keys}`,
        data: createRequestToDtoMapper(payload),
        skipShowErrorInSnackbar: true,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Keys]],
      successRequestMessage,
    }).then((data) => data);
  },
  {
    serializeError: (e) => {
      return (e as AxiosError<ErrorResponse>)?.response?.data || (e as SerializedError);
    },
  }
);
export const deleteCredential = createAsyncThunk(
  `${EntityStoreName.ApiCredentials}/delete`,
  async ({ clientId, successRequestMessage }: ApiCredentialgDeleteRequest): Promise<string> => {
    const organizationId = store.getState().session.organizationId;

    return apiRequestCall<string>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        urlPrefix: `${organizationId}/${ApiResource.Keys}/${clientId}`,
      },
      successRequestMessage,
      invalidationKeys: [[ApiVersion.V1, ApiResource.Keys]],
    }).then(() => clientId);
  }
);

export const clearAllReducer = (state: ApiCredentialsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<ApiCredentialsState>): void => {
  builder
    .addCase(getKeysList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getKeysList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getKeysList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createApiCredential.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createApiCredential.fulfilled, (state, action) => {
      entityAdapter.addOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createApiCredential.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(deleteCredential.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteCredential.fulfilled, (state, action) => {
      entityAdapter.removeOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteCredential.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    });
};
