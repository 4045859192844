import { createEntityAdapter } from '@reduxjs/toolkit';
import { DataGridColumnFilterData } from 'shared/interfaces';
import { dataGridFilterGenerators } from 'shared/utils/data-grid/generators';

import { DataGridFiltersState } from './interfaces/data-grid-filters-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: DataGridColumnFilterData) =>
    dataGridFilterGenerators.generateFilterIdentifier(data.gridName, data.columnName),
});

export const initialState: DataGridFiltersState = entityAdapter.getInitialState();
