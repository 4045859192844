import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { FixBandwidthEntity } from 'app/interfaces/entity/fix-bandwidth.entity';
import { entityStoreStatus } from 'app/utils/store';
import { fixBandwidthStateMock } from 'modules/cloud-services/store/fix-bandwidth/fix-bandwidth-state.mock';
import { FixBandwidthState } from 'modules/cloud-services/store/fix-bandwidth/interfaces';

export const getFixBandwidth = createAsyncThunk<FixBandwidthEntity>(
  `${EntityStoreName.FixBandwidth}/getFixBandwidth`,
  async (): Promise<FixBandwidthEntity> => {
    return Promise.resolve(fixBandwidthStateMock.entity);
  }
);

export const registerReducers = (builder: ActionReducerMapBuilder<FixBandwidthState>): void => {
  builder
    .addCase(getFixBandwidth.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getFixBandwidth.fulfilled, (state, action) => {
      state.entity = action.payload;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getFixBandwidth.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
