export enum EntityStoreName {
  ApiCredentials = 'api-credentials',
  Ccvs = 'ccvs',
  CloudConnections = 'cloud-connections',
  ChangeRequests = 'change-requests',
  ClientDashboards = 'client-dashboards',
  GlobalDashboards = 'global-dashboards',
  FixBandwidth = 'fix-bandwidth',
  Locations = 'locations',
  LocationAvailability = 'location-availability',
  Organizations = 'organizations',
  Tickets = 'tickets',
  TicketComments = 'ticket-comments',
  TicketAttachments = 'ticket-attachments',
  Users = 'users',
  Sso = 'sso',
  Mfa = 'mfa',
  SsoGroupMappings = 'sso-group-mappings',
  Roles = 'roles',
}
