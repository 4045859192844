import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import {
  SsoGroupMappingEntity,
  SsoGroupMappingsState,
} from 'modules/admin/sso-management/store/sso-group-mappings/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: SsoGroupMappingEntity) => data.identifier,
});

export const initialState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
}) as unknown as SsoGroupMappingsState;
