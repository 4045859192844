import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ConfirmationModalName } from 'app/enums';
import { i18n, LangNameSpace } from 'app/i18n';
import { Identifier } from 'app/interfaces';
import { modalSelector } from 'app/store/modal/selectors';
import { modalReducers } from 'app/store/modal/slice';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderButton } from 'shared/components/LoaderButton';
import { ConfirmationModalData } from 'shared/interfaces';

import { useStyles } from './ConfirmationModal.styles';

export interface ConfirmationModalProps<Payload> {
  name: ConfirmationModalName;
  title?: ((data: Payload) => string) | string;
  text?: ((data: Payload) => string | ReactNode) | string;
  successBtnTitle?: string;
  cancelBtnTitle?: string;
  onSubmit?: (identifier: Identifier) => Promise<void>;
  onSuccess?: () => void;
  disableSubmitBtn?: () => boolean;
  onClose?: () => void;
  skipBottomContentPadding?: boolean;
}

export const ConfirmationModal = <Payload extends ConfirmationModalData>({
  name,
  title,
  text,
  successBtnTitle = i18n.t('components.ConfirmationModal.successBtnTitle', { ns: LangNameSpace.Shared }),
  onSubmit = () => Promise.resolve(),
  onSuccess = () => null,
  cancelBtnTitle = i18n.t('components.ConfirmationModal.cancelBtnTitle', { ns: LangNameSpace.Shared }),
  children,
  onClose,
  disableSubmitBtn = () => false,
  skipBottomContentPadding = false,
  ...rest
}: React.PropsWithChildren<ConfirmationModalProps<Payload>>) => {
  const { classes, cx } = useStyles();
  const opened = useSelector(modalSelector.isConfirmationOpen(name));

  const dispatch = useDispatch();
  const payload = useSelector(modalSelector.confirmationData) as unknown as Payload;
  const [loading, setLoading] = useState<boolean>(false);

  const submitHandler = useCallback(() => {
    setLoading(true);
    onSubmit(payload?.identifier)
      .then(onSuccess)
      .finally(() => setLoading(false))
      .then(() => dispatch(modalReducers.closeConfirmationModal({ name })));
  }, [payload, dispatch, onSubmit, onSuccess, name]);

  const handleOnClose = useCallback(() => {
    onClose?.();
    dispatch(modalReducers.closeConfirmationModal({ name }));
  }, [dispatch, name, onClose]);

  const titleValue = useMemo(
    () => (opened ? (typeof title === 'function' ? title(payload) : title) : ''),
    [opened, payload, title]
  );
  const textValue = useMemo(
    () => (opened ? (typeof text === 'function' ? text(payload) : text) : ''),
    [opened, payload, text]
  ) as string;

  return (
    <Dialog
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          handleOnClose();
        }
      }}
      open={opened}
      maxWidth="xs"
      {...rest}
    >
      <DialogTitle className={classes.title}>
        <Typography component="div" typography="b1SemiBold" data-testid="modal-title">
          {titleValue}
        </Typography>
        <IconButton
          size="small"
          onClick={handleOnClose}
          className={classes.closeIconBtn}
          data-testid="closeModalButton"
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }} dividers={false}>
        <Box className={cx(classes.body, { [classes.skipPaddingBottom]: skipBottomContentPadding })}>
          {textValue ? (
            <Typography
              variant="body2"
              className={classes.textContainer}
              dangerouslySetInnerHTML={{ __html: textValue }}
            />
          ) : null}
          {children}
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button size="medium" color="primary" variant="outlined" onClick={handleOnClose}>
          {cancelBtnTitle}
        </Button>
        <LoaderButton
          className={classes.submitButton}
          isLoading={loading}
          variant="contained"
          color="primary"
          onClick={submitHandler}
          disabled={disableSubmitBtn() || loading}
          data-testid="successBtn"
        >
          {successBtnTitle}
        </LoaderButton>
      </DialogActions>
    </Dialog>
  );
};
