import { ApiCredentialsEntity } from 'app/interfaces/entity/api-credentials/api-credentials.entity';

import { ApiCredentialsCreateRequest } from '../api-credentails-create-request';
import { ApiCredentialsDto } from './api-credentials.dto';
import { ApiCredentialCreateDto } from './api-credentials-create.dto';

export const dtoToEntityMapper = (data: ApiCredentialsDto): ApiCredentialsEntity => {
  return {
    createdAt: data.createdAt,
    name: data.name,
    clientId: data.clientId,
    createdBy: data.createdBy,
    roles: data.roles,
  };
};

export const createRequestToDtoMapper = (data: ApiCredentialsCreateRequest): ApiCredentialCreateDto => {
  return {
    ...data,
    name: data.name,
    roles: data.roles,
  };
};

export const clientSecretEntityMapper = (data: ApiCredentialsDto): ApiCredentialsEntity => {
  return { ...data };
};
