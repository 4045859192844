import { TicketAttachmentEntity } from 'app/interfaces/entity/ticket';

import { TicketAttachmentDto } from './interfaces/dto';

export const dtoToEntityMapper = (data: TicketAttachmentDto): TicketAttachmentEntity => {
  return data;
};

export const requestToDtoMapper = (files: Array<File>): FormData => {
  const res = new FormData();

  files.forEach((file) => res.append(file?.name, file));

  return res;
};
