import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';
import { initialState } from 'modules/mfa/store/mfa/adapter';
import { clearReducer, registerReducers } from 'modules/mfa/store/mfa/repository';

export const mfaState = createSlice({
  name: EntityStoreName.Mfa,
  initialState,
  reducers: {
    clear: clearReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const mfaReducers = { ...mfaState.actions };
