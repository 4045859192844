import { BandwidthType, ConnectionSubType, Provider, ProviderType } from 'app/enums';
import { ConnectionType } from 'app/enums/connection-type';
import { i18n, LangNameSpace } from 'app/i18n';
import { CloudConnectionEntity } from 'app/interfaces/entity/cloud-connection';
import { ProviderConfig } from 'app/interfaces/provider-config';
import { getInterRegionTargetLocation } from 'modules/cloud-services/utils';

export const GENERAL_BANDWIDTH_OPTIONS: number[] = [1, 2, 5, 10, 40, 100];
export const AZURE_BANDWIDTH_OPTIONS: number[] = [1, 2, 4, 10, 40];
export const GCP_BANDWIDTH_OPTIONS: number[] = [1, 2, 4, 10, 20, 40];
export const ORACLE_BANDWIDTH_OPTIONS: number[] = [1, 2, 3, 5, 10, 20, 40];

export const INTER_REGION_BANDWIDTH_OPTIONS: number[] = [1, 2, 5, 10, 20, 40];
export const PRIVATE_INTRA_FACILITY_BANDWIDTH_OPTIONS: number[] = [1, 10, 100];
export const PRIVATE_PROVIDER_BACKBONE_BANDWIDTH_OPTIONS: number[] = [1, 2, 5, 10, 20, 40];
export const PRIVATE_SDN_BANDWIDTH_OPTIONS: number[] = [1, 2, 5, 10, 40];
export const PRIVATE_TRADITIONAL_TELCO_BANDWIDTH_OPTIONS: number[] = [1, 10, 100];

export const BANDWIDTH_LABEL = i18n.t('constants.PROVIDER_CONFIG.bandwidth', { ns: LangNameSpace.App });

const SubTypeConnectionTypeToProviderMapping: Partial<Record<ConnectionSubType | ConnectionType, Provider>> = {
  [ConnectionSubType.AwsDirectConnect]: Provider.Aws,
  [ConnectionSubType.AzureExpressRouteCircuit]: Provider.Azure,
  [ConnectionSubType.GcpPartnerInterconnect]: Provider.Gcp,
  [ConnectionSubType.OciFastConnect]: Provider.Oci,
  [ConnectionType.Internet]: Provider.Internet,
  [ConnectionType.InterRegion]: Provider.InterRegion,
  [ConnectionType.Private]: Provider.Private,
  [ConnectionSubType.IntrafacilityCrossConnect]: Provider.Private,
  [ConnectionSubType.FacilityBackboneConnection]: Provider.Private,
  [ConnectionSubType.SdnProviderConnection]: Provider.Private,
  [ConnectionSubType.TraditionalCircuit]: Provider.Private,
};
export const getProviderV2 = (type: ConnectionSubType | ConnectionType): Provider => {
  return SubTypeConnectionTypeToProviderMapping[type] as Provider;
};

export const PROVIDER_CONFIG: Record<Provider | string, ProviderConfig> = {
  [Provider.Aws]: {
    type: ProviderType.Cloud,
    connectionType: ConnectionType.Cloud,
    connectionSubType: ConnectionSubType.AwsDirectConnect,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.cloud', { ns: LangNameSpace.App }),
    displayName: 'Amazon Web Services',
    bandwidthOption: {
      type: BandwidthType.Integer,
      min: 1,
      max: 40,
    },
    regions: {
      'us-east-2': 'US East (Ohio)',
      'us-east-1': 'US East (N. Virginia)',
      'us-east-1-regional': 'US East (Virginia) regional',
      'us-west-1': 'US West (N. California)',
      'us-west-2': 'US West (Oregon)',
      'ap-east-1': 'Asia Pacific (Hong Kong)',
      'ap-south-1': 'Asia Pacific (Mumbai)',
      'ap-northeast-3': 'Asia Pacific (Osaka-Local)',
      'ap-northeast-2': 'Asia Pacific (Seoul)',
      'ap-southeast-1': 'Asia Pacific (Singapore)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'ca-central-1': 'Canada (Central)',
      'cn-north-1': 'China (Beijing)',
      'cn-northwest-1': 'China (Ningxia)',
      'eu-central-1': 'Europe (Frankfurt)',
      'eu-south-1': 'Europe (Milan)',
      'eu-west-1': 'Europe (Ireland)',
      'eu-west-2': 'Europe (London)',
      'eu-west-3': 'Europe (Paris)',
      'eu-north-1': 'Europe (Stockholm)',
      'me-south-1': 'Middle East (Bahrain)',
      'sa-east-1': 'South America (São Paulo)',
      'us-gov-east-1': 'AWS GovCloud (US-East)',
      'us-gov-west-1': 'AWS GovCloud (US)',
      'af-south-1': 'Africa (Cape Town)',
    },
  },
  [Provider.Azure]: {
    type: ProviderType.Cloud,
    connectionType: ConnectionType.Cloud,
    connectionSubType: ConnectionSubType.AzureExpressRouteCircuit,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.cloud', { ns: LangNameSpace.App }),
    displayName: 'Azure',
    bandwidthOption: {
      type: BandwidthType.Radio,
      options: AZURE_BANDWIDTH_OPTIONS,
    },
    regions: {
      centralus: 'Central US',
      eastus2: 'East US 2',
      eastus: 'East US',
      northcentralus: 'North Central US',
      southcentralus: 'South Central US',
      westus2: 'West US 2',
      westcentralus: 'West Central US',
      westus: 'West US',
      canadacentral: 'Canada Central',
      canadaeast: 'Canada East',
      brazilsouth: 'Brazil South',
      brazilsoutheast: 'Brazil Southeast',
      northeurope: 'North Europe',
      westeurope: 'West Europe',
      francecentral: 'France Central',
      francesouth: 'France South',
      uksouth: 'UK South',
      ukwest: 'UK West',
      germanycentral: 'Germany Central',
      germanynortheast: 'Germany North East',
      germanywestcentral: 'Germany West Central',
      germanynorth: 'Germany North',
      switzerlandnorth: 'Switzerland North',
      switzerlandwest: 'Switzerland West',
      norwaywest: 'Norway West',
      norwayeast: 'Norway East',
      eastasia: 'East Asia',
      southeastasia: 'South East Asia',
      australiacentral: 'Australia Central',
      australiacentral2: 'Australia Central 2',
      australiaeast: 'Australia East',
      australiasoutheast: 'Australia Southeast',
      chinaeast: 'China East',
      chinaeast2: 'China East 2',
      chinanorth: 'China North',
      chinanorth2: 'China North 2',
      centralindia: 'Central India',
      southindia: 'South India',
      westindia: 'West India',
      japaneast: 'Japan East',
      japanwest: 'Japan West',
      koreacentral: 'Korea Central',
      koreasouth: 'Korea South',
      southafricanorth: 'South Africa North',
      southafricawest: 'South Africa West',
      uaecentral: 'UAE Central',
      uaenorth: 'UAE North',
      usdodcentral: 'US DoD Central',
      usdodeast: 'US DoD East',
      usgovarizona: 'US Gov Arizona',
      usgoviowa: 'US Gov Iowa',
      usgovtexas: 'US Gov Texas',
      usgovvirginia: 'US Gov Virginia',
    },
  },
  [Provider.Gcp]: {
    type: ProviderType.Cloud,
    connectionType: ConnectionType.Cloud,
    connectionSubType: ConnectionSubType.GcpPartnerInterconnect,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.cloud', { ns: LangNameSpace.App }),
    displayName: 'Google Cloud',
    bandwidthOption: {
      type: BandwidthType.Radio,
      options: GCP_BANDWIDTH_OPTIONS,
    },
    regions: {
      'asia-east1': 'Taiwan, APAC',
      'asia-east2': 'Hong Kong, APAC',
      'asia-northeast1': 'Tokyo, Japan, APAC',
      'asia-northeast2': 'Osaka, Japan, APAC',
      'asia-northeast3': 'Seoul, South Korea, APAC',
      'asia-south1': 'Mumbai, India APAC',
      'asia-southeast1': 'Singapore, APAC',
      'asia-southeast2': 'Jakarta, Indonesia, APAC',
      'australia-southeast1': 'Sydney, Australia, APAC',
      'europe-north1': 'Hamina, Finland, Europe',
      'europe-west1': 'St. Ghislain, Belgium, Europe',
      'europe-west2': 'London, England, Europe',
      'europe-west3': 'Frankfurt, Germany Europe',
      'europe-west4': 'Eemshaven, Netherlands, Europe',
      'europe-west6': 'Zurich, Switzerland, Europe',
      'southamerica-east1': 'Sao Paulo, Brazil, South America',
      'northamerica-northeast1': 'Montréal, Québec, North America',
      'us-central1': 'Iowa, North America',
      'us-east1': 'South Carolina, North America',
      'us-east4': 'Virginia, North America',
      'us-west1': 'Oregon, North America',
      'us-west2': 'California, North America',
      'us-west3': 'Utah, North America',
      'us-west4': 'Nevada, North America',
    },
  },
  [Provider.Oci]: {
    type: ProviderType.Cloud,
    connectionType: ConnectionType.Cloud,
    connectionSubType: ConnectionSubType.OciFastConnect,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.cloud', { ns: LangNameSpace.App }),
    displayName: 'Oracle',
    bandwidthOption: {
      type: BandwidthType.Radio,
      options: ORACLE_BANDWIDTH_OPTIONS,
    },
    regions: {
      'ap-sydney-1': 'Australia East (Sydney)',
      'ap-melbourne-1': 'Australia Southeast (Melbourne)',
      'sa-saopaulo-1': 'Brazil East (Sao Paulo)',
      'ca-montreal-1': 'Canada Southeast (Montreal)',
      'ca-toronto-1': 'Canada Southeast (Toronto)',
      'sa-santiago-1': 'Chile (Santiago)',
      'eu-frankfurt-1': 'Germany Central (Frankfurt)',
      'ap-hyderabad-1': 'India South (Hyderabad)',
      'ap-mumbai-1': 'India West (Mumbai)',
      'ap-osaka-1': 'Japan Central (Osaka)',
      'ap-tokyo-1': 'Japan East (Tokyo)',
      'eu-amsterdam-1': 'Netherlands Northwest (Amsterdam)',
      'me-jeddah-1': 'Saudi Arabia West (Jeddah)',
      'ap-seoul-1': 'South Korea Central (Seoul)',
      'ap-chuncheon-1': 'South Korea North (Chuncheon)',
      'eu-zurich-1': 'Switzerland North (Zurich)',
      'me-dubai-1': 'UAE East (Dubai)',
      'uk-london-1': 'UK South (London)',
      'uk-cardiff-1': 'UK West (Newport)',
      'us-ashburn-1': 'US East (Ashburn)',
      'us-phoenix-1': 'US West (Phoenix)',
      'us-sanjose-1': 'US West (San Jose)',
    },
  },
  [Provider.Internet]: {
    type: ProviderType.Fix,
    connectionType: ConnectionType.Internet,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.internet', { ns: LangNameSpace.App }),
    displayName: 'Internet',
    bandwidthOption: {
      type: BandwidthType.Integer,
      min: 1,
      max: 100,
      skipCheckAvailability: true,
    },
    regions: {},
    options: {
      labelInWizard: 'IPSEC / VPN Connection',
      step2subTitle: i18n.t('constants.PROVIDER_CONFIG.step2subTitle.internet', { ns: LangNameSpace.App }),
      step3subTitle: i18n.t('constants.PROVIDER_CONFIG.step3subTitle.internet', { ns: LangNameSpace.App }),
      disableInWizard: false,
    },
  },
  [Provider.InterRegion]: {
    type: ProviderType.Fix,
    connectionType: ConnectionType.InterRegion,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.interRegion', { ns: LangNameSpace.App }),
    displayName: 'Inter Region',
    displayNameFn: (providerData, index) => {
      const factionLocations = (providerData as CloudConnectionEntity)?.factionLocations;
      const targetLocation = getInterRegionTargetLocation(factionLocations, providerData?.currentLocation?.identifier);

      let cityName = undefined;
      if (targetLocation && providerData?.allLocations) {
        const targetLocationData = providerData?.allLocations.filter((loc) => loc.identifier === targetLocation);
        cityName = targetLocationData && targetLocationData[0]?.city;
      }
      return `${cityName || 'Inter Region'} ${index}`;
    },
    bandwidthOption: {
      type: BandwidthType.Radio,
      options: INTER_REGION_BANDWIDTH_OPTIONS,
    },
    regions: {},
    options: {
      labelInWizard: 'Inter-Region FIX Connection',
      step2subTitle: i18n.t('constants.PROVIDER_CONFIG.step2subTitle.interRegion', { ns: LangNameSpace.App }),
      step3subTitle: i18n.t('constants.PROVIDER_CONFIG.step3subTitle.interRegion', { ns: LangNameSpace.App }),
      disableInWizard: false,
    },
  },
  [Provider.Private]: {
    type: ProviderType.Fix,
    connectionType: ConnectionType.Private,
    connectionTypeName: i18n.t('constants.PROVIDER_CONFIG.connectionTypeName.private', { ns: LangNameSpace.App }),
    displayName: 'Private',
    bandwidthOption: {
      type: BandwidthType.Radio,
      options: GENERAL_BANDWIDTH_OPTIONS,
    },
    regions: {},
    subtypes: {
      [ConnectionSubType.IntrafacilityCrossConnect]: {
        displayName: 'Intra-Facility',
        bandwidthOption: {
          type: BandwidthType.Radio,
          options: PRIVATE_INTRA_FACILITY_BANDWIDTH_OPTIONS,
          skipCheckAvailability: true,
        },
        regions: {},
        options: {
          disableInWizard: false,
        },
      },
      [ConnectionSubType.SdnProviderConnection]: {
        displayName: 'SDN Provider',
        bandwidthOption: {
          type: BandwidthType.Radio,
          options: PRIVATE_SDN_BANDWIDTH_OPTIONS,
        },
        regions: {},
        options: {
          disableInWizard: false,
        },
      },
      [ConnectionSubType.FacilityBackboneConnection]: {
        displayName: 'Provider-Backbone',
        bandwidthOption: {
          type: BandwidthType.Radio,
          options: PRIVATE_PROVIDER_BACKBONE_BANDWIDTH_OPTIONS,
        },
        regions: {},
        options: {
          disableInWizard: false,
        },
      },
      [ConnectionSubType.TraditionalCircuit]: {
        displayName: 'Traditional Telco',
        bandwidthOption: {
          type: BandwidthType.Radio,
          options: PRIVATE_TRADITIONAL_TELCO_BANDWIDTH_OPTIONS,
        },
        regions: {},
        options: {
          disableInWizard: false,
        },
      },
    },
    options: {
      labelInWizard: 'Private Connection',
      step2subTitle: i18n.t('constants.PROVIDER_CONFIG.step2subTitle.private', { ns: LangNameSpace.App }),
      step3subTitle: i18n.t('constants.PROVIDER_CONFIG.step3subTitle.private', { ns: LangNameSpace.App }),
      disableInWizard: false,
    },
  },
};
