export const changeRequest = {
  components: {
    ChangeRequestDetailsModal: {
      titles: {
        id: 'Id',
        bandwidth: 'Bandwidth',
        notes: 'Notes',
        status: 'Status',
        referenceId: 'Reference Id',
        connectionId: 'Connection Id',
        provider: 'Provider',
        location: 'Location',
        region: 'Region',
        customerCode: 'Customer Code',
        submitDate: 'Submit Date',
        completionDate: 'Completion Date',
      },
    },
    ChangeRequestsGrid: {
      title: 'Change Requests',
      columns: {
        provider: {
          title: 'Provider',
        },
        bandwidth: {
          title: 'Bandwidth',
        },
        region: {
          title: 'Cloud Location',
        },
        location: {
          title: 'Faction Location',
        },
        createdDate: {
          title: 'Submit Date',
        },
        resolvedDate: {
          title: 'Completion Date',
        },
        status: {
          title: 'Status',
        },
        revokeTicketNumber: {
          title: 'Reference ID',
        },
      },
    },
  },
};
