export const mfa = {
  components: {
    MfaCreateForm: {
      title: 'Multi-Factor Authentication (MFA) Required',
      text1:
        'Multi-Factor Authentication (MFA) is required to access the Faction Portal. Please provide an email address to continue setup.',
      text2:
        'A verification code will be sent to the provided email address. The code will be valid for <b>{{time}}</b> minutes',
    },
    MfaCreateModal: {
      successRequestMessages: {
        create: 'MFA successfully created.',
        resendEmail: 'The MFA confirmation code has been resent.',
        confirm: 'MFA successfully verified.',
      },
      actions: {
        resendEmail: 'Resend Verification Email',
        create: 'Create',
        confirm: 'Save',
      },
      closeBtn: 'Cancel',
      btnBack: 'back',
    },
    MfaVerificationForm: {
      text: 'A verification code has been sent to <br /><b>{{email}}</b>. Please enter the verification code below to complete Multi-Factor Authentication setup.',
      labels: {
        confirmationCode: 'Enter MFA Verification Code',
      },
    },
  },
  hooks: {
    mfaCreateFormValidationSchema: {
      labels: {
        email: 'Email',
      },
    },
    mfaVerificationFormValidationSchema: {
      confirmationCode: {
        error: 'MFA Verification Code only digits allowed',
        label: 'MFA Verification Code',
      },
    },
  },
};
