import { i18n, LangNameSpace } from 'app/i18n';

import { ChangeRequestStatus } from '../enums/change-request';

export const CHANGE_REQUEST_STATUSES = {
  [ChangeRequestStatus.New]: {
    identifier: ChangeRequestStatus.New,
    name: i18n.t('constants.CHANGE_REQUEST_STATUSES.new', { ns: LangNameSpace.App }),
  },
  [ChangeRequestStatus.Closed]: {
    identifier: ChangeRequestStatus.Closed,
    name: i18n.t('constants.CHANGE_REQUEST_STATUSES.closed', { ns: LangNameSpace.App }),
  },
};
