import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MfaCreateFormFields } from 'modules/mfa/interfaces';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

import { mfaCreateFormValidationSchema } from './validation-schemas';

export const useMfaCreateForm = () => {
  const resolver = yupResolver(mfaCreateFormValidationSchema);

  const form = useForm<MfaCreateFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
