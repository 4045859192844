import { Action } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useStore } from 'react-redux';

export type DispatchMultipleHook = (actions: Action[]) => void;

export const useDispatchMultiple = (): DispatchMultipleHook => {
  const store = useStore();
  return useCallback((actions: Action[]): void => actions.forEach((action) => store.dispatch(action)), [store]);
};
