import { Provider } from 'app/enums';
import { LocationAvailabilityEntity } from 'app/interfaces/entity/location';
import { LocationsAvailabilityDto } from 'app/store/locations-availability/interfaces/dto';
import { getObjectValue } from 'shared/utils';

export const dtoToEntityMapper = (data: LocationsAvailabilityDto): LocationAvailabilityEntity => {
  return {
    regions: data.regions.map((item) => item.toLowerCase()),
    provider: getObjectValue(Provider, data.provider) as Provider,
  };
};
