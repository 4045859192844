export enum ConnectionSubType {
  AwsDirectConnect = 'aws_direct_connect',
  AzureExpressRouteCircuit = 'azure_express_route_circuit',
  GcpPartnerInterconnect = 'gcp_partner_interconnect',
  OciFastConnect = 'oci_fast_connect',
  FacilityBackboneConnection = 'facility_backbone_connection',
  IntrafacilityCrossConnect = 'intrafacility_cross_connect',
  SdnProviderConnection = 'sdn_provider_connection',
  TraditionalCircuit = 'traditional_circuit',
}
