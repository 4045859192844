import { DashboardEntity } from 'app/interfaces/entity/dashboard';
import { DashboardType } from 'modules/monitoring/enums';

export const formatDashboardsName = (dashboard: DashboardEntity) => {
  if (dashboard?.type === DashboardType.Client) {
    return dashboard;
  }

  let name = dashboard?.name.replace(/cnry/i, '').replace(/\s\s+/g, ' ').trim();

  if (name) {
    const [location, connection] = name.split(' ');
    name = `${location.toUpperCase()} - ${connection}`;
  }

  return {
    ...dashboard,
    name,
  };
};
