import { stringToHash } from 'app/services/api/utils';
import { CacheRequestConfig, MemoryStorage } from 'axios-cache-interceptor';

import { apiService } from './api';

export const invalidate = async (invalidationKeys: string[]): Promise<void[]> => {
  const storeKeys = Object.keys((apiService.storage as MemoryStorage)?.data ?? {});

  const keys = invalidationKeys
    .map((key) => storeKeys.filter((initialKey) => initialKey.match(new RegExp(`${key}(-\\d+){0,2}$`))))
    .flat();

  return Promise.all(keys.map((key) => apiService.storage.remove(key)));
};

export const generateKey = (options: CacheRequestConfig<unknown, unknown>) => {
  const headersHash = stringToHash(JSON.stringify(options.headers));
  const paramsHash = stringToHash(JSON.stringify(options.params));

  return `${options.url}-${paramsHash}-${headersHash}`;
};
