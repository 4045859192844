import { FontStyle, FontWeight } from 'app/enums/theme';
import MontserratBlack from 'assets/fonts/Montserrat/Montserrat-Black.ttf';
import MontserratBlackItalic from 'assets/fonts/Montserrat/Montserrat-BlackItalic.ttf';
import MontserratBold from 'assets/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratBoldItalic from 'assets/fonts/Montserrat/Montserrat-BoldItalic.ttf';
import MontserratExtraBold from 'assets/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import MontserratExtraBoldItalic from 'assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf';
import MontserratExtraLight from 'assets/fonts/Montserrat/Montserrat-ExtraLight.ttf';
import MontserratExtraLightItalic from 'assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf';
import MontserratItalic from 'assets/fonts/Montserrat/Montserrat-Italic.ttf';
import MontserratLight from 'assets/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratLightItalic from 'assets/fonts/Montserrat/Montserrat-LightItalic.ttf';
import MontserratMedium from 'assets/fonts/Montserrat/Montserrat-Medium.ttf';
import MontserratMediumItalic from 'assets/fonts/Montserrat/Montserrat-MediumItalic.ttf';
import MontserratRegular from 'assets/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratSemiBold from 'assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
import MontserratSemiBoldItalic from 'assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf';
import MontserratThin from 'assets/fonts/Montserrat/Montserrat-Thin.ttf';
import MontserratThinItalic from 'assets/fonts/Montserrat/Montserrat-ThinItalic.ttf';

export const MONTSERRAT_FONT_NAME = 'Montserrat';

export const MONTSERRAT_FONT_FACES = `
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Montserrat-Regular'), url(${MontserratRegular}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Regular};
    font-display: swap;
    src: local('Montserrat-Regular-Italic'), url(${MontserratItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Montserrat-Black'), url(${MontserratBlack}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Heavy};
    font-display: swap;
    src: local('Montserrat-BlackItalic'), url(${MontserratBlackItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Montserrat-Bold'), url(${MontserratBold}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Bold};
    font-display: swap;
    src: local('Montserrat-BoldItalic'), url(${MontserratBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.ExtraBold};
    font-display: swap;
    src: local('Montserrat-ExtraBold'), url(${MontserratExtraBold}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.ExtraBold};
    font-display: swap;
    src: local('Montserrat-ExtraBoldItalic'), url(${MontserratExtraBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.ExtraLight};
    font-display: swap;
    src: local('Montserrat-ExtraLight'), url(${MontserratExtraLight}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.ExtraLight};
    font-display: swap;
    src: local('Montserrat-ExtraLightItalic'), url(${MontserratExtraLightItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Montserrat-Light'), url(${MontserratLight}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Light};
    font-display: swap;
    src: local('Montserrat-LightItalic'), url(${MontserratLightItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Montserrat-Medium'), url(${MontserratMedium}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Medium};
    font-display: swap;
    src: local('Montserrat-MediumItalic'), url(${MontserratMediumItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.SemiBold};
    font-display: swap;
    src: local('Montserrat-SemiBold'), url(${MontserratSemiBold}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.SemiBold};
    font-display: swap;
    src: local('Montserrat-SemiBoldItalic'), url(${MontserratSemiBoldItalic}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Normal};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Montserrat-Thin'), url(${MontserratThin}) format('opentype');
  }
  @font-face {
    font-family: "Montserrat";
    font-style: ${FontStyle.Italic};
    font-weight: ${FontWeight.Thin};
    font-display: swap;
    src: local('Montserrat-ThinItalic'), url(${MontserratThinItalic}) format('opentype');
  }
`;
