import { Theme } from '@mui/material';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    ...TYPOGRAPHY.b2SemiBold,
  },
  text1: {
    ...TYPOGRAPHY.b2Regular,
    paddingTop: theme.spacing(3),
  },
  text2: {
    ...TYPOGRAPHY.b2Regular,
    paddingTop: theme.spacing(2),
  },
  detailsContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
  },

  formGroup: {},
}));
