export const sankey = {
  components: {
    SankeyFixNode: {
      imgAlt: 'Faction Inc.',
      title: 'FIX',
    },
    SankeyNodeBandwidth: {
      description: 'Bandwidth:',
    },
    SankeyCloudConnectionNode: {
      crossCloud: {
        title: 'Cross-Cloud:',
        enabled: 'Enabled',
        disabled: 'Disabled',
        tooltipTitle: `Allowing routing of traffic from one cloud to<br />another cloud through the FIX.`,
      },
    },
  },
};
