import { SERVICE_ROUTES } from 'app/constants';
import { Route } from 'app/enums';
import { useRoutes } from 'app/hooks/use-routes';
import { sessionSelector } from 'app/store/session/selectors';
import React, { createElement, FC, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route as ReactRoute, Routes, useLocation } from 'react-router-dom';
import { Loader } from 'shared/components/Loader';

import { NotFound } from '../NotFound';
import { GuardedRoute } from '../RouterGuard';

export const AppRouter: FC<React.PropsWithChildren<unknown>> = () => {
  const { appRoutes, appRedirects, firstAvailableRoutePath } = useRoutes();
  const isMfaConfigurationRequired = useSelector(sessionSelector.isMfaConfigurationRequired);
  const location = useLocation();

  const isShowMfa = useCallback(
    (route: Route) => {
      if (SERVICE_ROUTES.includes(route)) {
        return false;
      }
      return isMfaConfigurationRequired;
    },
    [isMfaConfigurationRequired]
  );

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {firstAvailableRoutePath !== Route.Root && (
          <ReactRoute path={Route.Root} element={<Navigate to={firstAvailableRoutePath} replace={true} />} />
        )}
        {appRedirects.map((redirect) => (
          <ReactRoute
            key={`${redirect.from}-${redirect.to}`}
            path={redirect.from}
            element={<Navigate to={redirect.to} replace={true} state={location} />}
          />
        ))}
        {appRoutes.map((route) => (
          <ReactRoute
            path={route.path}
            key={`${route.path}${route.component.name}`}
            element={
              <GuardedRoute key={`${route.path}${route.component.name}`} path={route.path} guards={route.guards}>
                {isShowMfa(route.path) ? <div></div> : createElement(route.component)}
              </GuardedRoute>
            }
          >
            {route.children &&
              route.children?.map((childRoute) => {
                return (
                  <ReactRoute
                    path={childRoute.path}
                    key={`${childRoute.path}${childRoute.component.name}`}
                    element={
                      <GuardedRoute
                        key={`${childRoute.path}${childRoute.component.name}`}
                        path={childRoute.path}
                        guards={childRoute.guards}
                      >
                        {isShowMfa(childRoute.path) ? <span></span> : <childRoute.component />}
                      </GuardedRoute>
                    }
                  />
                );
              })}
          </ReactRoute>
        ))}
        <ReactRoute path={Route.NotFound} element={<NotFound />} />
        <ReactRoute path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
