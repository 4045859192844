import { createSlice } from '@reduxjs/toolkit';
import { StoreName } from 'app/enums/store';

import { initialState } from './initialState';
import { resetReducer, setSortModelReducer } from './reducers';

export const dataGridSortState = createSlice({
  name: StoreName.DataGridSort,
  initialState,
  reducers: {
    setSortModel: setSortModelReducer,
    reset: resetReducer,
  },
});

export const dataGridSortReducers = { ...dataGridSortState.actions };
