import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';

import { initialState } from './adapter';
import { registerReducers } from './repository';

export const ssoState = createSlice({
  name: EntityStoreName.Sso,
  initialState,
  reducers: {},
  extraReducers: (builder) => registerReducers(builder),
});

export const ssoReducers = { ...ssoState.actions };
