import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';
import { initialState } from 'modules/admin/api-credentials-management/store/keys/adapter';
import { clearAllReducer, registerReducers } from 'modules/admin/api-credentials-management/store/keys/repository';

export const apiCredentialsState = createSlice({
  name: EntityStoreName.ApiCredentials,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const apiCredentialsReducers = { ...apiCredentialsState.actions };
