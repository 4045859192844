import {
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import { i18n, LangNameSpace } from 'app/i18n';
import { stringToHash } from 'app/services/api/utils';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './PassswordHint.styles';

type PasswordHintProps = {
  value: string;
};

enum RequirementRule {
  moreThanRepeatedCharacter = 2,
  uniqueCharacter = 5,
  characters = 8,
  specialCharacter = 1,
  numbers = 1,
  uppercaseCharacter = 1,
  lowercaseCharacter = 1,
}

const REQUIREMENTS = [
  {
    text: i18n.t('components.PasswordHint.validations.moreThanRepeatedCharacter', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.moreThanRepeatedCharacter,
    }),
    validation: (value: string) => (value.match(/(.)\1{2,}/) ?? []).length === 0,
  },
  {
    text: i18n.t('components.PasswordHint.validations.uniqueCharacter', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.uniqueCharacter,
    }),
    validation: (value: string) => new Set(value).size >= 5,
  },
  {
    text: i18n.t('components.PasswordHint.validations.characters', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.characters,
    }),
    validation: (value: string) => value.length >= 8,
  },
  {
    text: i18n.t('components.PasswordHint.validations.specialCharacter', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.specialCharacter,
    }),
    validation: (value: string) => value.match(/[^a-zA-Z0-9]+/),
  },
  {
    text: i18n.t('components.PasswordHint.validations.number', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.numbers,
    }),
    validation: (value: string) => value.match(/[0-9]+/),
  },
  {
    text: i18n.t('components.PasswordHint.validations.uppercaseCharacter', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.uppercaseCharacter,
    }),
    validation: (value: string) => value.match(/[A-Z]+/),
  },
  {
    text: i18n.t('components.PasswordHint.validations.lowercaseCharacter', {
      ns: LangNameSpace.Shared,
      count: RequirementRule.lowercaseCharacter,
    }),
    validation: (value: string) => value.match(/[a-z]+/),
  },
];

export const PasswordHint: FC<React.PropsWithChildren<PasswordHintProps>> = ({ value }) => {
  const { t } = useTranslation(LangNameSpace.Shared);
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const requirementsList = useMemo(() => {
    return REQUIREMENTS.map((val) => {
      return { key: stringToHash(val.text), text: val.text, active: value && val.validation(value) };
    });
  }, [value]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <Typography className={classes.hint} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <InfoIcon className={classes.hintIcon} />
        <Box component="span" className={classes.hintTitle}>
          {t('components.PasswordHint.hintTitle')}
        </Box>
      </Typography>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: 'none',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box className={classes.container}>
          <List>
            {requirementsList.map(({ text, key, active }) => (
              <ListItem key={key} className={active ? 'active' : undefined}>
                <ListItemIcon>{active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}</ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};
