import { Box, Button } from '@mui/material';
import { AppError, Route } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './SentryFallback.styles';

interface FallbackProps {
  resetError: () => void;
  error?: Error;
}

export const SentryFallback: FC<React.PropsWithChildren<FallbackProps>> = ({ resetError, error }) => {
  const { classes } = useStyles();
  const { t } = useTranslation(LangNameSpace.App);
  const navigate = useNavigate();
  const chunkError = useMemo(
    () => `${error?.name}: ${error?.message}`.startsWith(AppError.ChunkLoadError),
    [error?.name, error?.message]
  );
  const errorText = useMemo(
    () => (chunkError ? t('components.SentryFallback.errors.update') : t('components.SentryFallback.errors.default')),
    [t, chunkError]
  );
  const buttonText = useMemo(
    () => (chunkError ? t('components.SentryFallback.btn.reload') : t('components.SentryFallback.btn.goHome')),
    [t, chunkError]
  );

  const clickHandler = (): void => {
    resetError();
    if (chunkError) {
      window.location.reload();
    } else {
      navigate(Route.Root);
    }
  };

  return (
    <Box className={classes.root}>
      <h1 className={classes.title}>{t('components.SentryFallback.title')}</h1>
      <p className={classes.text}>{errorText}</p>
      <Button onClick={clickHandler} type="button" variant="contained" color="primary">
        {buttonText}
      </Button>
    </Box>
  );
};
