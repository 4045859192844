import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ModalName } from 'app/enums';
import { i18n, LangNameSpace } from 'app/i18n';
import { modalSelector } from 'app/store/modal/selectors';
import { modalReducers } from 'app/store/modal/slice';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './Modal.styles';

export interface ModalProps extends Omit<DialogProps, 'open'> {
  name: ModalName;
  title?: string;
  isCloseDisabled?: boolean;
  modalControls?: ReactElement | null;
  actions?: ReactElement | null;
  onClose?: () => void;
  isCloseButtonAvailable?: boolean;
  isCloseIconHidden?: boolean;
  allowBackdropClose?: boolean;
  onCloseRequest?: (buttonName?: 'close' | 'cancel') => boolean;
  headerMode?: boolean;
  closeText?: string;
  skipBottomContentPadding?: boolean;
  actionsBlockFullWidth?: boolean;
  closeBtnVariant?: 'outlined' | 'contained' | 'text';
}

export const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
  isCloseDisabled = false,
  children,
  onClose,
  name,
  title,
  modalControls,
  actions,
  isCloseButtonAvailable = true,
  isCloseIconHidden = false,
  allowBackdropClose = false,
  onCloseRequest = () => true,
  headerMode = true,
  closeText = i18n.t('components.Modal.closeText', { ns: LangNameSpace.Shared }),
  closeBtnVariant = 'outlined',
  skipBottomContentPadding = true,
  actionsBlockFullWidth = false,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const opened = useSelector(modalSelector.isOpen(name));
  const modalTitle = useSelector(modalSelector.modalTitle);
  const defaultTitle = title === undefined ? modalTitle : title;
  const dispatch = useDispatch();

  const handleOnClose = (buttonName: 'close' | 'cancel' = 'close'): void => {
    if (isCloseDisabled || (onCloseRequest && !onCloseRequest?.(buttonName))) {
      return;
    }
    onClose?.();
    dispatch(modalReducers.closeModal({ name }));
  };

  return (
    <Dialog
      onClose={(e, reason) => {
        if (allowBackdropClose || reason !== 'backdropClick') {
          handleOnClose();
        }
      }}
      fullWidth
      open={opened}
      data-testid={`modal-${name}`}
      {...rest}
    >
      <DialogTitle
        className={cx([classes.title, { [classes.headerMode]: headerMode, [classes.skipPadding]: !defaultTitle }])}
      >
        <Typography component="div" typography="b1SemiBold" data-testid="modal-title">
          {defaultTitle}
        </Typography>
        {modalControls}
        {!isCloseIconHidden && (
          <IconButton
            size="small"
            onClick={() => handleOnClose()}
            className={cx([classes.closeIconBtn, { headerMode: headerMode, skipPadding: !defaultTitle }])}
            data-testid="closeModalButton"
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }} dividers={false}>
        <Box className={cx(classes.body, { [classes.skipPaddingBottom]: skipBottomContentPadding })}>{children}</Box>
      </DialogContent>
      <DialogActions className={cx(classes.actions, { [classes.fullWidth]: actionsBlockFullWidth })}>
        {isCloseButtonAvailable && (
          <Button
            data-testid="closeBtn"
            size="medium"
            color="primary"
            variant={closeBtnVariant}
            onClick={() => handleOnClose('cancel')}
          >
            {closeText}
          </Button>
        )}
        {actions}
      </DialogActions>
    </Dialog>
  );
};
