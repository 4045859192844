import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { LocationAvailabilityEntity } from 'app/interfaces/entity/location';
import { LocationAvailabilityState } from 'app/store/locations-availability/interfaces';

export const entityAdapter = createEntityAdapter({
  selectId: (data: LocationAvailabilityEntity) => data.provider as string,
});

export const initialState: LocationAvailabilityState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
