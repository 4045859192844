import { Route } from 'app/enums';
import { RedirectConfig } from 'app/interfaces/redirect-config';
import { GuardedRoute } from 'app/interfaces/route';
import { RedirectConfigModel } from 'app/models';
import { authGuard, rolesGuard } from 'app/routing/guards';
import React from 'react';

import { Role } from '../auth/enums';

const TicketPage = React.lazy(() => import('./pages/TicketPage'));
const TicketsPage = React.lazy(() => import('./pages/TicketsPage'));

export const ticketsRoutes: GuardedRoute[] = [
  {
    path: Route.TicketsList,
    component: TicketsPage,
    availability: [Role.User, Role.Technician],
    guards: [rolesGuard([Role.User, Role.Technician]), authGuard],
    exact: true,
  },
  {
    path: Route.TicketDetails,
    component: TicketPage,
    availability: [Role.User, Role.Technician],
    guards: [rolesGuard([Role.User, Role.Technician]), authGuard],
    exact: true,
  },
];

export const ticketsRedirects: RedirectConfig[] = [new RedirectConfigModel(Route.Tickets, Route.TicketsList, true)];
