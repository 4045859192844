import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GlobalStyles as MuiGlobalStyles, useTheme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { TYPOGRAPHY } from 'app/theme/constants';
import React, { FC, useMemo } from 'react';

export const GlobalStyles: FC = () => {
  const theme = useTheme();
  const cache = useMemo(
    () =>
      createCache({
        key: 'css',
        prepend: true,
      }),
    []
  );

  const styles = {
    'html, body, #root': {
      height: '100%',
    },
    form: {
      width: '100%',
    },
    '.MuiFormControl-root': {
      width: '100%',
    },
    '.MuiLink-root': {
      color: theme.custom.colors.link,
      textDecorationColor: 'initial',
      textDecorationThickness: 0.5,
      textUnderlineOffset: theme.spacing(0.5),
      '&:hover': {
        textDecorationThickness: 1,
      },
    },
    '.MuiTypography-subtitle1, .MuiMenuItem-root': {
      fontSize: theme.spacing(1.75),
    },
    '.MuiButton-root': {
      ...TYPOGRAPHY.Button1SemiBold,
      padding: theme.spacing(1.25, 1.375),
      borderRadius: theme.spacing(0.5),

      '&:not(:first-of-type)': {
        marginLeft: theme.spacing(1.5),
      },

      '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1.25),
        marginLeft: theme.spacing(0.375),
      },
      '& .MuiButton-endIcon': {
        marginRight: 0,
        marginLeft: theme.spacing(1.375),
      },

      ':not(.MuiButton-outlinedPrimary, .MuiButton-textPrimary)': {
        '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
          backgroundColor: theme.palette.button.pressed,
        },
      },
    },
    '.MuiPickersDay-root:hover, .MuiPickersDay-root:focus': {
      backgroundColor: theme.palette.grid.filters.icon.backgroundHover,
    },
    '.MuiRadio-root, .MuiCheckbox-root': {
      color: theme.custom.colors.label,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    '.MuiFormHelperText-root': {
      overflow: 'auto',
    },
    '.MuiCheckbox-root': {
      '&:hover': {},
      '&.Mui-checked, &.MuiCheckbox-indeterminate': {
        color: theme.custom.colors.link,
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
    '.MuiMenuItem-root': {
      maxHeight: theme.spacing(3.75),
      color: theme.palette.text.primary,

      '&:hover': {
        backgroundColor: theme.palette.dropDown.background.hover,
      },
      '&.Mui-selected:hover:hover': {
        backgroundColor: theme.palette.dropDown.background.hover,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.dropDown.background.hover,
        fontWeight: FontWeight.SemiBold,
      },
    },
    '.MuiAutocomplete-paper': {
      '& .MuiAutocomplete-option.Mui-focused': {
        backgroundColor: theme.palette.dropDown.background.hover,
      },
      '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused:hover': {
        backgroundColor: theme.palette.dropDown.background.hover,
        fontWeight: FontWeight.SemiBold,
      },
      '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme.palette.dropDown.background.hover,
      },
    },
    '.MuiMenu-list': {
      '& .MuiDivider-root': {
        margin: theme.spacing(1.5, 0),
      },
    },

    // Data grid
    // Used to enable the sticky position of the control cell
    '.MuiDataGrid-virtualScrollerContent': {
      overflow: 'initial',
      backgroundColor: theme.palette.background.paper,
    },

    '.MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.paper,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: theme.custom.border.default,
    },

    '.MuiDataGrid-overlay': {
      backgroundColor: theme.palette.background.paper,
    },

    '.MuiDataGrid-row:nth-of-type(even), & .MuiDataGrid-row:nth-of-type(odd)': {
      '&:hover': {
        backgroundColor: theme.palette.grid.background.hover,

        '& .MuiDataGrid-cell:first-of-type': {
          backgroundColor: theme.palette.action.hoverWithoutOpacity,
        },
      },
    },
    '.MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-menu': {
      '& .MuiPaper-root': {
        boxShadow: theme.custom.boxShadow.menu,
        borderWidth: 1,
        borderColor: theme.custom.border.default,
        borderStyle: 'solid',
        marginTop: 11,
      },
    },

    '::-webkit-scrollbar': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
    '::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      width: theme.spacing(0.75),
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(1.125),
      borderWidth: theme.spacing(0.375),
      borderStyle: 'solid',
      borderColor: 'transparent',
      backgroundClip: 'content-box',
      '&:vertical': {
        height: theme.spacing(0.75),
      },
    },
    '.MuiDialog-paper': {
      boxShadow: theme.custom.boxShadow.dialog,
      borderWidth: 0,
      width: '100%',

      '&.MuiDialog-paperWidthXs': {
        maxWidth: theme.spacing(50),
      },
      '&.MuiDialog-paperWidthSm': {
        maxWidth: theme.spacing(75),
      },
      '&.MuiDialog-paperWidthMd': {
        maxWidth: theme.spacing(94),
      },
      '&.MuiDialog-paperWidthLg': {
        maxWidth: theme.spacing(121),
      },
      '&.MuiDialog-paperFullScreen': {
        maxWidth: '100%',
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
    '.MuiMenu-paper': {
      boxShadow: theme.custom.boxShadow.menu,
    },
  };

  return (
    <CacheProvider value={cache}>
      <MuiGlobalStyles styles={styles} />
    </CacheProvider>
  );
};
