import { TimeScaleType } from 'app/enums';
import dayjs from 'dayjs';

const timescaleMapping = new Map([
  [TimeScaleType.hour, ['1hour', '2hour', '5hour', '8hour', '12hour', '1day']],
  [TimeScaleType.day, ['2days', '7days']],
  [TimeScaleType.month, ['1month', 'Last calendar month', '3month']],
  [TimeScaleType.year, ['1year']],
]);

export const getTimeScaleByTimeRange = (startTime: number, endTime: number): TimeScaleType => {
  if (!startTime || !endTime) {
    return TimeScaleType.hour;
  }

  if (dayjs(endTime).diff(startTime, 'year') > 0) {
    return TimeScaleType.year;
  }

  if (dayjs(endTime).diff(startTime, 'month') >= 1) {
    return TimeScaleType.month;
  }

  if (dayjs(endTime).diff(startTime, 'day') > 1) {
    return TimeScaleType.day;
  }

  return TimeScaleType.hour;
};

export const getTimeScale = (timescale: string): TimeScaleType | undefined => {
  for (const [scaleType, scales] of timescaleMapping.entries()) {
    if (scales.includes(timescale)) {
      return scaleType;
    }
  }
};
