import { Collapse, Link, Theme, useTheme } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import classNames from 'classnames';
import { CustomContentProps, SnackbarContent } from 'notistack';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './CustomSnackbarContent.style';

const ariaDescribedby = 'notistack-snackbar';
const ComponentClasses = {
  MuiContent: 'notistack-MuiContent',
  MuiContentVariant: (variant: string) => `notistack-MuiContent-${variant}`,
};

// Based on https://github.com/iamhosseindhv/notistack/blob/master/src/ui/MaterialDesignContent/MaterialDesignContent.tsx
export const CustomSnackbarContent = forwardRef<HTMLDivElement, CustomContentProps>((props, forwardedRef) => {
  const { id, message, action: componentOrFunctionAction, iconVariant, variant, style, className } = props;

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState<boolean>();
  const [isMore3rows, setIsMore3rows] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const theme: Theme = useTheme();
  const { t } = useTranslation(LangNameSpace.App);

  const { classes } = useStyles();

  const icon = iconVariant[variant];

  let action = componentOrFunctionAction;
  if (typeof action === 'function') {
    action = action(id);
  }

  useEffect(() => {
    setIsMore3rows(Number(textRef?.current?.clientHeight) >= 84);
  }, [textRef, containerRef]);

  useEffect(() => {
    if (isMore3rows) {
      setIsShowMoreVisible(Number(textRef?.current?.clientHeight) >= 96);
    }
  }, [textRef, isMore3rows]);

  return (
    <SnackbarContent
      ref={forwardedRef}
      role="alert"
      aria-describedby={ariaDescribedby}
      style={style}
      className={classNames(
        classes.root,
        ComponentClasses.MuiContent,
        ComponentClasses.MuiContentVariant(variant),
        classes[variant],
        className,
        { showLess: isMore3rows }
      )}
      data-testid={`snackbar-${variant}`}
    >
      <div className={classes.icon}>{icon}</div>
      <div id={ariaDescribedby} className={classes.messageBlock}>
        <div ref={containerRef} className={classNames(classes.message, { showLess: isMore3rows })}>
          <Collapse
            collapsedSize={isShowMoreVisible ? theme.spacing(9) : theme.spacing(4)}
            in={!collapsed}
            className={classNames(classes.textContainer, { showLess: isMore3rows && isShowMoreVisible })}
          >
            <div ref={textRef}>{message}</div>
          </Collapse>
        </div>
        <div className={classes.moreMessage}>
          {isShowMoreVisible && (
            <Link
              component="div"
              className={classes.moreLink}
              underline="none"
              onClick={() => setCollapsed((state) => !state)}
              data-testid="showMoreBtn"
            >
              {collapsed ? t('providers.SnackbarProvider.showMore') : t('providers.SnackbarProvider.showLess')}
            </Link>
          )}
        </div>
      </div>
      <div className={classNames(classes.action, 'action')}>{action}</div>
    </SnackbarContent>
  );
});
