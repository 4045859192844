import { HeaderPalette } from '@types';

export const HEADER_DARK: HeaderPalette = {
  background: {
    MuiPaper: '#44454A',
    mainMenu: {
      main: '#242529',
      hover: '#0095d3',
      button: 'rgba(255, 255, 255, 0.08)',
      iconButton: 'rgba(255, 255, 255, 0.14)',
    },
    subMenu: {
      main: '#111114',
      hover: '#0095d3',
      active: '#0095d3',
    },
  },
  text: {
    mainMenu: {
      main: 'rgba(255, 255, 255, 0.8)',
      hover: '#ffffff',
      active: '#ffffff',
    },
    subMenu: {
      main: 'rgba(224, 227, 231, 0.8)',
      hover: '#ffffff',
      active: '#ffffff',
    },
  },
  border: {
    main: 'rgba(255, 255, 255, 0.5)',
    subMenu: '#2C2D33',
  },
};

export const HEADER_LIGHT: HeaderPalette = {
  background: {
    MuiPaper: '#FFFFFF',
    mainMenu: {
      main: '#012B3B',
      hover: '#297494',
      button: 'rgba(255, 255, 255, 0.08)',
      iconButton: 'rgba(255, 255, 255, 0.14)',
    },
    subMenu: {
      main: '#FFFFFF',
      hover: '#297494',
      active: '#297494',
    },
  },
  text: {
    mainMenu: {
      main: 'rgba(255, 255, 255, 0.8)',
      hover: '#ffffff',
      active: '#ffffff',
    },
    subMenu: {
      main: '#606271',
      hover: '#000000',
      active: '#1D1D1F',
    },
  },
  border: {
    main: 'rgba(255, 255, 255, 0.5)',
    subMenu: '#E0E3E7',
  },
};
