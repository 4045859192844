import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { profileEditValidationSchema } from 'modules/profile/hooks/validation-schemas';
import { ProfileEditFormFields } from 'modules/profile/interfaces';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

export const useProfileEditForm = () => {
  const resolver = yupResolver(profileEditValidationSchema);

  const form = useForm<ProfileEditFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      timezone: '',
      contactPreferences: [],
    },
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
