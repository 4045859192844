import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeMode } from 'app/enums/theme';
import { useThemeMode } from 'app/hooks';
import { OemConfigurationProvider } from 'app/providers/OemConfigurationProvider';
import { SnackbarProvider } from 'app/providers/SnackbarProvider';
import { initSentry } from 'app/sentry';
import { OEM_DEFAULT } from 'app/theme/constants';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { CombineProviders, ProviderModel } from 'shared/components/CombineProviders';
import { CloseUnsavedConfirmationModal } from 'shared/components/Modal';

import { GlobalStyles } from '../GlobalStyles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

interface AppConfigurationProps {
  themeOptions?: ThemeOptions;
}

export const AppConfiguration: FC<React.PropsWithChildren<AppConfigurationProps>> = ({ children, themeOptions }) => {
  const [oemConfig, setOemConfig] = useState(OEM_DEFAULT.oemConfig);
  const themeMode = useThemeMode();
  const isLightMode = useMemo(() => themeMode === ThemeMode.Light, [themeMode]);

  const theme = useMemo(() => {
    return createTheme(
      themeOptions
        ? deepmerge(OEM_DEFAULT.theme?.light, themeOptions)
        : isLightMode
          ? OEM_DEFAULT.theme?.light
          : OEM_DEFAULT.theme?.dark
    );
  }, [themeOptions, isLightMode]);

  const providers = [
    new ProviderModel(LocalizationProvider, { dateAdapter: AdapterDayjs }),
    new ProviderModel(StyledEngineProvider, { injectFirst: true }),
    new ProviderModel(ThemeProvider, { theme }),
    new ProviderModel(OemConfigurationProvider, { oemConfig, setOemConfig }),
    new ProviderModel(SnackbarProvider),
  ];

  useEffect(() => {
    initSentry();
  }, []);

  return (
    <CombineProviders providers={providers}>
      <CssBaseline />
      <GlobalStyles />
      <>{children}</>
      <CloseUnsavedConfirmationModal />
    </CombineProviders>
  );
};

export default AppConfiguration;
