import { EntityStoreName } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { LocationEntity } from 'app/interfaces/entity/location';
import { sortLocations } from 'app/store/locations/utils';
import { sessionSelector } from 'app/store/session/selectors';
import { ccvsSelector } from 'modules/cloud-services/store/ccvs/selectors';
import { cloudConnectionsSelector } from 'modules/cloud-services/store/cloud-connections/selectors';
import { useMemo } from 'react';

import { createAppSelector } from '../createAppSelector';
import { AppState } from '../store';
import { entityAdapter } from './adapter';

const selectAll = entityAdapter.getSelectors<AppState>((state) => state[EntityStoreName.Locations]).selectAll;

const selectAllWithState = createAppSelector(
  selectAll,
  ccvsSelector.all,
  cloudConnectionsSelector.all,
  (locations, ccvs, cloudConnections): LocationEntity[] => {
    const ccvLocations = [...new Set(ccvs.map((ccv) => ccv.factionLocationId))];
    const cloudLocations = [
      ...new Set([
        ...cloudConnections.reduce((items: Identifier[], cloudConnection) => {
          const conLocations = cloudConnection.factionLocations;
          return [...items, ...conLocations];
        }, []),
      ]),
    ];
    const activeLocations = [...new Set([...cloudLocations, ...ccvLocations])];

    return locations
      .map((location: LocationEntity) => {
        const isLocationActive = activeLocations.includes(location.identifier);
        return { ...location, isAvailable: isLocationActive };
      })
      .sort(sortLocations);
  }
);

const selectOneFactory = (identifier?: string): ((state: AppState) => LocationEntity | undefined) =>
  createAppSelector(selectAll, sessionSelector.locationId, (locations, locationId) =>
    locations.find((location: LocationEntity) => location?.identifier === (identifier ?? locationId))
  );

export const locationsSelector = {
  allWithState: selectAllWithState,
  all: selectAll,
};

export const locationsSelectorFactory = {
  useSelectOne: (locationId?: string) => useMemo(() => selectOneFactory(locationId), [locationId]),
};
