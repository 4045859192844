import { EntityStoreRequestType } from 'app/enums/store';

export const fixBandwidthStateMock = {
  entity: {
    total: 130,
    allocated: 90,
    reserved: 30,
    unreserved: 10,
  },
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
};
