import { Route } from 'app/enums';
import { GuardedRoute } from 'app/interfaces/route';
import { authGuard } from 'app/routing/guards';
import React from 'react';

const RootPage = React.lazy(() => import('./pages/RootPage'));

export const rootRoutes: GuardedRoute[] = [
  {
    path: Route.Root,
    component: RootPage,
    availability: [],
    guards: [authGuard],
    exact: true,
  },
];
