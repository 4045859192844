import { SortDirection } from 'app/enums';

export const getSortRule = (sortField: string) => {
  const sortDirection = sortField[0] === '-' ? SortDirection.Desc : SortDirection.Asc;
  const sortKey = sortField.replace('-', '');

  return { sortKey, sortDirection };
};

export const getSortString = (sortKey: string, sortDirection: SortDirection = SortDirection.Asc) => {
  return `${sortDirection === SortDirection.Desc ? '-' : ''}${sortKey}`;
};
