import { createSlice } from '@reduxjs/toolkit';
import { EntityStoreName } from 'app/enums/store';
import { initialState } from 'modules/admin/user-management/store/users/adapter';
import { clearAllReducer, registerReducers } from 'modules/admin/user-management/store/users/repository';

export const usersState = createSlice({
  name: EntityStoreName.Users,
  initialState,
  reducers: {
    clearAll: clearAllReducer,
  },
  extraReducers: (builder) => registerReducers(builder),
});

export const usersReducers = { ...usersState.actions };
