import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { PAGE_SIDE_PADDING } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: FontWeight.Medium,
    alignItems: 'center',
  },

  header: {
    padding: theme.spacing(0, PAGE_SIDE_PADDING * 0.125),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.header.background.mainMenu.main,
    maxHeight: theme.custom.headerHeight,
    height: theme.custom.headerHeight,
    overflow: 'hidden',
  },

  subHeader: {
    padding: theme.spacing(0, PAGE_SIDE_PADDING * 0.125),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.header.background.subMenu.main,
    maxHeight: theme.custom.subHeaderHeight,
    height: theme.custom.subHeaderHeight,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.header.border.subMenu,
    borderBottomWidth: theme.spacing(0.125),
  },

  leftSide: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  rightSide: {
    display: 'flex',
    gap: theme.spacing(0, 1.875),
    padding: theme.spacing(0.75),

    '& .MuiButtonBase-root': {
      color: theme.palette.header.text.mainMenu.main,
      backgroundColor: theme.palette.header.background.mainMenu.iconButton,
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(5),
      width: theme.spacing(5),
      padding: theme.spacing(1.25),
      alignSelf: 'center',

      '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
        borderRadius: theme.spacing(0.5),
      },
    },
  },
}));
