import { Route } from 'app/enums';
import { pingIdentityClient } from 'app/services/ping-identity';
import { sessionSelector } from 'app/store/session/selectors';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'shared/components/Loader';

export const Login: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const isAuthorized = useSelector(sessionSelector.isAuthorized);

  const redirectAfterLoginPath = useSelector(sessionSelector.redirectAfterLoginPath);

  useEffect(() => {
    if (isAuthorized) {
      return navigate(Route.Root);
    } else {
      pingIdentityClient.signinRedirect({ state: { redirectAfterLoginPath } });
    }
  }, [navigate, isAuthorized, redirectAfterLoginPath]);

  return <Loader />;
};
