import { UserProfileTags } from 'app/enums/gtag';
import { usePageTitle } from 'app/hooks/use-page-title';
import { OemConfigurationContext } from 'app/providers';
import { sessionSelector } from 'app/store/session/selectors';
import env from 'config/env.json';
import { organizationsSelector } from 'modules/organization/store/selectors';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

export interface HeadProps {
  fonts?: string[];
  fontFaces?: string[];
}

export const AppHead: FC<React.PropsWithChildren<HeadProps>> = ({ fonts, fontFaces }: HeadProps) => {
  const [gTag, setGtag] = useState<string | null>(null);
  const { oemConfig, setOemConfig } = useContext(OemConfigurationContext);
  const isAuthorized = useSelector(sessionSelector.isAuthorized);
  const userProfile = useSelector(sessionSelector.userProfile);
  const organizations = useSelector(organizationsSelector.all);

  const { pageTitle } = usePageTitle();

  useEffect(() => {
    if (!isAuthorized) return;
    if (pageTitle === undefined) return;

    setOemConfig({ pageTitle });
  }, [pageTitle, isAuthorized, setOemConfig]);

  const filteredOrgCode = useMemo(() => {
    if (!userProfile) return;
    if (organizations.length === 0) return;

    const filterByPopulation = organizations.filter((o) => o.pingPopulationId === userProfile.population);

    let orgCode = null;

    if (filterByPopulation.length > 0) {
      orgCode = filterByPopulation[0].orgCode;
    }

    return orgCode;
  }, [organizations, userProfile]);

  useEffect(() => {
    if (!isAuthorized) return;
    if (!userProfile) return;

    if (!filteredOrgCode) {
      setGtag(`gtag('event', 'User', {
        '${UserProfileTags.Id}': '${userProfile.email}',
        '${UserProfileTags.Email}': '${userProfile.email}',
        '${UserProfileTags.OrgCode}': 'Unknown',

      });`);
    }

    if (filteredOrgCode) {
      setGtag(`gtag('event', 'User', {
      '${UserProfileTags.Id}': '${userProfile.email}',
      '${UserProfileTags.Email}': '${userProfile.email}',
      '${UserProfileTags.OrgCode}': '${filteredOrgCode}',
    });`);
    }
  }, [isAuthorized, userProfile, filteredOrgCode]);

  return (
    <Helmet>
      <title>{oemConfig?.pageTitle}</title>
      <>{fonts && fonts.map((link: string) => <link key={link} href={link} rel="stylesheet" />)}</>
      {fontFaces && <style>{fontFaces.join('')}</style>}

      {/*Global site tag (gtag.js) - Google Analytics*/}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${env.REACT_APP_ANALYTICS}`} />
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${env.REACT_APP_ANALYTICS}');
          ${gTag}
        `}
      </script>
    </Helmet>
  );
};
