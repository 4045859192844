import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { AppState } from 'app/store';
import { transform } from 'lodash';
import { useSelector } from 'react-redux';

type RepositoryStateHook = {
  [key in EntityStoreName]?: EntityStoreRequestType;
};

/**
 * Used for checking repository statuses.
 * If at least one repository has active requests for statuses from @params - function return 'true', otherwise 'false'
 *
 * @param params
 *
 * @return boolean
 */
export const useEntityRepositoryStatus = (params: RepositoryStateHook): boolean => {
  return useSelector((root: AppState) => {
    const result = transform<RepositoryStateHook, boolean[]>(
      params,
      (res, status, sliceName: EntityStoreName) => {
        res.push(root[sliceName].status[status as EntityStoreRequestType]?.length !== 0);
      },
      []
    );

    return result.includes(true);
  });
};
