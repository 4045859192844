import { ModalName, WizardName } from 'app/enums';
import { modalSelector } from 'app/store/modal/selectors';
import React, { FC, memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';

export function withModalOpenChecker<T>(Component: FC<React.PropsWithChildren<T>>, name: ModalName | WizardName) {
  return memo(({ ...props }: React.PropsWithChildren<T>): ReactElement | null => {
    const opened = useSelector(modalSelector.isOpen(name));
    if (!opened) {
      return null;
    }

    return <Component {...props} />;
  });
}
