import { FormControl, FormGroup, TextField } from '@mui/material';
import { LangNameSpace } from 'app/i18n';
import { CONTACT_OPTIONS, CONTACT_PREFERENCES } from 'modules/admin/user-management/constants';
import { ContactPreference } from 'modules/admin/user-management/enums';
import { ProfileEditFormFields } from 'modules/profile/interfaces';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { FormFieldError } from 'shared/components/Form/FormFieldError';
import { SelectInput } from 'shared/components/Form/SelectInput/SelectInput';
import { TimeZoneDropdown } from 'shared/components/Form/TimeZoneDropdown';

interface ProfileEditFormProps {
  form: UseFormReturn<ProfileEditFormFields>;
}

export const ProfileEditForm: FC<React.PropsWithChildren<ProfileEditFormProps>> = ({ form }) => {
  const { t } = useTranslation(LangNameSpace.Modules);
  const { control } = form;

  return (
    <form data-testid="editProfileForm">
      <FormGroup>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <TextField
                required
                size="small"
                label={t('profile.components.ProfileEditForm.labels.firstName')}
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <TextField
                required
                size="small"
                label={t('profile.components.ProfileEditForm.labels.lastName')}
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <TextField
                required
                size="small"
                label={t('profile.components.ProfileEditForm.labels.email')}
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <TextField
                size="small"
                label={t('profile.components.ProfileEditForm.labels.phone')}
                error={invalid}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="timezone"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl className="form-field" size="small">
              <TimeZoneDropdown
                inputProps={{
                  size: 'small',
                  label: t('profile.components.ProfileEditForm.labels.timezone'),
                  error: invalid,
                  name: field.name,
                }}
                {...field}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="contactPreferences"
          control={control}
          render={({ field: { onChange, ...rest }, fieldState: { error, invalid } }) => (
            <FormControl className="form-field">
              <SelectInput
                {...rest}
                options={CONTACT_OPTIONS}
                onChange={(event, currentValue) => {
                  onChange(currentValue);
                }}
                getOptionLabel={(option) => CONTACT_PREFERENCES[option as ContactPreference].name}
                inputProps={{
                  label: t('profile.components.ProfileEditForm.labels.contactPreferences'),
                  error: invalid,
                  name: rest?.name,
                }}
              />
              <FormFieldError error={error?.message} />
            </FormControl>
          )}
        />
      </FormGroup>
    </form>
  );
};
