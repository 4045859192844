import { WidgetPalette } from '@types';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';
import { AlertWidgetSeverity } from 'modules/monitoring/enums';

export const WIDGET_LIGHT: WidgetPalette = {
  background: COLORS_LIGHT.neutral.white,
  borderColor: COLORS_LIGHT.neutral.T16,
  boxShadow: '0px 6px 20px rgba(42, 63, 117, 0.06)',
  table: {
    totalBackground: '#F3F4F5',
    totalBorderColor: '#F0F1F3',
    navActionColor: '#1D1D1F',
    navActionDisabledColor: '#606271',
    navActionOpacity: '0.6',
    navActionDisabledOpacity: '0.5',
    progressBarBackground: '#88D1F1',
    header: {
      color: COLORS_LIGHT.neutral[400],
    },
    body: {
      color: COLORS_LIGHT.neutral.black,
    },
  },
  liner: {
    labelValueColor: '#606271',
    legendBackground: 'linear-gradient(0deg, rgba(224, 227, 231, 0.3), rgba(224, 227, 231, 0.3)), #FFFFFF',
  },
  tooltipMain: {
    background: '#ffffff',
    color: '#000',
  },
  tooltipSecond: {
    background: '#44454A',
    color: '#FFFFFF',
  },
  bigNumber: {
    title: {
      color: COLORS_LIGHT.neutral.black,
    },
    value: {
      border: COLORS_LIGHT.neutral.T16,
      color0: COLORS_LIGHT.neutral[400],

      color4: COLORS_LIGHT.warning[300],
      color3: COLORS_LIGHT.alert[400],
      color2: COLORS_LIGHT.alert[300],
    },
  },
  gauge: {
    peak: { background: COLORS_LIGHT.neutral.T4, color: COLORS_LIGHT.neutral.black },
    button: { background: COLORS_LIGHT.neutral.T4 },
  },
  pie: {
    label: {
      color: COLORS_LIGHT.neutral.white,
    },
    cell: {
      stroke: COLORS_LIGHT.neutral.white,
    },
  },
  alert: {
    badges: {
      [AlertWidgetSeverity.Critical]: {
        color: COLORS_LIGHT.custom['400'],
        background: COLORS_LIGHT.custom['400a'],
      },
      [AlertWidgetSeverity.Error]: {
        color: COLORS_LIGHT.custom['200'],
        background: COLORS_LIGHT.custom['200a'],
      },
      [AlertWidgetSeverity.Warning]: {
        color: COLORS_LIGHT.custom['50'],
        background: COLORS_LIGHT.custom['50a'],
      },
    },
    clearedBadge: {
      [AlertWidgetSeverity.Critical]: {
        color: COLORS_LIGHT.custom['700'],
        background: COLORS_LIGHT.custom['700a'],
      },
      [AlertWidgetSeverity.Error]: {
        color: COLORS_LIGHT.custom['700'],
        background: COLORS_LIGHT.custom['700a'],
      },
      [AlertWidgetSeverity.Warning]: {
        color: COLORS_LIGHT.custom['700'],
        background: COLORS_LIGHT.custom['700a'],
      },
    },
  },
};

export const WIDGET_DARK: WidgetPalette = {
  background: COLORS_DARK.neutral[800],
  borderColor: COLORS_DARK.neutral.T16,
  boxShadow: '0px 6px 20px rgba(42, 63, 117, 0.06)',
  table: {
    totalBackground: '#414247',
    totalBorderColor: '#F0F1F3',
    navActionColor: '#ffffff',
    navActionDisabledColor: '#ffffff',
    navActionOpacity: '0.8',
    navActionDisabledOpacity: '0.3',
    progressBarBackground: '#095A7E',
    header: {
      color: COLORS_DARK.neutral[400],
    },
    body: {
      color: COLORS_DARK.neutral.white,
    },
  },
  liner: {
    labelValueColor: '#ffffff',
    legendBackground: '#2C2D33',
  },
  tooltipMain: {
    background: '#44454A',
    color: '#FFFFFF',
  },
  tooltipSecond: {
    background: '#44454A',
    color: '#FFFFFF',
  },
  bigNumber: {
    title: {
      color: COLORS_LIGHT.neutral[200],
    },
    value: {
      border: COLORS_DARK.neutral.T24,
      color0: COLORS_LIGHT.neutral[400],
      color2: COLORS_LIGHT.warning[300],
      color3: COLORS_DARK.alert[400],
      color4: COLORS_DARK.alert[300],
    },
  },
  gauge: {
    peak: { background: COLORS_DARK.neutral.T4, color: COLORS_DARK.neutral[300] },
    button: { background: COLORS_DARK.neutral.T4 },
  },
  pie: {
    label: {
      color: COLORS_DARK.neutral.black,
    },
    cell: {
      stroke: COLORS_DARK.neutral[800],
    },
  },
  alert: {
    badges: {
      [AlertWidgetSeverity.Critical]: {
        color: COLORS_DARK.custom['400'],
        background: COLORS_DARK.custom['400a'],
      },
      [AlertWidgetSeverity.Error]: {
        color: COLORS_DARK.custom['200'],
        background: COLORS_DARK.custom['200a'],
      },
      [AlertWidgetSeverity.Warning]: {
        color: COLORS_DARK.custom['50'],
        background: COLORS_DARK.custom['50a'],
      },
    },
    clearedBadge: {
      [AlertWidgetSeverity.Critical]: {
        color: COLORS_DARK.custom['700'],
        background: COLORS_DARK.custom['700a'],
      },
      [AlertWidgetSeverity.Error]: {
        color: COLORS_DARK.custom['700'],
        background: COLORS_DARK.custom['700a'],
      },
      [AlertWidgetSeverity.Warning]: {
        color: COLORS_DARK.custom['700'],
        background: COLORS_DARK.custom['700a'],
      },
    },
  },
};
