import { Language as InternetIcon, Lock as PrivateIcon, RoomSharp as InterRegionIcon } from '@mui/icons-material';
import { ThemeIcons } from '@types';
import { Environment } from 'app/enums';
import { Provider } from 'app/enums/provider';
import AwsIconDark from 'assets/images/aws-logo-dark.svg';
import AwsIconLight from 'assets/images/aws-logo-light.svg';
import AzureIcon from 'assets/images/azure.svg';
import AzureLogoIcon from 'assets/images/azure-logo.svg';
import FactionInternalHeaderLogo from 'assets/images/faction_internal.png';
import FactionFullDarkLogo from 'assets/images/Faction-Logo-Dark-H-RGB.svg';
import FactionHeaderLogo from 'assets/images/Faction-Logo-Light-H-RGB.svg';
import FixLogoDark from 'assets/images/Faction-Monogram-Dark-RGB.svg';
import FixLogoLight from 'assets/images/Faction-Monogram-Light-RGB.svg';
import GcpIconDark from 'assets/images/g-cloud-dark.svg';
import GcpIconLight from 'assets/images/g-cloud-light.svg';
import GcpLogoIcon from 'assets/images/gcp-logo.svg';
import Loader from 'assets/images/logo-animated.svg';
import OracleLogoIcon from 'assets/images/oracle-logo.svg';
import OracleIcon from 'assets/images/oracle-seeklogo.svg';
import env from 'config/env.json';

export const OEM_ICONS_DARK: ThemeIcons = {
  providers: {
    main: {
      [Provider.Aws]: AwsIconDark,
      [Provider.Azure]: AzureIcon,
      [Provider.Gcp]: GcpIconDark,
      [Provider.Oci]: OracleIcon,
      [Provider.Internet]: InternetIcon,
      [Provider.InterRegion]: InterRegionIcon,
      [Provider.Private]: PrivateIcon,
    },
    short: {
      [Provider.Aws]: AwsIconDark,
      [Provider.Azure]: AzureLogoIcon,
      [Provider.Gcp]: GcpLogoIcon,
      [Provider.Oci]: OracleLogoIcon,
      [Provider.Internet]: InternetIcon,
      [Provider.InterRegion]: InterRegionIcon,
      [Provider.Private]: PrivateIcon,
    },
  },
  general: {
    HeaderLogo: [Environment.Production, Environment.Staging].includes(
      env.REACT_APP_FRONTEND_ENVIRONMENT as Environment
    )
      ? FactionHeaderLogo
      : FactionInternalHeaderLogo,
    Fix: FixLogoDark,
    Loader: Loader,
    fullLogo: FactionHeaderLogo,
  },
};

export const OEM_ICONS_LIGHT: ThemeIcons = {
  providers: {
    main: {
      [Provider.Aws]: AwsIconLight,
      [Provider.Azure]: AzureIcon,
      [Provider.Gcp]: GcpIconLight,
      [Provider.Oci]: OracleIcon,
      [Provider.Internet]: InternetIcon,
      [Provider.InterRegion]: InterRegionIcon,
      [Provider.Private]: PrivateIcon,
    },
    short: {
      [Provider.Aws]: AwsIconLight,
      [Provider.Azure]: AzureLogoIcon,
      [Provider.Gcp]: GcpLogoIcon,
      [Provider.Oci]: OracleLogoIcon,
      [Provider.Internet]: InternetIcon,
      [Provider.InterRegion]: InterRegionIcon,
      [Provider.Private]: PrivateIcon,
    },
  },
  general: {
    HeaderLogo: [Environment.Production, Environment.Staging].includes(
      env.REACT_APP_FRONTEND_ENVIRONMENT as Environment
    )
      ? FactionHeaderLogo
      : FactionInternalHeaderLogo,
    Fix: FixLogoLight,
    Loader: Loader,
    fullLogo: FactionFullDarkLogo,
  },
};
