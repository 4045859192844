import { AddAPhoto } from '@mui/icons-material';
import { Box, IconButton, Input, InputBaseComponentProps } from '@mui/material';
import { UserEntity } from 'app/interfaces/entity/user';
import { UserAvatar } from 'modules/admin/user-management/components/UserAvatar';
import { AVATAR_RESTRICTIONS } from 'modules/profile/constants';
import React, { ChangeEvent, forwardRef, useCallback, useRef, useState } from 'react';

import { useStyles } from './UserAvatarUpload.styles';

interface UserAvatarUploadProps extends Omit<InputBaseComponentProps, 'defaultValue'> {
  user: UserEntity;
  onChange?: (file: File) => void;
  disabled?: boolean;
}

export const UserAvatarUpload = forwardRef<HTMLInputElement, UserAvatarUploadProps>(
  ({ user, onChange, disabled = false, ...rest }, ref) => {
    const { classes } = useStyles();
    const inputFile = useRef<HTMLInputElement>(null);
    const [photoUrl, setPhotoUrl] = useState<string | undefined>(() => user.photoUrl);

    const onUpload = useCallback(() => {
      if (inputFile?.current) {
        inputFile.current.click();
      }
    }, []);

    const onAvatarChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (!user || !file?.name) {
          return;
        }

        if (AVATAR_RESTRICTIONS.types.includes(file?.type)) {
          setPhotoUrl(URL.createObjectURL(file));
        }

        onChange?.(file);
      },
      [onChange, user]
    );

    if (!user) {
      return null;
    }

    return (
      <Box ref={ref}>
        <label className={classes.label}>
          <Input
            type="file"
            ref={inputFile}
            inputProps={{ accept: AVATAR_RESTRICTIONS.inputTypes, ...rest }}
            className={classes.input}
            onChange={onAvatarChange}
            disabled={disabled}
            data-testid="UserAvatarUpload"
          />
          <Box className={classes.avatarContainer}>
            <Box className="add-button">
              <IconButton
                data-testid="avatarFileChooser"
                color="primary"
                className={classes.actionButton}
                onClick={onUpload}
                disabled={disabled}
              >
                <AddAPhoto sx={{ marginTop: '-16px' }} fontSize="large" />
              </IconButton>
            </Box>
            <Box className={classes.avatar}>
              <UserAvatar className="userAvatar" user={{ ...user, photoUrl }} />
            </Box>
          </Box>
        </label>
      </Box>
    );
  }
);
