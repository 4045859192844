import { EntityStoreName, StoreName } from 'app/enums/store';
import { ThemeMode } from 'app/enums/theme';
import { Lang } from 'app/i18n';
import { CustomDateRange } from 'app/interfaces/entity/dashboard';
import { WidgetDateRangeEntity } from 'app/interfaces/entity/dashboard/date-range.entity';
import { OrganizationEntity } from 'app/interfaces/entity/organization';
import { UserEntity } from 'app/interfaces/entity/user';
import { extractHighestRole } from 'modules/admin/user-management/utils';
import { entityAdapter } from 'modules/organization/store/adapter';
import { OrganizationsState } from 'modules/organization/store/interfaces/organizations-state';
import { OutputSelector, Selector } from 'reselect';

import { createAppSelector } from '../createAppSelector';
import { AppState } from '../store';
import { SessionState } from './interfaces/session-state';

const selectSessionState = (state: AppState): SessionState => state[StoreName.Session];

const token: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.token ?? ''
);

const isAuthorized: OutputSelector<[Selector<AppState, unknown>]> = createAppSelector(
  token,
  (value: string) => value.length > 0
);

const IsOrgIdFromMenu: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.IsOrgIdFromMenu ?? false
);

const userProfile: OutputSelector<[Selector<AppState, SessionState>], UserEntity> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.userProfile as UserEntity
);

const userRoles: OutputSelector<[Selector<AppState, SessionState>], string[]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.userProfile?.roles ?? []
);

const userHighestRole = createAppSelector(userRoles, (roles: string[]) => extractHighestRole(roles));

const isSsoUser = createAppSelector(userProfile, (user: UserEntity): boolean => user?.idpId != null);

const isUserMfaEnabled = createAppSelector(userProfile, (user: UserEntity) => user?.mfaEnabled);

const isMfaConfigurationRequired = createAppSelector(
  userProfile,
  isSsoUser,
  isUserMfaEnabled,
  (state, required, mfaEnabledToken): boolean => !required && !mfaEnabledToken
);

const organizationId = createAppSelector(
  selectSessionState,
  (state: AppState): OrganizationsState => state.organizations,
  (session, organizations) =>
    session?.organizationId && organizations.ids.includes(session?.organizationId) ? session.organizationId : ''
);

//TODO: Investigate if function works
const userOrganizationId = createAppSelector(
  userProfile,
  entityAdapter.getSelectors<AppState>((state) => state[EntityStoreName.Organizations]).selectAll,
  (user, organizations) => {
    if (user?.population) {
      const organizationEntity = organizations.find(
        (organization: OrganizationEntity) => organization.pingPopulationId === user.population
      );
      return organizationEntity?.identifier;
    }
  }
);
const ticketId: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.ticketDetailsId ?? ''
);

const locationId = createAppSelector(
  organizationId,
  (state: AppState): Record<string, string> | undefined => state.session.organizationLocations,
  (orgId, organizationLocations) => organizationLocations?.[orgId] ?? ''
);

const themeMode: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.themeMode ?? ThemeMode.Light
);

const isNewApiMode: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.isNewApiMode ?? true
);

const language: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.language ?? Lang.En
);

const redirectAfterLoginPath: OutputSelector<[Selector<AppState, SessionState>]> = createAppSelector(
  selectSessionState,
  (session: SessionState) => session?.redirectAfterLoginPath
);

const monitoringDashboardId = createAppSelector(
  organizationId,
  (state: AppState): Record<string, string> | undefined => state.session.organizationDashboards,
  (orgId, organizationDashboards) => organizationDashboards?.[orgId] ?? ''
);

const monitoringPerformanceDashboardId = createAppSelector(
  selectSessionState,
  (session: SessionState): string | undefined => session.performanceDashboardId
);

const monitoringPerformanceSelectedDashboardDateRange = createAppSelector(
  selectSessionState,
  (session: SessionState): WidgetDateRangeEntity | undefined => session.performanceDashboardDateRange
);

const monitoringPerformanceCustomDashboardDateRange = createAppSelector(
  selectSessionState,
  (session: SessionState): CustomDateRange | undefined => session.performanceDashboardCustomDateRange
);
const monitoringPerformanceDashboardCrossCloudId = createAppSelector(
  selectSessionState,
  (session: SessionState): string | undefined => session.performanceCrossCloudDashboardId
);

const monitoringPerformanceDashboardLocationId = createAppSelector(
  selectSessionState,
  (session: SessionState): string | undefined => session.performanceDashboardLocationId
);

const clientSecret = createAppSelector(
  selectSessionState,
  (session: SessionState): string | undefined => session.clientSecret
);

export const sessionSelector = {
  token,
  ticketId,
  isAuthorized,
  userProfile,
  userRoles,
  userHighestRole,
  organizationId,
  locationId,
  themeMode,
  isNewApiMode,
  redirectAfterLoginPath,
  monitoringDashboardId,
  monitoringPerformanceDashboardId,
  monitoringPerformanceDashboardLocationId,
  monitoringPerformanceDashboardCrossCloudId,
  monitoringPerformanceSelectedDashboardDateRange,
  monitoringPerformanceCustomDashboardDateRange,
  isUserMfaEnabled,
  isSsoUser,
  isMfaConfigurationRequired,
  language,
  clientSecret,
  userOrganizationId,
  IsOrgIdFromMenu,
};
