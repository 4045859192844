export enum ChangeRequestsGridField {
  Provider = 'provider',
  Bandwidth = 'bandwidth',
  Region = 'region',
  Location = 'factionLocationId',
  CreatedDate = 'createdDate',
  ResolvedDate = 'resolvedDate',
  Status = 'status',
  RevokeTicketNumber = 'revokeTicketNumber',
}
