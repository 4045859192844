import { Theme } from '@mui/material';
import { FontWeight } from 'app/enums/theme';
import { TYPOGRAPHY } from 'app/theme/constants';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  hint: {
    display: 'flex',
    color: theme.palette.text.secondary,
    alignSelf: 'flex-start',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hintIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  hintTitle: {
    ...TYPOGRAPHY.Pagination,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1.5),
    position: 'relative',

    '& .MuiList-root': {
      paddingTop: 0,
    },

    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3),

      '& .MuiSvgIcon-root': {
        height: theme.spacing(2),
        width: theme.spacing(2),
        color: theme.palette.text.secondary,
      },
    },
    '& .MuiListItem-root': {
      '&.active': {
        '& .MuiListItemIcon-root': {
          '& .MuiSvgIcon-root': {
            color: 'green',
          },
        },
      },
      padding: theme.spacing(0.25, 0),
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: theme.spacing(1.375),
        fontWeight: FontWeight.Regular,
        lineHeight: theme.spacing(1.75),
      },
    },
  },
  title: {
    display: 'flex',
    fontSize: theme.spacing(1.625),
    fontWeight: FontWeight.Regular,
    lineHeight: theme.spacing(3.5),
  },
}));
