import { CcvEntity } from 'app/interfaces/entity/ccv';
import { CcvHealthStatus } from 'modules/cloud-services/enums';

import { CcvDto } from './interfaces/dto';

export const dtoToEntityMapper = (data: CcvDto): CcvEntity => {
  return {
    fixId: 'fix',
    bandwidth: data.sloThroughput,
    identifier: data.identifier,
    name: data.name,
    v3Product: data.type,
    tier: data.tier,
    status: CcvHealthStatus.Healthy,
    factionLocationId: data.factionLocationId,
    totalSizeInBytes: data.totalSizeInBytes,
    usedSizeInBytes: data.usedSizeInBytes,
    createdDate: data.createdAt,
    iops: data.iops,
  };
};
