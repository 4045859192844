import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { entityStoreStatus } from 'app/utils/store';
import { entityAdapter } from 'modules/change-requests/store/adapter';
import { ChageRequestsState } from 'modules/change-requests/store/interfaces';

export const getChangeRequestsList = createAsyncThunk('changeRequest/getList', async () => {
  // TODO: update to the real API call when backend will be done
  return [];
});

export const registerReducers = (builder: ActionReducerMapBuilder<ChageRequestsState>): void => {
  builder
    .addCase(getChangeRequestsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getChangeRequestsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getChangeRequestsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
