import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { CcvEntity } from 'app/interfaces/entity/ccv';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { entityStoreStatus } from 'app/utils/store';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper } from './dto-mappers';
import { CcvsState } from './interfaces';
import { CcvDto } from './interfaces/dto';

export const getCcvList = createAsyncThunk(`${EntityStoreName.Ccvs}/getList`, (_, { signal }): Promise<CcvEntity[]> => {
  return apiRequestCall<CcvDto[]>({
    params: {
      method: 'GET',
      apiVersion: ApiVersion.V1,
      resource: ApiResource.Ccvs,
      signal,
    },
  }).then((data) => data.map(dtoToEntityMapper));
});

export const clearAllReducer = (state: CcvsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<CcvsState>): void => {
  builder
    .addCase(getCcvList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCcvList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCcvList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
