import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntityStoreRequestType } from 'app/enums/store';
import { LocationEntity } from 'app/interfaces/entity/location';
import { LocationsState } from 'app/store/locations/interfaces/locations-state';

export const entityAdapter = createEntityAdapter({
  selectId: (data: LocationEntity) => data.identifier,
});

export const initialState: LocationsState = entityAdapter.getInitialState({
  status: {
    [EntityStoreRequestType.Creating]: [],
    [EntityStoreRequestType.Updating]: [],
    [EntityStoreRequestType.Loading]: [],
    [EntityStoreRequestType.Deleting]: [],
  },
});
