import { Route } from 'app/enums';
import { LangNameSpace } from 'app/i18n';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useStyles } from './HeaderLogo.styles';

interface HeaderLogoProps {
  src: string;
}

export const HeaderLogo: FC<React.PropsWithChildren<HeaderLogoProps>> = ({ src }) => {
  const { classes } = useStyles();
  const { t } = useTranslation(LangNameSpace.App);

  return (
    <Link to={Route.Root}>
      <img className={classes.root} src={src} alt={t('components.Header.logoAlt')} data-testid="faction-logo" />
    </Link>
  );
};
