import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { changePasswordValidationSchema } from 'modules/profile/hooks/validation-schemas/change-password-form';
import { ChangePasswordFormFields } from 'modules/profile/interfaces';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormWatchers } from 'shared/hooks/use-form-watchers';

export const useChangePasswordForm = () => {
  const resolver = yupResolver(changePasswordValidationSchema);

  const form = useForm<ChangePasswordFormFields>({
    mode: 'onTouched',
    resolver: resolver,
    reValidateMode: 'onChange',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  useFormWatchers(useMemo(() => [form], [form]));

  return form;
};
