import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ width?: number }>()((theme: Theme, { width }) => ({
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: width ? theme.spacing(width) : 'auto',

    '&:before, &:after': {
      display: 'block',
      content: '""',
      width: 0,
    },
  },
}));
