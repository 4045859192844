export const APP = {
  theme: {
    light: 'Light',
    system: 'System',
    dark: 'Dark',
  },
  components: {
    Header: {
      logoAlt: 'Faction Inc.',
      orgMenu: {
        title: 'Organization',
        search: {
          placeholder: 'Search...',
          noMatchFound: 'No matched results',
        },
      },
    },
    NotFound: {
      title: 'Page not found',
      btnText: 'Go Home',
      text: 'The requested URL <i>{{value}}</i> was not found on this server.',
    },
    SentryFallback: {
      title: 'Oops...!',
      errors: {
        default: 'Something went wrong...',
        update: 'This application has been updated, please refresh your browser to see the latest content.',
      },
      btn: {
        reload: 'Reload',
        goHome: 'Go Home',
      },
    },
  },
  constants: {
    CHANGE_REQUEST_STATUSES: {
      new: 'New',
      closed: 'Closed',
    },
    APP_MENU: {
      service: 'Services',
      tickets: 'Tickets',
      ticketsList: 'List',
      monitoring: 'Monitoring',
      monitoringServicesDashboards: 'Services Dashboards',
      monitoringSitePerformance: 'Site Performance',
      admin: 'Admin',
      adminUserManagement: 'User Management',
      adminSSOManagement: 'SSO Management',
      adminAPICredentials: 'API Credentials',
    },
    PROVIDER_CONFIG: {
      bandwidth: 'Bandwidth',
      connectionTypeName: {
        cloud: 'Cloud Connection',
        internet: 'Internet Connection',
        interRegion: 'Inter Region Connection',
        private: 'Private Connection',
      },
      step2subTitle: {
        interRegion:
          'Inter-Region Connection allows for connections to be established between FIX attached resources at different locations.',
        private:
          'Private Connection allows for direct connections to be established between FIX attached resources and co-located private resources.',
        internet:
          'IPSEC / VPN Connection allows for connections to be established between FIX attached resources and outside resources through the public internet.',
      },
      step3subTitle: {
        interRegion:
          'By confirming you are requesting the following connection be established to the FIX. A ticket will be created for the request, and additional information requests will be completed through the ticket system.',
        private:
          'By confirming you are requesting the following connection be established to the FIX. A ticket will be created for the request, and additional information requests will be completed through the ticket system. Private Connections require a Letter of Authorization - Channel Facility Assignment to be establishing the connection.',
        internet:
          'By confirming you are requesting the following connection be established to the FIX. A ticket will be created for the request, and additional information requests will be completed through the ticket system.',
      },
    },
  },
  enums: {
    AttachmentValidationMessage: {
      MimeType: '${path} file(s) type is not allowed',
      FileSize: '${path} file(s) size cannot exceed ${size}',
    },
    ModalTitle: {
      Documentation: 'Documentation',
      Profile: 'Edit Profile',
      ProfileChangePassword: 'Change Password',
      ChangeRequestView: 'Change Request Details',
      MfaCreate: 'Setup MFA',
      UserEdit: 'Edit User',
      UserCreate: 'Add New User',
      UserView: 'View User',
      UserChangePassword: 'Change Password',
      EditSSO: 'Edit SSO Group',
      SsoGroupMappingCreate: 'Set Up SSO Connection',
    },
    ConfirmationModalTitle: {
      UserDeleteConfirm: 'Delete User',
      CloudConnectionDelete: 'Delete Cloud Connection',
      SsoGroupMappingDeleteConfirm: 'Delete SSO Group',
      CloudConnectionDeleteConfirm: 'Delete Cloud Connection',
      UserMfaDeleteConfirm: 'Delete MFA',
      UserMfaCreateConfirm: 'Confirm MFA',
      SsoDeleteConfirm: 'Delete SSO Connection',
    },
    WizardTitle: {
      AttachToCloud: 'New Connection',
      EditSSO: 'Edit SSO Connection',
      SetupSSO: 'Set Up SSO Connection',
      SetupSSOGroup: 'Set Up SSO Group',
      TicketCreate: 'Create New Ticket',
      ChangeConnection: 'Change Connection',
    },
  },
  hooks: {
    usePageTitle: {
      currentStep: 'Step {{page}}',
    },
  },
  providers: {
    SnackbarProvider: {
      showMore: 'Show more...',
      showLess: 'Show less',
    },
  },
  services: {
    api: {
      constants: {
        errorMessage: 'Something went wrong',
      },
    },
  },
};
