import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: theme.spacing(0, 1),

      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      paddingLeft: theme.spacing(2.25),
      '&.MuiInputLabel-shrink': {
        paddingLeft: theme.spacing(1),
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: theme.spacing(1.75),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.secondary,
      fontSize: theme.spacing(2.75),
    },
    '& .MuiInputAdornment-positionStart': {
      margin: 0,
    },
  },
  closeIcon: {
    width: theme.spacing(2.5),

    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(2.5),
    },
  },
}));
