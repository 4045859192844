import { ActionReducerMapBuilder, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { CloudConnectionEntity } from 'app/interfaces/entity/cloud-connection';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { ErrorResponse } from 'app/services/api/interfaces';
import { entityStoreStatus } from 'app/utils/store';
import { AxiosError } from 'axios';
import { omit } from 'lodash';

import { entityAdapter } from './adapter';
import {
  createRequestToDtoMapper,
  dtoToEntityMapper,
  updateRequestToDtoMapper,
  updateResponseToDtoMapper,
} from './dto-mapers';
import {
  CloudConnectionChangeConnectionRequest,
  CloudConnectionDeleteRequest,
  CloudConnectionsState,
  ConnectionCreateRequest,
} from './interfaces';
import { CloudConnectionChangeConnectionDto, CloudConnectionCreateDto, CloudConnectionDto } from './interfaces/dto';

export const getCloudConnectionList = createAsyncThunk<
  CloudConnectionEntity[],
  { skipShowErrorInSnackbar?: boolean } | undefined
>(
  `${EntityStoreName.CloudConnections}/getList`,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  ({ skipShowErrorInSnackbar } = {}, { signal }): Promise<CloudConnectionEntity[]> => {
    return apiRequestCall<CloudConnectionDto[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.CloudConnections,
        signal,
        skipShowErrorInSnackbar,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.CloudConnections]],
    }).then((data) =>
      data
        .filter((item) => item.active)
        .map((item) => dtoToEntityMapper(item))
        .filter((item) => item?.provider)
    );
  }
);

export const getCloudConnectionById = createAsyncThunk<
  CloudConnectionEntity,
  { skipShowErrorInSnackbar?: boolean; identifier: Identifier }
>(
  `${EntityStoreName.CloudConnections}/getById`,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  ({ skipShowErrorInSnackbar, identifier }, { signal }): Promise<CloudConnectionEntity> => {
    return apiRequestCall<CloudConnectionDto>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.CloudConnections,
        urlPrefix: `${String(identifier)}`,
        signal,
        skipShowErrorInSnackbar,
        cache: false,
      },
      invalidationKeys: [[identifier, ApiResource.CloudConnections]],
    }).then((data) => dtoToEntityMapper(data));
  }
);

export const createCloudConnection = createAsyncThunk(
  `${EntityStoreName.CloudConnections}/create`,
  ({ successRequestMessage, ...request }: ConnectionCreateRequest): Promise<CloudConnectionEntity> => {
    const data = createRequestToDtoMapper(request);
    return apiRequestCall<CloudConnectionDto, CloudConnectionCreateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.CloudConnections,
        data,
        skipShowErrorInSnackbar: true,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.CloudConnections]],
      successRequestMessage,
    }).then((result) => ({ ...dtoToEntityMapper(result), active: result?.active }));
  },
  {
    serializeError: (e) => {
      return (e as AxiosError<ErrorResponse>)?.response?.data || (e as SerializedError);
    },
  }
);

export const deleteCloudConnection = createAsyncThunk(
  `${EntityStoreName.CloudConnections}/delete`,
  async ({ identifier, successRequestMessage }: CloudConnectionDeleteRequest): Promise<Identifier> => {
    return apiRequestCall<null>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.CloudConnections,
        urlPrefix: String(identifier),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.CloudConnections]],
      successRequestMessage,
    }).then(() => identifier);
  }
);

export const changeConnectionCloudConnection = createAsyncThunk(
  `${EntityStoreName.CloudConnections}/changeConnection`,
  async ({
    successRequestMessage,
    ...request
  }: CloudConnectionChangeConnectionRequest): Promise<CloudConnectionEntity> => {
    return apiRequestCall<CloudConnectionChangeConnectionDto, { bandwidth?: number; crossCloud?: boolean }>({
      params: {
        method: 'PATCH',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.CloudConnections,
        urlPrefix: String(request.identifier),
        data: updateRequestToDtoMapper(request),
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.CloudConnections]],
      successRequestMessage,
    }).then((result) => updateResponseToDtoMapper(result));
  }
);

export const clearAllReducer = (state: CloudConnectionsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<CloudConnectionsState>): void => {
  builder
    .addCase(getCloudConnectionList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCloudConnectionList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCloudConnectionList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCloudConnectionById.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCloudConnectionById.fulfilled, (state, action) => {
      entityAdapter.setOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getCloudConnectionById.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createCloudConnection.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createCloudConnection.fulfilled, (state, action) => {
      if (action.payload?.active) {
        entityAdapter.addOne(state, omit(action.payload, ['active']) as CloudConnectionEntity);
      }
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createCloudConnection.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(changeConnectionCloudConnection.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(changeConnectionCloudConnection.fulfilled, (state, action) => {
      if (action.payload?.active) {
        entityAdapter.upsertOne(state, omit(action.payload, ['active']) as CloudConnectionEntity);
      } else {
        entityAdapter.removeOne(state, action.payload.identifier);
      }
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(changeConnectionCloudConnection.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(deleteCloudConnection.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteCloudConnection.fulfilled, (state, action) => {
      entityAdapter.removeOne(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteCloudConnection.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    });
};
