import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  modal: {},

  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: theme.spacing(-1),
    minHeight: theme.spacing(48),
  },

  avatar: {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    margin: theme.spacing('auto', 2, 2),
    fontSize: theme.spacing(5),
    color: theme.palette.grey[100],
  },

  readonlyBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5),
  },

  profileFormContainer: {
    width: '100%',
  },

  detailsContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },

  roles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  roleChip: {
    margin: theme.spacing(1, 1, 0, 0),
    fontSize: theme.spacing(1.5),

    '&:last-child': {
      marginRight: theme.spacing(0),
    },
  },

  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& .MuiButtonBase-root': {
      '&:only-of-type': {
        flex: 'initial',
      },
      display: 'flex',
      flex: 'initial',
    },
  },

  actionButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },

  editProfileLink: {
    '&[disabled]': {
      color: theme.palette.text.disabled,
      cursor: 'pointer',
      textDecoration: 'none',
      pointerEvents: 'none',
    },
  },

  saveBtn: {},
}));
