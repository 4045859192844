import { CustomColors } from '@types';

const COLORS: CustomColors<false> = {
  primary: {
    800: '#012b3b',
    700: '#13d54',
    600: '#095a7e',
    500: '#0B6D96',
    400: '#21BDEF',
    300: '#39CFF1',
    200: '#7FD8FF',
    100: '#07ACED',
    T48: 'rgba(4, 98, 135, 0.48)',
    T40: 'rgba(4, 98, 135, 0.40)',
    T32: 'rgba(4, 98, 135, 0.32)',
    T24: 'rgba(4, 98, 135, 0.24)',
    T16: 'rgba(4, 98, 135, 0.16)',
    T12: 'rgba(4, 98, 135, 0.12)',
    T8: 'rgba(4, 98, 135, 0.8)',
  },
  brand: {
    orange: '#F15A29',
    gray: '#636363',
  },
  neutral: {
    black: '#111114',
    white: '#FFFFFF',
    900: '#1B1B1D',
    800: '#242427',
    700: '#2E2E30',
    600: '#414143',
    500: '#5D5D5F',
    400: '#838385',
    300: '#A9A9AA',
    200: '#CFCFD0',
    100: '#E2E2E3',
  },
  success: {},
  warning: {},
  alert: {},
  info: {},
  custom: {},
};
export const COLORS_LIGHT: CustomColors<true> = {
  ...{
    ...COLORS,
    warning: { 400: '#C62828', 300: '#E53935', 200: '#FFCDD2', 100: '#FFEBEE' },
    alert: { 400: '#FFA000', 300: '#FFC107', 200: '#FFECB3', 100: '#FFF8E1' },
    info: { 400: '#1561AD', 300: '#1976D2', 200: '#BAD6F2', 100: '#E3EEF9' },
    success: {
      400: '#057A49',
      300: '#079D5E',
      200: '#B5E2CF',
      100: '#E1F3EB',
    },
    neutral: {
      ...COLORS.neutral,
      T48: 'rgba(17, 17, 20, 0.48)',
      T40: 'rgba(17, 17, 20, 0.4)',
      T32: 'rgba(17, 17, 20, 0.32)',
      T24: 'rgba(17, 17, 20, 0.24)',
      T16: 'rgba(17, 17, 20, 0.16)',
      T12: 'rgba(17, 17, 20, 0.12)',
      T8: 'rgba(17, 17, 20, 0.08)',
      T4: 'rgba(17, 17, 20, 0.04)',
    },
    custom: {
      900: '#42A5F5',
      800: '#EF5350',
      700: '#4CAF50',
      600: '#37E4EF',
      500: '#1565C0',
      400: '#C62828',
      300: '#1B5E20',
      200: '#ED6C02',
      100: '#BA68C8',
      50: '#FFBF42',
      25: '#764A21',
      '900a': 'rgba(66, 165, 245, 0.1)',
      '800a': 'rgba(239, 83, 80, 0.1)',
      '700a': 'rgba(76, 175, 80, 0.1)',
      '600a': 'rgba(55, 228, 239, 0.1)',
      '500a': 'rgba(21, 101, 192, 0.1)',
      '400a': 'rgba(198, 40, 40, 0.1)',
      '300a': 'rgba(27, 94, 32, 0.1)',
      '200a': 'rgba(237, 108, 2, 0.1)',
      '100a': 'rgba(186, 104, 200, 0.1)',
      '50a': 'rgba(255, 191, 66, 0.1)',
      '25a': 'rgba(118, 74, 33, 0.1)',
    },
  },
};

export const COLORS_DARK: CustomColors<true> = {
  ...{
    ...COLORS,
    warning: { 400: '#7A1919', 300: '#8C2320', 200: '#571111', 100: '#400D0D' },
    alert: { 400: '#996000', 300: '#997404', 200: '#6B5103', 100: '#4A3802' },
    info: { 400: '#0F457A', 300: '#145DA6', 200: '#0E4275', 100: '#0A2F54' },
    success: {
      400: '#03472B',
      300: '#056E42',
      200: '#034D2E',
      100: '#023821',
    },
    neutral: {
      ...COLORS.neutral,
      T48: 'rgba(255, 255, 255, 0.48)',
      T40: 'rgba(255, 255, 255, 0.40)',
      T32: 'rgba(255, 255, 255, 0.32)',
      T24: 'rgba(255, 255, 255, 0.24)',
      T16: 'rgba(255, 255, 255, 0.16)',
      T12: 'rgba(255, 255, 255, 0.12)',
      T8: 'rgba(255, 255, 255, 0.08)',
      T4: 'rgba(255, 255, 255, 0.04)',
    },
    custom: {
      900: '#10B5FF',
      800: '#EF5350',
      700: '#94DD5B',
      600: '#37EFD9',
      500: '#779DFF',
      400: '#E22E2E',
      300: '#1CB75A',
      200: '#ED6C02',
      100: '#E242FD',
      50: '#FFBF42',
      25: '#D39D76',
      '900a': 'rgba(16, 181, 255, 0.2)',
      '800a': 'rgba(239, 83, 80, 0.2)',
      '700a': 'rgba(148, 221, 91, 0.1)',
      '600a': 'rgba(55, 239, 217, 0.1)',
      '500a': 'rgba(119, 157, 255, 0.2)',
      '400a': 'rgba(226, 46, 46, 0.2)',
      '300a': 'rgba(28, 183, 90, 0.15)',
      '200a': 'rgba(237, 108, 2, 0.1)',
      '100a': 'rgba(226, 66, 253, 0.15)',
      '50a': 'rgba(255, 191, 66, 0.1)',
      '25a': 'rgba(211, 157, 118, 0.15)',
    },
  },
};
