import { EntityStoreName } from 'app/enums/store';
import { AppState, createAppSelector } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';

import { entityAdapter } from './adapter';

const selectAll = entityAdapter.getSelectors<AppState>((state) => state[EntityStoreName.Ccvs]).selectAll;

const selectAllByLocation = createAppSelector(selectAll, sessionSelector?.locationId, (ccvList, locationId) => {
  if (locationId === undefined) {
    return [];
  }

  return ccvList.filter((ccv) => ccv?.factionLocationId === locationId);
});

const selectTotalBandwidthByLocation = createAppSelector(selectAllByLocation, (ccvList) =>
  ccvList.reduce((sum, ccv) => sum + ccv.bandwidth, 0)
);

const selectIsEmpty = createAppSelector(
  (state: AppState) => state[EntityStoreName.Ccvs],
  (ccvList) => ccvList.ids !== null && ccvList.ids.length === 0
);

export const ccvsSelector = {
  all: selectAll,
  allByLocation: selectAllByLocation,
  totalBandwidthByLocation: selectTotalBandwidthByLocation,
  isEmpty: selectIsEmpty,
};
