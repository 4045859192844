import { AlertsGridField, AlertWidgetSeverity } from 'modules/monitoring/enums';

const FILTER_MAPPING: Record<string, string> = {
  group: 'monitorObjectGroups', // monitorObjectGroups
  cleared: 'cleared', // no = empty, all = *
  acked: 'acked', // acked all = empty, yes = true
  sdted: 'sdted', // sdted all = empty, no = false
  keyword: '_all', // example = "*qwe*"
  anomaly: 'anomaly',
  host: AlertsGridField.ResourceWebsite, // monitorObjectName
  dataSource: AlertsGridField.LogicModule, // resourceTemplateName
  instance: AlertsGridField.Instance, // instanceName
  dataPoint: AlertsGridField.Datapoint, // dataPointName
  severity: AlertsGridField.Severity, // severity - convert string to number
  rule: AlertsGridField.AlertRule, // rule
  chain: AlertsGridField.EscalationChain,
  dependencyRole: AlertsGridField.DependencyRole, // dependencyRole
  dependencyRoutingState: AlertsGridField.RoutingState, // dependencyRoutingState
};

const severityMapping: Record<string, number> = {
  critical: AlertWidgetSeverity.Critical,
  error: AlertWidgetSeverity.Error,
  warning: AlertWidgetSeverity.Warning,
};

const encodeQueryParam = (p: string | number | boolean) => {
  const value = typeof p === 'string' ? p.replaceAll(/\+/g, ' ') : p;
  return encodeURIComponent(value);
};

export const makeAlertFilters = (filtersRaw?: Record<string, string>) => {
  const filter: string[] = [];

  const isFilter = (val: string | number) => !(val === '' || val === 'all');

  const strToBoolean = (val: string | number) => (val === 'yes' ? 'true' : val === 'no' ? 'false' : val);

  for (const filterName in filtersRaw) {
    let equalSign = ':';

    let filterValue: string | number = decodeURIComponent(filtersRaw[filterName]?.replaceAll(/\+/g, ' '));
    const filterKey: string = FILTER_MAPPING[filterName];

    if (filterName === 'cleared') {
      if (filterValue === 'all') {
        filterValue = '*';
      }
      if (filterValue === 'no') {
        filterValue = '';
      }
    }

    if (filterName === 'keyword' && filterValue !== '') {
      filterValue = `"*${filterValue}*"`;
      equalSign = '~';
    }

    if (filterName === 'severity' && filterValue !== '') {
      filterValue = severityMapping[filterValue];
    }

    if (filterKey && isFilter(filterValue)) {
      filter.push(`${filterKey}${equalSign}${encodeQueryParam(strToBoolean(filterValue))}`);
    }
  }

  return filter.join(',');
};
