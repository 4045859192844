import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { store } from 'app/store';
import { entityStoreStatus } from 'app/utils/store';
import { initialState } from 'modules/mfa/store/mfa/adapter';
import { dtoToEntityMapper, requestToDtoMapper } from 'modules/mfa/store/mfa/dto-mappers';
import { MfaDeleteRequest, MfaEntity, MfaRequest, MfaState } from 'modules/mfa/store/mfa/interfaces';
import { MfaCreateDto, MfaDto } from 'modules/mfa/store/mfa/interfaces/dto';

export const getMfa = createAsyncThunk(`${EntityStoreName.Mfa}/get`, (_, { signal }): Promise<MfaEntity> => {
  const userIdentifier = store?.getState()?.session?.userProfile?.identifier;

  return apiRequestCall<MfaDto>({
    params: {
      method: 'GET',
      apiVersion: ApiVersion.V1,
      resource: ApiResource.Users,
      urlPrefix: `${userIdentifier}/${ApiResource.Mfa}`,
      signal,
    },
  }).then(dtoToEntityMapper);
});

export const createMfa = createAsyncThunk(
  `${EntityStoreName.Mfa}/create`,
  async ({ successRequestMessage, ...payload }: MfaRequest): Promise<MfaEntity> => {
    const userIdentifier = store?.getState()?.session?.userProfile?.identifier as Identifier;

    return apiRequestCall<MfaDto, MfaCreateDto>({
      params: {
        method: 'POST',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        data: requestToDtoMapper(payload),
        urlPrefix: `${userIdentifier}/${ApiResource.Mfa}`,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users, userIdentifier, ApiResource.Mfa]],
      successRequestMessage,
    }).then(dtoToEntityMapper);
  }
);

export const updateMfa = createAsyncThunk(
  `${EntityStoreName.Mfa}/update`,
  ({ successRequestMessage, ...payload }: MfaRequest): Promise<MfaEntity> => {
    const userIdentifier = store?.getState()?.session?.userProfile?.identifier as Identifier;

    return apiRequestCall<MfaDto, Partial<MfaRequest>>({
      params: {
        method: 'PUT',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: `${userIdentifier}/${ApiResource.Mfa}`,
        data: requestToDtoMapper(payload),
      },
      successRequestMessage,
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users, userIdentifier, ApiResource.Mfa]],
    }).then(dtoToEntityMapper);
  }
);

export const deleteMfa = createAsyncThunk(
  `${EntityStoreName.Mfa}/delete`,
  async ({ successRequestMessage }: MfaDeleteRequest): Promise<Identifier> => {
    const userIdentifier = store?.getState()?.session?.userProfile?.identifier as Identifier;

    return apiRequestCall<Identifier, MfaDeleteRequest>({
      params: {
        method: 'DELETE',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Users,
        urlPrefix: `${userIdentifier}/${ApiResource.Mfa}`,
      },
      invalidationKeys: [[ApiVersion.V1, ApiResource.Users, userIdentifier, ApiResource.Mfa]],
      successRequestMessage,
    });
  }
);

export const clearReducer = (state: MfaState): void => {
  state.entity = initialState.entity;
  state.empty = true;
};

export const registerReducers = (builder: ActionReducerMapBuilder<MfaState>): void => {
  builder
    .addCase(getMfa.pending, (state, action) => {
      state.empty = true;
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getMfa.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.empty = false;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getMfa.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(createMfa.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createMfa.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.empty = false;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(createMfa.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Creating, action.meta.requestId);
    })
    .addCase(updateMfa.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateMfa.fulfilled, (state, action) => {
      state.entity = action.payload;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(updateMfa.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Updating, action.meta.requestId);
    })
    .addCase(deleteMfa.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteMfa.fulfilled, (state, action) => {
      state.entity = initialState.entity;
      state.empty = true;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    })
    .addCase(deleteMfa.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Deleting, action.meta.requestId);
    });
};
