import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '&.notistack-Snackbar': {
      width: theme.spacing(58.875),
    },
  },
  containerRoot: {
    '&.notistack-SnackbarContainer': {
      marginTop: theme.spacing(6.375),
    },
  },
}));
