import { ThemeMode } from 'app/enums/theme';
import { ApplicationConfiguration } from 'app/interfaces';
import { COLORS_DARK, COLORS_LIGHT } from 'app/theme/constants/colors';
import { COMPONENTS_DARK, COMPONENTS_LIGHT } from 'app/theme/constants/components-default';
import { STATUSES_COLOURS } from 'app/theme/constants/default-colours';
import { HEADER_DARK, HEADER_LIGHT } from 'app/theme/constants/header-palette-default';
import { OEM_ICONS_DARK, OEM_ICONS_LIGHT } from 'app/theme/constants/icons-oem-defaults';
import { MODAL_DARK, MODAL_LIGHT } from 'app/theme/constants/modal-default';
import {
  SANKEY_DARK,
  SANKEY_DETAIL_PANEL_DARK,
  SANKEY_DETAIL_PANEL_LIGHT,
  SANKEY_LIGHT,
} from 'app/theme/constants/sankey';
import { TYPOGRAPHY } from 'app/theme/constants/typography';
import { WIDGET_DARK, WIDGET_LIGHT } from 'app/theme/constants/widget-palette-default';
import { WIZARD_DARK, WIZARD_LIGHT } from 'app/theme/constants/wizard-palette-default';

export const DEFAULT_TITLE = 'Faction';
export const DEFAULT_APP_TITLE = 'Faction Portal';

export const OEM_DEFAULT: ApplicationConfiguration = {
  theme: {
    light: {
      custom: {
        headerHeight: 70,
        subHeaderHeight: 50,
        border: {
          default: '#E0E3E7',
          secondary: '#E0E3E7',
        },
        boxShadow: {
          menu: '0px 0px 20px rgba(34, 39, 57, 0.1)',
          dialog: `0px 4px 12px ${COLORS_LIGHT.neutral.T12}`,
        },
        colors: {
          label: COLORS_LIGHT.neutral[500],
          value: COLORS_LIGHT.neutral.black,
          success: '#079D5E',
          disabled: '#FFFFFF',
          avatar: '#E0E3E7',
          link: COLORS_LIGHT.primary[300],
          gridDisabledFilter: '#B0B1B1',
          modalHeader: '#EFEFF1',
          docLink: COLORS_LIGHT.primary[300],
          white: '#FFFFFF',
          progressBar: 'rgba(224, 227, 231, 0.5)',
          popupIndicatorColor: '#606271',
          popupIndicatorBg: '#DFDFE3',
          profileAvatarBg: COLORS_LIGHT.primary[800],
          profileAvatarColor: COLORS_LIGHT.neutral.white,
          MuiInputBase: '#FFFFFF',
          MuiOutlinedInputBorder: '#E0E3E7',
          MuiChip: COLORS_LIGHT.neutral[500],
          MuiChipBg: COLORS_LIGHT.neutral.T8,
        },
      },
      typography: TYPOGRAPHY,
      components: COMPONENTS_LIGHT,
      palette: {
        mode: ThemeMode.Light,
        header: HEADER_LIGHT,
        primary: {
          main: '#095A7E',
          dark: '#074865',
        },
        secondary: {
          main: '#012B3B',
        },
        button: {
          pressed: '#063C55',
        },
        wizard: WIZARD_LIGHT,
        background: {
          default: '#F1F1F3',
          paper: '#FFFFFF',
        },
        input: {
          background: {
            gray: COLORS_LIGHT.neutral.T4,
          },
        },
        text: {
          primary: COLORS_LIGHT.neutral.black,
          secondary: COLORS_LIGHT.neutral[500],
        },
        action: {
          hoverOpacity: 0.4,
          disabledOpacity: 0.5,
          activatedOpacity: 0.6,
          disabledBackground: '#8DACBF',
          disabled: '#606271',
          hover: 'rgba(224,227,231,0.6)',
        },
        success: {
          main: '#4CAF50',
        },
        info: {
          main: '#03A9F4',
        },
        warning: {
          main: '#fa8800',
        },
        error: {
          main: COLORS_LIGHT.warning[400],
        },
        sankey: SANKEY_LIGHT,
        sankeyDetailPanel: SANKEY_DETAIL_PANEL_LIGHT,
        widget: WIDGET_LIGHT,
        ticketStatuses: STATUSES_COLOURS.light,
        snackbar: {
          background: {
            error: COLORS_LIGHT.warning[400],
            warning: COLORS_LIGHT.alert[300],
            info: COLORS_LIGHT.info[300],
            success: COLORS_LIGHT.success[300],
          },
        },
        dropDown: {
          background: {
            hover: '#F0F1F3',
          },
          color: {
            active: '#0086BE',
          },
          iconColor: {
            default: '#838385',
          },
        },
        grid: {
          background: {
            hover: '#F3F4F5',
          },
          filters: {
            icon: {
              color: '#606271',
              backgroundHover: '#DFDFE3',
            },
          },
        },
        bandwidthSlider: {
          trackColor: '#21B0EF',
          railColor: '#E0E3E7',
        },
        langComponent: {
          background: '#FFFFFF',
          active: '#095A7E',
        },
        colors: COLORS_LIGHT,
        modal: MODAL_LIGHT,
      },
      icons: OEM_ICONS_LIGHT,
    },
    dark: {
      custom: {
        headerHeight: 70,
        subHeaderHeight: 50,
        border: {
          default: `#54555A`,
          secondary: '#242529',
        },
        boxShadow: {
          menu: '0px 0px 20px rgba(34, 39, 57, 0.1)',
          dialog: `0px 4px 12px ${COLORS_LIGHT.neutral.T12}`,
        },
        colors: {
          label: COLORS_DARK.neutral[400],
          value: COLORS_DARK.neutral.white,
          success: '#079D5E',
          disabled: '#FFFFFF',
          avatar: '#606271',
          link: COLORS_DARK.primary[100],
          gridDisabledFilter: '#B0B1B1',
          modalHeader: '#2C2D33',
          docLink: COLORS_DARK.primary[300],
          white: '#FFFFFF',
          progressBar: '#44454A',
          popupIndicatorColor: '#FFFFFF',
          popupIndicatorBg: '#444649',
          profileAvatarBg: COLORS_DARK.primary[500],
          profileAvatarColor: COLORS_DARK.neutral.white,
          MuiInputBase: '#2C2D33',
          MuiOutlinedInputBorder: 'rgba(36, 37, 41, 0.5)',
          MuiChip: COLORS_DARK.neutral[100],
          MuiChipBg: COLORS_DARK.neutral.T12,
        },
      },
      typography: TYPOGRAPHY,
      components: COMPONENTS_DARK,
      palette: {
        mode: ThemeMode.Dark,
        header: HEADER_DARK,
        primary: {
          main: '#0086BE',
          dark: '#006B98',
        },
        secondary: {
          main: '#242529',
        },
        button: {
          pressed: '#005072',
        },
        wizard: WIZARD_DARK,
        background: {
          default: '#111114',
          paper: '#242529',
        },
        input: {
          background: {
            gray: COLORS_DARK.neutral.T4,
          },
        },
        text: {
          primary: COLORS_DARK.neutral.white,
          secondary: COLORS_DARK.neutral[400],
        },
        action: {
          hoverOpacity: 0.4,
          disabledOpacity: 0.5,
          activatedOpacity: 0.6,
          disabledBackground: '#8DACBF',
          disabled: 'rgba(255,255,255,0.3)',
          hover: 'rgba(224,227,231,0.6)',
        },
        success: {
          main: '#4CAF50',
        },
        info: {
          main: '#03A9F4',
        },
        warning: {
          main: '#fa8800',
        },
        error: {
          main: COLORS_LIGHT.warning[400],
        },
        sankey: SANKEY_DARK,
        sankeyDetailPanel: SANKEY_DETAIL_PANEL_DARK,
        widget: WIDGET_DARK,
        ticketStatuses: STATUSES_COLOURS.dark,
        snackbar: {
          background: {
            error: COLORS_DARK.warning[400],
            warning: COLORS_DARK.alert[400],
            info: COLORS_DARK.info[300],
            success: COLORS_DARK.success[300],
          },
        },
        dropDown: {
          background: {
            hover: '#38393F',
          },
          color: {
            active: '#21B0EF',
          },
          iconColor: {
            default: '#FFFFFF',
          },
        },
        grid: {
          background: {
            hover: '#2F3034',
          },
          filters: {
            icon: {
              color: '#FFFFFF',
              backgroundHover: '#444649',
            },
          },
        },
        bandwidthSlider: {
          trackColor: '#21B0EF',
          railColor: '#4A4B4F',
        },
        langComponent: {
          background: '#44454A',
          active: '#21B0EF',
        },
        colors: COLORS_DARK,
        modal: MODAL_DARK,
      },
      icons: OEM_ICONS_DARK,
    },
  },
  oemConfig: {
    pageTitle: DEFAULT_APP_TITLE,
    title: DEFAULT_TITLE,
  },
};
