import { Route } from 'app/enums';
import env from 'config/env.json';
import { UserManagerSettings } from 'oidc-client-ts';

const baseRedirectUrl = window.location.origin;

const {
  REACT_APP_PI_AUTH_PATH: authPath,
  REACT_APP_PI_CLIENT_ID: clientId,
  REACT_APP_PI_RESPONSE_TYPE: responseType,
  REACT_APP_PI_SCOPE: scope,
  REACT_APP_PI_REDIRECT_PATH: redirectRoute,
} = env;

const redirectUri = redirectRoute ? `${baseRedirectUrl}${redirectRoute}` : `${baseRedirectUrl}${Route.LoginRedirect}`;

export const PING_IDENTITY_CONFIGURATION: UserManagerSettings = {
  authority: `https://${authPath}/as/.well-known/openid-configuration`,
  client_id: clientId,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: `${baseRedirectUrl}${Route.LogoutRedirect}`, // Could be removed if we want to stay user at Ping Identity logout page
  response_type: responseType,
  revokeTokensOnSignout: true,
  revokeTokenTypes: ['refresh_token', 'access_token'],
  scope,
  metadata: {
    issuer: `https://${authPath}/as`,
    token_endpoint: `https://${authPath}/as/token`,
    authorization_endpoint: `https://${authPath}/as/authorize`,
    userinfo_endpoint: `https://${authPath}/as/userinfo`,
    end_session_endpoint: `https://${authPath}/as/signoff`,
    jwks_uri: `https://${authPath}/as/jwks`,
    revocation_endpoint: `https://${authPath}/as/revoke`,
  },
};
