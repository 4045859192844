import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useDispatchMultiple, useStore } from 'app/hooks';
import { LangNameSpace } from 'app/i18n';
import { OrganizationEntity } from 'app/interfaces/entity/organization';
import { sessionSelector } from 'app/store/session/selectors';
import { sessionReducers } from 'app/store/session/slice';
import classNames from 'classnames';
import { useRoles } from 'modules/auth/hooks';
import { organizationsSelector } from 'modules/organization/store/selectors';
import React, { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SearchInput } from 'shared/components/Form/SearchInput';
import { CropTextFormatter } from 'shared/components/Format';

import { useStyles } from './OrganizationMenu.styles';

const OrganizationMenuComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const organizations = useSelector(organizationsSelector.all);
  const { isChangeOrganizationAllowed } = useRoles();
  const selectedOrganizationId = useSelector(sessionSelector.organizationId);
  const dispatchMultiple = useDispatchMultiple();
  const { clearOnOrganizationChange } = useStore();
  const { t } = useTranslation(LangNameSpace.App);

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState<string>('');

  const selectedOrganization = useMemo(
    () => organizations.find((org) => org.identifier === selectedOrganizationId),
    [selectedOrganizationId, organizations]
  );
  const filteredList = useMemo(
    () =>
      organizations?.filter((org: OrganizationEntity) =>
        org?.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
      ),
    [organizations, search]
  );

  const list = search ? filteredList : organizations;

  if (!isChangeOrganizationAllowed) {
    return (
      <Box className={classNames(classes.root, classes.oneCompanyRoot)}>
        <Box className={classes.oneCompanyBlock}>
          <Box className={classes.buttonContainer}>
            <Typography className={classNames(classes.orgTitle, classes.oneCompanyOrgTitle)}>
              {t('components.Header.orgMenu.title')}
            </Typography>
            <Box className={classes.selected}>
              <CropTextFormatter title={selectedOrganization?.name}>
                <Box>{selectedOrganization?.name}</Box>
              </CropTextFormatter>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Button
        className={classes.button}
        disableRipple
        onClick={(event) => setAnchor(event.currentTarget)}
        data-testid="companyBtn"
      >
        <Box className={classes.buttonContainer}>
          <Typography className={classes.orgTitle}>{t('components.Header.orgMenu.title')}</Typography>
          <Box className={classes.selected}>
            <CropTextFormatter title={selectedOrganization?.name}>
              <Box>{selectedOrganization?.name}</Box>
            </CropTextFormatter>
            <KeyboardArrowDown className={classes.arrowIcon} />
          </Box>
        </Box>
      </Button>
      <Menu
        anchorEl={anchor}
        className={classes.menuContainer}
        keepMounted
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <Box className={classes.menu}>
          <Box className={classes.search}>
            <SearchInput
              onClear={() => setSearch('')}
              placeholder={t('components.Header.orgMenu.search.placeholder')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          {list?.length > 0 ? (
            <Box className={classes.list}>
              {list.map((organization) => (
                <MenuItem
                  key={organization.identifier}
                  value={organization.identifier}
                  data-testid="OrganizationMenuItem"
                  onClick={() => {
                    if (organization.identifier !== selectedOrganizationId) {
                      dispatchMultiple([
                        sessionReducers.setOrganizationId(organization.identifier),
                        sessionReducers.setOrgIdFromMenu(true),
                      ]);
                      clearOnOrganizationChange();
                      setAnchor(null);
                    }
                  }}
                  selected={organization.identifier === selectedOrganizationId}
                >
                  {organization.name}
                </MenuItem>
              ))}
            </Box>
          ) : (
            <Box className={classes.noMatchFound}>
              <Typography variant="subtitle1" color="text.secondary" pl={2}>
                {t('components.Header.orgMenu.search.noMatchFound')}
              </Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export const OrganizationMenu = memo(OrganizationMenuComponent);
