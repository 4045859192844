import * as Sentry from '@sentry/react';
import env from 'config/env.json';
import { createBrowserHistory } from 'history';

import { AppError, Environment } from './enums';

const history = createBrowserHistory();

export const initSentry = (): void => {
  if (env.REACT_APP_FRONTEND_ENVIRONMENT === Environment.Local) {
    return;
  }

  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    release: `${env.REACT_APP_FRONTEND_ENVIRONMENT}-${env.SENTRY_RELEASE_VERSION}`,
    replaysSessionSampleRate: env.REACT_APP_FRONTEND_ENVIRONMENT === Environment.Production ? 0.1 : 1.0,
    integrations: [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        mask: ['#password'],
      }),
    ],
    environment: env.REACT_APP_FRONTEND_ENVIRONMENT,
    beforeSend: (event, hint) => {
      const isChunkLoadError = (hint?.originalException as Error)?.name === AppError.ChunkLoadError;
      const isLocalhost = event.request?.url?.includes('://localhost');

      if (isChunkLoadError || isLocalhost) {
        return null;
      }

      return event;
    },
  });
};
