import { UserEntity } from 'app/interfaces/entity/user';
import {
  UserChangePasswordRequest,
  UserCreateRequest,
  UserUpdateRequest,
} from 'modules/admin/user-management/store/users/interfaces';
import { UserCreateDto, UserDto, UserUpdateDto } from 'modules/admin/user-management/store/users/interfaces/dto';
import { UserChangePasswordDto } from 'modules/admin/user-management/store/users/interfaces/dto/user-change-password.dto';
import { safePick } from 'shared/utils/safe-pick';

export const dtoToEntityMapper = (data: UserDto): UserEntity => {
  return {
    ...data,
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    roles: data.roles,
    phone: data.phone ?? '',
    timezone: data.timezone ?? '',
    population: data.population,
    contactPreferences: data.contactPreferences,
  };
};

export const createRequestToDtoMapper = (data: UserCreateRequest): UserCreateDto => {
  return {
    ...data,
    phone: data.phone || null,
    timezone: data.timezone || null,
  };
};

export const updateRequestToDtoMapper = (data: Partial<UserUpdateRequest>): Partial<UserUpdateDto> => {
  return {
    ...safePick(data, [
      'firstName',
      'lastName',
      'roles',
      'email',
      'photoUrl',
      'phone',
      'timezone',
      'contactPreferences',
    ]),
  };
};

export const changePasswordRequestToDtoMapper = (data: UserChangePasswordRequest): UserChangePasswordDto => {
  return {
    ...safePick(data, ['currentPassword']),
    newPassword: data.newPassword,
  };
};
