import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { SortDirection } from 'app/enums';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import {
  DashboardEntity,
  DashboardGlobalEntity,
  WidgetAlertEntity,
  WidgetAlertSettingsEntity,
  WidgetBigNumberEntity,
  WidgetGaugeEntity,
  WidgetLineEntity,
  WidgetPieChartEntity,
  WidgetTableEntity,
} from 'app/interfaces/entity/dashboard';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { entityStoreStatus } from 'app/utils/store';
import { isArray } from 'lodash';
import { AlertsGridField, DashboardType } from 'modules/monitoring/enums';
import {
  AlertWidgetRequest,
  DashboardClientState,
  DashboardGlobalState,
  WidgetRequest,
} from 'modules/monitoring/store/interfaces';
import {
  DashboardDto,
  WidgetAlertDto,
  WidgetAlertSettingsDto,
  WidgetBigNumberDto,
  WidgetLineDto,
  WidgetPieChartDto,
  WidgetTableDto,
} from 'modules/monitoring/store/interfaces/dto';
import { WidgetGaugeDto } from 'modules/monitoring/store/interfaces/dto/widget-gauge.dto';
import { getSortString, makeAlertFilters } from 'modules/monitoring/utils';
import { prepareGlobalDashboards } from 'modules/monitoring/utils/prepare-global-dashboards';

import { entityAdapter, initialGlobalState } from './adapter';
import {
  dashboardDtoToEntityMapper,
  widgetAlertDtoToEntityMapper,
  widgetAlertSettingsDtoToEntityMapper,
  widgetBigNumberDtoToEntityMapper,
  widgetGaugeDtoToEntityMapper,
  widgetLineDtoToEntityMapper,
  widgetPieChartDtoToEntityMapper,
  widgetTableDtoToEntityMapper,
} from './dto-mappers';

export const clearAllClientReducer = (state: DashboardClientState) => {
  entityAdapter.removeAll(state);
};

export const clearGlobalReducer = (state: DashboardGlobalState): void => {
  state.entity = initialGlobalState.entity;
  state.empty = true;
};

export const getClientDashboardsList = createAsyncThunk(
  `${EntityStoreName.ClientDashboards}/getList`,
  (_, { signal }): Promise<DashboardEntity[]> => {
    const params = new URLSearchParams({ type: DashboardType.Client });

    return apiRequestCall<DashboardDto[], DashboardEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        params,
        signal,
        cache: false,
      },
    }).then((data) => data.map(dashboardDtoToEntityMapper(DashboardType.Client)));
  }
);

export const getGlobalDashboardsList = createAsyncThunk(
  `${EntityStoreName.GlobalDashboards}/getList`,
  (_, { signal }): Promise<Record<string, DashboardGlobalEntity[]>> => {
    const params = new URLSearchParams({ type: DashboardType.Global });

    return apiRequestCall<DashboardDto[], DashboardEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        params,
        signal,
      },
    }).then(prepareGlobalDashboards);
  }
);

export const getLineWidget = createAsyncThunk(
  `dashboards/getLineWidget`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetLineEntity> => {
    return apiRequestCall<WidgetLineDto, WidgetLineEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetLineDtoToEntityMapper);
  }
);

export const getTableWidget = createAsyncThunk(
  `dashboards/getTableWidget`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetTableEntity> => {
    return apiRequestCall<WidgetTableDto, WidgetTableEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetTableDtoToEntityMapper);
  }
);

export const getBigNumberWidget = createAsyncThunk(
  `dashboards/getBigNumberWidget`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetBigNumberEntity> => {
    return apiRequestCall<WidgetBigNumberDto, WidgetBigNumberEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetBigNumberDtoToEntityMapper);
  }
);

export const getGaugeWidget = createAsyncThunk(
  `dashboards/getGaugeWidget`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetGaugeEntity> => {
    return apiRequestCall<WidgetGaugeDto, WidgetGaugeEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetGaugeDtoToEntityMapper);
  }
);

export const getPieChartWidget = createAsyncThunk(
  `dashboards/getPieChartWidget`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetPieChartEntity> => {
    return apiRequestCall<WidgetPieChartDto, WidgetPieChartEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetPieChartDtoToEntityMapper);
  }
);

export const getAlertWidget = createAsyncThunk(
  `dashboards/getAlertWidget`,
  (
    {
      identifier,
      dashboardIdentifier,
      paginationModel,
      sortModel,
      customColumns: customColumnsRaw,
      filters,
      start,
      end,
      format,
      onSuccess,
      onError,
    }: AlertWidgetRequest,
    { signal }
  ): Promise<WidgetAlertEntity> => {
    const limit = paginationModel.pageSize;
    const offset = paginationModel.page * limit;
    const sort = sortModel?.length
      ? encodeURIComponent(
          sortModel
            .map((e) => {
              let fieldName = e.field;
              if (fieldName === 'firstColumn') {
                fieldName = AlertsGridField.Severity;
              }

              return getSortString(fieldName, e.sort as SortDirection);
            })
            .join(',')
        )
      : undefined;

    const filter = makeAlertFilters(filters);
    const customColumns =
      isArray(customColumnsRaw) && customColumnsRaw?.length
        ? encodeURIComponent(customColumnsRaw.join(','))
        : undefined;

    return apiRequestCall<WidgetAlertDto, WidgetAlertEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}`,
        params: {
          customColumns,
          filter,
          needMessage: true,
          size: limit,
          offset,
          sort,
          start,
          end,
          format,
        },
        signal,
        cache: false,
      },
      handlers: { success: onSuccess, error: onError },
    }).then(widgetAlertDtoToEntityMapper);
  }
);

export const getAlertWidgetSettings = createAsyncThunk(
  `dashboards/getAlertWidgetSettings`,
  (
    { identifier, dashboardIdentifier, start, end, format, time }: WidgetRequest,
    { signal }
  ): Promise<WidgetAlertSettingsEntity> => {
    return apiRequestCall<WidgetAlertSettingsDto, WidgetAlertSettingsEntity>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Dashboards,
        urlPrefix: `${dashboardIdentifier}/${ApiResource.Widgets}/${identifier}/settings`,
        params: { start, end, format, time },
        signal,
        cache: false,
      },
    }).then(widgetAlertSettingsDtoToEntityMapper);
  }
);

export const registerClientReducers = (builder: ActionReducerMapBuilder<DashboardClientState>): void => {
  builder
    .addCase(getClientDashboardsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getClientDashboardsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getClientDashboardsList.rejected, (state, action) => {
      entityAdapter.setAll(state, []);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};

export const registerGlobalReducers = (builder: ActionReducerMapBuilder<DashboardGlobalState>): void => {
  builder
    .addCase(getGlobalDashboardsList.pending, (state, action) => {
      state.empty = true;
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getGlobalDashboardsList.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.empty = false;
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getGlobalDashboardsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
