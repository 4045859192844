import { EntityStoreName } from 'app/enums/store';
import { Identifier } from 'app/interfaces';
import { CloudConnectionEntity } from 'app/interfaces/entity/cloud-connection';
import { AppState, createAppSelector } from 'app/store';
import { sessionSelector } from 'app/store/session/selectors';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { entityAdapter } from './adapter';

const userState = (state: AppState) => state[EntityStoreName.CloudConnections];

const selectAll = entityAdapter.getSelectors<AppState>((state) => state[EntityStoreName.CloudConnections]).selectAll;

const selectOne = (connectionId: Identifier) =>
  createAppSelector(userState, (state) => entityAdapter.getSelectors().selectById(state, String(connectionId)));

const selectGroupedListFactory = (
  groupKey: keyof CloudConnectionEntity
): ((app: AppState) => Record<string, CloudConnectionEntity[]>) =>
  createAppSelector(selectAll, (cloudConnections: CloudConnectionEntity[]) => {
    if (!cloudConnections) {
      return {};
    }

    return groupBy(cloudConnections, groupKey) as Record<string, CloudConnectionEntity[]>;
  });

const selectAllByLocation = createAppSelector(selectAll, sessionSelector.locationId, (cloudConnections, locationId) => {
  if (!locationId) {
    return [];
  }

  return (
    cloudConnections.filter((cloudConnection: CloudConnectionEntity) => {
      if (cloudConnection?.factionLocations?.length === 2) {
        return (cloudConnection?.factionLocations).find((loc) => loc === locationId);
      }

      return cloudConnection?.factionLocationId === locationId;
    }) as CloudConnectionEntity[]
  ).sort((connectionA, connectionB) => connectionA.provider?.localeCompare(connectionB.provider));
});

const selectGroupedListByLocationFactory = (
  groupKey: keyof CloudConnectionEntity
): ((app: AppState) => Record<string, CloudConnectionEntity[]>) =>
  createAppSelector(selectAllByLocation, (cloudConnections: CloudConnectionEntity[]) => {
    return groupBy(cloudConnections, groupKey) as Record<string, CloudConnectionEntity[]>;
  });

const selectIsEmpty = createAppSelector(
  (state: AppState) => state[EntityStoreName.CloudConnections],
  (cloudConnections) => cloudConnections.ids !== null && cloudConnections.ids.length === 0
);

export const cloudConnectionsSelector = {
  one: selectOne,
  all: selectAll,
  allByLocation: selectAllByLocation,
  isEmpty: selectIsEmpty,
};

export const cloudConnectionsSelectorFactory = {
  useSelectGroupedList: (groupKey: keyof CloudConnectionEntity) =>
    useMemo(() => selectGroupedListFactory(groupKey), [groupKey]),
  useSelectGroupedListByLocation: (groupKey: keyof CloudConnectionEntity) =>
    useMemo(() => selectGroupedListByLocationFactory(groupKey), [groupKey]),
};
