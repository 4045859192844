import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SystemProps } from '@mui/system';
import React, { FC, ReactNode } from 'react';
import { ValueOrDefaultFormat } from 'shared/components/Format';

import { useStyles } from './LabeledText.styles';

export type LabeledTextProps =
  | (SystemProps<Theme> & {
      label: string;
      value: string;
      fontWeight?: number;
    })
  | (SystemProps<Theme> & {
      label: string;
      value?: string;
      children: ReactNode;
      fontWeight?: number;
    });

export const LabeledText: FC<React.PropsWithChildren<LabeledTextProps>> = ({
  label,
  value,
  children,
  fontWeight = 500,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography className={classes.label} variant="subtitle1" color="textSecondary">
        <ValueOrDefaultFormat value={label} />
      </Typography>
      <Typography className={classes.value} variant="body1" fontWeight={fontWeight} {...props}>
        {value || children}
      </Typography>
    </>
  );
};
