import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityStoreName, EntityStoreRequestType } from 'app/enums/store';
import { OrganizationEntity } from 'app/interfaces/entity/organization';
import { apiRequestCall } from 'app/services/api';
import { ApiResource, ApiVersion } from 'app/services/api/enums';
import { AppState } from 'app/store';
import { sessionReducers } from 'app/store/session/slice';
import { entityStoreStatus } from 'app/utils/store';
import { OrganizationDto } from 'modules/organization/store/interfaces/dto';

import { entityAdapter } from './adapter';
import { dtoToEntityMapper } from './dto-mappers';
import { OrganizationsState } from './interfaces/organizations-state';

export const getOrganizationsList = createAsyncThunk(
  `${EntityStoreName.Organizations}/getList`,
  (_, { signal, getState, dispatch }): Promise<OrganizationEntity[]> => {
    return apiRequestCall<OrganizationDto[], OrganizationEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        signal,
      },
    }).then((organizations) => {
      const selectedId = (getState() as AppState).session.organizationId ?? '';
      const sortedOrganizations = organizations.sort((a, b) => a.name.localeCompare(b.name));
      const { identifier: firstFromList } = sortedOrganizations[0];
      const hasCurrentInList = sortedOrganizations.map((organization) => organization.identifier).includes(selectedId);

      if (firstFromList && (!selectedId || (selectedId && !hasCurrentInList))) {
        dispatch(sessionReducers.setOrganizationId(firstFromList));
      }

      return sortedOrganizations.map(dtoToEntityMapper);
    });
  }
);

export const getApiCredentialsList = createAsyncThunk(
  `${EntityStoreName.Organizations}/getList`,
  (_, { signal, getState, dispatch }): Promise<OrganizationEntity[]> => {
    return apiRequestCall<OrganizationDto[], OrganizationEntity[]>({
      params: {
        method: 'GET',
        apiVersion: ApiVersion.V1,
        resource: ApiResource.Organizations,
        signal,
      },
    }).then((organizations) => {
      const selectedId = (getState() as AppState).session.organizationId ?? '';
      const sortedOrganizations = organizations.sort((a, b) => a.name.localeCompare(b.name));
      const { identifier: firstFromList } = sortedOrganizations[0];
      const hasCurrentInList = sortedOrganizations.map((organization) => organization.identifier).includes(selectedId);

      if (firstFromList && (!selectedId || (selectedId && !hasCurrentInList))) {
        dispatch(sessionReducers.setOrganizationId(firstFromList));
      }

      return sortedOrganizations.map(dtoToEntityMapper);
    });
  }
);

export const clearAllReducer = (state: OrganizationsState): void => {
  entityAdapter.removeAll(state);
};

export const registerReducers = (builder: ActionReducerMapBuilder<OrganizationsState>): void => {
  builder
    .addCase(getOrganizationsList.pending, (state, action) => {
      entityStoreStatus.addRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getOrganizationsList.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    })
    .addCase(getOrganizationsList.rejected, (state, action) => {
      entityStoreStatus.removeRequestId(state, EntityStoreRequestType.Loading, action.meta.requestId);
    });
};
